import React, { useReducer } from 'react';
import Modal from 'atoms/Modal';
import AddCreditCardForm from 'organisms/AddCreditCardForm';
import PaymentForm from './PaymentForm';

const PaymentModal = ({ campaign, onClose, onPayment }) => {
    const [showCardForm, toggleShowCardForm] = useReducer(state => !state, false);

    return (
        <Modal onClose={onClose}>
            {showCardForm ? (
                <AddCreditCardForm onCancel={toggleShowCardForm} onSave={toggleShowCardForm} />
            ) : (
                <PaymentForm
                    onAddNewCreditCard={toggleShowCardForm}
                    onCancel={onClose}
                    onPayment={onPayment}
                    campaign={campaign}
                />
            )}
        </Modal>
    );
};

export default PaymentModal;
