import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Wizard from 'components/Wizard';
import { Creators as ActionsCreators } from 'store/modules/creative';
import Templates from './Steps/Templates';
import Comercial from './Steps/Comercial';
import Offers from './Steps/Offers';
import MoreInfo from './Steps/MoreInfo';
import Summary from './Steps/Summary';
import Payment from './Steps/Payment';
import { useActiveStep } from './hooks';

const Create = () => {
    const step = useActiveStep();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(ActionsCreators.setNewCreative());
        dispatch(ActionsCreators.setStep(0));
        return () => {
            dispatch(ActionsCreators.setNewCreative());
            dispatch(ActionsCreators.setStep(0));
        };
    }, [dispatch]);

    return (
        <div className="tw-w-full">
            <Wizard step={step}>
                <Wizard.Step title="Templates">
                    <Templates />
                </Wizard.Step>
                <Wizard.Step title="Comercial">
                    <Comercial />
                </Wizard.Step>
                <Wizard.Step title="Ofertas">
                    <Offers />
                </Wizard.Step>
                <Wizard.Step title="Mais Informações">
                    <MoreInfo />
                </Wizard.Step>
                <Wizard.Step title="Resumo">
                    <Summary />
                </Wizard.Step>
                <Wizard.Step title="Pagamento">
                    <Payment />
                </Wizard.Step>
            </Wizard>
        </div>
    );
};

export default Create;
