import { useCurrentCampaign } from 'hooks/campaign';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import schema from './schema';

const useMediaCentralForm = () => {
    const dispatch = useDispatch();

    const { mediaCentral } = useCurrentCampaign();

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            selectedTVPrograms: mediaCentral.selectedTVPrograms || [],
            selectedRadioPrograms: mediaCentral.selectedRadioPrograms || [],
        },
    });

    const onSubmit = values => {
        dispatch(CampaignCreators.addMediaCentral(values));
        dispatch(CampaignCreators.unlockStep('mediaCentral'));
        dispatch(CampaignCreators.nextStep());
    };

    return { ...methods, handleSubmit: methods.handleSubmit(onSubmit) };
};

export default useMediaCentralForm;
