import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { capitalize } from 'utils';
import * as Sentry from '@sentry/react';
import NoMatchRoute from 'routes/NoMatchRoute';
import Dashboard from 'pages/advertiser/Dashboard';
import RegisterCampaignPage from 'pages/advertiser/Campaign/Create';
import ListCampaign from 'pages/advertiser/Campaign/List';
import DetailCampaign from 'pages/advertiser/Campaign/Detail';
import Wallet from 'pages/advertiser/Profile/wallet';
import CreativeCreate from 'pages/advertiser/Creative/Create';
import CreativeList from 'pages/advertiser/Creative/List';
import CreativeDetails from 'pages/advertiser/Creative/Details';
import BuyPackage from 'pages/advertiser/Dashboard/PackagesShop/BuyPackage';
import Profile from 'pages/shared/Profile';
import PageWrapper from 'components/PageWrapper';

const SentryRoute = Sentry.withSentryRouting(Route);

const CampaignsRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <SentryRoute
                exact
                path={`${path}/criar`}
                render={routeProperties => {
                    const query = new URLSearchParams(routeProperties.location.search);
                    const campaignVehicleType = query.get('tipo');
                    const title = campaignVehicleType
                        ? `Nova Campanha |  ${
                              campaignVehicleType.length > 2 ? capitalize(campaignVehicleType) : campaignVehicleType
                          }`
                        : 'Nova Campanha';
                    return (
                        <PageWrapper pageTitle={title}>
                            <RegisterCampaignPage {...routeProperties} />
                        </PageWrapper>
                    );
                }}
            />
            <SentryRoute exact path={`${path}/:id`} component={DetailCampaign} />
            <SentryRoute
                exact
                path={path}
                render={routeProperties => (
                    <PageWrapper pageTitle="Minhas Campanhas">
                        <ListCampaign {...routeProperties} />
                    </PageWrapper>
                )}
            />
        </Switch>
    );
};

const CentralCreativeRoutes = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <SentryRoute
                exact
                path={`${path}/:id/detalhes`}
                render={routeProperties => (
                    <PageWrapper pageTitle="Detalhes do Criativo">
                        <CreativeDetails {...routeProperties} />
                    </PageWrapper>
                )}
            />
            <SentryRoute
                exact
                path={`${path}/criar`}
                render={routeProperties => (
                    <PageWrapper pageTitle="Novo Criativo">
                        <CreativeCreate {...routeProperties} />
                    </PageWrapper>
                )}
            />

            <SentryRoute
                exact
                path={`${path}`}
                render={routeProperties => (
                    <PageWrapper pageTitle="Central de Criativo">
                        <CreativeList {...routeProperties} />
                    </PageWrapper>
                )}
            />
        </Switch>
    );
};

const AdvertiserRoutes = () => (
    <Switch>
        <SentryRoute
            exact
            path="/"
            render={routeProperties => (
                <PageWrapper pageTitle="Dashboard">
                    <Dashboard {...routeProperties} />
                </PageWrapper>
            )}
        />
        <SentryRoute
            exact
            path="/perfil"
            render={routeProperties => (
                <PageWrapper pageTitle="Meu Perfil">
                    <Profile {...routeProperties} />
                </PageWrapper>
            )}
        />

        <Route path="/campanhas">
            <CampaignsRoutes />
        </Route>

        <SentryRoute path="/campanha/:id" component={DetailCampaign} />
        <SentryRoute exact path="/perfil/carteira" component={Wallet} />
        <SentryRoute
            exact
            path="/central-criativa/:id/detalhes"
            render={routeProperties => (
                <PageWrapper pageTitle="Detalhes do Criativo">
                    <CreativeDetails {...routeProperties} />
                </PageWrapper>
            )}
        />

        <SentryRoute
            exact
            path="/comprar-pacote"
            render={routeProperties => (
                <PageWrapper pageTitle="Comprar Pacote">
                    <BuyPackage {...routeProperties} />
                </PageWrapper>
            )}
        />

        <Route path="/central-criativa">
            <CentralCreativeRoutes />
        </Route>

        <SentryRoute exact path="/perfil/carteira" component={Wallet} />

        <SentryRoute path="*">
            <NoMatchRoute />
        </SentryRoute>
    </Switch>
);

export default AdvertiserRoutes;
