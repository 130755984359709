import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Loading from 'react-fullscreen-loading';
import { auth } from 'base';

import yup from 'validator';
import Input from 'molecules/InputField';

const schema = yup.object().shape({
    password: yup.string().defined().required().min(6).label('Senha'),
    confirmPassword: yup
        .string()
        .defined()
        .required()
        .min(6)
        .oneOf([yup.ref('password'), null], 'As senhas são diferentes')
        .label('Confirmar senha'),
});

function Profile() {
    const [loading, setLoading] = useState(false);

    const { handleSubmit, register, errors } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const { push } = useHistory();

    const onSubmit = ({ password }) => {
        setLoading(true);

        function onSuccess() {
            toast.success('Senha atualizada com sucesso!');
            setLoading(false);
        }

        function onFailure() {
            toast.error('Falha ao atualizar a senha, refaça o login e tente novamente!');
            setLoading(false);
        }

        auth.currentUser
            .updatePassword(password)
            .then(() => onSuccess())
            .catch(() => onFailure());
    };

    return (
        <>
            {loading && <Loading />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="tw-flex tw-w-full tw-flex-col tw-max-w-md">
                    <Input
                        label="Senha"
                        name="password"
                        className="tw-w-full tw-mt-4 md:tw-mt-0"
                        type="password"
                        error={errors.password}
                        ref={register}
                    />
                    <Input
                        label="Confirmar senha"
                        name="confirmPassword"
                        className="tw-w-full tw-mt-4 md:tw-mt-4"
                        type="password"
                        error={errors.confirmPassword}
                        ref={register}
                    />
                </div>
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-mt-14">
                    <button className="button button-primary-outlined tw-block" type="button" onClick={() => push('/')}>
                        Cancelar
                    </button>
                    <button type="submit" className="button button-primary tw-mt-4 md:tw-mt-0 tw-block md:tw-ml-4">
                        {loading ? 'Salvando...' : 'Salvar Alterações'}
                    </button>
                </div>
            </form>
        </>
    );
}

export default Profile;
