import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import authReducer from './auth';
import userReducer from './user';
import programReducer from './program';
import companyReducer from './company';
import campaignsReducer from './campaign';
import discountsReducer from './discounts';
import creativeReducer from './creative';
import couponReducer from './coupons';
import regionsReducer from './regions';
import packagesReducer from './packages';

export default combineReducers({
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    authReducer,
    userReducer,
    programReducer,
    companyReducer,
    discountsReducer,
    campaignsReducer,
    creative: creativeReducer,
    couponReducer,
    regionsReducer,
    packagesReducer,
});
