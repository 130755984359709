import React, { memo } from 'react';
import PropTypes from 'prop-types';

const UserIcon = ({ className = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className={`icon ${className}`}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
        />
    </svg>
);

UserIcon.propTypes = {
    className: PropTypes.string,
};

export default memo(UserIcon);
