/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete } from '@material-ui/lab';
import { Close as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Button, FormLabel, Grid, Slider, TextField } from '@material-ui/core';
import { toBase64 } from 'utils';
import states from 'utils/states';
import { setNewProgram, setProgram } from 'store/modules/program';
import { getCities } from 'src/services/ibge';
import { useProgram } from 'hooks/program';
import { useUserCompany } from 'hooks/company';
import UploadInput from 'components/UploadInput';
import MaterialInputCurrency from 'components/Currency/Material';
import schema from './schema';

const useStyles = makeStyles(() => ({
    bannerContainer: {
        position: 'relative',
        marginTop: 32,
        width: 160,
        height: 160,
        ['& > img']: {
            backgroundColor: '#ccc',
            borderRadius: 5,
            width: '100%',
        },
    },
    removeBanner: {
        position: 'absolute',
        top: -15,
        right: -15,
    },
    buttons: {
        marginTop: 40,
    },
}));

const mediaTypes = ['TV', 'RADIO'];
function mapCity(city) {
    return {
        id: city.id,
        nome: city.nome,
        mesorregiao: {
            nome: city.microrregiao.mesorregiao.nome,
            id: city.microrregiao.mesorregiao.id,
        },
    };
}

function BaseInfo({ handleNext }) {
    const [cities, setCities] = useState([]);
    const [programMesoRegiao, setProgramMesoRegiao] = useState(null);
    const [banner, setBanner] = useState('');
    const [ageRange, setAgeRange] = useState([0, 100]);

    const dispatch = useDispatch();
    const history = useHistory();
    const program = useProgram();
    const company = useUserCompany();
    const classes = useStyles();

    const { control, register, errors, setValue, getValues, handleSubmit, reset, formState } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            programType: '',
            programCity: '',
            programState: '',
            cities: [],
        },
    });
    const { isSubmitting } = formState;

    const loadCities = useCallback(async state => {
        const response = await getCities(state);
        const citiesMap = response.data.map(city => mapCity(city));
        setCities(citiesMap);
    }, []);

    const handleStateChange = useCallback(
        async state => {
            try {
                loadCities(state.value);
                setValue('programState', state);
            } catch {
                toast.error('Falha ao carregar cidades');
            }
        },
        [setValue, loadCities],
    );

    function handleCityChanged(cityName) {
        setProgramMesoRegiao(cities.find(item => item.nome === cityName)?.mesorregiao || program.mesorregiao);
        setValue('programCity', cityName || null);
    }

    const handleAgeRangeChange = (event, newValue) => setAgeRange(newValue);

    const getAgeRangeLabel = useCallback(() => {
        const [minAge, maxAge] = ageRange;
        return `${minAge} a ${maxAge} anos`;
    }, [ageRange]);

    const handleFileChange = useCallback(async event => {
        const file = event.target?.files[0];
        if (file) {
            const image = await toBase64(file);
            setBanner(image);
        }
    }, []);

    const onSubmit = useCallback(
        values => {
            if (!banner) {
                toast.error('Selecione um banner para o programa');
                return;
            }

            const stateValue = states.find(item => item.label === values.programState)?.value;

            dispatch(
                setProgram({
                    ...values,
                    programMesoRegiao,
                    cities,
                    url: banner,
                    programState: stateValue,
                    ageRange: ageRange.join(','),
                }),
            );

            handleNext();
        },
        [banner, dispatch, cities, ageRange, handleNext, programMesoRegiao],
    );

    const handleNewProgram = useCallback(() => {
        dispatch(setNewProgram());
        reset({
            programName: '',
            availableSeconds: '',
            programType: company.type,
            programCity: '',
            programState: '',
            insertionDiscount: '',
        });
        setBanner('');
        setProgramMesoRegiao(null);
        setAgeRange([0, 100]);
        history.push('/programa/criar/novo');
    }, [dispatch, reset, history, company]);

    useEffect(() => {
        for (const name of Object.keys(errors)) {
            toast.error(errors[name].message);
        }
    }, [errors]);

    useEffect(() => {
        if (program.ageRange) {
            const [minAge, maxAge] = program.ageRange.split(',');
            setAgeRange([Number(minAge), Number(maxAge)]);
        }

        if (program.url) {
            setBanner(program.url);
        }
    }, [program]);

    useEffect(() => {
        setValue('programType', company.type || 'TV');
    }, [company, setValue]);

    useEffect(() => {
        if (program.programState) {
            loadCities(program.programState);
        }
    }, [loadCities, program]);

    useEffect(() => {
        if (program?.programCity) {
            setProgramMesoRegiao(
                cities.find(item => item.nome === program.programCity)?.mesorregiao || program.mesorregiao,
            );
        }
    }, [program, cities]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel component="legend">Faixa etária do público *</FormLabel>
                    <Slider
                        value={ageRange}
                        onChange={handleAgeRangeChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                    />
                    <span>{getAgeRangeLabel()}</span>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="programName"
                        defaultValue={program.programName || ''}
                        inputRef={register}
                        label="Nome do programa"
                        size="medium"
                        margin="dense"
                        fullWidth
                        disabled={isSubmitting}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="availableSeconds"
                        defaultValue={program.availableSeconds || ''}
                        inputRef={register}
                        label="Segundos disponíveis"
                        size="medium"
                        margin="dense"
                        type="number"
                        fullWidth
                        disabled={isSubmitting}
                    />
                </Grid>
                <Grid item xs={2}>
                    <MaterialInputCurrency
                        name="insertionCost"
                        defaultValue={program.insertionCost}
                        control={control}
                        getValues={getValues}
                        size="medium"
                        margin="dense"
                        fullWidth
                        disabled={isSubmitting}
                        label="Valor de 30s"
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        name="insertionDiscount"
                        defaultValue={program.insertionDiscount || ''}
                        inputRef={register}
                        label="Desconto (%)"
                        size="medium"
                        margin="dense"
                        type="number"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        options={states}
                        getOptionLabel={option => option.label}
                        fullWidth
                        defaultValue={states.find(state => state.value === program.programState)}
                        noOptionsText="Nenhum estado com este nome"
                        onChange={(_, newValue) => handleStateChange(newValue)}
                        renderInput={parameters => (
                            <TextField
                                {...parameters}
                                label="Selecione o estado"
                                margin="normal"
                                name="programState"
                                inputRef={register}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        options={cities.map(city => city.nome)}
                        fullWidth
                        defaultValue={program.programCity}
                        noOptionsText="Selecione um estado"
                        onChange={(_, newValue) => handleCityChanged(newValue)}
                        renderInput={parameters => (
                            <TextField
                                {...parameters}
                                label="Cidade em que será exibido"
                                margin="normal"
                                name="programCity"
                                inputRef={register}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        name="programType"
                        control={control}
                        defaultValue={company.type}
                        render={({ onChange, onBlur, value, name }) => (
                            <Autocomplete
                                options={mediaTypes}
                                disabled={isSubmitting}
                                fullWidth
                                noOptionsText="Nenhuma opção encontrada"
                                id={name}
                                name={name}
                                value={value}
                                onChange={(event, newValue) => onChange(newValue)}
                                onBlur={onBlur}
                                renderInput={parameters => (
                                    <TextField {...parameters} label="Selecione o tipo do programa" margin="normal" />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <UploadInput label="Selecionar banner do programa" handleFileChange={handleFileChange} />
                    {banner && (
                        <div className={classes.bannerContainer}>
                            <IconButton className={classes.removeBanner} onClick={() => setBanner('')}>
                                <DeleteIcon color="secondary" fontSize="large" />
                            </IconButton>
                            <img src={banner} alt="banner" />
                        </div>
                    )}
                </Grid>
            </Grid>
            <div className={classes.buttons}>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={handleNewProgram}
                    disabled={isSubmitting}
                >
                    Novo Programa
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={isSubmitting}
                >
                    Próximo
                </Button>
            </div>
            {__DEVELOPMENT__ && <DevTool control={control} />}
        </form>
    );
}

export default BaseInfo;
