/* eslint-disable no-unused-vars */
import React, { forwardRef, memo } from 'react';
import NumberFormat from 'react-number-format';
import { currencyFormatter } from 'utils';

const InputCurrency = forwardRef(({ onChange, ...numberFormatOptions }, reference) => {
    function handleValueChange(value) {
        onChange(value.floatValue);
    }
    function handleRenderText(formattedValue) {
        return currencyFormatter.format(Number(formattedValue));
    }

    return (
        <NumberFormat
            type="tel"
            prefix="R$ "
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            fixedDecimalScale={true}
            renderText={handleRenderText}
            onValueChange={handleValueChange}
            getInputRef={nfReference => (reference = nfReference)}
            {...numberFormatOptions}
        />
    );
});
InputCurrency.displayName = 'InputCurrency';

export default memo(InputCurrency);
