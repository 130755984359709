import { createCoverageCity, getAllCoverageCities, getCoverageCity, updateCoverageCity } from 'api/coverageCities';
import { getProgramsReferenceByRegionId } from 'api/programs';
import { defaultConverter } from 'utils';
import { firebase } from 'base';

/**
 * Get coverage city snapshot
 * @param {string} state - Then state initials
 * @param {string} city - Then city name
 * @returns {Promise<null|firebase.firestore.DocumentSnapshot>}
 */
async function getCoveragedCity(state, city) {
    const coveragedCity = await getCoverageCity(state, city).withConverter(defaultConverter).get();

    if (coveragedCity.empty) {
        return null;
    }

    const [firstCity] = coveragedCity.docs;
    return firstCity.data();
}

/**
 * Remove program reference from coverage cities
 * @param {} cities
 * @param programReference
 * @returns {Promise<*>}
 */
export async function deleteProgramRefence(cities, programReference) {
    const allCities = await getAllCoverageCities().withConverter(defaultConverter).get();

    function byListedCities(coverageCityDocument) {
        const city = coverageCityDocument.data();
        return cities.includes(city.name);
    }

    return allCities.docs
        .filter(coverageCityDocument => byListedCities(coverageCityDocument))
        .map(coverageCityDocument => {
            return updateCoverageCity({
                id: coverageCityDocument.id,
                programsRefs: firebase.firestore.FieldValue.arrayRemove(programReference),
            });
        });
}

/**
 * Sync coverage cities
 * @param {string} state - The state
 * @param {Array<string>} cities - The cities
 * @param {firebase.firestore.DocumentReference} [programReference] - The program reference
 * @param {boolean} [ignoreDelete] - Should clean up all old cities references
 * @returns {Promise<void>}
 */
export async function syncCoverageCities(state, cities, programReference, ignoreDelete) {
    const syncCoverageCityPromises = cities.map(async city => {
        const coveragedCity = await getCoveragedCity(state, city);

        await (coveragedCity
            ? updateCoverageCity({
                  ...coveragedCity,
                  programsRefs: firebase.firestore.FieldValue.arrayUnion(programReference),
              })
            : createCoverageCity({
                  name: city,
                  state,
                  programsRefs: [programReference],
              }));
    });

    await Promise.all(syncCoverageCityPromises);

    if (!ignoreDelete) {
        await deleteProgramRefence(cities, programReference);
    }
}

export async function removeProgramReferences(state, cities, companyId) {
    const programReferencesToRemove = [];
    for await (const city of cities) {
        const coveragedCity = await getCoveragedCity(state, city);

        if (!coveragedCity || !coveragedCity.exists) {
            continue;
        }

        if (coveragedCity.programsRefs) {
            for await (const programReference of coveragedCity.programsRefs) {
                const program = await programReference.get();

                if (!program.exists) {
                    continue;
                }

                const isACompanyProgram = program.data().companyId === companyId;
                if (isACompanyProgram) {
                    programReferencesToRemove.push(programReference);
                }
            }
        }

        if (programReferencesToRemove?.length) {
            await updateCoverageCity({
                ...coveragedCity,
                programsRefs: firebase.firestore.FieldValue.arrayRemove(programReferencesToRemove),
            });
        }
    }
}

export async function syncCoveragedCitiesOnModifyRegion(state, cities, companyId, selectedCities, regionId) {
    await removeProgramReferences(state, cities, companyId);

    const programReferences = await getProgramsReferenceByRegionId(regionId);

    if (programReferences?.length) {
        for await (const programReference of programReferences) {
            await syncCoverageCities(state, selectedCities, programReference, true);
        }
    }
}
