import React, { forwardRef, memo } from 'react';
import { PropTypes } from 'prop-types';
import { Controller } from 'react-hook-form';

import InputCurrency from 'atoms/InputCurrency';

const InputFieldCurrencyFieldV2 = forwardRef(
    ({ label, name, error, className = '', inputClassName = '', control, defaultValue, ...rest }, reference) => {
        function handleChange(value) {
            control.setValue(name, value);
        }

        return (
            <div className={`form-control ${className}`}>
                <label className="tw-font-semibold" htmlFor={name}>
                    {label}
                </label>
                <div>
                    <Controller
                        control={control}
                        defaultValue={defaultValue}
                        name={name}
                        render={({ value }) => (
                            <InputCurrency
                                onChange={handleChange}
                                name={name}
                                value={value}
                                className={`${
                                    inputClassName ? inputClassName : ''
                                } tw-py-2 tw-px-0 tw-w-full tw-bg-transparent tw-border-b-2 tw-border-t-0 tw-border-r-0 tw-border-l-0`}
                                ref={reference}
                                {...rest}
                            />
                        )}
                    />
                </div>
                {error && <p className="error-message">{error.message}</p>}
            </div>
        );
    },
);

InputFieldCurrencyFieldV2.displayName = 'Input';

InputFieldCurrencyFieldV2.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
    }),
};

export default memo(InputFieldCurrencyFieldV2);
