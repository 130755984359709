import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import * as PropTypes from 'prop-types';
import UploadButton from 'components/UploadButton';

const UploadComercialImages = ({ label, name, uploadAction, defaultValue }) => {
    const { watch, setValue } = useFormContext();

    const filesUrl = watch(name, []);

    const handleUploadAction = useCallback(file => uploadAction(file), [uploadAction]);

    function handleRemove(event, index) {
        event.preventDefault();
        const filteredFiles = [...filesUrl];
        filteredFiles.splice(index, 1);
        setValue(name, filteredFiles);
    }

    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
        }
    }, [setValue, name, defaultValue]);

    return (
        <div className="tw-flex tw-flex-col tw-mb-6">
            <label htmlFor={name} className="tw-cursor-pointer">
                <span className="tw-block">{label}</span>
                <UploadButton
                    accept="image/*"
                    id={name}
                    name={name}
                    text="Selecione seu arquivo"
                    uploadAction={handleUploadAction}
                    multiple={true}
                />
            </label>
            {filesUrl.map((fileUrl, index) => (
                <div className="tw-flex tw-items-center tw-mt-4 tw-flex-wrap" key={fileUrl}>
                    <div className="tw-relative">
                        <img
                            src={fileUrl}
                            alt="image"
                            width="217"
                            height="152"
                            className="tw-ml-3 tw-mb-3 tw-rounded-md"
                        />
                        <button
                            className="tw-border-none tw-font-bold tw-bg-transparent tw-cursor-pointer tw-text-red tw-ml-3 tw-absolute tw-top-1 tw-right-2"
                            type="button"
                            onClick={event => handleRemove(event, index)}
                        >
                            x
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

UploadComercialImages.propTypes = {
    uploadAction: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default UploadComercialImages;
