import React, { useCallback, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useCurrentCampaign, usePrograms } from 'hooks/campaign';
import ProgramCard from './ProgramCard';

function hasProgram(program) {
    return sProgram => sProgram.id === program.id;
}

const DEFAULT_PROGRAMS = [];

function sortByProgramName(a, b) {
    if (a.programName < b.programName) {
        return -1;
    }
    if (a.programName > b.programName) {
        return 1;
    }
    return 0;
}

const ProgramSelector = ({ company }) => {
    const programs = usePrograms();
    const { campaignEditable } = useCurrentCampaign();
    const { formState, control, getValues, setValue } = useFormContext();
    const { errors } = formState;

    const isTVProgram = company.type === 'TV';
    const fieldName = isTVProgram ? 'selectedTVPrograms' : 'selectedRadioPrograms';

    const programsForSelectedCompany = useMemo(
        () => (company?.id ? programs.filter(program => program.companyId === company.id).sort(sortByProgramName) : []),
        [programs, company],
    );

    const handleUnselectProgram = useCallback(
        (program, selectedPrograms) => {
            setValue(
                fieldName,
                selectedPrograms.filter(selectedProgram => selectedProgram.id !== program.id),
            );
        },
        [fieldName, setValue],
    );
    const handleSelectProgram = useCallback(
        (program, programs) => setValue(fieldName, [...programs, program]),
        [fieldName, setValue],
    );

    return (
        <>
            <h3 className="tw-mt-6">Programas {company.name}</h3>
            {errors[fieldName] && <p className="error-message">{errors[fieldName].message}</p>}
            <Controller
                name={fieldName}
                control={control}
                defaultValue={getValues(fieldName) || DEFAULT_PROGRAMS}
                render={({ value: programs }) => (
                    <div className="tw-flex tw-flex-wrap tw-items-stretch tw-gap-4">
                        {programsForSelectedCompany.map(program => (
                            <ProgramCard
                                key={program.id}
                                program={program}
                                disabled={!campaignEditable}
                                isSelected={programs.some(hasProgram(program))}
                                onSelect={program => handleSelectProgram(program, programs)}
                                onUnselect={program => handleUnselectProgram(program, programs)}
                            />
                        ))}
                    </div>
                )}
            />
        </>
    );
};

export default ProgramSelector;
