import React from 'react';
import { FaPen, FaTimes } from 'react-icons/fa';

const RegionRow = ({ region, onEdit, onDelete }) => {
    function handleEdit(event) {
        event.preventDefault();
        onEdit(region);
    }

    function handleDelete(event) {
        event.preventDefault();
        onDelete(region);
    }

    return (
        <tr>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                <p>{region.regionName}</p>
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="left">
                {region.state}
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="left">
                {region.cities?.length || 0}
            </td>

            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="center">
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-center">
                    <FaPen size={18} color="#087A99" className="tw-cursor-pointer" onClick={handleEdit} />
                    <FaTimes size={22} color="#cd0e4b" className="tw-cursor-pointer tw-ml-3" onClick={handleDelete} />
                </div>
            </td>
        </tr>
    );
};
export default RegionRow;
