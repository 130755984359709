import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Loading from 'react-fullscreen-loading';
import { uploadUserProfilePhoto } from 'api/users';
import yup from 'validator';
import { firebase } from 'base';
import { useUser } from 'hooks/user';
import Input from 'molecules/InputField';
import { updateUser } from 'store/modules/user';
import CameraIcon from './CameraIcon';
import styleConfig from '../../../style_config.json';

const schema = yup.object().shape({
    displayName: yup.string().defined().required().label('Nome'),
    gender: yup.string().defined().oneOf(['male', 'female', 'other']).label('Gênero'),
    email: yup.string().defined().required().label('E-mail'),
    company: yup.string().defined().required().label('Empresa'),
    cnpj: yup.string().defined().required().label('CNPJ'),
    phone: yup.string().defined().required().label('Telefone'),
    hasAgency: yup.bool().defined().label('Possui agência?'),
    receiveEmails: yup.bool().defined().label('Receber informações'),
});

function General() {
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState('');
    const [tasksMap, setTasksMap] = useState(new Map());

    const { handleSubmit, register, errors, control, setValue } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const { push } = useHistory();
    const dispatch = useDispatch();

    const user = useUser();

    const onSubmit = values => {
        setLoading(true);

        function onSuccess() {
            toast.success('Dados salvos com sucesso!');
            setLoading(false);
        }

        function onFailure() {
            toast.error('Falha ao salvar dados!');
            setLoading(false);
        }

        dispatch(updateUser({ ...user, ...values, profilePhoto }, { onSuccess, onFailure }));
    };

    const handleChange = async event => {
        const file = event.target.files[0];
        if (file) {
            uploadFile(file);
        }
    };

    const uploadFile = useCallback(
        file => {
            setLoadingUpload(true);
            const fileHash = v4();
            const onUploadNext = snapshot => {
                const percentCompleted = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                const fileTaskInfo = tasksMap.get(fileHash);
                setTasksMap(
                    new Map(
                        tasksMap.set(fileHash, {
                            ...fileTaskInfo,
                            percentCompleted,
                        }),
                    ),
                );
            };
            const onUploadError = error => {
                setLoadingUpload(false);
                if (error) {
                    toast.error('Falha ao adicionar imagem!');
                }
                const fileTaskInfo = tasksMap.get(fileHash);
                setTasksMap(
                    new Map(
                        tasksMap.set(fileHash, {
                            ...fileTaskInfo,
                            error,
                        }),
                    ),
                );
            };
            const onUploadCompleted = () => {
                setLoadingUpload(false);
                const fileTaskInfo = tasksMap.get(fileHash);
                fileTaskInfo.uploadTask
                    .then(imageReference => imageReference.ref.getDownloadURL())
                    .then(downloadUrl => {
                        setTasksMap(
                            new Map(
                                tasksMap.set(fileHash, {
                                    ...fileTaskInfo,
                                    downloadUrl,
                                }),
                            ),
                        );
                        if (downloadUrl) {
                            setProfilePhoto(downloadUrl);
                        }
                    });
            };

            const uploadTask = uploadUserProfilePhoto(file);
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
                next: onUploadNext,
                error: onUploadError,
                complete: onUploadCompleted,
            });
            tasksMap.set(fileHash, {
                uploadTask: uploadTask,
            });
        },
        [tasksMap],
    );

    const handleSelectProfilePhoto = () => {
        const element = document.querySelector('#profilePhoto');
        if (element) element.click();
    };

    useEffect(() => {
        if (user) {
            register('email');
            setValue('email', user.email);

            if (user.profilePhoto) {
                setProfilePhoto(user.profilePhoto);
            }
        }
    }, [user, register, setValue]);

    const defaultImage = styleConfig['logo-only.svg'];

    return (
        <form className="tw-w-full" onSubmit={handleSubmit(onSubmit)}>
            <Loading loading={loadingUpload} background="rgba(0,0,0,0.1)" loaderColor="#E5195A" tex />

            <div className="tw-full tw-flex tw-flex-col md:tw-flex-row">
                <div className="tw-flex tw-w-full tw-justify-start md:tw-w-1/5 tw-flex-col tw-mt-5">
                    <div className="tw-relative tw-rounded-full tw-h-48 tw-w-48 tw-bg-gray-100">
                        <div className="tw-absolute tw--top-0 tw--right-0 tw-cursor-pointer">
                            <CameraIcon onClick={handleSelectProfilePhoto} />
                        </div>
                        <div className="tw-flex tw-justify-center tw-items-center tw-h-48 tw-w-48">
                            <img
                                src={profilePhoto || defaultImage}
                                alt="image"
                                className="tw-rounded-full tw-h-48 tw-w-48"
                            />
                        </div>
                        <input
                            id="profilePhoto"
                            type="file"
                            multiple
                            accept="image/x-png,image/gif,image/jpeg"
                            className="tw-hidden"
                            onChange={event => handleChange(event)}
                        />
                    </div>
                </div>
                <div className="tw-flex tw-w-full md:tw-w-1/3 tw-flex-col">
                    <Input
                        label="Nome"
                        name="displayName"
                        className="tw-w-full tw-mt-4"
                        error={errors.displayName}
                        defaultValue={user?.displayName || ''}
                        placeholder="Digite aqui"
                        ref={register}
                    />
                    <Controller
                        as={Input}
                        control={control}
                        label="Email"
                        name="email"
                        inputClassName="tw-text-gray-300"
                        className="tw-w-full tw-mt-4"
                        disabled
                        error={errors.email}
                        defaultValue={user?.email || ''}
                        placeholder="Digite aqui"
                    />
                    <Input
                        label="Empresa"
                        name="company"
                        className="tw-w-full tw-mt-4"
                        error={errors.company}
                        defaultValue={user?.company || ''}
                        placeholder="Digite aqui"
                        ref={register}
                    />
                    <div className="tw-flex tw-justify-between tw-align-middle tw-mt-8">
                        <label className="tw-font-bold tw-text-back" htmlFor="hasAgency">
                            Possui agência?
                        </label>
                        <input
                            id="hasAgency"
                            type="checkbox"
                            ref={register}
                            defaultChecked={user?.hasAgency}
                            name="hasAgency"
                            className="active:tw-bg-pink hover:tw-bg-pink checked:tw-bg-pink tw-rounded-sm checked:border-transparent"
                        />
                    </div>
                    <div className="tw-flex tw-justify-between tw-align-middle tw-mt-4">
                        <label className="tw-font-bold tw-text-back" htmlFor="receiveEmails">
                            Receber informações
                        </label>
                        <input
                            id="receiveEmails"
                            className="checked:tw-bg-pink tw-rounded-sm checked:border-transparent"
                            type="checkbox"
                            defaultChecked={user?.receiveEmails}
                            ref={register}
                            name="receiveEmails"
                        />
                    </div>
                </div>
                <div className="tw-flex tw-w-full md:tw-w-1/3 tw-flex-col md:tw-ml-6">
                    <div className="tw-flex tw-flex-col tw-w-full form-control tw-mt-4">
                        <label>Gênero</label>
                        <select
                            label="Gênero"
                            name="gender"
                            ref={register}
                            errors={errors.gender}
                            defaultValue={user?.gender}
                            className="tw-w-full tw-border-gray-200 tw-px-0 tw-bg-transparent tw-border-b-2 tw-border-t-0 tw-border-r-0 tw-border-l-0"
                        >
                            <option value="select">Selecionar</option>
                            <option value="male">Masculino</option>
                            <option value="female">Feminino</option>
                            <option value="other">Outro</option>
                        </select>
                    </div>
                    <Input
                        label="Telefone"
                        name="phone"
                        defaultValue={user?.phone}
                        className="tw-w-full tw-mt-4"
                        error={errors.phone}
                        mask="(99) 9 9999-9999"
                        placeholder="(DD) X XXXX-XXXX"
                        control={control}
                    />
                    <Input
                        label="CNPJ"
                        name="cnpj"
                        inputClassName="tw-text-gray-300"
                        defaultValue={user?.cnpj}
                        className="tw-w-full tw-mt-4"
                        error={errors.cnpj}
                        placeholder="XX.XXX.XXX/XXXX-XX"
                        disabled
                        mask="99.999.999/9999-99"
                        control={control}
                    />
                </div>
            </div>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-mt-14">
                <button className="button button-primary-outlined tw-block" type="button" onClick={() => push('/')}>
                    Cancelar
                </button>
                <button type="submit" className="button button-primary tw-mt-4 md:tw-mt-0 tw-block md:tw-ml-4">
                    {loading ? 'Salvando...' : 'Salvar Alterações'}
                </button>
            </div>
            <br />
        </form>
    );
}

export default General;
