import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const CardIcon = ({ title, icon, to, customClassName, ...rest }) => {
    const history = useHistory();
    function handleClick(event) {
        event.preventDefault();
        history.push(to);
    }
    return (
        <button className={`${customClassName || ''} icon-card`} onClick={handleClick} {...rest}>
            <div>{icon}</div>
            <div>
                <span className="tw-text-sm tw-mt-2">{title}</span>
            </div>
        </button>
    );
};

CardIcon.propTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
};

export default CardIcon;
