import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import {
    getPackages,
    savePackage,
    updatePackage,
    deletePackage,
    getPackagesByRegionId,
    getPackageById,
} from 'api/packages';
import { Types, Creators } from 'store/modules/packages';

function* fetchPackagesSaga(action) {
    const { companyId, meta } = action;
    try {
        const snapshot = yield call(getPackages, companyId);
        const documents = snapshot.docs.map(document_ => {
            return {
                id: document_.id,
                ...document_.data(),
            };
        });
        yield put(Creators.fetchPackagesSuccess(documents));
        if (meta?.onSuccess) yield call(meta.onSuccess);
    } catch (error) {
        Sentry.captureException(error);
        if (meta?.onFailure) yield call(meta.onFailure);
    }
}

function* savePackageSaga(action) {
    const { companyId, packageData, meta } = action;
    try {
        yield call(savePackage, companyId, packageData);
        if (meta?.onSuccess) yield call(meta.onSuccess);
    } catch (error) {
        Sentry.captureException(error);
        if (meta?.onFailure) yield call(meta.onFailure);
    }
}

function* updatePackageSaga(action) {
    const { packageData, meta } = action;
    try {
        yield call(updatePackage, packageData);
        if (meta?.onSuccess) yield call(meta.onSuccess);
    } catch (error) {
        Sentry.captureException(error);
        if (meta?.onFailure) yield call(meta.onFailure);
    }
}

function* deletePackageSaga(action) {
    const { packageId, meta } = action;
    try {
        yield call(deletePackage, packageId);
        if (meta?.onSuccess) yield call(meta.onSuccess);
    } catch (error) {
        Sentry.captureException(error);
        if (meta?.onFailure) yield call(meta.onFailure);
    }
}

function* fetchPackagesSagaByRegionId(action) {
    const { regionId, meta } = action;
    try {
        const snapshot = yield call(getPackagesByRegionId, regionId);
        const documents = snapshot.docs.map(document_ => ({
            id: document_.id,
            ...document_.data(),
        }));
        yield put(Creators.fetchPackagesSuccess(documents?.filter(document => !!document.availablePackages)));
        if (meta?.onSuccess) yield call(meta.onSuccess);
    } catch (error) {
        Sentry.captureException(error);
        if (meta?.onFailure) yield call(meta.onFailure);
    }
}

function* getPackageByIdSaga(action) {
    const { packageId, meta } = action;
    try {
        const snapshot = yield call(getPackageById, packageId);
        if (snapshot?.exists) {
            yield put(Creators.getPackageByIdSuccess({ id: snapshot.id, ...snapshot.data() }));
        }
        if (meta?.onSuccess) yield call(meta.onSuccess);
    } catch (error) {
        Sentry.captureException(error);
        if (meta?.onFailure) yield call(meta.onFailure);
    }
}

function* packagesRoot() {
    yield all([
        takeLatest(Types.FETCH_PACKAGES, fetchPackagesSaga),
        takeLatest(Types.SAVE_PACKAGE, savePackageSaga),
        takeLatest(Types.UPDATE_PACKAGE, updatePackageSaga),
        takeLatest(Types.DELETE_PACKAGE, deletePackageSaga),
        takeLatest(Types.FETCH_PACKAGES_BY_REGION_ID_AND_AVAILABILITY, fetchPackagesSagaByRegionId),
        takeLatest(Types.GET_PACKAGE_BY_ID, getPackageByIdSaga),
    ]);
}

export default packagesRoot;
