import React from 'react';

function PlusIcon({ onClick, color }) {
    return (
        <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <circle cx="19" cy="19" r="19" fill={color || '#E5195A'} />
            <path
                d="M11.2273 17.3637H17.3637V11.2273C17.3637 10.5498 17.9135 10 18.5909 10H19.4091C20.0865 10 20.6363 10.5498 20.6363 11.2273V17.3637H26.7727C27.4502 17.3637 28 17.9135 28 18.5909V19.4091C28 20.0865 27.4502 20.6363 26.7727 20.6363H20.6363V26.7727C20.6363 27.4502 20.0865 28 19.4091 28H18.5909C17.9135 28 17.3637 27.4502 17.3637 26.7727V20.6363H11.2273C10.5498 20.6363 10 20.0865 10 19.4091V18.5909C10 17.9135 10.5498 17.3637 11.2273 17.3637Z"
                fill="white"
            />
        </svg>
    );
}

export default PlusIcon;
