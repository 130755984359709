import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ title, children, error }) => {
    return (
        <>
            <label className="tw-font-semibold">{title}</label>
            <div className="tw-mt-2">
                {children}
                {error && <p className="error-message"> {error.message}</p>}
            </div>
        </>
    );
};

Section.propTypes = {
    title: PropTypes.string.isRequired,
};

export default Section;
