import React from 'react';
import { FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import useApproveInsertionsForm from './useApproveInsertionsForm';

const ApproveInsertionsForm = ({ campaign, children, onApprove }) => {
    const methods = useApproveInsertionsForm(campaign, onApprove);
    const { onSubmit, control } = methods;

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                {children(methods)}
                {__DEVELOPMENT__ && <DevTool control={control} />}
            </form>
        </FormProvider>
    );
};

export default ApproveInsertionsForm;
