import React from 'react';
import clsx from 'clsx';

const CompanyCard = ({ company, onClick, isSelected, disabled }) => {
    const handleClick = event => {
        event.preventDefault();
        onClick(company);
    };
    return (
        <button
            type="button"
            className="tw-text-center tw-mr-4 focus:tw-outline-none"
            onClick={handleClick}
            disabled={disabled}
        >
            <div
                className={clsx('icon-card tw-w-28 tw-h-28 tw-p-4 tw-inline tw-m-auto', {
                    'icon-card__active tw-shadow-md': isSelected,
                })}
            >
                <img src={company.logo} alt={company.name} />
            </div>
            <span className="tw-text-purple tw-font-bold tw-mt-4 tw-block">{company.name}</span>
        </button>
    );
};

export default CompanyCard;
