import { auth, firestore } from 'base';
import { createTypes } from 'reduxsauce';

const Types = createTypes(
    `
  GET_USER
  GET_USERS
  UPDATE_USER
  GET_CAMPAIGN_USER
`,
    { prefix: 'user/' },
);

// Actions
// const GET_USER = 'user/GET_USER';
// const GET_USERS = 'user/GET_USERS';
// const UPDATE_USER = 'user/UPDATE_USER';

// Actions creator
export const getUser = () => async dispatch => {
    const currentUser = auth.currentUser;
    const firestoreUser = await firestore
        .collection('users')
        .doc(currentUser.uid)
        .get()
        .then(document_ => document_.data());

    return dispatch({
        type: Types.GET_USER,
        payload: firestoreUser,
    });
};

export const getCampaignUser = (userId, meta) => async dispatch => {
    const currentUser = auth.currentUser;
    const firestoreUser = await firestore
        .collection('users')
        .where('id', 'in', [userId])
        .doc(currentUser.uid)
        .get()
        .then(document_ => {
            if (meta.onSuccess) meta.onSuccess();
            return document_.data();
        })
        .catch(() => {
            if (meta.onFailure) meta.onFailure();
        });

    return dispatch({
        type: Types.GET_CAMPAIGN_USER,
        payload: firestoreUser,
    });
};

export const getUsers = () => async dispatch => {
    let firestoreUsers = [];
    await firestore
        .collection('users')
        .get()
        .then(snapshot => snapshot.forEach(document_ => firestoreUsers.push(document_.data())));

    return dispatch({
        type: Types.GET_USERS,
        payload: firestoreUsers,
    });
};

export const updateUser = (newUser, meta) => async dispatch => {
    try {
        const firestoreUser = firestore.collection('users').doc(newUser.id);

        const user = await firestoreUser.update(newUser);

        if (meta?.onSuccess) {
            meta?.onSuccess();
        }

        return dispatch({
            type: Types.UPDATE_USER,
            payload: user,
        });
    } catch {
        if (meta?.onFailure) {
            meta?.onFailure();
        }
    }
};

const initState = {
    user: {},
    campaignUser: {},
    users: [],
};

// Reducer
export default function (state = initState, action) {
    switch (action.type) {
        case Types.GET_USER:
            return {
                ...state,
                user: action.payload,
            };
        case Types.GET_CAMPAIGN_USER:
            return {
                ...state,
                campaignUser: action.payload,
            };
        case Types.GET_USERS:
            return {
                ...state,
                users: action.payload,
            };
        case Types.UPDATE_USER:
            return {
                ...state,
                user: action.payload,
            };
        default:
            return state;
    }
}
