/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable no-unused-vars */
import React from 'react';

function Steps({ steps, activeStep }) {
    return (
        <div className="tw-w-full tw-flex tw-flex-col tw-justify-around">
            <div className="tw-w-full tw-flex tw-justify-center">
                <img
                    src={steps[activeStep]?.image}
                    className="tw-max-h-72 tw-w-full"
                    alt="step-image"
                    style={{ maxWidth: 220 }}
                />
            </div>
            <div className="tw-w-full tw-flex tw-justify-center tw-my-6 tw-text-center">
                <h1 className="tw-text-purple tw-normal-case tw-font-bold">{steps[activeStep]?.title}</h1>
            </div>
            <div className="tw-w-full tw-flex tw-justify-center tw-text-center">
                <p>{steps[activeStep]?.description}</p>
            </div>
        </div>
    );
}

export default Steps;
