import React, { useState } from 'react';
import { toast } from 'react-toastify';
import linkFunction from 'linkfunction';
import { useMutation } from 'react-query';
import * as Sentry from '@sentry/react';
import yup from 'validator';
import { calculeAmountCouponDiscount, checkIsValidCoupon } from 'base';
import InputField from 'molecules/InputField';

const couponSchema = yup.object({
    code: yup
        .string()
        .min(3)
        .transform(function (value) {
            if (value && this.isType(value)) {
                return value.toUpperCase().trim();
            }
            return value;
        })
        .test('checkIsValidCoupon', '${path} inválido', async function (value) {
            if (value) {
                return checkIsValidCoupon({
                    couponCode: value,
                })
                    .then(resp => resp.data || resp.result)
                    .catch(error => {
                        Sentry.captureException(error);
                        return false;
                    });
            }

            return true;
        })
        .label('Cupom'),
});

function useCouponMutation(grantTotal, options) {
    return useMutation(
        code =>
            calculeAmountCouponDiscount({
                couponCode: code,
                totalAmount: grantTotal,
            }),
        options,
    );
}

const CouponForm = ({ coupon, grantTotal, onRemove, onSubmit }) => {
    const [loading, setLoading] = useState(false);
    const [couponInput, setCouponInput] = useState(coupon?.code || '');
    const { mutate: addCoupon } = useCouponMutation(grantTotal, {
        onSuccess(resp) {
            onSubmit({
                code: couponInput,
                value: resp.data,
            });
        },
        onError(error) {
            Sentry.captureException(error);
            toast.error(error.message);
        },
    });

    function handleAddCouponClick(event) {
        event.preventDefault();
        setLoading(true);

        couponSchema
            .validate({ code: couponInput })
            .then(resp => addCoupon(resp.code))
            .catch(error => toast.error(error.message))
            .finally(() => setLoading(false));
    }

    function handleRemoveCouponClick(event) {
        event.preventDefault();
        onRemove();
    }

    return (
        <div className="tw-flex tw-gap-x-4 tw-items-center tw-align-middle md:tw-flex-row tw-flex-col">
            <div>
                <InputField
                    type="text"
                    name="code"
                    placeholder="Possui cupom de desconto? Insira aqui..."
                    className="tw-py-2 tw-px-1 tw-w-80"
                    disabled={loading || coupon}
                    onChange={linkFunction(setCouponInput)}
                    value={couponInput}
                />
            </div>

            <div>
                {coupon ? (
                    <button
                        type="button"
                        className="button button-primary-outlined button-small"
                        onClick={handleRemoveCouponClick}
                    >
                        Remover
                    </button>
                ) : (
                    <button
                        type="button"
                        className="button button-primary-outlined button-small"
                        disabled={loading}
                        onClick={handleAddCouponClick}
                    >
                        {loading ? 'Aplicando...' : 'Aplicar'}
                    </button>
                )}
            </div>
        </div>
    );
};

export default CouponForm;
