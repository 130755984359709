/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validator';
import Select from 'molecules/Select';
import Input from 'molecules/InputField';
import states from 'utils/states';
import { clearString, isCnpjValid } from 'utils';
import { getAddressByCEP } from 'services/cep';

const schema = yup.object().shape({
    company: yup.string().defined().required().label('Empresa'),
    cnpj: yup.string().defined().required().label('CNPJ'),
    address: yup
        .object({
            cep: yup.string().required().label('CEP'),
            city: yup.string().required().label('Cidade'),
            state: yup.string().required().label('Estado'),
            street: yup.string().required().label('Logradouro'),
            neighborhood: yup.string().required().label('Bairro'),
            number: yup.string().required().label('Número'),
            complement: yup.string(),
        })
        .required(),
});

const SecondStep = ({ setStep, setRegisterData, registerData }) => {
    const { handleSubmit, register, errors, control, setError, watch, setValue } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const onSubmit = React.useCallback(
        values => {
            if (!isCnpjValid(values.cnpj)) {
                setError('cnpj', { message: 'CNPJ Inválido!' });
                return;
            }
            setRegisterData(previous => ({
                ...previous,
                ...values,
            }));
            setStep(previous => previous + 1);
        },
        [setRegisterData, setStep, setError],
    );

    const handleBack = () => setStep(previous => previous - 1);

    const zipCode = watch('address.cep', '');

    useEffect(() => {
        async function fetchAddressData(zip) {
            const response = await getAddressByCEP(zip);
            if (response.data) {
                const { cep, state, city, street, neighborhood } = response.data;
                setValue('address.cep', cep);
                setValue('address.state', state);
                setValue('address.street', street);
                setValue('address.city', city);
                setValue('address.neighborhood', neighborhood);
            }
        }
        if (clearString(zipCode).length === 8) {
            fetchAddressData(clearString(zipCode));
        }
    }, [setValue, zipCode]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="tw-flex tw-w-full tw-flex-col tw-justify-between md:tw-flex-row">
                <Input
                    label="Razão Social da Empresa"
                    name="company"
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/2 md:tw-mr-2"
                    error={errors.company}
                    defaultValue={registerData.company}
                    placeholder="Digite aqui"
                    ref={register}
                />
                <Input
                    label="CNPJ"
                    name="cnpj"
                    defaultValue={registerData.cnpj}
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/2 md:tw-ml-2"
                    error={errors.cnpj}
                    placeholder="XX.XXX.XXX/XXXX-XX"
                    mask="99.999.999/9999-99"
                    control={control}
                />
            </div>
            <div className="tw-flex tw-w-full tw-flex-col tw-justify-between md:tw-flex-row lg:tw-my-8">
                <Input
                    label="CEP"
                    name="address.cep"
                    error={errors.address?.cep}
                    defaultValue={registerData?.address?.cep}
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/5 md:tw-max-w-xs"
                    placeholder="Digite aqui"
                    control={control}
                    mask="99999-999"
                />
                <Input
                    label="Cidade"
                    name="address.city"
                    error={errors.address?.city}
                    defaultValue={registerData?.address?.city}
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-2/5 md:tw-max-w-xs md:tw-mx-4"
                    placeholder="Digite aqui"
                    ref={register}
                />
                <Controller
                    as={Select}
                    label="Estado"
                    name="address.state"
                    error={errors.address?.state}
                    defaultValue={registerData?.address?.state || ''}
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-5/12 md:tw-max-w-xs"
                    control={control}
                >
                    <option value="select">Selecionar</option>
                    {states.map(({ label, value }) => (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    ))}
                </Controller>
            </div>
            <div className="tw-flex tw-w-full tw-flex-col tw-justify-between md:tw-flex-row">
                <Input
                    label="Logradouro"
                    name="address.street"
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/2 md:tw-mr-2"
                    error={errors.address?.street}
                    defaultValue={registerData?.address?.street}
                    placeholder="Digite aqui"
                    ref={register}
                />
                <Input
                    label="Bairro"
                    name="address.neighborhood"
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/2 md:tw-ml-2"
                    error={errors.address?.neighborhood}
                    defaultValue={registerData?.address?.neighborhood}
                    placeholder="Digite aqui"
                    ref={register}
                />
            </div>
            <div className="tw-flex tw-w-full tw-flex-col tw-justify-between md:tw-flex-row md:tw-mt-8">
                <Input
                    label="Número"
                    name="address.number"
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/5 md:tw-mr-2"
                    error={errors.address?.number}
                    defaultValue={registerData?.address?.number}
                    placeholder="Digite aqui"
                    ref={register}
                />
                <Input
                    label="Complemento"
                    name="address.complement"
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-4/5 md:tw-ml-2"
                    defaultValue={registerData?.address?.complement}
                    placeholder="Digite aqui"
                    ref={register}
                />
            </div>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-mt-14">
                <button
                    className="button button-primary-outlined tw-block tw-w-full md:tw-w-1/4"
                    type="button"
                    onClick={handleBack}
                >
                    Voltar
                </button>
                <button
                    type="submit"
                    className="button button-primary tw-mt-4 md:tw-mt-0 tw-block tw-w-full md:tw-w-1/4 md:tw-ml-4"
                >
                    Prosseguir
                </button>
            </div>
        </form>
    );
};

export default SecondStep;
