import React from 'react';
import clsx from 'clsx';
import InfoIcon from './InfoIcon';
import UncheckedIcon from './UncheckedIcon';
import CheckedIcon from './CheckedIcon';

function DayAvailability({ day }) {
    return (
        <span
            className={clsx('tw-uppercase tw-mr-2', {
                'tw-text-purple tw-font-bold': day.available,
                'tw-text-gray': !day.available,
            })}
        >
            {day.label[0]}
        </span>
    );
}

const ProgramCard = ({ program, isSelected, disabled, onSelect, onUnselect }) => {
    const handleClick = event => {
        event.preventDefault();
        if (isSelected) {
            onUnselect(program);
        } else {
            onSelect(program);
        }
    };

    return (
        <button
            type="button"
            onClick={handleClick}
            disabled={disabled}
            className={clsx('icon-card tw-w-80 tw-h-auto tw-p-2 tw-text-sm', {
                'icon-card__active tw-shadow-md': isSelected,
            })}
        >
            <img
                src={
                    program?.banners?.find(banner => banner.isMain)?.image || program?.banners?.length > 0
                        ? program?.banners[0]?.image
                        : ''
                }
                alt={program.name}
                className="tw-block tw-rounded-md tw-h-32 tw-w-full"
            />
            <span className="tw-font-bold tw-text-left tw-pt-3 tw-block">{program.programName}</span>

            <div className="tw-flex tw-justify-between tw-align-middle tw-items-end tw-w-full">
                <div>
                    <div className="tw-text-left tw-mb-2">
                        {program.showDays.map(day => (
                            <DayAvailability day={day} key={day.day} />
                        ))}
                    </div>
                    <span className="tw-font-bold tw-text-left tw-pt-3 tw-block">{program.period}</span>
                </div>

                <div>
                    <InfoIcon className="tw-h-6 tw-w-6 tw-inline-block tw-mr-2" />
                    {isSelected ? (
                        <CheckedIcon className="tw-h-6 tw-w-6 tw-inline-block" />
                    ) : (
                        <UncheckedIcon className="tw-h-6 tw-w-6 tw-inline-block" />
                    )}
                </div>
            </div>
        </button>
    );
};

export default ProgramCard;
