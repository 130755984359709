import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteProgram, getProgramsByCompany } from 'store/modules/program';
import Loading from 'components/Loading';
import DeleteProgramModal from './ConfirmDeleteModal';
import AddProgramButton from './AddProgramButton';
import ProgramRow from './ProgramRow';
import { useCompanyForCurrentUser, useProgramsByCompany, useRegiosByCompany } from '../hooks';

const ListPrograms = () => {
    const [loading, setLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [programToDelete, setProgramToDelete] = useState();
    const dispatch = useDispatch();

    const company = useCompanyForCurrentUser();
    const programs = useProgramsByCompany(company?.id);
    const regions = useRegiosByCompany(company?.id);

    const toggleDeleteModal = useCallback(() => {
        setShowDeleteModal(previous => !previous);
    }, []);

    const handleDeleteProgram = useCallback(() => {
        setLoading(true);

        function onSuccess() {
            setLoading(false);
            toast.success('Programa excluído com sucesso!');
            dispatch(getProgramsByCompany(company.id));
        }

        function onFailure() {
            setLoading(false);
            toast.error('Falha ao excluir programa');
        }

        if (programToDelete) {
            dispatch(deleteProgram(programToDelete.id, { onSuccess, onFailure }));
            toggleDeleteModal();
        }
    }, [programToDelete, dispatch, toggleDeleteModal, company]);

    const handlePrepareToDeleteProgram = program => {
        setProgramToDelete(program);
        toggleDeleteModal();
    };

    return (
        <div className="tw-w-full tw-overflow-x-auto tw-mb-9">
            {loading && <Loading />}
            <DeleteProgramModal
                showDeleteModal={showDeleteModal}
                toggleDeleteModal={toggleDeleteModal}
                handleDeleteProgram={handleDeleteProgram}
            />
            <AddProgramButton />
            <table className="tw-w-full tw-mt-6">
                <thead>
                    <tr>
                        <th className="tw-text-purple tw-text-left" align="left">
                            Nome
                        </th>
                        <th className="tw-text-purple" align="right">
                            Segundos Disponíveis
                        </th>
                        <th className="tw-text-purple" align="right">
                            Valor da Inserção de 30 Seg
                        </th>
                        <th className="tw-text-purple tw-text-center">Região de Exibição</th>
                        <th className="tw-text-purple tw-text-left">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {programs.map(program => (
                        <ProgramRow
                            key={program.programName + program.id + program.programTime}
                            program={program}
                            regions={regions}
                            onDelete={handlePrepareToDeleteProgram}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ListPrograms;
