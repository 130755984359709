import React from 'react';

function CloseIcon({ onClick, className }) {
    return (
        <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            className={className}
        >
            <path
                d="M15.5001 2.1125C18.8061 2.11832 21.9921 3.35182 24.4401 5.57371C26.8882 7.79561 28.4238 10.8475 28.749 14.1375C29.0743 17.4275 28.166 20.721 26.2004 23.3793C24.2349 26.0375 21.352 27.871 18.1112 28.524C14.8703 29.1771 11.5024 28.6032 8.66067 26.9137C5.81896 25.2242 3.70603 22.5395 2.7317 19.3803C1.75738 16.2211 1.99112 12.8126 3.38758 9.81604C4.78404 6.81943 7.24369 4.44828 10.2894 3.1625C11.938 2.46563 13.7102 2.1085 15.5001 2.1125ZM15.5001 0.362495C7.13986 0.362495 0.362549 7.13981 0.362549 15.5C0.362549 23.8602 7.13986 30.6375 15.5001 30.6375C23.8602 30.6375 30.6376 23.8602 30.6376 15.5C30.6376 7.13981 23.8602 0.362495 15.5001 0.362495ZM15.3036 17.6131L11.5302 23.7714H8.66236L14.0056 15.5604L8.66236 7.22862H11.7717L15.6961 13.3562L19.4397 7.22818H22.3076L16.9946 15.4094L22.3377 23.7714H19.2284L15.3036 17.6131Z"
                fill="#2CAA60"
            />
        </svg>
    );
}

export default CloseIcon;
