import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { FaWhatsapp } from 'react-icons/fa';
import { signOut } from 'store/modules/auth';
import UserIconSmall from 'icons/UserIconSmall';
import PlusIcon from 'icons/PlusIcon';
import MenuAltIcon from 'icons/MenuAltIcon';
import PencilIcon from 'icons/PencilIcon';
import LogoutIcon from 'icons/LogoutIcon';
import ChartBarIcon from 'icons/ChartBarIcon';
import MenuItem from 'components/Menu/MenuItem';
import styleConfig from '../../style_config.json';

const rootStyle = { zIndex: 999 };

const Menu = ({ mobile, onMouseLeave }) => {
    const [isHover, setIsHover] = useState(false);

    function handleMouseEnter(event) {
        event.preventDefault();
        setIsHover(true);
    }

    function handleMouseLeave(event) {
        if (event) {
            event.preventDefault();
        }
        onMouseLeave();
        setIsHover(false);
    }

    const rootClass = clsx(
        'tw-bg-pink',
        'tw-fixed',
        'tw-bottom-0',
        {
            'tw-w-11/12 tw-max-w-xs': mobile,
            'tw-hidden tw-w-28 hover:tw-w-96 sm:tw-flex': !mobile,
        },
        'tw-top-0',
        'tw-flex tw-flex-col tw-content-between tw-place-content-between',
        {
            'menu-active': isHover,
        },
    );

    const dispatch = useDispatch();
    const history = useHistory();
    const handleLogout = event => {
        event.preventDefault();
        dispatch(signOut(() => history.push('/')));
    };
    return (
        <OutsideClickHandler onOutsideClick={handleMouseLeave}>
            <div
                className={rootClass}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={rootStyle}
            >
                <div className="tw-flex tw-items-center tw-place-content-between tw-align-middle tw-justify-center tw-content-center tw-bg-transparent sm:tw-bg-purple tw-h-24">
                    {isHover || mobile ? (
                        <img
                            src={styleConfig['logo-horizontal.svg']}
                            alt="Logo horizontal"
                            className="tw-w-full tw-px-10 tw-py-10 sm:tw-px-20 sm:tw-py-10 tw-inline tw-fill-current"
                        />
                    ) : (
                        <img
                            src={styleConfig['logo-only.svg']}
                            alt="Logo only"
                            className="tw-w-full tw-h-24 tw-max-w-max tw-px-6 tw-py-4 tw-inline tw-fill-current"
                        />
                    )}
                </div>

                <div>
                    <div className="tw-flex tw-flex-col tw-place-content-stretch tw-justify-evenly tw-align-middle tw-place-items-stretch tw-items-center tw-flex-grow tw-pt-6">
                        <MenuItem
                            to="/"
                            handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                            title={(isHover || mobile) && 'Dashboard'}
                            icon={<ChartBarIcon className="tw-w-7 tw-h-9 tw-inline" />}
                        />
                        <MenuItem
                            handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                            title={(isHover || mobile) && 'Meu perfil'}
                            to="/perfil"
                            icon={<UserIconSmall className="tw-w-7 tw-h-9 tw-inline" />}
                        />
                        <MenuItem
                            handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                            title={(isHover || mobile) && 'Criar Campanha'}
                            to="/campanhas/criar"
                            icon={<PlusIcon className="tw-w-7 tw-h-9 tw-inline" />}
                        />
                        <MenuItem
                            handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                            title={(isHover || mobile) && 'Listar Campanhas'}
                            to="/campanhas"
                            icon={<MenuAltIcon className="tw-w-7 tw-h-9 tw-inline" />}
                        />
                        <MenuItem
                            handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                            title={(isHover || mobile) && 'Central de Criativo'}
                            to="/central-criativa"
                            icon={<PencilIcon className="tw-w-7 tw-h-9 tw-inline" />}
                        />
                        <MenuItem
                            handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                            title={(isHover || mobile) && 'Suporte Whatsapp'}
                            to={styleConfig['whatsAppSupportLink']}
                            icon={<FaWhatsapp size={28} color="#fff" />}
                        />
                    </div>
                </div>
                <div className="tw-flex tw-flex-col tw-place-content-evenly tw-justify-evenly tw-items-center tw-mb-2">
                    <MenuItem
                        handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                        title={(isHover || mobile) && 'Sair'}
                        icon={<LogoutIcon className="tw-w-7 tw-h-7 tw-inline" />}
                        hover={isHover}
                        onClick={handleLogout}
                    />
                </div>
            </div>
        </OutsideClickHandler>
    );
};

export default Menu;
