import React from 'react';
import { useCurrentCampaign } from 'hooks/campaign';
import clsx from 'clsx';
import { getTotalImpactOfPrograms } from 'services/advertiser/campaign';
import CitiesList from 'organisms/CitiesList';

const ResumeItem = ({ title, children, className }) => (
    <div>
        <strong className={className}>{title}</strong>
        <div>{children}</div>
    </div>
);

const CampaignOverview = ({ programs, vertical = false }) => {
    const {
        objectivesAndDuration,
        localization: { code },
    } = useCurrentCampaign();

    const totalImpact = getTotalImpactOfPrograms(programs);

    const titleClass = clsx('tw-font-bold tw-mb-3', {
        'tw-text-2xl tw-text-pink tw-mb-6': !vertical,
    });
    const itemClass = clsx('tw-text-sm', {
        'tw-text-pink': vertical,
        'tw-text-purple': !vertical,
    });
    const gridClass = clsx('tw-grid tw-flex-col', {
        'tw-grid-cols-2 tw-gap-2': vertical,
        'tw-grid-cols-3 tw-gap-4': !vertical,
    });

    return (
        <>
            <div>
                <p className="tw-font-bold-semibold tw-mt-8 tw-mb-2">Visualizações</p>
                <div className="tw-w-52 tw-bg-purple tw-text-white tw-text-center tw-h-40 tw-rounded-2xl tw-place-content-center tw-gap-2 tw-p-4">
                    <strong className="tw-text-4xl">{Number(totalImpact || 0).toLocaleString('pt-BR')}</strong>
                    <p>Estimativa de visualizações totais geradas para esta Campanha </p>
                </div>
            </div>

            <div className="tw-mt-4">
                <p className={titleClass}>Resumo da Campanha</p>

                <div className={gridClass}>
                    <ResumeItem className={itemClass} title="Nome da Campanha">
                        {objectivesAndDuration.name}
                    </ResumeItem>
                    <ResumeItem className={itemClass} title="Objetivo da Campanha">
                        {objectivesAndDuration.objective === 'brandAwareness' ? 'Reconhecimento' : 'Alcance'}
                    </ResumeItem>
                    <ResumeItem className={itemClass} title="Seu Público">
                        Todos
                    </ResumeItem>
                    <ResumeItem className={itemClass} title="Duração do Comercial">
                        {objectivesAndDuration.duration} segundos
                    </ResumeItem>
                    <ResumeItem className={itemClass} title="Cidade Selecionada">
                        {objectivesAndDuration.city}
                    </ResumeItem>
                    <ResumeItem className={itemClass} title="Regiões de Cobertura">
                        <CitiesList state={code} city={objectivesAndDuration.city} programs={programs} />
                    </ResumeItem>
                </div>
            </div>
        </>
    );
};

export default CampaignOverview;
