import React, { memo } from 'react';

const InputFormControl = React.forwardRef(({ label, name, error, className = '', icon, ...rest }, reference) => {
    const rootClass = 'form-control ' + className;
    return (
        <div className={rootClass}>
            <label htmlFor={name}>{label}</label>
            <div className="with-icon">
                {icon}
                <input name={name} className="tw-w-full" ref={reference} {...rest} />
            </div>
            {error && <p className="error-message">{error.message}</p>}
        </div>
    );
});

InputFormControl.displayName = 'InputFormControl';

export default memo(InputFormControl);
