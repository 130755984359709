import React from 'react';
import { CAMPAIGN_STATUS } from 'services/advertiser/campaign';
import TVIcon from './icons/TVIcon';
import DolarIcon from './icons/DolarIcon';
import CheckIcon from './icons/CheckIcon';
import CameraIcon from './icons/CameraIcon';
import ClaqueteIcon from './icons/ClaqueteIcon';

function StepsIconsInfo({ campaign }) {
    return (
        <div className="tw-flex tw-flex-row tw-items-center tw-h-9 tw-relative">
            <DolarIcon
                message={() =>
                    [CAMPAIGN_STATUS.refused].includes(campaign?.status) ? (
                        <></>
                    ) : campaign?.status !== CAMPAIGN_STATUS.pendingPayment ? (
                        <div className="tw-shadow-md tw-bg-white tw-rounded-md tw-p-3 tw-absolute tw-bottom-6">
                            <p>
                                <strong>
                                    Etapa&nbsp;
                                    <span className="tw-text-purple">1</span>
                                    /5 Concluída:&nbsp;
                                </strong>
                                <span className="tw-text-pink">Pagamento&nbsp;</span>
                                Aprovado.
                            </p>
                        </div>
                    ) : (
                        <></>
                    )
                }
                className="tw-w-5 tw-h-5"
                color={
                    [CAMPAIGN_STATUS.refused].includes(campaign?.status)
                        ? '#D8D8D8'
                        : [CAMPAIGN_STATUS.pendingPayment].includes(campaign?.status)
                        ? '#E79E2D'
                        : 'purple'
                }
            />
            <span className="tw-text-customgray">&nbsp;{'>'}&nbsp;</span>
            <TVIcon
                className="tw-w-5 tw-h-5"
                message={() =>
                    [CAMPAIGN_STATUS.refused].includes(campaign?.status) ? (
                        <></>
                    ) : [
                          CAMPAIGN_STATUS.pendingCreative,
                          CAMPAIGN_STATUS.pendingApproveCreative,
                          CAMPAIGN_STATUS.approved,
                          CAMPAIGN_STATUS.finished,
                      ].includes(campaign?.status) ? (
                        <div className="tw-shadow-md tw-bg-white tw-rounded-md tw-p-3 tw-absolute tw-bottom-6">
                            <p>
                                <strong>
                                    Etapa&nbsp;
                                    <span className="tw-text-purple">2</span>
                                    /5 Concluída:&nbsp;
                                </strong>
                                <span className="tw-text-pink">Inserções&nbsp;</span>
                                Aprovadas.
                            </p>
                        </div>
                    ) : (
                        <></>
                    )
                }
                color={
                    [CAMPAIGN_STATUS.refused].includes(campaign?.status)
                        ? '#D8D8D8'
                        : [CAMPAIGN_STATUS.pendingApproveInsertions].includes(campaign?.status)
                        ? '#E79E2D'
                        : ![
                              CAMPAIGN_STATUS.pendingCreative,
                              CAMPAIGN_STATUS.pendingApproveCreative,
                              CAMPAIGN_STATUS.approved,
                              CAMPAIGN_STATUS.finished,
                          ].includes(campaign?.status)
                        ? '#D8D8D8'
                        : 'purple'
                }
            />
            <span className="tw-text-customgray">&nbsp;{'>'}&nbsp;</span>
            <CameraIcon
                className="tw-w-5 tw-h-5"
                message={() =>
                    [CAMPAIGN_STATUS.refused].includes(campaign?.status) ? (
                        <></>
                    ) : ![
                          CAMPAIGN_STATUS.pendingPayment,
                          CAMPAIGN_STATUS.pendingCreative,
                          CAMPAIGN_STATUS.pendingApproveInsertions,
                      ].includes(campaign?.status) ? (
                        <div className="tw-shadow-md tw-bg-white tw-rounded-md tw-p-3 tw-absolute tw-bottom-6">
                            <p>
                                <strong>
                                    Etapa&nbsp;
                                    <span className="tw-text-purple">3</span>
                                    /5 Concluída:&nbsp;
                                </strong>
                                <span className="tw-text-pink">Criativo&nbsp;</span>
                                Enviado.
                            </p>
                        </div>
                    ) : (
                        <></>
                    )
                }
                color={
                    [
                        CAMPAIGN_STATUS.pendingPayment,
                        CAMPAIGN_STATUS.pendingApproveInsertions,
                        CAMPAIGN_STATUS.refused,
                    ].includes(campaign?.status)
                        ? '#D8D8D8'
                        : [CAMPAIGN_STATUS.pendingCreative, CAMPAIGN_STATUS.pendingApproveCreative].includes(
                              campaign?.status,
                          )
                        ? '#E79E2D'
                        : 'purple'
                }
            />
            <span className="tw-text-customgray">&nbsp;{'>'}&nbsp;</span>
            <ClaqueteIcon
                className="tw-w-5 tw-h-5"
                message={() =>
                    [CAMPAIGN_STATUS.refused].includes(campaign?.status) ? (
                        <></>
                    ) : ![
                          CAMPAIGN_STATUS.pendingPayment,
                          CAMPAIGN_STATUS.pendingCreative,
                          CAMPAIGN_STATUS.pendingApproveCreative,
                          CAMPAIGN_STATUS.pendingApproveInsertions,
                          CAMPAIGN_STATUS.refused,
                      ].includes(campaign?.status) ? (
                        <div className="tw-shadow-md tw-bg-white tw-rounded-md tw-p-3 tw-absolute tw-bottom-6">
                            <p>
                                <strong>
                                    Etapa&nbsp;
                                    <span className="tw-text-purple">4</span>
                                    /5 Concluída:&nbsp;
                                </strong>
                                <span className="tw-text-pink">Criativo&nbsp;</span>
                                Aprovado.
                            </p>
                        </div>
                    ) : (
                        <></>
                    )
                }
                color={
                    [CAMPAIGN_STATUS.refused].includes(campaign?.status)
                        ? '#D8D8D8'
                        : ![CAMPAIGN_STATUS.approved, CAMPAIGN_STATUS.finished].includes(campaign?.status)
                        ? '#D8D8D8'
                        : 'purple'
                }
            />
            <span className="tw-text-customgray">&nbsp;{'>'}&nbsp;</span>
            <CheckIcon
                className="tw-w-5 tw-h-5"
                message={() =>
                    [CAMPAIGN_STATUS.refused].includes(campaign?.status) ? (
                        <></>
                    ) : CAMPAIGN_STATUS.finished === campaign?.status ? (
                        <div className="tw-shadow-md tw-bg-white tw-rounded-md tw-p-3 tw-absolute tw-bottom-6">
                            <p>
                                <strong>
                                    Etapa&nbsp;
                                    <span className="tw-text-purple">5</span>
                                    /5 Concluída:&nbsp;
                                </strong>
                                <span className="tw-text-pink">Campanha&nbsp;</span>
                                Concluída.
                            </p>
                        </div>
                    ) : (
                        <></>
                    )
                }
                color={
                    [CAMPAIGN_STATUS.refused].includes(campaign?.status)
                        ? '#D8D8D8'
                        : ![CAMPAIGN_STATUS.finished].includes(campaign?.status)
                        ? '#D8D8D8'
                        : '#e5195a'
                }
            />
        </div>
    );
}

export default StepsIconsInfo;
