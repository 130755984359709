import { firestore } from 'base';

export const getCoupons = async userId => {
    return firestore.collection('coupons').where('userId', '==', userId).get();
};

export const saveCoupon = async data => {
    return firestore.collection('coupons').add(data);
};

export const deleteCoupon = async cuponId => {
    return firestore.collection('coupons').doc(cuponId).delete();
};
