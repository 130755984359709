import React from 'react';
import PropTypes from 'prop-types';

const ArrowRightIcon = ({ className = '', ...rest }) => (
    <svg
        id="Camada_1"
        data-name="Camada 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 18"
        className={`icon ${className}`}
        {...rest}
    >
        <path
            className="cls-1"
            fill="#0a191e"
            fillOpacity="0.6"
            d="M8.08,21.05a1.1,1.1,0,0,1-.74-.28,1,1,0,0,1,0-1.4l7.2-6.82L7.34,5.73a1,1,0,0,1,0-1.4,1.09,1.09,0,0,1,1.47,0l7.92,7.52a.94.94,0,0,1,0,1.39L8.81,20.76A1,1,0,0,1,8.08,21.05Z"
            transform="translate(-7.04 -3.05)"
        />
        <path
            className="cls-2"
            fill="#fff"
            fillOpacity="0.9"
            d="M8.08,20.05a1.1,1.1,0,0,1-.74-.28,1,1,0,0,1,0-1.4l7.2-6.82L7.34,4.73a1,1,0,0,1,0-1.4,1.09,1.09,0,0,1,1.47,0l7.92,7.52a.94.94,0,0,1,0,1.39L8.81,19.76A1,1,0,0,1,8.08,20.05Z"
            transform="translate(-7.04 -3.05)"
        />
    </svg>
);

ArrowRightIcon.propTypes = {
    className: PropTypes.string,
};

export default ArrowRightIcon;
