/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import Modal from 'react-awesome-modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import yup from 'validator';

import Input from 'molecules/InputField';

const schema = yup.object().shape({
    cnpj: yup.string().defined().required().label('CNPJ'),
    discount: yup
        .number()
        .min(0, 'O desconto não pode ser menor que 0%')
        .max(100, 'O desconto não pode ser maior que 100%')
        .required()
        .defined()
        .label('Desconto'),
    name: yup.string().defined().required().label('Nome'),
});

function AddDiscountModal({ showDiscountModal, toggleDiscountModal, editingDiscount, onSubmit }) {
    const { register, control, errors, handleSubmit, setValue, reset, clearErrors } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (editingDiscount?.cnpj) {
            setValue('cnpj', editingDiscount?.cnpj);
        } else {
            setValue('cnpj', '');
        }
        if (editingDiscount?.name) {
            setValue('name', editingDiscount?.name);
        } else {
            setValue('name', '');
        }
        if (editingDiscount?.discount) {
            setValue('discount', editingDiscount?.discount);
        } else {
            setValue('discount', '');
        }
    }, [editingDiscount, setValue]);

    useEffect(() => {
        return function cleanup() {
            reset();
            clearErrors();
        };
    }, [reset, clearErrors]);

    return (
        <Modal visible={showDiscountModal} width="568" effect="fadeInUp" onClickAway={toggleDiscountModal}>
            <form className="tw-p-9 tw-flex tw-flex-col" onSubmit={handleSubmit(onSubmit)}>
                <h3>Desconto Por CNPJ</h3>
                <Input
                    label="CNPJ"
                    name="cnpj"
                    defaultValue={editingDiscount?.cnpj || ''}
                    className="tw-w-full tw-mt-4"
                    mask="99.999.999/9999-99"
                    control={control}
                    error={errors?.cnpj}
                />
                <Input
                    label="Nome"
                    name="name"
                    className="tw-w-full tw-mt-4"
                    defaultValue={editingDiscount?.name || ''}
                    ref={register}
                    error={errors?.name}
                />
                <Input
                    label="Desconto (%)"
                    name="discount"
                    defaultValue={editingDiscount?.discount || ''}
                    className="tw-w-full tw-mt-4"
                    type="number"
                    ref={register}
                    error={errors?.discount}
                />
                <div className="tw-w-full tw-flex tw-justify-center md:tw-flex-row tw-flex-col tw-mt-3 md:tw-mt-16 tw-text-center">
                    <button className="button button-primary-outlined" type="button" onClick={toggleDiscountModal}>
                        Cancelar
                    </button>
                    <button className="button button-primary md:tw-mt-0 tw-mt-3 md:tw-ml-3" type="submit">
                        Salvar
                    </button>
                </div>
            </form>
        </Modal>
    );
}

export default AddDiscountModal;
