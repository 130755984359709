import { firestore } from 'base';

export const getDiscounts = async companyId => {
    const userReference = await firestore.collection('company').doc(companyId.trim()).get();
    const user = userReference.data();
    return user.discounts || [];
};

export const saveDiscounts = async (companyId, discounts) => {
    await firestore.collection('company').doc(companyId.trim()).update('discounts', discounts);
    return discounts;
};

export const getDiscountsRange = async companyId => {
    const userReference = await firestore.collection('company').doc(companyId.trim()).get();
    const user = userReference.data();
    return user.discountsRange || [];
};

export const saveDiscountsRange = async (companyId, discounts) => {
    await firestore.collection('company').doc(companyId.trim()).update('discountsRange', discounts);
    return discounts;
};
