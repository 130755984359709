import produce from 'immer';
import { createReducer, createActions } from 'reduxsauce';

const initialState = {
    coupons: [],
};

// Actions
export const { Types, Creators } = createActions(
    {
        saveCoupon: ['userId', 'coupon', 'meta'],
        deleteCoupon: ['id', 'meta'],
        fetchCoupons: ['userId', 'meta'],
        fetchCouponsSuccess: ['coupons'],
    },
    { prefix: 'coupons/' },
);

function fetchCouponsSuccess(state = initialState, action) {
    return produce(state, draft => {
        draft.coupons = action.coupons;
    });
}

export default createReducer(initialState, {
    [Types.FETCH_COUPONS_SUCCESS]: fetchCouponsSuccess,
});
