import React, { useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { processPaymentForCreative } from 'base';
import { useCreativePriceFor } from 'hooks/creative';
import AddCreditCardForm from 'organisms/AddCreditCardForm';
import CreditCardSelect from 'organisms/CreditCardSelect';
import CouponForm from 'components/CouponForm';
import CreativePaymentTable from 'organisms/CreativePaymentTable/CreativePaymentTable';

function useMutationPaymentCreative(creative) {
    const history = useHistory();
    const queryClient = useQueryClient();

    return useMutation(coupon => processPaymentForCreative({ creativeId: creative.id, coupon: coupon || null }), {
        onSuccess() {
            queryClient.invalidateQueries('getCreativeById').then(() => history.push('/central-criativa'));
        },
        onError(error) {
            Sentry.captureException(error);
            toast.error(error.message);
        },
    });
}

const PaymentFormSection = ({ creative }) => {
    const [showCardForm, toggleShowCardForm] = useReducer(state => !state, false);
    const { mutate: processPayment, isLoading: isProcessingPayment } = useMutationPaymentCreative(creative);

    const [selectedCoupon, setSelectedCoupon] = useState();

    const handleCouponSubmit = coupon => setSelectedCoupon(coupon);
    const handleCouponRemove = () => setSelectedCoupon();

    function handlePaymentClick(event) {
        event.preventDefault();
        return processPayment(selectedCoupon);
    }

    const { data: creativePrice, isLoading: isLoadingPrice } = useCreativePriceFor('tv', Number(creative.durationTime));

    return (
        <>
            <h2 className="tw-text-pink tw-normal-case tw-font-bold tw-my-4">Pagamento</h2>
            {showCardForm ? (
                <AddCreditCardForm className="" onCancel={toggleShowCardForm} onSave={toggleShowCardForm} />
            ) : (
                <>
                    <div className="tw-w-full tw-overflow-x-auto">
                        {isLoadingPrice ? (
                            <p className="tw-text-gray">Carregando valores...</p>
                        ) : (
                            <CreativePaymentTable
                                creative={creative}
                                price={creativePrice}
                                quantity={1}
                                description="Edição, criação e montagem de Comercial"
                                coupon={selectedCoupon}
                            />
                        )}

                        <div className="my-4">
                            <CouponForm
                                coupon={selectedCoupon}
                                grantTotal={creativePrice}
                                onSubmit={handleCouponSubmit}
                                onRemove={handleCouponRemove}
                            />
                        </div>

                        <p className="tw-mt-8 tw-mb-4">
                            Selecione o cartão cadastrado ou clique no ícone para cadastra um novo.{' '}
                        </p>
                        <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-x-4 tw-mb-4">
                            <CreditCardSelect />
                            <button
                                type="button"
                                onClick={toggleShowCardForm}
                                disabled={isLoadingPrice || isProcessingPayment}
                            >
                                <span className="tw-rounded-full tw-px-4 tw-py-2 tw-mr-2 tw-bg-pink tw-text-white">
                                    +
                                </span>
                                Adicionar novo cartão
                            </button>
                        </div>

                        <button
                            type="button"
                            className="button button-primary"
                            onClick={handlePaymentClick}
                            disabled={isLoadingPrice || isProcessingPayment}
                        >
                            Pagar
                        </button>
                    </div>
                </>
            )}
        </>
    );
};

export default PaymentFormSection;
