/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';

const DolarMenuIcon = () => {
    return (
        <svg
            width="49"
            height="49"
            viewBox="0 0 49 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: 36, height: 36 }}
        >
            <circle cx="24.1614" cy="24.5629" r="24.1142" fill="#7CC4AE" />
            <path
                d="M26.9893 23.8107C26.0048 23.2469 24.958 22.8173 23.9217 22.3716C23.3206 22.1138 22.7454 21.8131 22.2376 21.3943C21.2375 20.5673 21.4293 19.2248 22.6003 18.6932C22.932 18.5429 23.2792 18.4945 23.6315 18.4731C24.9891 18.3979 26.2794 18.6556 27.5075 19.2678C28.1189 19.5739 28.321 19.4772 28.5283 18.8167C28.7459 18.1187 28.9273 17.4098 29.1294 16.7064C29.2641 16.2338 29.0983 15.9224 28.6682 15.7237C27.8806 15.3639 27.0722 15.1062 26.2224 14.9666C25.1135 14.7893 25.1135 14.784 25.1083 13.6295C25.1031 12.0024 25.1031 12.0024 23.5279 12.0024C23.2999 12.0024 23.0719 11.997 22.8439 12.0024C22.1081 12.0239 21.9837 12.1581 21.963 12.926C21.9526 13.2697 21.963 13.6133 21.9578 13.9624C21.9526 14.9827 21.9474 14.9665 21.0044 15.321C18.7244 16.1801 17.3149 17.7911 17.1647 20.3686C17.03 22.6508 18.1803 24.192 19.9887 25.3143C21.1028 26.007 22.3361 26.4151 23.5175 26.9574C23.9787 27.1669 24.4191 27.4085 24.8026 27.7414C25.9374 28.7134 25.7301 30.3297 24.3829 30.9419C23.6626 31.2694 22.9009 31.35 22.1184 31.248C20.9111 31.0922 19.7556 30.7647 18.6674 30.1794C18.03 29.8357 17.8435 29.927 17.6259 30.6412C17.4393 31.2587 17.2735 31.8816 17.1077 32.5045C16.8849 33.3422 16.9678 33.5409 17.7399 33.9329C18.7244 34.4269 19.7763 34.6793 20.8489 34.8565C21.6884 34.9961 21.7143 35.0337 21.7246 35.9359C21.7298 36.344 21.7298 36.7574 21.735 37.1656C21.7402 37.6811 21.9785 37.9818 22.4915 37.9925C23.0719 38.0033 23.6574 38.0033 24.2378 37.9871C24.7145 37.9764 24.958 37.7079 24.958 37.2085C24.958 36.65 24.984 36.0862 24.9632 35.5277C24.9373 34.9585 25.1757 34.6686 25.7042 34.5182C26.9219 34.1745 27.9583 33.4979 28.7563 32.4884C30.9741 29.6961 30.1294 25.6096 26.9893 23.8107Z"
                fill="white"
            />
        </svg>
    );
};

export default DolarMenuIcon;
