import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useCurrentCampaign } from 'hooks/campaign';
import CheckboxCardField from 'molecules/CheckboxCardField';
import Section from './Section';

const socialClassClassA = 'socialClass.classA';
const socialClassClassB = 'socialClass.classB';
const socialClassClassC = 'socialClass.classC';
const socialClassClassD = 'socialClass.classD';
const socialClassClassE = 'socialClass.classE';

const SocialClassSelector = () => {
    const { formState, control } = useFormContext();
    const { errors } = formState;
    const currentCampaign = useCurrentCampaign();
    const socialClass = currentCampaign.targetAndSegmentation?.socialClass;

    return (
        <Section title="Classe Social *" error={errors.socialClass}>
            <div className="tw-flex tw-justify-start tw-align-middle tw-place-items-center tw-text-pink tw-flex-wrap">
                {errors.socialClasses && <p>{errors.socialClasses.message}</p>}
                <CheckboxCardField
                    name={socialClassClassA}
                    label="A"
                    className="tw-p-2 tw-mr-4 tw-mt-6"
                    defaultChecked={socialClass?.classA || false}
                    control={control}
                />

                <CheckboxCardField
                    name={socialClassClassB}
                    label="B"
                    className="tw-p-2 tw-mr-4 tw-mt-6"
                    defaultChecked={socialClass?.classB || false}
                    control={control}
                />

                <CheckboxCardField
                    name={socialClassClassC}
                    label="C"
                    className="tw-p-2 tw-mr-4 tw-mt-6"
                    defaultChecked={socialClass?.classC || false}
                    control={control}
                />

                <CheckboxCardField
                    name={socialClassClassD}
                    label="D"
                    className="tw-p-2 tw-mr-4 tw-mt-6"
                    defaultChecked={socialClass?.classD || false}
                    control={control}
                />
                <CheckboxCardField
                    name={socialClassClassE}
                    label="E"
                    className="tw-mt-6"
                    defaultChecked={socialClass?.classE || false}
                    control={control}
                />
            </div>
        </Section>
    );
};

export default SocialClassSelector;
