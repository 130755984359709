import React, { useCallback, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useQuery as useReactQuery } from 'react-query';
import { useCurrentCampaign } from 'hooks/campaign';
import { useQuery } from 'hooks/query';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import { getAllCoverageCities } from 'api/coverageCities';
import BrazilMap from 'components/BrazilMap/BrazilMap';
import Loading from 'components/Loading';
import { AnalyticsContext } from '../analyticsContext';

const errorMessage = 'Ops... Ainda não temos nenhum parceiro em sua região!';

async function getStatesWithPrograms() {
    const states = new Map();
    const snap = await getAllCoverageCities().get();

    const coverageCitiesPromises = snap.docs.map(async coverageCity => {
        const { programsRefs, state } = coverageCity.data();
        if (programsRefs?.length > 0) {
            const programReferencesPromise = programsRefs.map(programReference => programReference.get());
            const allPrograms = await Promise.all(programReferencesPromise);
            let availablePrograms = allPrograms
                .filter(program => program.exists)
                .map(program => [program.id, program.data()]);

            if (states.has(state)) {
                const existentPrograms = states.get(state);
                availablePrograms = [...existentPrograms.entries(), ...availablePrograms];
            }

            states.set(state, new Map(availablePrograms));
        }
    });

    await Promise.all(coverageCitiesPromises);

    return states;
}

function useAvailableStates() {
    return useReactQuery('getStatesWithPrograms', () => getStatesWithPrograms(), {
        refetchOnMount: false,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        refetchInterval: false,
        placeholderData: new Map(),
    });
}

const LocalizationStep = () => {
    const { campaignEditable: editable } = useCurrentCampaign();

    const dispatch = useDispatch();
    const { logEvent } = useContext(AnalyticsContext);
    const { data: availableStates, isSuccess, isFetching, isFetched } = useAvailableStates();

    const campaignVehicleType = useQuery().get('tipo') || 'TV';
    useEffect(() => {
        dispatch(CampaignCreators.setKind(campaignVehicleType.toUpperCase()));
    }, [campaignVehicleType, dispatch]);

    const handleSelectState = useCallback(
        (state, code) => {
            const statePrograms = availableStates.get(code);
            if (!statePrograms && statePrograms.size <= 0) {
                logEvent('state_selected_unavailable', { state, code });
                return toast.error(errorMessage);
            }

            logEvent('state_selected', { state, code });

            dispatch(CampaignCreators.addState({ state, code, programs: [...statePrograms.values()] }));
            dispatch(CampaignCreators.nextStep());
        },
        [availableStates, dispatch, logEvent],
    );

    const hasStates = isSuccess && availableStates.size > 0;
    const hasNoStates = isSuccess && isFetched && availableStates.size <= 0;

    return (
        <>
            <h3>Selecione o estado em que deseja anunciar</h3>
            {isFetching && <Loading />}
            {isFetching && <p className="tw-mt-2 tw-text-gray">Carregando mapa com programas disponíveis...</p>}
            {hasStates && (
                <BrazilMap
                    onSelect={handleSelectState}
                    availableStates={availableStates}
                    className="tw-pt-6 tw-pb-4 tw-w-full tw-pl-2"
                    disabled={!editable}
                >
                    <div className="tw-absolute tw-bottom-0 tw-left-16 tw-flex tw-flex-col tw-justify-start tw-place-content-start tw-place-items-start tw-text-sm">
                        <span>Legenda:</span>
                        <div className="tw-flex tw-align-middle tw-mt-2">
                            <span className="tw-w-6 tw-h-5 tw-bg-pink tw-block tw-mr-1" />
                            Disponível
                        </div>
                        <div className="tw-flex tw-align-middle tw-mt-2">
                            <span className="tw-w-6 tw-h-5 tw-bg-purple tw-block tw-mr-1" />
                            Selecionado
                        </div>
                    </div>
                </BrazilMap>
            )}
            {hasNoStates && <p>Nenhum programa disponível no momento!</p>}
        </>
    );
};

export default LocalizationStep;
