import produce from 'immer';
import { createReducer, createActions } from 'reduxsauce';

const initialState = {
    packages: [],
    package: null,
};

// Actions
export const { Types, Creators } = createActions(
    {
        fetchPackagesSuccess: ['packages'],
        savePackage: ['companyId', 'packageData', 'meta'],
        updatePackage: ['packageData', 'meta'],
        fetchPackages: ['companyId', 'meta'],
        deletePackage: ['packageId', 'meta'],
        setPackage: ['packageData'],
        fetchPackagesByRegionIdAndAvailability: ['regionId', 'meta'],
        getPackageById: ['packageId', 'meta'],
        getPackageByIdSuccess: ['packageItem'],
    },
    { prefix: 'packages/' },
);

// Reducer
function fetchPackagesSuccess(state = initialState, action) {
    return produce(state, draft => {
        draft.packages = action.packages;
    });
}

function setPackage(state = initialState, action) {
    return produce(state, draft => {
        draft.package = action.packageData;
    });
}

function getPackageByIdSuccess(state = initialState, action) {
    return produce(state, draft => {
        draft.package = action.packageItem;
    });
}

export default createReducer(initialState, {
    [Types.FETCH_PACKAGES_SUCCESS]: fetchPackagesSuccess,
    [Types.SET_PACKAGE]: setPackage,
    [Types.GET_PACKAGE_BY_ID_SUCCESS]: getPackageByIdSuccess,
});
