/* eslint-disable no-case-declarations */
export default (state, action) => {
    const testArray = [...state.transactions];
    switch (action.type) {
        case 'DELETE_TRANSACTION':
            return {
                ...state,
                transactions: state.transactions.filter(transaction => transaction.id !== action.payload),
            };
        case 'ADD_TRANSACTION':
            return {
                ...state,
                transactions: [action.payload, ...state.transactions],
            };
        case 'UPDATE_TRANSACTION_LABEL':
            testArray.splice(action.payload.index, 1, action.payload.newTransaction);
            return {
                transactions: [...testArray],
            };
        case 'UPDATE_CAMPANHAS':
            const replaceIndex = testArray.findIndex(info => info.id === action.payload.id);

            testArray[replaceIndex].programsSelecionados = action.payload.programs;
            return {
                transactions: [...testArray],
            };
        default:
            return state;
    }
};
