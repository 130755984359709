import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-awesome-modal';
import * as Sentry from '@sentry/react';
import { DevTool } from '@hookform/devtools';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useWatch } from 'react-hook-form';
import SelectInput from 'molecules/SelectInput/SelectInput';
import InputField from 'molecules/InputField';
import { Creators as ActionCreators } from 'store/modules/regions';
import { getCities } from 'src/services/ibge';
import { useRegion } from 'hooks/regions';
import states from 'utils/states';
import useRegionForm from './useRegionForm';
import { useCompanyForCurrentUser } from '../hooks';

const loadAllCitiesForState = async state => {
    const response = await getCities(state);
    return response.data.map(({ nome }) => ({
        label: nome,
        value: nome,
    }));
};

function useCitiesForState(state) {
    const [allCitiesForState, setAllCitiesForState] = useState([]);

    useEffect(() => {
        if (state?.value) {
            loadAllCitiesForState(state.value)
                .then(cities => setAllCitiesForState(cities))
                .catch(error => {
                    Sentry.captureException(error);
                    toast.error('Error ao carregar cidades.');
                });
        }
    }, [state]);

    return allCitiesForState;
}

const CitiesSelector = ({ control, setValue, region, error, disabled }) => {
    const state = useWatch({
        control,
        name: 'state',
    });

    useEffect(() => {
        const hasCities = region?.cities?.length > 0;
        if (hasCities) {
            setValue('cities', region.cities);
        }
    }, [region, setValue]);

    const allCitiesForState = useCitiesForState(state);

    return (
        <SelectInput
            label="Cidades*"
            isMulti
            options={allCitiesForState}
            name="cities"
            control={control}
            className="tw-mt-4"
            placeholder={<span>Selecionar cidades</span>}
            defaultValue={region.cities}
            error={error}
            disabled={disabled}
        />
    );
};

const CreateRegionModal = ({ showModal, toggleModal }) => {
    const dispatch = useDispatch();
    let region = useRegion();

    region = useMemo(
        () => ({
            ...region,
            cities:
                region?.cities?.map(city => ({
                    label: city,
                    value: city,
                })) || [],
            state: states.find(item => item.value === region?.state),
        }),
        [region],
    );

    const company = useCompanyForCurrentUser();

    const { register, errors, onSubmit, control, reset, isLoading, setValue, formState } = useRegionForm(region, {
        onSuccess() {
            handleCloseModal();
            toast.success('Região salva com sucesso!');
        },
        onError(error) {
            Sentry.captureException(error);
            toast.error(`Falha ao salvar região.`);
        },
        onMutate(variables) {
            const progressToastId = toast(`Salvando região "${variables.regionName}"...`, {
                type: toast.TYPE.INFO,
                hideProgressBar: false,
                progress: 0.5,
            });
            return { progressToastId };
        },
        onSettled(data, error, variable, context) {
            toast.done(context.progressToastId);
        },
    });

    const { isSubmitting } = formState;

    useEffect(() => {
        const hasState = !!region?.state;
        if (hasState) {
            setValue('state', region?.state);
        }
    }, [setValue, region]);

    const handleCloseModal = () => {
        dispatch(ActionCreators.getRegions(company.id));
        reset();
        dispatch(ActionCreators.setRegion(null));
        toggleModal();
    };

    return (
        <Modal visible={showModal} width="720" effect="fadeInUp" onClickAway={handleCloseModal}>
            <form className="tw-w-full tw-flex tw-flex-col tw-p-6" onSubmit={onSubmit}>
                <h3>Região de Cobertura</h3>
                <InputField
                    name="regionName"
                    ref={register}
                    label="Nome da Região*"
                    className="tw-mt-4"
                    inputClassName="tw-w-full tw-border-gray-500"
                    defaultValue={region?.regionName || ''}
                    error={errors?.regionName}
                    disabled={isSubmitting}
                />

                <SelectInput
                    label="Estado*"
                    isMulti={false}
                    options={states}
                    name="state"
                    control={control}
                    className="tw-mt-4"
                    placeholder={<span>Selecionar estado</span>}
                    defaultValue={region.state}
                    error={errors?.state}
                    disabled={isSubmitting}
                />

                <CitiesSelector
                    setValue={setValue}
                    control={control}
                    region={region}
                    error={errors?.cities}
                    disabled={isSubmitting}
                />

                <div className="tw-w-full tw-flex tw-justify-between md:tw-flex-row tw-flex-col tw-mt-3 md:tw-mt-16 tw-text-center">
                    <button
                        className="button button-primary-outlined"
                        type="button"
                        onClick={handleCloseModal}
                        disabled={isLoading}
                    >
                        Cancelar
                    </button>
                    <button className="button button-primary md:tw-mt-0 tw-mt-3" type="submit" disabled={isLoading}>
                        {isLoading ? 'Sincronizando...' : 'Salvar'}
                    </button>
                </div>

                {__DEVELOPMENT__ && <DevTool control={control} />}
            </form>
        </Modal>
    );
};

export default CreateRegionModal;
