import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import schema from './schema';
import { useQuery } from 'hooks/query';

function useBudgetForm() {
    const dispatch = useDispatch();
    const queryTipo = useQuery().get('tipo') || 'TV';
    const tipo = queryTipo.toLowerCase();

    const methods = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(
        values => {
            const { amount, budgetType } = values;

            if (budgetType === 'automatic') {
                dispatch(
                    CampaignCreators.addBudget({
                        ...values,
                        radioBudget: tipo === 'radio' ? amount : 0,
                        tvBudget: tipo === 'tv' ? amount : 0,
                    }),
                );
            } else {
                dispatch(CampaignCreators.addBudget(values));
            }
            dispatch(CampaignCreators.nextStep());
            dispatch(CampaignCreators.unlockStep('budget'));
        },
        [dispatch, tipo],
    );

    return { ...methods, onSubmit: methods.handleSubmit(onSubmit) };
}

export default useBudgetForm;
