import React from 'react';
import * as ReactDOM from 'react-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Modal = ({ children, className = '', onClose, ...rest }) => {
    const modalClass = clsx('tw-m-auto tw-mt-32 tw-z-50 tw-bg-white tw-relative tw-rounded-md', className);

    function handleClickOutside(event) {
        event.preventDefault();
        onClose();
    }

    return ReactDOM.createPortal(
        <div className="tw-flex tw-w-screen tw-h-screen tw-fixed tw-z-50 tw-top-1 tw-left-0 tw-overflow-auto" {...rest}>
            <div className="tw-w-screen tw-h-screen tw-bg-black tw-opacity-70 tw-fixed" onClick={handleClickOutside} />
            <div className={modalClass}>{children}</div>
        </div>,
        document.body,
    );
};

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default Modal;
