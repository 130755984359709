import React from 'react';

function ProgramInsertion({ program }) {
    const countInsertionsByDay = (showDays, day) =>
        showDays.reduce((total, showDay) => {
            if (showDay.day === day) {
                total += showDay?.insertions || 0;
            }
            return total;
        }, 0);

    return (
        <div className="tw-flex tw-flex-row">
            <p className="tw-flex tw-flex-col tw-font-bold">
                <span
                    className={countInsertionsByDay(program.showDays, 'sunday') > 0 ? 'tw-text-pink' : 'tw-text-gray'}
                >
                    D
                </span>
                <span className="tw-font-bold">{countInsertionsByDay(program.showDays, 'sunday') || ''}</span>
            </p>
            <p className="tw-flex tw-flex-col tw-ml-4 tw-font-bold">
                <span
                    className={countInsertionsByDay(program.showDays, 'monday') > 0 ? 'tw-text-pink' : 'tw-text-gray'}
                >
                    S
                </span>
                <span className="tw-font-bold">{countInsertionsByDay(program.showDays, 'monday') || ''}</span>
            </p>
            <p className="tw-flex tw-flex-col tw-ml-4 tw-font-bold">
                <span
                    className={countInsertionsByDay(program.showDays, 'tuesday') > 0 ? 'tw-text-pink' : 'tw-text-gray'}
                >
                    T
                </span>
                <span className="tw-font-bold">{countInsertionsByDay(program.showDays, 'tuesday') || ''}</span>
            </p>
            <p className="tw-flex tw-flex-col tw-ml-4 tw-font-bold">
                <span
                    className={
                        countInsertionsByDay(program.showDays, 'wednesday') > 0 ? 'tw-text-pink' : 'tw-text-gray'
                    }
                >
                    Q
                </span>
                <span className="tw-font-bold">{countInsertionsByDay(program.showDays, 'wednesday') || ''}</span>
            </p>
            <p className="tw-flex tw-flex-col tw-ml-4 tw-font-bold">
                <span
                    className={countInsertionsByDay(program.showDays, 'thursday') > 0 ? 'tw-text-pink' : 'tw-text-gray'}
                >
                    Q
                </span>
                <span className="tw-font-bold">{countInsertionsByDay(program.showDays, 'thursday') || ''}</span>
            </p>
            <p className="tw-flex tw-flex-col tw-ml-4 tw-font-bold">
                <span
                    className={countInsertionsByDay(program.showDays, 'friday') > 0 ? 'tw-text-pink' : 'tw-text-gray'}
                >
                    S
                </span>
                <span className="tw-font-bold">{countInsertionsByDay(program.showDays, 'friday') || ''}</span>
            </p>
            <p className="tw-flex tw-flex-col tw-ml-4 tw-font-bold">
                <span
                    className={countInsertionsByDay(program.showDays, 'saturday') > 0 ? 'tw-text-pink' : 'tw-text-gray'}
                >
                    S
                </span>
                <span className="tw-font-bold">{countInsertionsByDay(program.showDays, 'saturday') || ''}</span>
            </p>
        </div>
    );
}

export default ProgramInsertion;
