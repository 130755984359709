import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { parse } from 'date-fns';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import { ptBR } from 'date-fns/locale';
import format from 'date-fns/format';
import { currencyFormatter } from 'utils';

function generateInsertionsRow(days) {
    const rows = [];
    for (let day of days) {
        rows.push(...Array.from({ length: day.insertions }).fill(day));
    }
    return rows;
}

const ProgramRow = ({ day, defaultDate, index, program, campaign, value }) => {
    const { register, setValue, formState } = useFormContext();
    const { isSubmitting, isValidating, errors } = formState;
    const duration = campaign.objectivesAndDuration?.duration;
    const startCampaign = campaign.objectivesAndDuration.startDate.toDate() || campaign?.createdAt?.toDate();
    const endCampaign = campaign.objectivesAndDuration?.endDate?.toDate() || addDays(campaign?.createdAt?.toDate(), 30);
    const weekDay = parse(day.day, 'EEEE', startCampaign);

    const fieldName = `dates.${index}`;
    const errorForProgram = errors.dates && errors.dates[index];

    useEffect(() => {
        if (campaign.piSentAt) {
            register(`${fieldName}.date`);
            setValue(`${fieldName}.date`, new Date(defaultDate));
        }
    }, [campaign.piSentAt, defaultDate, fieldName, register, setValue]);

    return (
        <tr className="tw-h-11">
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                <p>{program.programName}</p>
                <input
                    type="text"
                    className="tw-hidden"
                    name={`${fieldName}.program`}
                    value={program.id}
                    readOnly={true}
                    ref={register}
                />

                {errorForProgram?.program && (
                    <p className="error-message tw-text-xs">{errorForProgram.program.message}</p>
                )}
            </td>
            <td align="center" className="tw-border-b-2 tw-border-gray-light tw-py-1">
                {`1 - (${duration}")`}
            </td>
            <td align="center" className="tw-border-b-2 tw-border-gray-light tw-py-1 tw-w-42">
                <span className="tw-text-pink tw-text-sm tw-font-bold">
                    {campaign.piSentAt
                        ? format(defaultDate, 'EEE', {
                              locale: ptBR,
                          }).slice(0, 3)
                        : format(weekDay, 'EEE', {
                              locale: ptBR,
                          }).slice(0, 3)}
                    .
                </span>

                {campaign.piSentAt ? (
                    <span className="tw-ml-2 tw-text-pink tw-text-sm tw-font-bold">
                        {format(defaultDate, 'yyyy-MM-dd')}
                    </span>
                ) : (
                    <input
                        type="date"
                        min={format(startCampaign, 'yyyy-MM-dd')}
                        max={format(endCampaign, 'yyyy-MM-dd')}
                        defaultValue={format(defaultDate, 'yyyy-MM-dd')}
                        className="tw-shadow tw-w-40 tw-p-2 tw-ml-2"
                        ref={register}
                        name={`${fieldName}.date`}
                        disabled={isSubmitting || isValidating}
                    />
                )}
                {errorForProgram?.date && <p className="error-message tw-text-xs">{errorForProgram.date.message}</p>}
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="center">
                {currencyFormatter.format(value)}
            </td>
        </tr>
    );
};
const ProgramRows = ({ campaign, index: programIndex, program }) => {
    const days = program.showDays.filter(day => day.insertions > 0);
    const insertions = generateInsertionsRow(days);

    const valueOfEachInsertion = program.insertionValue || 0;

    const availableDefaultDays = useMemo(() => {
        const availableDefaultDays = new Map();
        let startDate = campaign.objectivesAndDuration?.startDate?.toDate() || campaign?.createdAt?.toDate();
        let lastDate = campaign.objectivesAndDuration?.endDate?.toDate() || addDays(campaign?.createdAt?.toDate(), 30);
        const registeredDates = Array.from(campaign.approvedDates || []);
        for (const insertion of insertions) {
            let weekDay = parse(insertion.day, 'EEEE', startDate);
            if (weekDay > lastDate) {
                startDate = campaign.objectivesAndDuration.startDate.toDate();
                weekDay = parse(insertion.day, 'EEEE', startDate);
            }
            if (weekDay < startDate) {
                weekDay = addWeeks(weekDay, 1);
            }
            const registeredDate = registeredDates.shift();
            availableDefaultDays.set(insertion.day, registeredDate?.date.toDate() || weekDay);
            startDate = addDays(weekDay, 1);
        }
        return availableDefaultDays;
    }, [
        campaign.approvedDates,
        campaign.objectivesAndDuration.endDate,
        campaign.objectivesAndDuration.startDate,
        insertions,
        campaign?.createdAt,
    ]);

    return insertions.map((day, index) => (
        <ProgramRow
            key={`${program.id}-${day.day}-${index}`}
            day={day}
            defaultDate={availableDefaultDays.get(day.day)}
            index={index + programIndex}
            program={program}
            campaign={campaign}
            value={valueOfEachInsertion}
        />
    ));
};

const ProgramsTable = ({ campaign, programs }) => (
    <table className="tw-w-full tw-mt-6">
        <thead>
            <tr>
                <th className="tw-text-purple tw-text-left" align="left">
                    Programa
                </th>
                <th className="tw-text-purple" align="center">
                    Qtde - Sec.
                </th>
                <th className="tw-text-purple" align="center">
                    Data
                </th>
                <th className="tw-text-purple" align="center">
                    Valor
                </th>
            </tr>
        </thead>
        <tbody>
            {programs.map((program, index) => (
                <ProgramRows key={program.id} index={index} program={program} campaign={campaign} />
            ))}
        </tbody>
    </table>
);

export default ProgramsTable;
