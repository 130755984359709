import React from 'react';
import moment from 'moment';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { FaEdit } from 'react-icons/fa';
import formatRelative from 'date-fns/formatRelative';
import { ptBR } from 'date-fns/locale';
import { updateCampaign } from 'api/campaign';
import { firebase } from 'base';
import { currencyFormatter } from 'utils';
import { totalInsertionsForPrograms } from 'services/advertiser/campaign';
import ApproveInsertionsForm from './ApproveInsertionsForm';
import RequestPIButton from './RequestPIButton';
import ProgramsTable from './ProgramsTable';
import addDays from 'date-fns/addDays';

const ApproveInsertionsModal = ({ campaign, open, onClose, programs }) => {
    const insertionsCount =
        totalInsertionsForPrograms(programs) ||
        campaign?.packageItem?.programs.reduce((sum, program) => (sum += program.insertionQuantity), 0);
    const handleCancelClick = event => {
        event.preventDefault();
        onClose();
    };

    const startDateFormatted = moment(
        campaign.objectivesAndDuration?.startDate?.toDate() || campaign?.createdAt?.toDate(),
    )?.format('DD/MM/YYYY');
    const endDateFormatted = moment(
        campaign.objectivesAndDuration?.endDate?.toDate() || addDays(campaign?.createdAt?.toDate(), 30),
    )?.format('DD/MM/YYYY');

    function handleChangeDates(event) {
        event.preventDefault();
        return updateCampaign(campaign.id, {
            ...campaign,
            piSentAt: firebase.firestore.FieldValue.delete(),
        });
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ApproveInsertionsForm campaign={campaign} onApprove={onClose}>
                {({ formState: { isValidating, isSubmitting } }) => (
                    <div className="tw-p-1">
                        <h2 className="tw-text-pink">{campaign.piSentAt ? 'Aprovar Inserções' : 'Solicitar PI'}</h2>
                        <p>
                            Confira a <strong>disponibilidade</strong> dos espaços para confirmar as inserções no{' '}
                            <span className="tw-text-purple tw-font-bold">mapa abaixo</span>.
                        </p>
                        <div className="tw-text-sm tw-p-3 tw-shadow-md tw-rounded-md tw-flex md:tw-flex-row tw-flex-col md:tw-justify-between md:tw-items-center tw-mt-4">
                            <div>
                                <p>
                                    <span className="tw-text-pink tw-font-bold">Total de Comerciais: </span>
                                    <span className="tw-text-gray tw-font-bold">{insertionsCount} </span>
                                    <span className="tw-text-gray">inserções</span>
                                </p>
                            </div>
                            <div className="tw-flex md:tw-flex-row tw-flex-col">
                                <p>
                                    <span className="tw-text-pink tw-font-bold">Total: </span>
                                    <span className="tw-text-gray">
                                        {currencyFormatter.format(campaign.campaignValue)}
                                    </span>
                                </p>
                            </div>
                            <div>
                                <p>
                                    <span className="tw-text-pink tw-font-bold">Período: </span>
                                    <span className="tw-text-gray">
                                        {`${startDateFormatted} a ${endDateFormatted}`}
                                    </span>
                                </p>
                            </div>
                        </div>

                        {campaign.piSentAt && (
                            <div className="tw-text-sm tw-p-2 tw-shadow-sm tw-rounded-md tw-flex tw-place-content-center tw-justify-between tw-align-middle tw-items-center tw-mx-8 tw-mt-2">
                                <p className="tw-text-gray tw-flex-grow ">
                                    PI solicitado a{' '}
                                    {formatRelative(campaign.piSentAt.toDate(), new Date(), {
                                        locale: ptBR,
                                    })}
                                </p>
                                <button
                                    type="button"
                                    className="button button-small tw-ml-2 tw-flex tw-justify-center tw-place-content-center tw-align-middle tw-items-center"
                                    disabled={isValidating || isSubmitting}
                                    onClick={handleChangeDates}
                                >
                                    <FaEdit size={20} color="gray" />
                                    Alterar datas
                                </button>
                            </div>
                        )}

                        <div className="tw-w-full tw-overflow-x-auto">
                            <ProgramsTable campaign={campaign} programs={programs} />
                        </div>
                        <div className="tw-flex md:tw-flex-row tw-flex-col tw-justify-center md:tw-justify-between md:tw-items-center tw-mt-4">
                            <button
                                type="button"
                                className="button tw-w-full md:tw-w-1/2 tw-text-pink tw-border-2 tw-border-pink tw-mr-4"
                                onClick={handleCancelClick}
                            >
                                Cancelar
                            </button>
                            {campaign.piSentAt ? (
                                <button
                                    type="submit"
                                    className="button tw-w-full md:tw-w-1/2 tw-border-2 tw-border-pink button-primary md:tw-mt-0 tw-mt-3"
                                    data-button-type="approve"
                                    disabled={isValidating || isSubmitting}
                                >
                                    Confirmar
                                </button>
                            ) : (
                                <RequestPIButton data-button-type="pi" disabled={isValidating || isSubmitting} />
                            )}
                        </div>
                    </div>
                )}
            </ApproveInsertionsForm>
        </Modal>
    );
};

export default ApproveInsertionsModal;
