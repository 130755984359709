/* eslint-disable no-unused-vars */
import React from 'react';
import Modal from 'react-awesome-modal';

function DeleteDiscountModal({ showDeleteModal, toggleDeleteModal, handleDeleteDiscount }) {
    return (
        <Modal visible={showDeleteModal} width="568" effect="fadeInUp" onClickAway={toggleDeleteModal}>
            <div className="tw-p-9">
                <h3>Confirma a exclusão do desconto?</h3>
                <div className="tw-w-full tw-flex tw-justify-between md:tw-flex-row tw-flex-col tw-mt-3 md:tw-mt-16 tw-text-center">
                    <button className="button button-primary-outlined" onClick={toggleDeleteModal}>
                        Cancelar
                    </button>
                    <button className="button button-primary md:tw-mt-0 tw-mt-3" onClick={handleDeleteDiscount}>
                        Confirmar
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default DeleteDiscountModal;
