import React, { forwardRef, useReducer } from 'react';
import Modal from 'react-responsive-modal';
import { useDispatch } from 'react-redux';
import { currencyFormatter } from 'utils';
import { useCurrentCampaign } from 'hooks/campaign';
import AddCreditCardForm from 'organisms/AddCreditCardForm';
import CreditCardSelect from 'organisms/CreditCardSelect';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import CouponForm from 'components/CouponForm';

const INSTALLMENTS_MAXIMUM_ALLOWED = 3;

const InstallmentsSelector = forwardRef(({ amount, ...rest }, reference) => {
    const installmentsList = Array.from({ length: INSTALLMENTS_MAXIMUM_ALLOWED }).map((_, index) => index + 1);

    return (
        <select className="cimtia-select tw-w-2/5" {...rest} ref={reference}>
            {installmentsList.map(installments => (
                <option key={installments} value={installments}>
                    {installments}x {currencyFormatter.format(amount / installments)}
                </option>
            ))}
        </select>
    );
});
InstallmentsSelector.displayName = 'InstallmentsSelector';

const Payment = ({ children, paymentMethod, packageValue, setInstallments }) => {
    const [isOpenPaymentModal, toggleIsOpenPaymentModal] = useReducer(state => !state, false);

    const { coupon } = useCurrentCampaign();
    const dispatch = useDispatch();

    function openPaymentModal(event) {
        event.preventDefault();
        toggleIsOpenPaymentModal();
    }

    function handleCouponRemove() {
        dispatch(CampaignCreators.setCoupon(false));
    }
    function handleCouponSubmit(coupon) {
        dispatch(CampaignCreators.setCoupon(coupon));
    }
    const handleInstallmentsChange = event => {
        const installments = Number(event.currentTarget.value);
        if (setInstallments) {
            setInstallments(installments);
        } else {
            dispatch(CampaignCreators.setInstallments(installments));
        }
    };

    return (
        <div className="tw-w-full tw-my-6">
            {paymentMethod === 'credit-card' && (
                <>
                    {packageValue > 0 && (
                        <p className="tw-mt-4">
                            Selecione o cartão cadastrado ou clique no ícone para cadastra um novo.{' '}
                        </p>
                    )}
                    <div>
                        {packageValue > 0 && (
                            <div className="tw-flex tw-gap-x-4 tw-my-4 md:tw-flex-row tw-flex-col">
                                <div className="tw-w-96">
                                    <CreditCardSelect />
                                </div>
                                <div className="tw-my-6 md:tw-my-0">
                                    <InstallmentsSelector amount={packageValue} onChange={handleInstallmentsChange} />
                                </div>
                                <button onClick={openPaymentModal}>
                                    <span className="tw-rounded-full tw-px-4 tw-py-2 tw-mr-2 tw-bg-pink tw-text-white">
                                        +
                                    </span>
                                    Adicionar novo cartão
                                </button>
                            </div>
                        )}
                        <div className="tw-my-4">
                            <CouponForm
                                coupon={coupon}
                                grantTotal={packageValue}
                                onSubmit={handleCouponSubmit}
                                onRemove={handleCouponRemove}
                            />
                        </div>
                    </div>
                </>
            )}

            <div>{children}</div>

            <Modal open={isOpenPaymentModal} onClose={toggleIsOpenPaymentModal}>
                <AddCreditCardForm onCancel={toggleIsOpenPaymentModal} onSave={toggleIsOpenPaymentModal} />
            </Modal>
        </div>
    );
};

export default Payment;
