/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import Avatar from 'react-avatar';
import React from 'react';
import { useUser } from 'hooks/user';

function TopNav({ children, pageTitle }) {
    const user = useUser();

    return (
        <div
            className="tw-flex md:tw-ml-28 tw-fixed tw-inset-x-0 tw tw-items-center tw-place-content-between tw-align-middle tw-h-24 tw-px-10 tw-py-5 tw-bg-white"
            style={{ zIndex: 998 }}
        >
            <h1>
                {children}
                <span className="tw-font-bold md:tw-text-3xl tw-text-sm tw-normal-case">{pageTitle}</span>
            </h1>
            <div>
                {user?.profilePhoto ? (
                    <img src={user?.profilePhoto} alt="profilePhoto" className="tw-rounded-full tw-h-11 tw-w-11" />
                ) : (
                    <Avatar
                        name={user?.displayName}
                        email={user?.email}
                        size={44}
                        unstyled={true}
                        round={true}
                        className="tw-rounded-full"
                    />
                )}
            </div>
        </div>
    );
}

export default TopNav;
