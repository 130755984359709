import React from 'react';
import { currencyFormatter } from 'utils';

const CreativePriceDescription = ({ coupon, amountToPay }) => (
    <div className="tw-flex tw-justify-end tw-mt-3">
        <div className="tw-w-full md:tw-w-1/3 tw-bg-white tw-shadow-lg tw-flex tw-justify-between tw-p-2 tw-rounded-md">
            <p className="tw-text-sm tw-text-gray-500 tw-font-bold">1 Edição</p>
            {coupon && (
                <div>
                    Desconto:
                    <strong className="tw-text-pink tw-ml-2">{coupon.code}</strong>{' '}
                    {currencyFormatter.format(coupon.value)}
                </div>
            )}
            <p className="tw-text-sm">
                <span className="tw-text-pink tw-font-bold">Total: </span>
                {currencyFormatter.format(amountToPay > 0 ? amountToPay : 0)}
            </p>
        </div>
    </div>
);

export default CreativePriceDescription;
