import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 2rem;
`;

export const Title = styled.h1`
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 2rem;
    span {
        font-weight: 800;
    }
`;

export const Nav = styled.nav`
    ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        li {
            text-align: center;
            &:not(:first-child) {
                margin-left: 1rem;
            }
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 128px;
            max-width: 40%;
            height: 128px;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: #34495e;
                background: #bbd2c5; /* fallback for old browsers */
                background: -webkit-linear-gradient(to bottom, #bbd2c5, #536976); /* Chrome 10-25, Safari 5.1-6 */
                background: linear-gradient(to bottom, #bbd2c5, #536976);
                text-decoration: none;
                padding: 0 10px;
                font-weight: bold;
                transition: all 0.3s ease-in;
                border-radius: 1rem;
                -webkit-box-shadow: 0px 0px 5px 3px rgba(189, 195, 199, 1);
                -moz-box-shadow: 0px 0px 5px 3px rgba(189, 195, 199, 1);
                box-shadow: 0px 0px 5px 3px rgba(189, 195, 199, 1);
                &:hover {
                    background: #333;
                    color: #fff;
                }
            }
        }
    }
`;
