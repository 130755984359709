import firebase from 'firebase/app';
import * as Sentry from '@sentry/react';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/performance';
import 'firebase/remote-config';

const fbConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    databaseURL: FIREBASE_DATABASE,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
    measurementId: FIREBASE_MEASUREMENT_ID,
};

const app = firebase.initializeApp(fbConfig);

const auth = app.auth();

// auth.useDeviceLanguage();
auth.languageCode = 'pt_br';
auth.settings.appVerificationDisabledForTesting = __DEVELOPMENT__;
if (FIREBASE_AUTH_EMULATOR_HOST) {
    console.warn('Using local emulator AUTH "%s"', FIREBASE_AUTH_EMULATOR_HOST);
    auth.useEmulator(FIREBASE_AUTH_EMULATOR_HOST);
}
const firestore = app.firestore();

if (FIRESTORE_EMULATOR_PORT) {
    console.warn('Using local emulator FIRESTORE "%s"', FIRESTORE_EMULATOR_PORT);
    firestore.useEmulator('localhost', FIRESTORE_EMULATOR_PORT);
}
const defaultRegion = 'southamerica-east1';

const storage = app.storage();

const firebasePerformance = app.performance();
const functions = app.functions(defaultRegion);
const analytics = app.analytics();

analytics.setAnalyticsCollectionEnabled(__PRODUCTION__);

if (FIREBASE_STORAGE_EMULATOR_PORT) {
    console.warn('Using local emulator STORAGE "%s"', `localhost:${FIREBASE_STORAGE_EMULATOR_PORT}`);
    storage.useEmulator('localhost', FIREBASE_STORAGE_EMULATOR_PORT);
}

if (FUNCTIONS_EMULATOR_HOST) {
    console.warn('Using local emulator FUNCTIONS "%s"', `${FUNCTIONS_EMULATOR_HOST}:${FUNCTIONS_EMULATOR_PORT}`);
    functions.useEmulator(FUNCTIONS_EMULATOR_HOST, FUNCTIONS_EMULATOR_PORT);
}

export const remoteConfig = app.remoteConfig();
remoteConfig.defaultConfig = {
    payment_phone_number: '5561984022100',
    feature_payment_by_pix: false,
    feature_radio: false,
};
if (__DEVELOPMENT__) {
    remoteConfig.settings.minimumFetchIntervalMillis = 0;
}
remoteConfig.setLogLevel(__DEVELOPMENT__ ? 'debug' : 'silent');
remoteConfig.fetchAndActivate().catch(error => Sentry.captureException(error));

// Cloud functions
export const refuseCampaignBySupplier = functions.httpsCallable('refuseCampaignBySupplier');
export const approveCampaignBySupplier = functions.httpsCallable('approveCampaignBySupplier');
export const saveUserCreditCard = functions.httpsCallable('saveUserCreditCard');
export const deleteUserCreditCard = functions.httpsCallable('deleteUserCreditCard');
export const processPaymentForAdvertiser = functions.httpsCallable('processPaymentForAdvertiser');
export const processPaymentForCreative = functions.httpsCallable('processPaymentForCreative');

/**
 * Send PI by e-mail
 * @param {string} data.campaignId - The campaign ID
 * @type {firebase.functions.HttpsCallable}
 */
export const sendPiMail = functions.httpsCallable('sendPiMail');

/**
 * @param {string} data.couponCode - The coupon code
 * @type {firebase.functions.HttpsCallable}
 */
export const checkIsValidCoupon = functions.httpsCallable('checkIsValidCoupon');

/**
 * Calcule the amount of the discount
 * @param {string} data.couponCode - The coupon code
 * @param {number} data.totalAmount - The total amount to be payed
 * @type {firebase.functions.HttpsCallable}
 */
export const calculeAmountCouponDiscount = functions.httpsCallable('calculeAmountCouponDiscount');

performance.dataCollectionEnabled = __PRODUCTION__;
performance.instrumentationEnabled = __PRODUCTION__;

const provider = new firebase.auth.GoogleAuthProvider();

export default app;

// eslint-disable-next-line unicorn/prefer-export-from
export { firebase, auth, storage, analytics, provider, firestore, firebasePerformance };
