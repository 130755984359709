import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRegions } from 'hooks/regions';
import { useUserCompany } from 'hooks/company';
import { setProgram } from 'store/modules/program';
import programBaseInfoSchema from './programBaseInfoSchema';

function useBaseInfoForm(banners, program, handleNext) {
    const dispatch = useDispatch();
    const regions = useRegions();
    const company = useUserCompany();
    const [cities] = useState([]);

    const methods = useForm({
        resolver: yupResolver(programBaseInfoSchema),
        context: {
            company,
        },
        defaultValues: {
            ...programBaseInfoSchema.getDefaultFromShape(),
            programType: company?.type?.toUpperCase() || 'TV',
        },
    });

    const { register, setValue } = methods;

    useEffect(() => {
        register('programType');
        setValue('programType', company?.type?.toUpperCase() || 'TV');
    }, [company, register, setValue]);

    const onSubmit = useCallback(
        values => {
            if (banners.length === 0) {
                toast.error('Selecione ao menos uma imagem para o programa');
                return;
            }

            const payload = {
                ...program,
                ...values,
                cities,
                region: regions.find(item => item.id === values.programRegionId),
                banners,
                ageRange: `${values.ageRange.min},${values.ageRange.max}`,
            };
            dispatch(setProgram(payload));

            handleNext();
        },
        [banners, dispatch, cities, handleNext, program, regions],
    );

    return { ...methods, onSubmit: methods.handleSubmit(onSubmit) };
}

export default useBaseInfoForm;
