import React from 'react';
import { currencyFormatter } from 'utils';
import { totalInsertionsForPrograms } from 'services/advertiser/campaign';
import { useCurrentCampaign } from 'hooks/campaign';
import useCurrentCampaignGrantTotal from './useCurrentCampaignGrantTotal';

const CouponDiscountSection = ({ coupon }) => {
    const totalDiscount = currencyFormatter.format(Number(coupon.value * -1));
    const totalToPay = useCurrentCampaignGrantTotal(coupon);
    return (
        <>
            <div>
                <strong className="tw-text-pink">{coupon.code}</strong> {totalDiscount}
            </div>
            <div>
                <strong className="tw-text-pink">Valor a Pagar</strong>{' '}
                {currencyFormatter.format(totalToPay > 0 ? totalToPay : 0)}
            </div>
        </>
    );
};

const CampaignTotal = ({ programs }) => {
    const { coupon } = useCurrentCampaign();
    const totalInsertions = totalInsertionsForPrograms(programs);
    const grantTotalValue = useCurrentCampaignGrantTotal();
    const showMessage = coupon && grantTotalValue < coupon.value;
    return (
        <>
            <div className="tw-flex tw-justify-between tw-align-middle tw-place-content-between tw-bg-white tw-rounded-md tw-shadow-md tw-p-4">
                <div className="tw-text-gray">
                    <span className="tw-font-bold">{totalInsertions}</span> Comerciais
                </div>
                <div>
                    <div>
                        <strong className="tw-text-pink">Total:</strong>{' '}
                        {currencyFormatter.format(Number(grantTotalValue))}
                    </div>
                    {coupon && <CouponDiscountSection coupon={coupon} />}
                </div>
            </div>

            {showMessage && (
                <div className="tw-flex tw-justify-between tw-align-middle tw-place-content-between tw-bg-white tw-rounded-md tw-shadow-md tw-p-4 tw-mt-2">
                    <p className="tw-text-purple tw-text-sm my-2">
                        Atenção, a diferença do desconto não poderá ser usada posteriormente{' '}
                    </p>
                </div>
            )}
        </>
    );
};

export default CampaignTotal;
