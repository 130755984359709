import React, { useReducer, useState } from 'react';
import Modal from 'react-responsive-modal';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import Select from 'molecules/Select';
import { useUserId } from 'hooks/user';
import { updateCampaign } from 'api/campaign';
import { Creators as CampaignActionsCreators } from 'store/modules/campaign';
import { useCreativesForCampaign } from './hooks';
import NewCreativeButton from './NewCreativeButton';

function useUpdateCampaignWithCreative(campaignId, mutationOptions) {
    return useMutation(campaignToUpdate => updateCampaign(campaignId, campaignToUpdate), mutationOptions);
}

const CreativeModal = ({ openCreativeModal, toggleOpenCreativeModal, campaign }) => {
    const [creative, setCreative] = useState({});
    const [showSelectCreative, toggleShowSelectCreative] = useReducer(state => !state, false);

    const dispatch = useDispatch();
    const userId = useUserId();
    const creatives = useCreativesForCampaign(campaign);
    const { mutate, isLoading } = useUpdateCampaignWithCreative(campaign.id, {
        onSuccess() {
            dispatch(CampaignActionsCreators.getCampaignsByUserId(userId));
            toggleOpenCreativeModal();
            toast.success('CRIATIVO ENVIADO COM SUCESSO!!');
        },
    });

    const handleCancel = event => {
        event.preventDefault();
        return showSelectCreative ? toggleShowSelectCreative() : toggleOpenCreativeModal();
    };

    const onSubmit = () => {
        return mutate({
            ...campaign,
            status: 'PENDING_APPROVE_CREATIVE',
            creative,
        });
    };

    const handleCreativeChange = event => setCreative(creatives.find(item => item.id === event.target?.value));

    return (
        <Modal open={openCreativeModal} onClose={toggleOpenCreativeModal}>
            <form onSubmit={onSubmit}>
                <h1 className="tw-text-pink tw-normal-case tw-font-bold">Anexar Criativo a Campanha</h1>
                <p>Selecione a forma com que deseja anexar um criativo a sua Campanha.</p>

                <div className="tw-w-full tw-flex tw-justify-center tw-mt-8">
                    <div className="tw-w-full tw-flex tw-flex-col tw-p-5">
                        <div
                            className={`${
                                showSelectCreative ? 'tw-bg-pink' : 'tw-bg-white tw-shadow-creativeshadow'
                            } tw-w-full tw-flex tw-justify-center tw-flex-col tw-rounded-md tw-h-24 tw-px-6 tw-cursor-pointer`}
                            onClick={toggleShowSelectCreative}
                        >
                            <p
                                className={`${
                                    showSelectCreative ? 'tw-text-white' : 'tw-text-gray-700'
                                } tw-text-center tw-text-sm`}
                            >
                                Anexar um criativo da sua lista
                            </p>
                            <h3
                                className={`${
                                    showSelectCreative ? 'tw-text-white' : 'tw-text-gray-700'
                                } tw-text-center tw-font-bold  tw-text-base`}
                            >
                                Meus Criativos
                            </h3>
                        </div>
                        {showSelectCreative ? (
                            <>
                                <p className="tw-text-sm tw-text-gray-700 tw-mt-5">Criativos Cadastrados</p>
                                <Select className="tw-w-full tw-mt-1" onChange={handleCreativeChange}>
                                    <option value="" />
                                    {creatives.map(({ id, commercialName }) => (
                                        <option key={id} value={id}>
                                            {commercialName}
                                        </option>
                                    ))}
                                </Select>
                            </>
                        ) : (
                            <NewCreativeButton campaign={campaign} />
                        )}
                    </div>
                </div>
                <div className="tw-mt-3 md:tw-mt-16 tw-text-center">
                    <button type="button" className="button button-primary-outlined tw-mr-4" onClick={handleCancel}>
                        {showSelectCreative ? 'Voltar' : 'Cancelar'}
                    </button>
                    <button
                        type="submit"
                        className="button button-primary"
                        disabled={!showSelectCreative || !creative.id || isLoading}
                    >
                        Anexar
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default CreativeModal;
