/* eslint-disable unicorn/prefer-module */
import './wdyr'; // <--- first import
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { IntercomProvider } from 'react-use-intercom';
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import 'react-toastify/dist/ReactToastify.css';
import { firebase } from 'base';
import 'utils/sendWebVitalsToFirebase';
import store from 'store';
import App from './App';
import styleConfig from './style_config.json';

const rrf = {
    firebase,
    config: {
        userProfile: 'users',
        useFirestoreForProfile: true,
    },
    dispatch: store.dispatch,
    createFirestoreInstance,
};

console.log(
    `%c${styleConfig['name']} \n%c VERSION %c ${__VERSION__}`,
    'font-size: 12px; color: #CD0E4B; border-right: 0;',
    'border: 1px solid #e5e5e5; font-size: 14px; background: #CD0E4B; color: #fff; border-right: 0;',
    'border: 1px solid #e5e5e5; font-size: 14px; border-left: 0; color: #666',
);

const root = document.querySelector('#root');
const zIndexToast = 99999;
const toastStyle = { zIndex: zIndexToast };
// eslint-disable-next-line react/display-name
const renderApp = AppComponent => () =>
    render(
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
            <Provider store={store}>
                <ReactReduxFirebaseProvider {...rrf}>
                    <ToastContainer style={toastStyle} />
                    <AppComponent />
                </ReactReduxFirebaseProvider>
            </Provider>
        </IntercomProvider>,
        root,
    );

requestAnimationFrame(renderApp(App));
