import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';
import { toast } from 'react-toastify';
import yup from 'validator';
import { signIn } from 'store/modules/auth';
import LockIcon from 'icons/LockIcon';
import UserIcon from 'icons/UserIcon';
import InputFormControl from './InputFormControl';

const schema = yup.object().shape({
    email: yup.string().email().defined().required().label('E-mail'),
    password: yup.string().defined().required().min(3).label('Senha'),
});

function useLoginForm() {
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();
    const history = useHistory();

    const onSubmit = ({ email, password }) => {
        setIsLoading(true);
        dispatch(
            signIn({ email, password }, error => {
                setIsLoading(false);
                if (error) {
                    return toast.error(error.message);
                }
                return history.push('/');
            }),
        );
    };

    return { ...methods, isLoading, onSubmit: methods.handleSubmit(onSubmit) };
}

const LoginForm = () => {
    const { onSubmit, isLoading, register, control, formState } = useLoginForm();
    const { errors, isSubmitting } = formState;

    return (
        <form onSubmit={onSubmit}>
            <InputFormControl
                label="E-mail"
                name="email"
                error={errors.email}
                type="email"
                placeholder="seu@email.com"
                className="tw-my-8"
                ref={register}
                autoComplete="email"
                disabled={isLoading || isSubmitting}
                icon={<UserIcon className="tw-text-purple" />}
            />
            <InputFormControl
                label="Senha"
                name="password"
                error={errors.password}
                type="password"
                ref={register}
                autoComplete="password"
                disabled={isLoading || isSubmitting}
                icon={<LockIcon className="tw-text-purple" />}
            />

            <Link to="recuperar-senha" className="tw-text-right tw-block tw-font-montserrat tw-mb-10 tw-mt-2 link-text">
                Esqueceu a senha?
            </Link>

            <button
                type="submit"
                className="button button-primary tw-block tw-w-full"
                disabled={isLoading || isSubmitting}
            >
                Login
            </button>

            {__DEVELOPMENT__ && <DevTool control={control} />}
        </form>
    );
};

export default LoginForm;
