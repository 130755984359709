/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { usePackage } from 'hooks/packages';
import { useQuery } from 'hooks/query';
import { Creators as PackagesActionCreators } from 'store/modules/packages';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import Loading from 'components/Loading';
import { getProgramById } from 'api/programs';
import { useUser } from 'hooks/user';
import Payment from './Payment';
import PaymentMethodSelector from 'components/shared/organisms/PaymentMethodSelector';

function BuyPackage() {
    const [loading, setLoading] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState('credit-card');
    const [installments, setInstallments] = useState(1);

    const packageItem = usePackage();
    const packageId = useQuery().get('id');
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useUser();

    const handleCancel = useCallback(() => {
        history.push('/');
    }, [history]);

    const handleBuyPackage = () => {
        setLoading(true);

        function handleCampaigSaved(documentReference, error) {
            if (error) {
                toast.error(error.message || 'Erro no PAGAMENTO! Confira os dados e tente novamente.');
            }

            if (documentReference) {
                toast.success('Campanha salva com sucesso!');
                history.push('/campanhas');
            }
        }

        function stopLoading() {
            setLoading(false);
        }

        dispatch(
            CampaignCreators.saveCampaignFromPackage(handleCampaigSaved, {
                onSuccess: stopLoading,
                onFailure: stopLoading,
            }),
        );
    };

    const saveCampaign = useCallback(async () => {
        try {
            setLoading(true);
            const programs = [];
            for await (const packageProgram of packageItem.programs) {
                const program = await getProgramById(packageProgram.programId).get();
                if (program.exists) {
                    programs.push({ id: program.id, ...program.data() });
                }
            }
            const campaign = {
                objectivesAndDuration: {
                    duration: packageItem.duration,
                    city: user.address.city,
                },
                budget: {
                    budgetType: 'manual',
                },
                mediaCentral: {
                    selectedTVPrograms: programs
                        ?.filter(program => program.programType === 'TV')
                        ?.map(program => ({ ...program, insertionCost: 0, insertionValue: 0, insertionDiscount: 0 })),
                    selectedRadioPrograms: programs
                        ?.filter(program => program.programType === 'RADIO')
                        ?.map(program => ({ ...program, insertionCost: 0, insertionValue: 0, insertionDiscount: 0 })),
                },
                campaignValue: packageItem.packageValue,
                localization: {
                    code: user.address.state,
                },
                packageItem,
                paymentMethod,
                installments,
            };
            dispatch(CampaignCreators.setNewCurrentCampaign(campaign));
        } catch {
            toast.error('Falha ao carregar dados!');
            setTimeout(() => {
                history.push('/');
            }, 1000);
        } finally {
            setLoading(false);
        }
    }, [dispatch, packageItem, history, user, paymentMethod, installments]);

    const handlePaymentMethodSelected = useCallback(paymentMethodSelected => {
        setPaymentMethod(paymentMethodSelected);
    }, []);

    useEffect(() => {
        if (packageId) {
            dispatch(
                PackagesActionCreators.getPackageById(packageId, {
                    onSuccess: () => setLoading(false),
                    onFailure: handleCancel,
                }),
            );
        }
    }, [packageId, handleCancel, dispatch]);

    useEffect(() => {
        if (packageItem && user?.address) {
            saveCampaign();
        }
    }, [packageItem, saveCampaign, user]);

    return (
        <>
            {loading && <Loading />}
            <div className="tw-w-full tw-overflow-x-auto tw-mb-9 tw-h-full tw-relative">
                <h2>{packageItem?.packageName}</h2>
                <p>{packageItem?.description}</p>
                <div className="tw-relative tw-mt-6" style={{ maxWidth: 234 }}>
                    <img
                        src={packageItem?.packageImage}
                        alt="image"
                        width="217"
                        height="152"
                        className="tw-mr-3 tw-mb-3 tw-rounded-md tw-cursor-pointer tw-border hover:tw-border-pink"
                    />
                </div>
                <div className="md:tw-h-53 tw-overflow-y-auto tw-overflow-x-auto">
                    {packageItem?.programs?.length > 0 && (
                        <table className="tw-w-full tw-mt-6">
                            <thead>
                                <tr>
                                    <th className="tw-text-purple tw-text-left">Nome do Programa</th>
                                    <th className="tw-text-purple tw-text-left">Período</th>
                                    <th className="tw-text-purple" align="center">
                                        Quantidade de inserções
                                    </th>
                                    <th className="tw-text-purple" align="center">
                                        Duração de cada anúncio
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {packageItem.programs.map(({ programId, programName, insertionQuantity, period }) => (
                                    <tr key={programId}>
                                        <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                                            <p>{programName}</p>
                                        </td>
                                        <td className="tw-border-b-2 tw-border-gray-light tw-py-1">{period}</td>
                                        <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="center">
                                            {insertionQuantity}
                                        </td>
                                        <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="center">
                                            {`${packageItem?.duration} Seg.`}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <h3 className="tw-mt-4">Formas de pagamento</h3>
                <PaymentMethodSelector
                    onPaymentMethodSelect={handlePaymentMethodSelected}
                    paymentMethod={paymentMethod}
                />
                <Payment
                    paymentMethod={paymentMethod}
                    packageValue={packageItem?.packageValue || 0}
                    setInstallments={setInstallments}
                />
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-mt-8">
                    <button
                        style={{ padding: 10 }}
                        className="button button-primary-outlined tw-mr-4 tw-text-xs md:tw-w-1/4 tw-w-full"
                        onClick={handleCancel}
                    >
                        Cancelar
                    </button>
                    <button
                        style={{ padding: 10 }}
                        className="button button-primary tw-text-xs md:tw-w-1/4 tw-w-full md:tw-mt-0 tw-mt-2"
                        onClick={handleBuyPackage}
                        disabled={loading}
                    >
                        PAGAR
                    </button>
                </div>
            </div>
        </>
    );
}

export default BuyPackage;
