import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentStep } from 'hooks/program';
import Wizard from 'components/Wizard';
import { setStep } from 'store/modules/program';
import BaseInfo from './Steps/BaseInfo';
import Audience from './Steps/Audience';
import Schedule from './Steps/Schedule';
import { useCompanyForCurrentUser } from '../hooks';

const Program = () => {
    const dispatch = useDispatch();
    const step = useCurrentStep();

    useCompanyForCurrentUser();

    const handleNext = useCallback(() => dispatch(setStep(step + 1)), [dispatch, step]);
    const handleBack = useCallback(() => dispatch(setStep(step - 1)), [dispatch, step]);

    return (
        <div className="tw-w-full">
            <Wizard step={step}>
                <Wizard.Step title="Informações">
                    <BaseInfo handleNext={handleNext} />
                </Wizard.Step>
                <Wizard.Step title="Segmentação">
                    <Audience handleNext={handleNext} handleBack={handleBack} />
                </Wizard.Step>
                <Wizard.Step title="Programação">
                    <Schedule handleNext={handleNext} handleBack={handleBack} />
                </Wizard.Step>
            </Wizard>
        </div>
    );
};

export default Program;
