import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.label`
    background: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-top: 20px;
    border-radius: 5px;
    border: 1px dashed #707070;
    color: #2a2a2a;
    font-weight: 500;
    font-size: 18px;
    transition: all 0.3s;

    svg {
        margin-right: 12px;
    }

    &:hover {
        background: ${shade(0.2, 'rgba(255, 255, 255, 0.6)')};
    }

    input[type='file'] {
        opacity: 0;
        z-index: -1;
        position: absolute;
    }
`;
