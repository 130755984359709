import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';

const Select = forwardRef(({ label, name, error, className = '', children, ...rest }, reference) => (
    <div className={`form-control ${className}`}>
        <label className="tw-font-semibold" htmlFor={name}>
            {label}
        </label>
        <div>
            <select name={name} className="cimtia-select" ref={reference} {...rest}>
                {children}
            </select>
        </div>
        {error && <p className="error-message">{error.message}</p>}
    </div>
));

Select.displayName = 'Select';

Select.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
    }),
    className: PropTypes.string,
};

Select.defaultProps = {
    className: '',
};

export default memo(Select);
