import { firestore } from 'base';

export const getRegionById = async id => {
    return firestore.collection('coverageRegions').doc(id).get();
};

export const getRegions = async companyId => {
    return firestore.collection('coverageRegions').where('companyId', '==', companyId).get();
};

export const createRegion = async data => {
    return firestore.collection('coverageRegions').add(data);
};

export const updateRegion = async data => {
    return firestore.collection('coverageRegions').doc(data.id).update(data);
};

export const deleteRegion = async regionId => {
    return firestore.collection('coverageRegions').doc(regionId).delete();
};

export const getRegionByCityAndState = async (city, state) => {
    console.log('city', city);
    console.log('state', state);
    return firestore
        .collection('coverageRegions')
        .where('cities', 'array-contains-any', [city])
        .where('state', '==', state)
        .get();
};
