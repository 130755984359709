import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getCoupons, saveCoupon, deleteCoupon } from 'api/coupons';
import { Types, Creators } from 'store/modules/coupons';

function* getCouponsSaga(action) {
    const { meta, userId } = action;

    const onSuccess = meta?.onSuccess;
    const onFailure = meta?.onFailure;

    try {
        const snapshot = yield call(getCoupons, userId);
        const documents = snapshot.docs.map(document_ => {
            return {
                id: document_.id,
                ...document_.data(),
            };
        });

        yield put(Creators.fetchCouponsSuccess(documents));

        if (onSuccess) yield call(onSuccess);
    } catch {
        if (onFailure) yield call(onFailure);
    }
}

function* saveCouponSaga(action) {
    const { meta, coupon } = action;

    const onSuccess = meta?.onSuccess;
    const onFailure = meta?.onFailure;

    try {
        yield call(saveCoupon, coupon);
        if (onSuccess) yield call(onSuccess);
    } catch {
        if (onFailure) yield call(onFailure);
    }
}

function* deleteCouponSaga(action) {
    const { meta, id } = action;

    const onSuccess = meta?.onSuccess;
    const onFailure = meta?.onFailure;

    try {
        yield call(deleteCoupon, id);
        if (onSuccess) yield call(onSuccess);
    } catch {
        if (onFailure) yield call(onFailure);
    }
}

function* couponRoot() {
    yield all([
        takeLatest(Types.FETCH_COUPONS, getCouponsSaga),
        takeLatest(Types.SAVE_COUPON, saveCouponSaga),
        takeLatest(Types.DELETE_COUPON, deleteCouponSaga),
    ]);
}

export default couponRoot;
