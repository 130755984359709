import React, { useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-awesome-modal';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Creators as ActionsCreators } from 'store/modules/creative';
import { useCurrentCreative } from 'hooks/creative';
import Select from 'molecules/Select';
import PlayIcon from 'icons/PlayIcon';
import CheckIcon from 'icons/CheckIcon';
import { useCampaignByQueryString, useVideosPrime } from './hooks';
import { useFeatureFlagRadio } from 'hooks/config';

const times = [
    // { label: '10 segundos', value: 10 },
    { label: '15 segundos', value: 15 },
    { label: '30 segundos', value: 30 },
    { label: '60 segundos', value: 60 },
];

const kinds = [
    { label: 'TV', value: 'TV' },
    { label: 'Radio', value: 'RADIO' },
];

const VideoCard = ({ item, selected, onSelect }) => {
    const [showVideo, toggleShowVideo] = useReducer(state => !state, false);

    function handleSelectClick(event) {
        event.preventDefault();
        onSelect(item);
    }
    return (
        <div className="tw-max-w-xs tw-mb-8 md:tw-mr-8">
            <div className="tw-flex tw-flex-col tw-relative">
                <h3 className="tw-text-gray-700 tw-mb-3">{item.name?.split(' - ')[0]}</h3>
                <div className={`${selected ? 'tw-border tw-border-purple' : ''} tw-shadow-xl tw-rounded-xl`}>
                    <PlayIcon
                        className="tw-absolute tw-bottom-14 tw-left-4 tw-cursor-pointer tw-z-50"
                        onClick={toggleShowVideo}
                    />
                    <video src={item.url} className="tw-rounded-t-xl" />
                    <div className="tw-w-full tw-h-11 tw-flex tw-justify-between tw-items-center tw-px-3">
                        <p>{item.name}</p>
                        <CheckIcon checked={selected} onClick={handleSelectClick} />
                    </div>
                </div>
            </div>

            <Modal visible={showVideo} key={item.id} width="710" effect="fadeInUp" onClickAway={toggleShowVideo}>
                <video src={item.url} controls />
            </Modal>
        </div>
    );
};
VideoCard.propTypes = {
    item: PropTypes.any,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
};

const SelectOptions = ({ label, name, optiopns, onChange, value, children, ...rest }) => {
    const handleChange = event => onChange(event.target.value);

    return (
        <div className="tw-max-w-xs tw-mt-9">
            <label htmlFor={name} className="tw-text-purple tw-font-bold tw-mb-2 tw-block">
                {label}
            </label>
            <Select name={name} onChange={handleChange} value={value} {...rest}>
                {children}
                {optiopns.map(({ label, value }) => (
                    <option key={label + value} value={String(value)}>
                        {label}
                    </option>
                ))}
            </Select>
        </div>
    );
};
SelectOptions.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    label: PropTypes.string.isRequired,
    campaign: PropTypes.any,
};

const Templates = () => {
    const [selectedVideo, setSelectedVideo] = useState('');
    const [time, setTime] = useState(0);
    const [kind, setKind] = useState();

    const dispatch = useDispatch();
    const creative = useCurrentCreative();
    const videos = useVideosPrime(Number(time), kind);
    const currentCampaign = useCampaignByQueryString();

    const handleSelectVideo = item => {
        if (!time) {
            toast.warn('Selecione uma duração!');
            return;
        }
        setSelectedVideo(item);
    };

    const handleNext = () => {
        dispatch(
            ActionsCreators.setMedia({
                media: selectedVideo,
                durationTime: Number(time),
                creativeType: kind || 'TV',
            }),
        );
        dispatch(ActionsCreators.goNextStep());
    };

    useEffect(() => {
        if (creative?.durationTime) {
            setTime(creative.durationTime);
        }

        if (creative?.media) {
            setSelectedVideo(creative?.media);
        }
    }, [creative]);

    useEffect(() => {
        if (currentCampaign?.objectivesAndDuration?.duration) {
            setTime(currentCampaign.objectivesAndDuration.duration);
        }
    }, [currentCampaign]);

    const { data: isFeatureRadioEnabled } = useFeatureFlagRadio();

    return (
        <div>
            <h2 className="tw-text-pink tw-normal-case tw-font-bold">Selecione seu Template</h2>
            <p>
                Selecione o tempo do seu criativo no menu abaixo para ver a versão correspondente na lista de templates
                .{' '}
            </p>

            <div className="tw-flex tw-gap-x-2">
                <SelectOptions optiopns={times} label="Duração" name="durationSelector" onChange={setTime} value={time}>
                    <option value="">Escolha a duração</option>
                </SelectOptions>
                {isFeatureRadioEnabled && (
                    <SelectOptions optiopns={kinds} label="Tipo" name="kindSelector" onChange={setKind} value={kind}>
                        <option value="">Escolha o tipo</option>
                    </SelectOptions>
                )}
            </div>

            {videos.length > 0 && (
                <div className="tw-w-full tw-flex tw-flex-wrap tw-mt-12 tw-justify-center">
                    {videos.map(item => (
                        <VideoCard
                            key={item.id}
                            item={item}
                            selected={selectedVideo.id === item.id}
                            onSelect={handleSelectVideo}
                        />
                    ))}
                </div>
            )}

            <div className="tw-my-10 tw-text-center">
                <Link className="button button-primary-outlined tw-mr-4" to="/central-criativa">
                    Voltar
                </Link>
                <button disabled={!selectedVideo?.id} className="button button-primary" onClick={handleNext}>
                    Prosseguir
                </button>
            </div>
        </div>
    );
};

export default Templates;
