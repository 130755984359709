import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import addWeeks from 'date-fns/addWeeks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import schema from './schema';

function useObjectivesAndDurationForm() {
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: schema.getDefaultFromShape(),
    });

    const dispatch = useDispatch();

    const onSubmit = ({ startDate, campaignWeeks, city: { value: city }, ...others }) => {
        const payload = {
            startDate,
            endDate: addWeeks(startDate, campaignWeeks),
            campaignWeeks,
            city,
            ...others,
        };

        dispatch(CampaignCreators.addObjectivesAndDuration(payload));
        dispatch(CampaignCreators.unlockStep('objectivesAndDuration'));
        dispatch(CampaignCreators.nextStep());
        dispatch(CampaignCreators.getPrograms(city));
    };

    return { ...methods, onSubmit: methods.handleSubmit(onSubmit) };
}
export default useObjectivesAndDurationForm;
