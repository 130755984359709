import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Loading from 'react-fullscreen-loading';
import { auth } from 'base';
import { signOut } from 'store/modules/auth';
import styleConfig from '../../../style_config.json';

const EmailConfirmation = () => {
    const [sendingEmail, setSendingEmail] = useState(true);

    const dispatch = useDispatch();

    const handleSignOut = () => dispatch(signOut());
    const handleSendEmailVerification = useCallback(() => {
        if (
            !auth.currentUser.emailVerified &&
            !['ndtv@cimtia.com', 'admin@admin.com', 'ridermansb@gmail.com'].includes(auth.currentUser.email.toString())
        ) {
            setSendingEmail(true);
            auth.currentUser
                .sendEmailVerification()
                .then(() => {
                    setSendingEmail(false);
                })
                .catch(() => {
                    toast.error('Ocorreu um erro, tente novamente');
                    setSendingEmail(false);
                });
        }
    }, []);

    useEffect(() => {
        handleSendEmailVerification();
    }, [handleSendEmailVerification]);

    return sendingEmail ? (
        <Loading loading background="rgba(0,0,0,0.1)" loaderColor="#E5195A" tex />
    ) : (
        <div className="tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center">
            <div className="tw-max-w-sm tw-flex tw-flex-col">
                <div className="tw-flex tw-justify-center tw-place-content-center tw-items-center">
                    <img
                        src={styleConfig['logo-vertical.svg']}
                        alt="Logo vertical"
                        className="tw-w-2/6 tw-h-20 tw-max-w-max tw-fill-current tw-text-white"
                    />
                </div>
                <div className="tw-p-4 tw-flex tw-flex-col tw-justify-between tw-h-full">
                    <h2 className="tw-text-center">Verifique seu e-mail.</h2>
                    <h4 className="tw-text-center">Um e-mail de verificação foi enviado.</h4>
                </div>
                <button className="button button-primary tw-mt-4 tw-mx-4" onClick={handleSendEmailVerification}>
                    {!sendingEmail ? ' Reenviar e-mail de verificação' : 'Reenvinado e-mail...'}
                </button>
                <button className="button tw-mt-4 tw-self-center" onClick={handleSignOut}>
                    Voltar
                </button>
            </div>
        </div>
    );
};

export default EmailConfirmation;
