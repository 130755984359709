import React from 'react';
import TVIcon from './icons/TVIcon';
import DolarIcon from './icons/DolarIcon';
import CheckIcon from './icons/CheckIcon';
import CameraIcon from './icons/CameraIcon';
import ClaqueteIcon from './icons/ClaqueteIcon';
import { CAMPAIGN_STATUS } from 'services/advertiser/campaign';

const IconsBreadsCrumb = ({ campaign }) => (
    <>
        <DolarIcon
            className="tw-ml-3 tw-w-5 tw-h-5"
            color={
                [CAMPAIGN_STATUS.pendingPayment, CAMPAIGN_STATUS.refused].includes(campaign?.status)
                    ? '#D8D8D8'
                    : 'purple'
            }
        />
        <span className="tw-text-customgray">&nbsp;{'>'}&nbsp;</span>
        <TVIcon
            className="tw-w-5 tw-h-5"
            color={
                ![
                    CAMPAIGN_STATUS.pendingCreative,
                    CAMPAIGN_STATUS.pendingApproveCreative,
                    CAMPAIGN_STATUS.approved,
                    CAMPAIGN_STATUS.finished,
                ].includes(campaign?.status)
                    ? '#D8D8D8'
                    : 'purple'
            }
        />
        <span className="tw-text-customgray">&nbsp;{'>'}&nbsp;</span>
        <CameraIcon
            className="tw-w-5 tw-h-5"
            color={
                ![CAMPAIGN_STATUS.pendingApproveCreative, CAMPAIGN_STATUS.approved, CAMPAIGN_STATUS.finished].includes(
                    campaign?.status,
                )
                    ? '#D8D8D8'
                    : 'purple'
            }
        />
        <span className="tw-text-customgray">&nbsp;{'>'}&nbsp;</span>
        <ClaqueteIcon
            className="tw-w-5 tw-h-5"
            color={
                ![CAMPAIGN_STATUS.approved, CAMPAIGN_STATUS.finished].includes(campaign?.status) ? '#D8D8D8' : 'purple'
            }
        />
        <span className="tw-text-customgray">&nbsp;{'>'}&nbsp;</span>
        <CheckIcon
            className="tw-w-5 tw-h-5"
            color={![CAMPAIGN_STATUS.finished].includes(campaign?.status) ? '#D8D8D8' : '#e5195a'}
        />
    </>
);

export default IconsBreadsCrumb;
