import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUserId } from 'hooks/user';
import { useCreative } from 'hooks/creative';
import { Creators as ActionsCreators } from 'store/modules/creative';
import CreativesList from './CreativesList';

function useCreativesForCurrentUser() {
    const dispatch = useDispatch();
    const userId = useUserId();

    const creatives = useCreative();
    useEffect(() => {
        dispatch(ActionsCreators.getCreatives(userId));
    }, [dispatch, userId]);
    return creatives;
}

const List = () => {
    const isLoading = useSelector(state => state.creative.isLoadingCreatives);
    const creatives = useCreativesForCurrentUser();

    return (
        <div className="tw-w-full">
            <h1 className="tw-text-pink tw-normal-case tw-font-bold">Meus criativos</h1>
            {isLoading && <p className="tw-text-gray tw-mb-4">Carregando criativos...</p>}
            <CreativesList creatives={creatives} />
        </div>
    );
};

export default List;
