import React, { useMemo } from 'react';
import { useCompanies, useCurrentCampaign } from 'hooks/campaign';
import format from 'date-fns/format';
import DayView from '../../DayView';
import { getInsertionsCount, getProgramInsertionValue } from 'services/advertiser/campaign';
import { currencyFormatter } from 'utils';
import { useUser } from 'hooks/user';

function totalCommercialsValueFor(insertions, program, companies, duration, userCNPJ, budgetType) {
    let insertionValue = program.insertionValue;
    if (!insertionValue) {
        insertionValue = getProgramInsertionValue(companies, program, duration, userCNPJ, budgetType);
    }
    return insertionValue * insertions;
}

const ProgramRow = ({ program }) => {
    const companies = useCompanies();
    const user = useUser();
    const { objectivesAndDuration, budget } = useCurrentCampaign();
    const comercialCount = getInsertionsCount(program);
    const comercialValue = totalCommercialsValueFor(
        comercialCount,
        program,
        companies,
        objectivesAndDuration?.duration,
        user?.cnpj,
        budget?.budgetType,
    );
    return (
        <tr key={program.id}>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1 tw-pr-4" align="left">
                <p>{program.programName}</p>
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="center">
                <div className="tw-flex tw-place-items-center tw-justify-center tw-align-middle">
                    {program.showDays.map(day => (
                        <DayView day={day} key={day.day} />
                    ))}
                </div>
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="center">
                {comercialCount}
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="center">
                {objectivesAndDuration.duration}”
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="left">
                {currencyFormatter.format(Number(comercialValue))}
            </td>
        </tr>
    );
};

const CartOverview = () => {
    const { mediaCentral, objectivesAndDuration } = useCurrentCampaign();
    const selectedPrograms = useMemo(
        () => [...mediaCentral.selectedTVPrograms, ...mediaCentral.selectedRadioPrograms],
        [mediaCentral.selectedRadioPrograms, mediaCentral.selectedTVPrograms],
    );

    const startDate = objectivesAndDuration.startDate;
    const endDate = objectivesAndDuration.endDate;

    const filteredPrograms = selectedPrograms.filter(program => getInsertionsCount(program) > 0);

    return (
        <div>
            <h3>Resumo de compras</h3>
            <span className="tw-text-purple">Período de Veiculação:</span> {format(startDate, 'dd/MM/yyyy')} a{' '}
            {format(endDate, 'dd/MM/yyyy')}
            <div className="tw-w-full tw-overflow-x-auto tw-mb-9">
                <table className="tw-w-full tw-mt-6">
                    <thead>
                        <tr>
                            <th className="tw-text-purple tw-text-left" align="left">
                                Programa
                            </th>
                            <th className="tw-text-purple tw-px-4" align="center">
                                Dias de Veiculação
                            </th>
                            <th className="tw-text-purple tw-px-4" align="center">
                                Inserções
                            </th>
                            <th className="tw-text-purple tw-px-4 tw-text-center" align="center">
                                Duração
                            </th>
                            <th className="tw-text-purple tw-text-left" align="left">
                                Valor
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPrograms.map(program => (
                            <ProgramRow key={program.id} program={program} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CartOverview;
