import { firestore } from 'base';

export const getAllCoverageCities = () => firestore.collection('coverageCities');

export const getCoverageById = id => getAllCoverageCities().doc(id);

export const getCoverageCitiesByState = state => getAllCoverageCities().where('state', '==', state);

export const getCoverageCity = (state, city) => getCoverageCitiesByState(state).where('name', '==', city);

export const createCoverageCity = data => getAllCoverageCities().add(data);

export const updateCoverageCity = data => getCoverageById(data.id).set(data, { merge: true });

export const getCoverageCitiesByProgram = programReference =>
    getAllCoverageCities().where('programsRefs', 'array-contains-any', [programReference]);
