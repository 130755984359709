import { auth, firestore, firebase } from 'base';

// Actions

// Actions creator
export const addCard = newCard => async dispatch => {
    const userId = auth.currentUser.uid;
    const user = firestore
        .collection('users')
        .doc(userId)
        .update({ wallet: firebase.firestore.FieldValue.arrayUnion(newCard) });

    return dispatch({
        type: 'NEW_CARD',
        payload: user,
    });
};

export const deleteCard = newWallet => async dispatch => {
    const userId = auth.currentUser.uid;
    const user = firestore.collection('users').doc(userId).update({ wallet: newWallet });

    return dispatch({
        type: 'DELETE_CARD',
        payload: user,
    });
};

export const selectDefaultCard = updateUser => async dispatch => {
    const userId = auth.currentUser.uid;
    const user = firestore.collection('user').doc(userId).update(updateUser);

    return dispatch({
        type: 'SELECT_DEFAULT_CARD',
        payload: user,
    });
};

const initialState = {};

// Reducer
const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default walletReducer;
