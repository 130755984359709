/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import TimeInput from 'react-time-input';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { useProgram } from 'hooks/program';
import { useUserCompany } from 'hooks/company';
import { createProgram, setNewProgram, setProgram, setStep, updateProgram } from 'store/modules/program';
import Loading from 'components/Loading';

const schema = yup.object({
    programTime: yup.string().required('Informe o horário!'),
});

const weekDays = [
    {
        available: false,
        checked: false,
        day: 'monday',
        label: 'Segunda-feira',
    },
    {
        available: false,
        checked: false,
        day: 'tuesday',
        label: 'Terça-feira',
    },
    {
        available: false,
        checked: false,
        day: 'wednesday',
        label: 'Quarta-feira',
    },
    {
        available: false,
        checked: false,
        day: 'thursday',
        label: 'Quinta-feira',
    },
    {
        available: false,
        checked: false,
        day: 'friday',
        label: 'Sexta-feira',
    },
    {
        available: false,
        checked: false,
        day: 'saturday',
        label: 'Sábado',
    },
    {
        available: false,
        checked: false,
        day: 'sunday',
        label: 'Domingo',
    },
];

const Schedule = ({ handleBack }) => {
    const [loading, setLoading] = useState(false);
    const [showDays, setShowDays] = useState(weekDays);

    const { errors, handleSubmit, control } = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();
    const history = useHistory();
    const program = useProgram();
    const company = useUserCompany();

    const onSubmit = useCallback(
        async values => {
            setLoading(true);

            function onSuccess() {
                setLoading(false);
                toast.success('Programa salvo com sucesso!');
                dispatch(setNewProgram());
                dispatch(setStep(0));
                history.push('/programas');
            }

            function onFailure(error) {
                setLoading(false);
                toast.error('Falha ao salvar programa ' + error?.message);
            }

            if (!showDays.some(showDay => showDay.available)) {
                toast.error('Selecione ao menos um dia de exibição do programa');
                return;
            }

            const data = {
                ...program,
                ...values,
                showDays,
                companyId: company?.id || null,
            };

            const parsedHour = Number(values.programTime.split(':')[0]);

            let period = '';

            if (parsedHour >= 0 && parsedHour < 6) {
                period = 'Madrugada';
            } else if (parsedHour >= 6 && parsedHour < 12) {
                period = 'Manhã';
            } else if (parsedHour >= 12 && parsedHour < 19) {
                period = 'Tarde';
            } else {
                period = 'Noite';
            }

            if (program.id) {
                dispatch(updateProgram(program.id, { ...data, period }, { onSuccess, onFailure }));
            } else {
                dispatch(createProgram({ ...data, period }, { onSuccess, onFailure }));
            }

            // TODO Se algo sair errado, todo payload é perdido, transformar em promise!
            dispatch(setProgram({}));
        },
        [dispatch, program, showDays, history, company],
    );

    const handleShowDayChange = useCallback(day => {
        setShowDays(previous =>
            previous.map(showDay => {
                if (showDay.day === day) {
                    return {
                        ...showDay,
                        available: !showDay.available,
                    };
                }
                return showDay;
            }),
        );
    }, []);

    useEffect(() => {
        for (const name of Object.keys(errors)) {
            toast.error(errors[name].message);
        }
    }, [errors]);

    useEffect(() => {
        if (program.showDays) {
            setShowDays(program.showDays);
        }
    }, [program]);

    return (
        <form className="tw-w-full" onSubmit={handleSubmit(onSubmit)}>
            {loading && <Loading />}
            <h2>Informe a hora e o dia de exibição</h2>
            <div className="tw-w-full tw-flex tw-flex-col tw-mt-6">
                <div className="tw-flex tw-flex-col tw-max-w-xs">
                    <span className="tw-block tw-my-3">Indique a hora de exibição</span>
                    <Controller
                        render={({ onChange, value }) => (
                            <TimeInput
                                className="tw-block tw-text-center tw-py-4 tw-w-2/5 tw-rounded-md tw-shadow-md tw-border-none tw-text-2xl tw-font-semibold"
                                onTimeChange={onChange}
                                initTime={value}
                            />
                        )}
                        control={control}
                        name="programTime"
                        defaultValue={program?.programTime}
                    />
                </div>
                <div className="tw-w-full tw-mt-6">
                    <span className="tw-block tw-my-3">Selecione os dias de exibição</span>
                    <div className="tw-w-full md:tw-w-1/2 tw-flex tw-flex-row tw-justify-between">
                        {showDays.map(showDay => (
                            <button
                                key={showDay.day}
                                type="button"
                                onClick={() => handleShowDayChange(showDay.day)}
                                className={`${
                                    showDays.find(item => item.day === showDay.day).available && 'tw-bg-pink'
                                } tw-flex tw-items-center tw-cursor-pointer tw-justify-center tw-rounded-md tw-shadow-lg tw-w-full tw-h-10 md:tw-h-16 tw-mx-1`}
                            >
                                <div className="tw-flex tw-flex-col">
                                    <span
                                        className={`tw-block ${
                                            showDays.find(item => item.day === showDay.day).available
                                                ? 'tw-text-white'
                                                : 'tw-text-gray-400'
                                        } md:tw-text-3xl tw-text-lg`}
                                    >
                                        {showDay.label.split('')[0]}
                                    </span>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className="tw-mt-10 tw-text-center tw-mb-4 md:tw-mt-24">
                <button
                    className="button button-primary-outlined tw-mr-4 tw-w-full md:tw-w-1/6"
                    onClick={handleBack}
                    type="button"
                >
                    Voltar
                </button>
                <button
                    className="button button-primary tw-border-2 tw-border-pink md:tw-mt-0 tw-mt-3 tw-w-full md:tw-w-1/6"
                    type="submit"
                >
                    Concluir
                </button>
            </div>
        </form>
    );
};

export default Schedule;
