import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import PageWrapper from 'components/PageWrapper';
import NoMatchRoute from 'routes/NoMatchRoute';
import DashboardSup from 'pages/supplier/Dashboard';
import RegisterMedia from 'pages/supplier/Media/Create';
import Discounts from 'pages/supplier/Commercial/Discounts';
import Commercial from 'pages/supplier/Commercial';
import EditProfile from 'pages/advertiser/Profile/editProfile';
import Campaigns from 'pages/supplier/Campaign';
import Profile from 'pages/supplier/Profile';
import CouponForm from 'pages/supplier/Commercial/Coupons/CouponForm';
import CouponList from 'pages/supplier/Commercial/Coupons/List';
import CampaignConfig from 'pages/supplier/Commercial/CampaignConfig';
import Package from 'pages/supplier/Commercial/Packages/Package';
import ListPackages from 'pages/supplier/Commercial/Packages/List';
import ProgramsManage from 'pages/supplier/Programs/ProgramsManage';
import Program from 'pages/supplier/Programs/Program/Program';

const SentryRoute = Sentry.withSentryRouting(Route);

const ProgramasRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <SentryRoute
                path={`${path}/:id`}
                render={routeProperties => (
                    <PageWrapper pageTitle="Programa" isSupplierDashboard>
                        <Program {...routeProperties} />
                    </PageWrapper>
                )}
            />

            <SentryRoute
                path={`${path}/editar`}
                render={routeProperties => (
                    <PageWrapper pageTitle="Programa" isSupplierDashboard>
                        <RegisterMedia {...routeProperties} />
                    </PageWrapper>
                )}
            />

            <SentryRoute
                path={path}
                render={routeProperties => (
                    <PageWrapper pageTitle="Programas e Regiões" isSupplierDashboard>
                        <ProgramsManage {...routeProperties} />
                    </PageWrapper>
                )}
            />
        </Switch>
    );
};

const SupplierRoutes = () => (
    <Switch>
        <SentryRoute
            exact
            path="/"
            render={routeProperties => (
                <PageWrapper pageTitle="Dashboard" isSupplierDashboard>
                    <DashboardSup {...routeProperties} />
                </PageWrapper>
            )}
        />
        <SentryRoute
            exact
            path="/perfil"
            render={routeProperties => (
                <PageWrapper pageTitle="Meu Perfil" isSupplierDashboard>
                    <Profile {...routeProperties} />
                </PageWrapper>
            )}
        />

        <SentryRoute
            path="/descontos"
            render={routeProperties => (
                <PageWrapper pageTitle="Área Comercial" isSupplierDashboard>
                    <Discounts {...routeProperties} />
                </PageWrapper>
            )}
        />
        <SentryRoute
            path="/comercial"
            render={routeProperties => (
                <PageWrapper pageTitle="Área Comercial" isSupplierDashboard>
                    <Commercial {...routeProperties} />
                </PageWrapper>
            )}
        />
        <SentryRoute path="/perfil/editar" component={EditProfile} />
        <SentryRoute
            path="/campanhas"
            render={routeProperties => (
                <PageWrapper pageTitle="Campanhas" isSupplierDashboard>
                    <Campaigns {...routeProperties} />
                </PageWrapper>
            )}
        />

        <Route path="/programas">
            <ProgramasRoutes />
        </Route>

        <SentryRoute
            path="/cupom"
            render={routeProperties => (
                <PageWrapper pageTitle="Cupom" isSupplierDashboard>
                    <CouponForm {...routeProperties} />
                </PageWrapper>
            )}
        />
        <SentryRoute
            path="/cupons"
            render={routeProperties => (
                <PageWrapper pageTitle="Cupons" isSupplierDashboard>
                    <CouponList {...routeProperties} />
                </PageWrapper>
            )}
        />
        <SentryRoute
            path="/configuracao-de-campanha"
            render={routeProperties => (
                <PageWrapper pageTitle="Configuração de Campanhas" isSupplierDashboard>
                    <CampaignConfig {...routeProperties} />
                </PageWrapper>
            )}
        />
        <SentryRoute
            path="/pacotes"
            render={routeProperties => (
                <PageWrapper pageTitle="Pacotes" isSupplierDashboard>
                    <ListPackages {...routeProperties} />
                </PageWrapper>
            )}
        />
        <SentryRoute
            path="/pacote"
            render={routeProperties => (
                <PageWrapper pageTitle="Configuração de Pacote" isSupplierDashboard>
                    <Package {...routeProperties} />
                </PageWrapper>
            )}
        />
        <SentryRoute path="*">
            <NoMatchRoute />
        </SentryRoute>
    </Switch>
);

export default SupplierRoutes;
