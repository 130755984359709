import { v4 } from 'uuid';
import { firestore, storage } from 'base';

export const getPackages = companyId => {
    return firestore.collection('packages').where('companyId', '==', companyId).get();
};

export const savePackage = (companyId, packageData) => {
    return firestore.collection('packages').add({ companyId, ...packageData });
};

export const updatePackage = packageData => {
    return firestore.collection('packages').doc(packageData.id).update(packageData);
};

export const deletePackage = packageId => {
    return firestore.collection('packages').doc(packageId).delete();
};

export const getPackagesRegionId = programRegionId => {
    return firestore.collection('packages').where('programRegionId', '==', programRegionId).get();
};

export const getPackagesByRegionId = regionId => {
    return firestore.collection('packages').where('packageRegionId', '==', regionId).get();
};

export const getPackageById = packageId => {
    return firestore.collection('packages').doc(packageId).get();
};

/**
 * Upload program images into storage
 * @param {File} imageFile - The image file
 */
export function uploadPackageImage(imageFile) {
    return storage.ref(`images/packageImages/${v4()}-${imageFile.name}`).put(imageFile);
}
