import { all, fork } from 'redux-saga/effects';

import creativesRoot from './creatives';
import discountsRoot from './discounts';
import campaignRoot from './campaigns';
import couponRoot from './coupons';
import regionsRoot from './regions';
import packagesSaga from './packages';

function* rootSaga() {
    yield all([
        fork(creativesRoot),
        fork(discountsRoot),
        fork(campaignRoot),
        fork(couponRoot),
        fork(regionsRoot),
        fork(packagesSaga),
    ]);
}

export default rootSaga;
