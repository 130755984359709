import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

function CameraIcon({ className, color, message: Message }) {
    const [showMessage, setShowMessage] = useState(false);

    const toggleMessage = () => setShowMessage(state => !state);

    return (
        <div className="tw-relative tw-cursor-pointer">
            {showMessage && Message && (
                <OutsideClickHandler onOutsideClick={toggleMessage}>
                    <Message />
                </OutsideClickHandler>
            )}
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                onClick={toggleMessage}
            >
                <g clipPath="url(#clip0)">
                    <path
                        d="M10.0021 20.0042C15.5261 20.0042 20.0042 15.5261 20.0042 10.0021C20.0042 4.4781 15.5261 0 10.0021 0C4.4781 0 0 4.4781 0 10.0021C0 15.5261 4.4781 20.0042 10.0021 20.0042Z"
                        fill={color || '#D8D8D8'}
                    />
                    <path
                        d="M15.9339 9.9313C15.8149 9.71976 15.5373 9.64043 15.3125 9.77264L13.8053 10.6452L13.4219 10.87V12.9589L13.7789 13.1704C13.7921 13.1704 13.7921 13.1837 13.8053 13.1837L15.2861 14.043C15.3522 14.0959 15.4447 14.1223 15.5373 14.1223C15.7885 14.1223 15.9868 13.924 15.9868 13.6728V11.9012L16 10.1561C16 10.0767 15.9868 9.9974 15.9339 9.9313Z"
                        fill="white"
                    />
                    <path
                        d="M7.12871 9.25716C8.30538 9.25716 9.25729 8.30524 9.25729 7.12858C9.25729 5.95191 8.30538 5 7.12871 5C5.95204 5 5.00013 5.95191 5.00013 7.12858C4.98691 8.30524 5.95204 9.25716 7.12871 9.25716ZM7.12871 6.25599C7.61789 6.25599 8.0013 6.65262 8.0013 7.12858C8.0013 7.61775 7.60467 8.00116 7.12871 8.00116C6.65276 8.00116 6.25613 7.60453 6.25613 7.12858C6.24291 6.6394 6.63954 6.25599 7.12871 6.25599Z"
                        fill="white"
                    />
                    <path
                        d="M12.5229 9.37537C12.1791 9.54725 11.7957 9.65301 11.3859 9.65301C10.8835 9.65301 10.4075 9.50758 10.0109 9.24316H8.50371C8.10708 9.50758 7.63112 9.65301 7.12873 9.65301C6.71888 9.65301 6.33547 9.56047 5.99172 9.37537C5.68764 9.54725 5.4761 9.89099 5.4761 10.2612V13.4871C5.4761 14.082 5.96528 14.558 6.547 14.558H11.9808C12.5758 14.558 13.0517 14.0688 13.0517 13.4871V10.2612C13.0385 9.89099 12.827 9.54725 12.5229 9.37537Z"
                        fill="white"
                    />
                    <path
                        d="M11.3859 9.25716C12.5625 9.25716 13.5144 8.30524 13.5144 7.12858C13.5144 5.95191 12.5625 5 11.3859 5C10.2092 5 9.25728 5.95191 9.25728 7.12858C9.25728 8.30524 10.2092 9.25716 11.3859 9.25716ZM11.3859 6.25599C11.875 6.25599 12.2584 6.65262 12.2584 7.12858C12.2584 7.61775 11.8618 8.00116 11.3859 8.00116C10.9099 8.00116 10.5133 7.60453 10.5133 7.12858C10.5133 6.6394 10.8967 6.25599 11.3859 6.25599Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

export default CameraIcon;
