import React from 'react';
import { useSelector } from 'react-redux';
import MenuAltIcon from 'icons/MenuAltIcon';
import UserIconSmall from 'icons/UserIconSmall';
import CardIcon from './icons/CardIcon';
import DolarMenuIcon from './icons/DolarIcon';
import CampaignMenuIcon from './icons/CampaignIcon';
import styleConfig from '../../../style_config.json';

const Dashboard = () => {
    const userProfile = useSelector(store => store.firebase.profile);

    return (
        <>
            <h4>
                Olá {userProfile.displayName}, seja bem vindo ao {styleConfig['name']}!
            </h4>

            <div className="tw-flex tw-gap-4 tw-gap-y-4 tw-mt-10 tw-flex-wrap tw-w-full md:tw-w-2/3">
                <CardIcon
                    to="/perfil"
                    title="Meu perfil"
                    customClassName="tw-p-4 md:tw-w-1/5 tw-w-full"
                    icon={<UserIconSmall className="tw-bg-blue tw-p-5" />}
                />
                <CardIcon
                    title="Campanhas"
                    to="/campanhas"
                    customClassName="tw-p-4 md:tw-w-1/5 tw-w-full"
                    icon={<CampaignMenuIcon />}
                />
                <CardIcon
                    title="Programas"
                    to="/programas"
                    customClassName="tw-p-4 md:tw-w-1/5 tw-w-full"
                    icon={<MenuAltIcon className="tw-bg-yellow" />}
                />
                <CardIcon
                    title="Área comercial"
                    to="/comercial"
                    customClassName="tw-p-4 md:tw-w-1/5 tw-w-full"
                    icon={<DolarMenuIcon />}
                />
            </div>
        </>
    );
};

export default Dashboard;
