import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2rem;
`;

export const Title = styled.h1`
    padding: 1.25rem 0;
    font-size: 1.25rem;
    font-weight: 800;
    text-transform: capitalize;
`;
