import React from 'react';

function UploadIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 14.8947V15.1053C0 23.3314 6.71571 30 15 30C23.2843 30 30 23.3314 30 15.1053V14.8947C30 6.66856 23.2843 0 15 0C6.71571 0 0 6.66856 0 14.8947Z"
                fill="#7F4895"
            />
            <path
                d="M19.7803 16.8721V18.8051H10.2199V16.8721C10.2199 16.0831 9.57559 15.4434 8.78108 15.4434C7.98658 15.4434 7.34229 16.0831 7.34229 16.8721V19.8976C7.34229 20.6865 7.98658 21.3263 8.78108 21.3263H21.2191C22.0136 21.3263 22.6579 20.6865 22.6579 19.8976V16.8721C22.6579 16.0831 22.0136 15.4434 21.2191 15.4434C20.4246 15.4427 19.7803 16.0825 19.7803 16.8721Z"
                fill="white"
            />
            <path
                d="M18.9233 11.2449L15.0002 6.31836L11.0771 11.2449H13.1042V17.3646H16.8969V11.2449H18.9233Z"
                fill="white"
            />
        </svg>
    );
}

export default UploadIcon;
