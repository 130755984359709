/* eslint-disable unicorn/better-regex */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';
import * as Sentry from '@sentry/react';
import { cleanCnpj } from 'utils';
import { getInsertionValue } from 'services/advertiser/campaign';
import { getCompanyById } from 'api/companies';

const CimtiaContext = React.createContext({});

function getCompanyIds(programs) {
    const companiesIds = programs.map(program => program.companyId);
    const uniqueCompaniesIds = companiesIds.reduce((list, id) => (!list.includes(id) ? [...list, id] : list), []);
    return uniqueCompaniesIds;
}

async function getCompaniesByIds(companyIds) {
    const companies = [];
    for await (const companyId of companyIds) {
        const company = await getCompanyById(companyId).get();
        companies.push({
            id: companyId,
            ...company.data(),
        });
    }
    return companies;
}

async function applyCustomDiscounts(budgetAmount, programs, cnpj, duration, companies) {
    const discounts = [];

    for (const company of companies) {
        discounts.push({
            id: company.id,
            discounts: company.discounts,
            discountsRange: company.discountsRange,
        });
    }

    for (const program of programs) {
        const foundDiscount = discounts.find(
            discountByCnpj =>
                discountByCnpj.discounts?.find(item => cleanCnpj(cnpj) === cleanCnpj(item.cnpj)) &&
                discountByCnpj.id === program.companyId,
        );

        const insertionDiscount = Number(program.insertionDiscount || 0);

        if (foundDiscount) {
            const discountItem = foundDiscount.discounts[0];
            program.insertionValue = program.insertionCost - program.insertionCost * (discountItem.discount / 100);
        } else {
            const companyDiscountsByRange = discounts.find(
                discount => discount.id === program.companyId,
            )?.discountsRange;

            let appliedDiscount = false;

            if (
                companyDiscountsByRange?.length > 0 &&
                insertionDiscount > 0 &&
                insertionDiscount <= 100 &&
                budgetAmount < 5000
            ) {
                for (const discountRange of [...companyDiscountsByRange].sort((a, b) => (a.value > b.value ? -1 : 1))) {
                    if (budgetAmount >= discountRange.value && !appliedDiscount) {
                        const partialDiscount =
                            program.insertionCost - program.insertionCost * (insertionDiscount / 100);

                        program.insertionValue = partialDiscount - partialDiscount * (discountRange.discount / 100);
                        appliedDiscount = true;
                    }
                }
            } else if (companyDiscountsByRange?.length > 0 && budgetAmount < 5000) {
                for (const discountRange of [...companyDiscountsByRange].sort((a, b) => (a.value > b.value ? -1 : 1))) {
                    appliedDiscount = false;
                    if (budgetAmount >= discountRange.value && !appliedDiscount) {
                        program.insertionValue =
                            program.insertionCost - program.insertionCost * (discountRange.discount / 100);

                        appliedDiscount = true;
                    }
                }
            } else if (insertionDiscount > 0 && insertionDiscount <= 100 && budgetAmount < 5000) {
                program.insertionValue = program.insertionCost - program.insertionCost * (insertionDiscount / 100);
            } else {
                program.insertionValue = program.insertionCost - program.insertionCost * (insertionDiscount / 100);
            }
        }

        program.insertionValue = getInsertionValue(Number(duration), program.insertionValue);
    }

    return programs;
}

export function CimtiaProvider({ children }) {
    const newShowDayInsertion = React.useCallback((showDays, dayString, campaignWeeks) => {
        let hasInserted = false;
        const updatedShowDays = showDays.map(showDay => {
            const currentInsertions = showDay.insertions || 0;
            if (showDay.day === dayString && showDay.available && currentInsertions < campaignWeeks) {
                hasInserted = true;
                return {
                    ...showDay,
                    selected: true,
                    insertions: (showDay.insertions || 0) + 1,
                };
            }
            return showDay;
        });
        return { updatedShowDays, hasInserted };
    }, []);

    const getCampaignValue = React.useCallback(
        selectedPrograms =>
            Number.parseFloat(
                selectedPrograms?.reduce((total, program) => {
                    const insertionCount = program.showDays.reduce((insertionsTotal, showDay) => {
                        return showDay.insertions > 0 ? (insertionsTotal += showDay.insertions) : insertionsTotal;
                    }, 0);
                    return (total += program.insertionValue * insertionCount);
                }, 0),
            ) || 0,
        [],
    );

    const insertOnDay = React.useCallback(
        (program, day, programs, budget, type, campaignWeeks) => {
            const campaignValue = getCampaignValue(programs.filter(item => item.programType === type));

            if (campaignValue + program.insertionValue > budget) return program.showDays;

            switch (day) {
                case 0:
                    return newShowDayInsertion(program.showDays, 'sunday', campaignWeeks);
                case 1:
                    return newShowDayInsertion(program.showDays, 'monday', campaignWeeks);
                case 2:
                    return newShowDayInsertion(program.showDays, 'tuesday', campaignWeeks);
                case 3:
                    return newShowDayInsertion(program.showDays, 'wednesday', campaignWeeks);
                case 4:
                    return newShowDayInsertion(program.showDays, 'thursday', campaignWeeks);
                case 5:
                    return newShowDayInsertion(program.showDays, 'friday', campaignWeeks);
                case 6:
                    return newShowDayInsertion(program.showDays, 'saturday', campaignWeeks);
            }
        },
        [newShowDayInsertion, getCampaignValue],
    );

    const getMaxInsertionsByType = React.useCallback((programs_, budget, duration, type) => {
        let totalBudget = budget;
        let insertions = 0;

        const programs = programs_?.filter(program => program.programType === type);

        for (const program of programs) {
            let availableSeconds = Number(program.availableSeconds);
            let insertionValue = Number(program.insertionValue) || 0;

            while (availableSeconds >= Number(duration) && totalBudget >= insertionValue) {
                insertions++;
                totalBudget -= insertionValue;
                availableSeconds -= Number(duration);
            }
        }

        return insertions;
    }, []);

    const getProgramsByType = React.useCallback(
        (programs, type) => programs?.filter(program => program.programType === type),
        [],
    );

    const getTotalScore = React.useCallback(
        programs =>
            programs?.reduce((total, program) => {
                total += program?.score;
                return total;
            }, 0) || 0,
        [],
    );

    const getInsertionsCoefficient = React.useCallback((programScore, totalScore, maxTypeInsertion) => {
        const relativeScore = Math.round((programScore * 100) / totalScore);
        const programInsertionCoefficient = Math.round(relativeScore * (maxTypeInsertion / 100));
        return programInsertionCoefficient || 1;
    }, []);

    const insertProgram = React.useCallback(
        (type, selectedPrograms, totalScore, maxTypeInsertion, budget, duration, campaignWeeks, companies) => {
            let index = 0;
            let selectedProgramsCount = selectedPrograms?.length;

            while (selectedProgramsCount--) {
                if (index >= selectedPrograms.length) {
                    index = 0;
                }

                let availableSeconds = Number(selectedPrograms[index]?.availableSeconds || 0);

                let currentDay = 5;

                let insertionCoefficient = getInsertionsCoefficient(
                    selectedPrograms[index].score,
                    totalScore,
                    maxTypeInsertion,
                );

                const currentCompany = companies.find(company => company.id === selectedPrograms[index].companyId);

                for (let indexOfCoefficient = 0; indexOfCoefficient < insertionCoefficient; indexOfCoefficient++) {
                    let searchDays = 7;

                    while (searchDays-- && searchDays > -1) {
                        if (currentDay > 6) {
                            currentDay = 0;
                        }

                        const selectedCompanyPrograms = selectedPrograms.filter(
                            program => program.companyId === currentCompany.id,
                        );

                        const companyMaxValue = currentCompany?.campaignTVMaxValue;
                        const currentCompanyCampaignValue = getCampaignValue(selectedCompanyPrograms);
                        const selectedProgramValue = selectedPrograms[index].insertionValue;
                        const hasMaximumCampaignValueReached =
                            currentCompanyCampaignValue + selectedProgramValue > companyMaxValue;

                        if (hasMaximumCampaignValueReached) continue;

                        if (availableSeconds >= Number(duration)) {
                            const { hasInserted, updatedShowDays } = insertOnDay(
                                selectedPrograms[index],
                                currentDay,
                                selectedPrograms,
                                budget,
                                type,
                                campaignWeeks,
                            );

                            if (hasInserted) {
                                availableSeconds -= Number(duration);
                                selectedPrograms[index].showDays = updatedShowDays;
                                selectedPrograms[index].availableSeconds = availableSeconds;
                            }
                        }

                        currentDay++;
                    }
                }

                index++;
            }
        },
        [getInsertionsCoefficient, insertOnDay, getCampaignValue],
    );

    const applyBudget = React.useCallback(
        (filteredPrograms, budget, duration, campaignWeeks, companies) => {
            return new Promise(resolve => {
                let radioBudget = budget.radioBudget;
                let tvBudget = budget.tvBudget;

                let selectedPrograms = filteredPrograms?.sort((a, b) => {
                    if (a.score > b.score) {
                        return -1;
                    } else if (a.score === b.score) {
                        return a.insertionCost < b.insertionCost ? -1 : 1;
                    } else {
                        return 1;
                    }
                });
                if (!selectedPrograms.some(program => program.programType) === 'RADIO') {
                    radioBudget = 0;
                    selectedPrograms = selectedPrograms.filter(program => program.programType === 'TV');
                }
                if (!selectedPrograms.some(program => program.programType) === 'TV') {
                    tvBudget = 0;
                    selectedPrograms = selectedPrograms.filter(program => program.programType === 'RADIO');
                }

                const maxTVInsertions = getMaxInsertionsByType(selectedPrograms, tvBudget, duration, 'TV');

                const maxRadioInsertions = getMaxInsertionsByType(selectedPrograms, radioBudget, duration, 'RADIO');

                if (maxRadioInsertions > 0) {
                    const radioPrograms = selectedPrograms.filter(program => program.programType === 'RADIO');

                    const totalRadioScore = getTotalScore(radioPrograms);

                    insertProgram(
                        'RADIO',
                        radioPrograms,
                        totalRadioScore,
                        maxRadioInsertions,
                        radioBudget,
                        duration,
                        campaignWeeks,
                        companies,
                    );
                }

                if (maxTVInsertions > 0) {
                    const tvPrograms = selectedPrograms.filter(program => program.programType === 'TV');

                    const totalTVScore = getTotalScore(tvPrograms);

                    insertProgram(
                        'TV',
                        tvPrograms,
                        totalTVScore,
                        maxTVInsertions,
                        tvBudget,
                        duration,
                        campaignWeeks,
                        companies,
                    );
                }

                const programsWithInsertions = selectedPrograms?.filter(
                    program =>
                        program.showDays.reduce((total, showDay) => {
                            total += showDay.insertions || 0;
                            return total;
                        }, 0) > 0,
                );

                resolve(programsWithInsertions);
            });
        },
        [getMaxInsertionsByType, getTotalScore, insertProgram],
    );

    const filterPrograms = React.useCallback(
        async (campaign, programs, cnpj) => {
            try {
                const {
                    targetAndSegmentation: { segment, bestTime, gender, socialClass, ageRange },
                    objectivesAndDuration: { duration, campaignWeeks, objective },
                    budget,
                } = campaign;

                let targetedPrograms;

                if (budget.tvBudget === 0) {
                    targetedPrograms = getProgramsByType(programs, 'RADIO');
                } else if (budget.radioBudget === 0) {
                    targetedPrograms = getProgramsByType(programs, 'TV');
                } else {
                    targetedPrograms = programs;
                }

                targetedPrograms = targetedPrograms.reduce((targets, program) => {
                    let score = 0;

                    let newProgram = {
                        ...program,
                    };

                    const [minProgramAge, maxProgramAge] = program.ageRange?.split(',') || [0, 100];
                    const [minAge, maxAge] = ageRange.split(',') || [0, 100];

                    if (!program.segments.includes(segment)) {
                        return targets;
                    }

                    if (objective === 'reach') {
                        score += Number(program?.impact || 0);
                    }

                    for (const item of program.segments) {
                        if (item === segment) {
                            score += 25;
                        }
                    }

                    if (program.period === bestTime || bestTime === 'Todos') {
                        score += 15;
                    }
                    if (program.gender === gender || gender === 'all') {
                        score += 10;
                    }
                    if (program.socialClass.classA && socialClass.classA) {
                        score += 5;
                    }
                    if (program.socialClass.classB && socialClass.classB) {
                        score += 5;
                    }
                    if (program.socialClass.classC && socialClass.classC) {
                        score += 5;
                    }
                    if (program.socialClass.classD && socialClass.classD) {
                        score += 5;
                    }
                    if (program.socialClass.classE && socialClass.classE) {
                        score += 5;
                    }

                    if (minAge >= minProgramAge) {
                        score += 5;
                        if (maxAge <= maxProgramAge) {
                            score += 5;
                        }
                    }

                    targets = [
                        ...targets,
                        {
                            ...newProgram,
                            score,
                        },
                    ];

                    return targets;
                }, []);

                const companies = await getCompaniesByIds(getCompanyIds(targetedPrograms));

                targetedPrograms = await applyCustomDiscounts(
                    budget.amount,
                    targetedPrograms,
                    cnpj,
                    duration,
                    companies,
                );

                return await applyBudget(targetedPrograms, budget, duration, campaignWeeks, companies);
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
            }
        },
        [applyBudget, getProgramsByType],
    );

    return <CimtiaContext.Provider value={{ filterPrograms }}>{children}</CimtiaContext.Provider>;
}

export function useCimtia() {
    const context = React.useContext(CimtiaContext);

    if (!context) {
        throw new Error('useCimtiaContext must be within its context');
    }

    return context;
}
