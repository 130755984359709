import React, { useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-awesome-modal';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Creators as ActionsCreators } from 'store/modules/creative';
import { updateCampaign } from 'api/campaign';
import { analytics } from 'base';
import { currencyFormatter } from 'utils';
import { useUser, useUserId } from 'hooks/user';
import { useCreativePriceFor, useCurrentCreative } from 'hooks/creative';
import { usePaymentInfoForUser } from 'hooks/payments';
import { useQuery } from 'hooks/query';
import { useFeatureFlagPaymentByPIXConfig, usePaymentPhoneNumberFromConfig } from 'hooks/config';
import CouponForm from 'components/CouponForm';
import PlayIcon from 'icons/PlayIcon';
import AddCreditCardForm from 'organisms/AddCreditCardForm';
import CreditCardSelect from 'organisms/CreditCardSelect';
import CreativePaymentTable from 'organisms/CreativePaymentTable';
import PaymentMethodSelector from 'organisms/PaymentMethodSelector';

const ComercialResume = ({ creative, onPlayClick }) => (
    <>
        <h2 className="tw-text-pink tw-normal-case tw-font-bold">Resumo do comercial</h2>

        <div className="tw-w-full tw-flex tw-justify-between tw-flex-col md:tw-flex-row">
            <div className="tw-w-full tw-flex tw-mt-8 md:tw-flex-row tw-flex-col">
                <div className="tw-w-full md:tw-w-1/2">
                    <div className="tw-flex tw-flex-col">
                        <h4 className="tw-text-purple tw-font-bold">Nome do comercial:</h4>
                        <p>{creative.commercialName}</p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-mt-8">
                        <h4 className="tw-text-purple tw-font-bold">Duração do comercial:</h4>
                        <p>{`${creative.durationTime} segundos`}</p>
                    </div>
                </div>
                <div className="tw-w-full md:tw-w-1/2">
                    <div className="tw-flex tw-flex-col">
                        <div className="tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">Nome do seu negócio:</h4>
                            <p>{creative.businessName}</p>
                        </div>
                        <div className="tw-flex tw-flex-col tw-mt-8">
                            <h4 className="tw-text-purple tw-font-bold">Site ou E-mail</h4>
                            <p>{creative.siteOrEmail ? creative.siteOrEmail : '-'}</p>
                        </div>
                    </div>
                </div>
                <div className="tw-w-full md:tw-w-1/2">
                    <div className="tw-flex tw-flex-col">
                        <div className="tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">Slogan:</h4>
                            <p>{creative.slogan}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tw-w-full tw-flex tw-flex-col md:tw-mt-0 tw-mt-6">
                <div className="tw-w-full tw-max-w-xs md:tw-mr-8 tw-flex tw-place-self-center">
                    <div className="tw-flex tw-flex-col tw-relative">
                        <h3 className="tw-text-gray-700 tw-mb-3">Template Escolhido</h3>
                        <div className="tw-w-full tw-shadow-xl tw-rounded-xl">
                            <PlayIcon
                                className="tw-absolute tw-bottom-14 tw-left-4 tw-cursor-pointer tw-z-50"
                                onClick={onPlayClick}
                            />
                            <video src={creative.media?.url} className="tw-rounded-t-xl" />
                            <div className="tw-w-full tw-h-11 tw-flex tw-justify-between tw-items-center tw-px-3">
                                <p>{creative.media?.name}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

const ActionButtonsSection = ({ isLoading, coupon, amountToPay, paymentMethod }) => {
    const [isSavingCreative, setIsSavingCreative] = useState(false);
    const creative = useCurrentCreative();
    const dispatch = useDispatch();
    const campaignId = useQuery().get('campaignId');
    const history = useHistory();
    const userId = useUserId();

    const user = useUser();

    const { data: paymentPhoneNumber, isLoading: isLoadingPhoneNumber } = usePaymentPhoneNumberFromConfig({
        enabled: paymentMethod === 'pix',
    });

    function handlePaymentByPix() {
        const text = encodeURIComponent(`Olá...
Me chamo ${user.displayName} (${user.email}) sou da empresa _${user.company}_ CNPJ _(${user.cnpj || user.cpf})_.

Acabei de registrar meu criativo:
 - *Comercial*: _"${creative.commercialName}"_
 - *Negócio*: _${creative.businessName}_
 - *Cidade*: _${creative.city}_
 - *Duração*: _${creative.durationTime}s_
 - *Telefone*: _${creative.phone}_
 - *Valor a pagar*: _${currencyFormatter.format(amountToPay)}s_

e gostaria de efetuar o pagamento via PIX.

Como devo proceder?
            `);
        const redirectUrl = `https://api.whatsapp.com/send?phone=${paymentPhoneNumber}&text=${text}`;
        window.open(redirectUrl, '_blank');
    }

    const handleBack = () => dispatch(ActionsCreators.goPreviousStep());
    const handlePayment = () => {
        async function onSuccess(creative) {
            try {
                if (campaignId) {
                    await updateCampaign(campaignId, {
                        status: 'PENDING_APPROVE_INSERTIONS',
                        creative,
                    });
                    toast.success('CRIATIVO ANEXADO A CAMPANHA COM SUCESSO!');
                } else {
                    toast.success('CRIATIVO SALVO COM SUCESSO!');
                }
            } finally {
                setIsSavingCreative(false);
            }
            history.push('/central-criativa');

            if (paymentMethod === 'pix') {
                handlePaymentByPix();
            }
        }
        function onFailure(error) {
            setIsSavingCreative(false);
            toast.error(error?.message || 'FALHA AO SALVAR CRIATIVO!');
        }

        setIsSavingCreative(true);
        dispatch(
            ActionsCreators.creativeSubmit(
                { ...creative, userId, coupon: coupon || null, paymentMethod },
                { onSuccess, onFailure },
                paymentMethod,
            ),
        );
    };

    const { data: paymentInfo } = usePaymentInfoForUser();
    const hasCreditCardAvailable = paymentInfo?.creditCard;
    const mustSelectCreditCard = paymentMethod === 'credit-card' && !hasCreditCardAvailable && amountToPay > 0;

    return (
        <div className="tw-mt-10 tw-text-center tw-mb-4 md:tw-mt-24">
            <button
                className="button button-primary-outlined tw-mr-4"
                onClick={handleBack}
                type="button"
                disabled={isLoading || isSavingCreative}
            >
                Voltar
            </button>
            <button
                className="button button-primary"
                type="submit"
                onClick={handlePayment}
                disabled={
                    isLoading ||
                    isSavingCreative ||
                    mustSelectCreditCard ||
                    (paymentMethod === 'pix' && isLoadingPhoneNumber)
                }
            >
                Pagar
            </button>
        </div>
    );
};

const todayMomentFormatted = moment().format('DD/MM/YYYY');

const Payment = () => {
    const [selectedCoupon, setSelectedCoupon] = useState();
    const [paymentMethod, setPaymentMethod] = useState('credit-card');
    const [openVideo, toggleVideo] = useReducer(state => !state, false);
    const [showCardForm, toggleShowCardForm] = useReducer(state => !state, false);

    const handleCouponSubmit = coupon => setSelectedCoupon(coupon);
    const handleCouponRemove = () => setSelectedCoupon();

    const creative = useCurrentCreative();

    const { data: creativePrice, isLoading: isGetCreativePriceLoading } = useCreativePriceFor(
        'tv',
        Number(creative.durationTime),
    );
    const isLoadingPrice = isGetCreativePriceLoading;

    const totalToPay = creativePrice - (selectedCoupon ? selectedCoupon.value : 0);

    const handlePaymentMethodSelected = paymentMethodSelected => {
        analytics.logEvent(`creative_payment_select-${paymentMethodSelected}`);
        setPaymentMethod(paymentMethodSelected);
    };

    const { data: featureFlagPixEnabled } = useFeatureFlagPaymentByPIXConfig();

    const isPaymentByCreditCardSelected = paymentMethod === 'credit-card';

    return (
        <div className="tw-w-full tw-mb-10">
            <Modal visible={openVideo} width="710" effect="fadeInUp" onClickAway={toggleVideo}>
                <video src={creative?.media?.url} controls />
            </Modal>
            {creative && <ComercialResume creative={creative} onPlayClick={toggleVideo} />}
            <h2 className="tw-text-pink tw-normal-case tw-font-bold tw-mt-6">Resumo da Compra</h2>
            <p className="tw-mt-2 tw-mb-6">
                <span className="tw-text-purple">Data da compra: </span>
                {todayMomentFormatted}
            </p>
            <CreativePaymentTable
                creative={creative}
                price={creativePrice}
                quantity={1}
                description="Edição, criação e montagem de Comercial"
                coupon={selectedCoupon}
            />

            <div className="my-4">
                <CouponForm
                    coupon={selectedCoupon}
                    grantTotal={creativePrice}
                    onSubmit={handleCouponSubmit}
                    onRemove={handleCouponRemove}
                />
            </div>

            <h2 className="tw-text-pink tw-normal-case tw-font-bold tw-mt-6">Pagamento</h2>
            {featureFlagPixEnabled && (
                <PaymentMethodSelector
                    onPaymentMethodSelect={handlePaymentMethodSelected}
                    paymentMethod={paymentMethod}
                />
            )}

            {showCardForm && (
                <AddCreditCardForm className="tw-mb-10" onCancel={toggleShowCardForm} onSave={toggleShowCardForm} />
            )}
            {isPaymentByCreditCardSelected && (
                <>
                    <p className="tw-my-4">Selecione o cartão cadastrado ou clique no ícone para cadastra um novo. </p>
                    <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-x-4">
                        <CreditCardSelect />
                        <button onClick={toggleShowCardForm}>
                            <span className="tw-rounded-full tw-px-4 tw-py-2 tw-mr-2 tw-bg-pink tw-text-white">+</span>
                            Adicionar novo cartão
                        </button>
                    </div>
                </>
            )}

            {!showCardForm && creative && (
                <ActionButtonsSection
                    isLoading={isLoadingPrice}
                    coupon={selectedCoupon}
                    amountToPay={totalToPay}
                    paymentMethod={paymentMethod}
                />
            )}
            {!creative && <p className="tw-text-gray">Buscando criativo...</p>}
        </div>
    );
};

export default Payment;
