import React from 'react';

function Ellipse({ color }) {
    return (
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="3.87692" cy="3.87692" r="3.87692" fill={color} />
        </svg>
    );
}

export default Ellipse;
