import React from 'react';
import yup from 'validator';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { recoverPassword } from 'store/modules/auth';
import InputFormControl from './InputFormControl';
import UserIcon from 'icons/UserIcon';

const schema = yup.object().shape({
    email: yup.string().email().defined().required().label('E-mail'),
});

const RecoveryPasswordForm = () => {
    const [isLoading, setIsLoading] = React.useState(false);

    const { handleSubmit, register, formState } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });
    const { errors } = formState;

    const dispatch = useDispatch();
    const onSubmit = ({ email }) => {
        setIsLoading(true);

        function onSuccess() {
            setIsLoading(false);
            toast.success('Um e-mail foi enviado para você!');
            window.open('/', '_self');
        }

        function onFailure() {
            setIsLoading(false);
            toast.error('Falha ao enviar e-mail, tente novamente!');
        }

        dispatch(recoverPassword(email, { onSuccess, onFailure }));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputFormControl
                label="E-mail"
                name="email"
                error={errors.email}
                type="email"
                placeholder="seu@email.com"
                className="tw-my-8"
                ref={register}
                disabled={isLoading}
                icon={<UserIcon />}
            />

            <button type="submit" className="button button-primary tw-block tw-w-full" disabled={isLoading}>
                Recuperar senha
            </button>
        </form>
    );
};

export default RecoveryPasswordForm;
