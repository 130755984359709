import React, { forwardRef, useContext, useReducer } from 'react';
import Modal from 'react-responsive-modal';
import { useDispatch } from 'react-redux';
import { AnalyticsContext } from 'pages/advertiser/Campaign/Create/analyticsContext';
import { currencyFormatter } from 'utils';
import { useCurrentCampaign } from 'hooks/campaign';
import AddCreditCardForm from 'organisms/AddCreditCardForm';
import CreditCardSelect from 'organisms/CreditCardSelect';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import CouponForm from 'components/CouponForm';
import useCurrentCampaignGrantTotal from '../../useCurrentCampaignGrantTotal';

const INSTALLMENTS_MAXIMUM_ALLOWED = 3;

const InstallmentsSelector = forwardRef(({ amount, ...rest }, reference) => {
    const installmentsList = Array.from({ length: INSTALLMENTS_MAXIMUM_ALLOWED }).map((_, index) => index + 1);

    return (
        <select className="cimtia-select tw-w-2/5" {...rest} ref={reference}>
            {installmentsList.map(installments => (
                <option key={installments} value={installments}>
                    {installments}x {currencyFormatter.format(amount / installments)}
                </option>
            ))}
        </select>
    );
});
InstallmentsSelector.displayName = 'InstallmentsSelector';

const Payment = ({ children, paymentMethod }) => {
    const [isOpenPaymentModal, toggleIsOpenPaymentModal] = useReducer(state => !state, false);

    const { coupon } = useCurrentCampaign();
    const grantTotalValue = useCurrentCampaignGrantTotal(coupon);
    const dispatch = useDispatch();

    const { addPaymentInfo } = useContext(AnalyticsContext);
    function openPaymentModal(event) {
        event.preventDefault();
        toggleIsOpenPaymentModal();
        addPaymentInfo();
    }

    function handleCouponRemove() {
        dispatch(CampaignCreators.setCoupon(false));
    }
    function handleCouponSubmit(coupon) {
        dispatch(CampaignCreators.setCoupon(coupon));
    }
    const handleInstallmentsChange = event => {
        const installments = Number(event.currentTarget.value);
        dispatch(CampaignCreators.setInstallments(installments));
    };

    return (
        <>
            {paymentMethod === 'credit-card' && (
                <>
                    {grantTotalValue > 0 && (
                        <p className="tw-mt-4">
                            Selecione o cartão cadastrado ou clique no ícone para cadastra um novo.{' '}
                        </p>
                    )}
                    <div>
                        {grantTotalValue > 0 && (
                            <div className="tw-flex tw-gap-x-4 tw-my-4">
                                <div className="tw-w-96">
                                    <CreditCardSelect />
                                </div>
                                <div>
                                    <InstallmentsSelector
                                        amount={grantTotalValue}
                                        onChange={handleInstallmentsChange}
                                    />
                                </div>
                                <button onClick={openPaymentModal}>
                                    <span className="tw-rounded-full tw-px-4 tw-py-2 tw-mr-2 tw-bg-pink tw-text-white">
                                        +
                                    </span>
                                    Adicionar novo cartão
                                </button>
                            </div>
                        )}

                        <div className="tw-my-4">
                            <CouponForm
                                coupon={coupon}
                                grantTotal={grantTotalValue}
                                onSubmit={handleCouponSubmit}
                                onRemove={handleCouponRemove}
                            />
                        </div>
                    </div>
                </>
            )}

            <div>{children}</div>

            <Modal open={isOpenPaymentModal} onClose={toggleIsOpenPaymentModal}>
                <AddCreditCardForm onCancel={toggleIsOpenPaymentModal} onSave={toggleIsOpenPaymentModal} />
            </Modal>
        </>
    );
};

export default Payment;
