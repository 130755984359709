import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';

/**
 * Handle promise errors by capture error to sentry and display an message using a toast
 * @param {string} [fallbackMessage] - Fallback message to display in case no error object is found
 * @returns {(function(*=): void)|*} - Funciton that handle the error
 */
export function handlePromiseError(fallbackMessage) {
    return function (error) {
        Sentry.captureException(error);
        toast.error(error.message || fallbackMessage);
    };
}
