import { useCallback, useMemo, useState } from 'react';
import { useCurrentCampaign } from 'hooks/campaign';
import { analytics, firebase } from 'base';
import { getInsertionsCount } from 'services/advertiser/campaign';
import useCurrentCampaignGrantTotal from './useCurrentCampaignGrantTotal';

export default function useCurrentCampaignAnalytics(defaultStep) {
    const {
        id: campaignId,
        ref: campaignReference,
        mediaCentral,
        objectivesAndDuration: { duration, city, name },
        localization: { code },
        coupon,
    } = useCurrentCampaign();
    const id = campaignId || campaignReference?.id;
    const selectedPrograms = useMemo(
        () => [...mediaCentral.selectedTVPrograms, ...mediaCentral.selectedRadioPrograms],
        [mediaCentral.selectedRadioPrograms, mediaCentral.selectedTVPrograms],
    );

    const [currentStep, setCurrentStep] = useState(defaultStep);

    const items = useMemo(
        () =>
            selectedPrograms.map(program => {
                const totalInsertions = getInsertionsCount(program);
                return {
                    item_id: program.id,
                    item_name: program.name,
                    price: program.insertionValue,
                    quantity: totalInsertions,
                    item_brand: program.companyId,
                    item_variant: program.period,
                };
            }),
        [selectedPrograms],
    );

    const grantTotalValue = useCurrentCampaignGrantTotal(coupon);

    const cart = useMemo(() => {
        return {
            item_list_id: `${name} - ${duration}s - ${city} / ${code}`,
            item_list_name: name,
            items,
        };
    }, [city, code, duration, items, name]);

    const viewItemsList = useCallback(() => {
        return analytics.logEvent(firebase.analytics.EventName.VIEW_ITEM_LIST, cart);
    }, [cart]);

    const selectItems = useCallback(() => {
        return analytics.logEvent(firebase.analytics.EventName.SELECT_ITEM, cart);
    }, [cart]);

    const purchase = useCallback(
        transaction => {
            const payment = {
                transaction_id: transaction.tid,
                currency: 'BRL',
                value: transaction.authorized_amount / 100,
                coupon: coupon?.code,
                items,
            };
            return analytics.logEvent(firebase.analytics.EventName.PURCHASE, payment);
        },
        [coupon?.code, items],
    );

    const beginCheckout = useCallback(() => {
        const checkout = {
            currency: 'BRL',
            value: grantTotalValue,
            coupon: coupon?.code,
            items,
        };
        return analytics.logEvent(firebase.analytics.EventName.BEGIN_CHECKOUT, checkout);
    }, [coupon?.code, grantTotalValue, items]);

    const addPaymentInfo = useCallback(() => {
        const payment = {
            currency: 'BRL',
            value: grantTotalValue,
            coupon: coupon?.code,
            items,
        };
        return analytics.logEvent(firebase.analytics.EventName.ADD_PAYMENT_INFO, payment);
    }, [coupon?.code, grantTotalValue, items]);

    const viewCart = useCallback(() => {
        const cart = {
            currency: 'BRL',
            value: grantTotalValue,
            coupon: coupon?.code,
            items,
        };
        return analytics.logEvent(firebase.analytics.EventName.VIEW_CART, cart);
    }, [coupon?.code, grantTotalValue, items]);

    const viewItem = useCallback(
        item => {
            const cart = {
                currency: 'BRL',
                value: grantTotalValue,
                coupon: coupon?.code,
                items: [item],
            };
            return analytics.logEvent(firebase.analytics.EventName.VIEW_ITEM, cart);
        },
        [coupon?.code, grantTotalValue],
    );

    const logStepEvent = useCallback(
        (stepName, data) => {
            setCurrentStep(stepName);
            return analytics.logEvent(`campaign_register_${stepName}`, {
                step: stepName,
                name,
                duration,
                city,
                code,
                campaignId: id,
                items,
                ...data,
            });
        },
        [city, code, duration, id, items, name],
    );

    const logEvent = useCallback(
        (name, data) => {
            return analytics.logEvent(`campaign_register_${currentStep || '<UNKNOWN_STEP>'}/${name}`, {
                duration,
                city,
                code,
                campaignId: id,
                items,
                ...data,
            });
        },
        [city, code, currentStep, duration, id, items],
    );

    return {
        selectItems,
        viewItemsList,
        viewItem,
        beginCheckout,
        purchase,
        addPaymentInfo,
        logStepEvent,
        logEvent,
        viewCart,
    };
}
