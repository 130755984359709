import { remoteConfig } from 'base';

/**
 * Get phone number for payment
 * @returns {Promise<number>}
 */
export function getPaymentPhoneNumber() {
    return remoteConfig.ensureInitialized().then(() => remoteConfig.getValue('payment_phone_number').asString());
}

/**
 * Get feature flag for payment by PIX
 * @returns {Promise<number>}
 */
export function getFeatureFlagPaymentByPIX() {
    return remoteConfig.ensureInitialized().then(() => remoteConfig.getValue('feature_payment_by_pix').asBoolean());
}

/**
 * Get feature flag for payment by PIX
 * @returns {Promise<number>}
 */
export function getFeatureRadio() {
    return remoteConfig.ensureInitialized().then(() => remoteConfig.getValue('feature_radio').asBoolean());
}

/**
 * Get style configuration
 * @returns {Promise<{}>}
 */
export async function getStyleConfig() {
    const config = await remoteConfig.ensureInitialized().then(() => remoteConfig.getValue('style_config').asString());
    return JSON.parse(config);
}
