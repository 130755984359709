import React, { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FaPen, FaPlus, FaTimes } from 'react-icons/fa';
import moment from 'moment';

import { useUserId } from 'hooks/user';
import Loading from 'components/Loading';
import { currencyFormatter } from 'utils';
import { useRegions } from 'hooks/regions';
import { useUserCompany } from 'hooks/company';
import { usePackages } from 'hooks/packages';
import { getCompanyByOwnerId } from 'store/modules/company';
import { Creators as ActionCreators } from 'store/modules/regions';
import { Creators as PackagesActionCreators } from 'store/modules/packages';
import DeletePackageModal from './ConfirmDeleteModal';

const ListPackages = () => {
    const [loading, setLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [packageToDelete, setPackageToDelete] = useState();
    const dispatch = useDispatch();
    const history = useHistory();

    const packages = usePackages();
    const userId = useUserId();
    const company = useUserCompany();
    const regions = useRegions();

    const fetchPackages = useCallback(() => {
        dispatch(PackagesActionCreators.fetchPackages(company.id));
    }, [dispatch, company]);

    useEffect(() => {
        if (company?.id) {
            fetchPackages();
        }
    }, [fetchPackages, company]);

    useEffect(() => {
        if (userId) {
            dispatch(getCompanyByOwnerId(userId));
        }
    }, [userId, dispatch]);

    const toggleDeleteModal = useCallback(() => {
        setShowDeleteModal(previous => !previous);
    }, []);

    const handleDeletePackage = useCallback(() => {
        setLoading(true);

        function onSuccess() {
            setLoading(false);
            toast.success('Packagea excluído com sucesso!');
            fetchPackages();
        }

        function onFailure() {
            setLoading(false);
            toast.error('Falha ao excluir packagea');
        }

        if (packageToDelete) {
            dispatch(PackagesActionCreators.deletePackage(packageToDelete.id, { onSuccess, onFailure }));
            toggleDeleteModal();
        }
    }, [packageToDelete, dispatch, toggleDeleteModal, fetchPackages]);

    const handleEditPackage = useCallback(
        packageData => {
            dispatch(PackagesActionCreators.setPackage(packageData));
            history.push('/pacote');
        },
        [dispatch, history],
    );

    const handlePrepareToDeletePackage = useCallback(
        packageData => {
            setPackageToDelete(packageData);
            toggleDeleteModal();
        },
        [toggleDeleteModal],
    );

    const handleAddNewPackage = () => {
        dispatch(PackagesActionCreators.setPackage(null));
        history.push('/pacote');
    };

    useEffect(() => {
        dispatch(ActionCreators.getRegions(company.id));
    }, [dispatch, company]);

    return (
        <div className="tw-w-full tw-overflow-x-auto tw-mb-9">
            {loading && <Loading />}
            <DeletePackageModal
                showDeleteModal={showDeleteModal}
                toggleDeleteModal={toggleDeleteModal}
                handleDeletePackage={handleDeletePackage}
            />
            <div className="tw-w-full tw-flex md:tw-justify-end tw-justify-center tw-my-4">
                <button
                    className="tw-flex tw-items-center tw-border-none tw-rounded-md tw-bg-pink tw-text-white tw-font-bold tw-px-5 tw-py-0 tw-h-10 md:tw-ml-3"
                    onClick={handleAddNewPackage}
                >
                    <FaPlus size={18} className="tw-text-white" />
                    <span className="tw-block tw-ml-3">Adicionar Pacote</span>
                </button>
            </div>
            {packages?.length > 0 && (
                <table className="tw-w-full tw-mt-6">
                    <thead>
                        <tr>
                            <th className="tw-text-purple" align="left">
                                Nome
                            </th>
                            <th className="tw-text-purple" align="left">
                                Tipo
                            </th>
                            <th className="tw-text-purple" align="left">
                                Período de Venda
                            </th>
                            <th className="tw-text-purple" align="left">
                                Região de Exibição
                            </th>
                            <th className="tw-text-purple" align="left">
                                Pacotes Disponíveis
                            </th>
                            <th className="tw-text-purple" align="left">
                                Preço do Pacote
                            </th>
                            <th className="tw-text-purple" align="left">
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {packages?.map((data, index) => (
                            <tr key={index}>
                                <td className="tw-border-b-2 tw-border-gray-light">
                                    <p>{data.packageName}</p>
                                </td>
                                <td className="tw-border-b-2 tw-border-gray-light">{data.packageType}</td>
                                <td className="tw-border-b-2 tw-border-gray-light">{`
                                    ${moment(data?.startDate.toDate()).format('DD/MM/YYYY')} - ${moment(
                                    data?.endDate.toDate(),
                                ).format('DD/MM/YYYY')}
                                `}</td>
                                <td className="tw-border-b-2 tw-border-gray-light tw-font-bold">
                                    {regions.find(item => item.id === data.packageRegionId)?.regionName || '-'}
                                </td>
                                <td className="tw-border-b-2 tw-border-gray-light">{data.availablePackages}</td>
                                <td className="tw-border-b-2 tw-border-gray-light">
                                    {`${currencyFormatter.format(data.packageValue)}`}
                                </td>
                                <td className="tw-border-b-2 tw-border-gray-light">
                                    <div className="tw-flex tw-flex-row tw-items-center">
                                        <FaPen
                                            size={18}
                                            color="#087A99"
                                            className="tw-cursor-pointer"
                                            onClick={() => handleEditPackage(data)}
                                        />
                                        <FaTimes
                                            size={22}
                                            color="#cd0e4b"
                                            className="tw-cursor-pointer tw-ml-3"
                                            onClick={() => handlePrepareToDeletePackage(data)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default ListPackages;
