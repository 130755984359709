import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Modal from 'react-responsive-modal';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validator';
import { uploadOfferImage } from 'api/creative';
import InputField from 'molecules/InputField';
import Select from 'molecules/Select';
import UploadImage from './UploadImage';
import { useUserId } from 'hooks/user';

const schema = yup.object().shape({
    product: yup.string().defined().required().label('Nome do produto ou serviço'),
    productDescription: yup.string().defined().required().label('Detalhes do produto ou serviço'),
    emphasis: yup.string().defined().required().label('Valor em destaque'),
});
const offersType = [
    { value: 'inCash', label: 'Valor à vista' },
    { value: 'fromPrice', label: 'À partir de' },
    { value: 'installments', label: 'Parcelado' },
    { value: 'cashDiscount', label: 'Desconto à vista' },
];
const regulatedProductType = [
    { value: 'medicines', label: 'Medicamentos' },
    { value: 'alcool', label: 'Bebidas alcoólicas' },
    { value: 'others', label: 'Outros' },
];

const OfferModal = ({ visible, onClose, onAdd }) => {
    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });
    const { register, handleSubmit, formState, watch, reset } = methods;

    const userId = useUserId();

    const regulatedProduct = watch('regulatedProduct');
    const { errors } = formState;

    const onSubmit = values => {
        let payload = values;
        if (!values.offerImage) {
            payload = {
                ...values,
                offerImage: '',
            };
        }
        onAdd(payload);
        reset();
    };

    return (
        <Modal open={visible} onClose={onClose} width="650" overflow="auto">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className="tw-w-full tw-p-9 tw-overflow-y-auto tw-h-screen">
                    <h2 className="tw-mb-6">Informações da sua oferta</h2>
                    <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
                        <InputField
                            label="Nome do produto ou serviço*"
                            name="product"
                            ref={register}
                            className="tw-mt-6"
                            inputClassName="tw-w-full tw-border-gray-500"
                            error={errors.product}
                        />
                        <InputField
                            label="Validade da oferta"
                            name="validity"
                            type="date"
                            ref={register}
                            className="tw-mt-6"
                            inputClassName="tw-w-full tw-border-gray-500"
                        />
                    </div>
                    <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-7">
                        <InputField
                            label="Detalhes do produto ou serviço*"
                            name="productDescription"
                            ref={register}
                            className="tw-mt-6"
                            inputClassName="tw-w-full tw-border-gray-500"
                            error={errors.productDescription}
                        />
                    </div>
                    <div className="form-control tw-mt-8">
                        <span className="label tw-mb-4">Tipo de oferta*</span>
                        {offersType.map((type, index) => (
                            <span
                                key={type.value}
                                className="tw-mr-4 tw-inline-flex tw-align-middle tw-place-items-center"
                            >
                                <input
                                    id="offerType"
                                    name="offerType"
                                    type="radio"
                                    value={type.value}
                                    ref={register}
                                    defaultChecked={index === 0}
                                />
                                <label htmlFor="offerType" className="tw-ml-2">
                                    {type?.label}
                                </label>
                            </span>
                        ))}
                    </div>
                    <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-7">
                        <InputField
                            label="Valor em destaque*"
                            name="emphasis"
                            ref={register}
                            className="tw-mt-6"
                            inputClassName="tw-w-full tw-border-gray-500"
                            error={errors.emphasis}
                        />
                    </div>
                    <div className="form-control tw-mt-8">
                        <span className="label tw-mb-4 tw-font-bold">
                            Você está anunciando medicamento, bebidas alcoólicas ou algum produto regulamentado?*
                        </span>

                        <span className="tw-mr-4 tw-inline-flex tw-align-middle tw-place-items-center">
                            <input
                                id="regulatedProductYes"
                                name="regulatedProduct"
                                type="radio"
                                value="YES"
                                ref={register}
                            />
                            <label htmlFor="offerType" className="tw-ml-2">
                                Sim
                            </label>
                        </span>
                        <span className="tw-mr-4 tw-inline-flex tw-align-middle tw-place-items-center">
                            <input
                                id="regulatedProductNO"
                                name="regulatedProduct"
                                type="radio"
                                value="NO"
                                defaultChecked
                                ref={register}
                            />
                            <label htmlFor="offerType" className="tw-ml-2">
                                Não
                            </label>
                        </span>
                        <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-3">
                            {regulatedProduct === 'YES' && (
                                <Select name="regulatedProductType" ref={register} className="tw-w-full">
                                    <option value="">Selecione</option>
                                    {regulatedProductType?.map(({ label, value }) => (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    ))}
                                </Select>
                            )}
                        </div>
                    </div>
                    <div className="tw-flex tw-flex-col tw-w-full tw-mt-8">
                        <div className="tw-flex tw-flex-col">
                            <UploadImage
                                label="Imagem da oferta"
                                name="offerImage"
                                uploadAction={file => uploadOfferImage(file, userId)}
                            />
                        </div>
                    </div>
                    <div className="tw-mt-10 tw-text-center tw-mb-4 md:tw-mt-8">
                        <button className="button button-primary-outlined tw-mr-4" onClick={onClose} type="button">
                            Cancelar
                        </button>
                        <button className="button button-primary" type="submit">
                            Salvar
                        </button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default OfferModal;
