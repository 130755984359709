import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validator';
import { Creators as ActionsCreators } from 'store/modules/campaign';
import { useDispatch } from 'react-redux';
import { useCurrentCampaign } from 'hooks/campaign';

const schema = yup.object({
    tvCreativeId: yup.string().label('TV'),
    radioCreativeId: yup.string().label('Radio'),
});

function useSelectCriativoForm(onSubmit) {
    const methods = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();
    const campaign = useCurrentCampaign();
    function onSubmitForm(values) {
        const criativos = [values.tvCreativeId, values.radioCreativeId].filter(Boolean);

        if (criativos.length > 0) {
            dispatch(ActionsCreators.updateCriativosForCampaign(campaign.id, [values.tvCreativeId]));
        }

        onSubmit();
    }

    return { ...methods, onSubmit: methods.handleSubmit(onSubmitForm) };
}

export default useSelectCriativoForm;
