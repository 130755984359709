import React from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useCurrentCampaign } from 'hooks/campaign';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import AgeRangeSelector from './AgeRangeSelector';
import SocialClassSelector from './SocialClassSelector';
import BestTimeSelector from './BestTimeSelector';
import SegmentSelector from './SegmentSelector';
import GenderSelector from './GenderSelector';
import useTargetAndSegmentationForm from './useTargetAndSegmentationForm';

const TargetAndSegmentationStep = () => {
    const methods = useTargetAndSegmentationForm();
    const currentCampaign = useCurrentCampaign();
    const editable = currentCampaign.campaignEditable;
    const { control, onSubmit, formState } = methods;
    const { isSubmitting, isValidating } = formState;

    const dispatch = useDispatch();

    const handlePreviousClick = event => {
        event.preventDefault;
        dispatch(CampaignCreators.previousStep());
    };

    return (
        <FormProvider {...methods}>
            <h3>Selecione abaixo o perfil do público que quer alcançar</h3>

            <form onSubmit={onSubmit} className="tw-w-full sm:tw-w-2/3 md:tw-w-1/2">
                <div className="tw-mt-8">
                    <GenderSelector />
                </div>

                <div className="tw-mt-8">
                    <SocialClassSelector />
                </div>

                <div className="tw-mt-8">
                    <AgeRangeSelector />
                </div>

                <div className="tw-mt-8">
                    <SegmentSelector />
                </div>

                <div className="tw-mt-8">
                    <BestTimeSelector />
                </div>

                <div className="tw-mt-10 tw-text-center">
                    <button
                        type="button"
                        onClick={handlePreviousClick}
                        className="button button-primary-outlined tw-mr-4"
                    >
                        Voltar
                    </button>

                    <button
                        type="submit"
                        className="button button-primary"
                        disabled={!editable || isSubmitting || isValidating}
                    >
                        {currentCampaign.targetAndSegmentation?.segment ? 'Salvar' : 'Próximo'}
                    </button>
                </div>

                {__DEVELOPMENT__ && <DevTool control={control} />}
            </form>
        </FormProvider>
    );
};

export default TargetAndSegmentationStep;
