import * as Sentry from '@sentry/react';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { Creators as ActionsCreators, Types } from 'store/modules/campaign';
import { addCampaign, deleteCampaign, getCampaignById, getCampaigns, updateCriativosForCampaign } from 'api/campaign';
import { firebase, processPaymentForAdvertiser } from 'base';
import { getInsertionsCount, getInsertionValue, getProgramInsertionValue } from 'services/advertiser/campaign';
import { getCoverageCity } from 'api/coverageCities';
import { getCompanyById } from 'api/companies';

async function parseCoveredCity(document) {
    if (!document) return [];
    const list = [];
    const { programsRefs } = document.data();
    if (programsRefs?.length) {
        for await (const programReference of programsRefs) {
            const program = await programReference.get();
            if (program.exists) {
                list.push({
                    id: program.id,
                    ...program.data(),
                });
            }
        }
    }

    return list;
}

function* getCampaignsSaga(action) {
    const { meta } = action;
    const onSuccess = meta?.onSuccess;
    const onFailure = meta?.onFailure;

    try {
        const snapshot = yield call(getCampaigns);
        const campaignDocuments = snapshot.docs.map(document => {
            return {
                id: document.id,
                ...document.data(),
            };
        });

        yield put(ActionsCreators.getCampaignsSuccess(campaignDocuments));

        if (onSuccess) yield call(onSuccess);
    } catch {
        if (onFailure) yield call(onFailure);
    }
}

function* updateCriativosForCampaignSaga(action) {
    const { id, criativos } = action;
    yield call(updateCriativosForCampaign, id, criativos);
}

function programHasInsertion(program) {
    return getInsertionsCount(program) > 0;
}

function* saveCurrentCampaignSaga(action) {
    const currentUser = yield select(state => state.firebase.profile);
    const currentCampaign = yield select(state => state.campaignsReducer.currentCampaign);
    const companies = yield select(state => state.firestore.ordered.company);

    const tvPrograms = currentCampaign.mediaCentral.selectedTVPrograms.filter(program => programHasInsertion(program));
    const radioPrograms = currentCampaign.mediaCentral.selectedRadioPrograms.filter(program =>
        programHasInsertion(program),
    );
    const selectedCompanies = new Set();
    const programs = [...tvPrograms, ...radioPrograms];
    programs.forEach(program => {
        selectedCompanies.add(program.companyId);
    });

    const {
        objectivesAndDuration: { duration },
        budget: { budgetType },
        coupon,
        installments,
        kind,
        paymentMethod,
        ref,
    } = currentCampaign;

    function* processPaymentByCreditCard(campaignId) {
        const items = programs.map(program => {
            const totalInsertions = getInsertionsCount(program);
            return {
                id: program.id,
                title: `${program.programName} (${program.programType}) - ${totalInsertions} inserções de ${duration} segundos`,
                unit_price: program.insertionValue || 0,
                quantity: totalInsertions,
            };
        });

        yield call(processPaymentForAdvertiser, {
            campaignId,
            items,
            coupon,
            installments,
        });
    }

    function* saveCampaign() {
        yield put(ActionsCreators.setUserForCurrentCampaign(currentUser?.id));

        const mapInsertionValue = programs =>
            programs?.map(program => {
                let newProgram = { ...program };
                if (!newProgram.insertionValue) {
                    newProgram = {
                        ...newProgram,
                        insertionValue: getProgramInsertionValue(
                            companies,
                            program,
                            duration,
                            currentUser?.cnpj,
                            budgetType,
                        ),
                    };
                }
                const insertionCost = getInsertionValue(Number(duration), program.insertionValue, budgetType);
                return {
                    ...newProgram,
                    insertionCost,
                };
            });

        // Save campaign
        const data = {
            coupon,
            installments,
            paymentMethod,
            kind: kind || 'TV',
            ...currentCampaign,
            campaignEditable: false,
            userId: currentUser?.id,

            userCompanyName: currentUser?.company,
            selectedCompanies: [...selectedCompanies.values()].map(id => getCompanyById(id)),

            status: 'PENDING_PAYMENT',
            mediaCentral: {
                selectedTVPrograms: mapInsertionValue(tvPrograms),
                selectedRadioPrograms: mapInsertionValue(radioPrograms),
            },
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
        const documentReference = yield call(addCampaign, data);
        yield put(ActionsCreators.setCampaignRef(documentReference));
        return documentReference;
    }

    try {
        yield put(ActionsCreators.setIsAddingCampaign(true));

        let documentReference = ref;

        if (!documentReference) {
            documentReference = yield saveCampaign();
        }

        const paymentByCreditCard = paymentMethod === 'credit-card';
        if (paymentByCreditCard) {
            yield* processPaymentByCreditCard(documentReference.id);
        }

        if (action.onSave) {
            action.onSave(documentReference);
        }
    } catch (error) {
        Sentry.captureException(error);
        if (action.onSave) {
            action.onSave(null, error);
        }
    } finally {
        yield put(ActionsCreators.setIsAddingCampaign(false));
    }
}

function* getCampaignByIdSaga(action) {
    const { meta, id } = action;
    const onSuccess = meta?.onSuccess;
    const onFailure = meta?.onFailure;

    try {
        const snapshot = yield call(getCampaignById, id);
        yield put(ActionsCreators.setNewCurrentCampaign(snapshot.data()));

        if (onSuccess) yield call(onSuccess);
    } catch {
        if (onFailure) yield call(onFailure);
    }
}

function* getCampaignPrograms(action) {
    const currentCampaign = yield select(state => state.campaignsReducer.currentCampaign);
    const { city } = action;
    const snapshots = yield call(() => getCoverageCity(currentCampaign.localization.code, city).get());
    const data = yield call(parseCoveredCity, snapshots.docs[0]);
    yield put(ActionsCreators.getProgramsSuccess(data));
}

function* saveCampaignFromPackageSaga(action) {
    const currentUser = yield select(state => state.firebase.profile);
    const currentCampaign = yield select(state => state.campaignsReducer.currentCampaign);
    let campaignId = null;

    const { selectedTVPrograms, selectedRadioPrograms } = currentCampaign.mediaCentral;

    const { coupon, installments, paymentMethod, ref, packageItem } = currentCampaign;

    function* processPaymentByCreditCard(campaignId) {
        const items = [
            {
                id: packageItem.id,
                title: `${packageItem.packageName} (${packageItem.packageType})`,
                unit_price: packageItem.packageValue || 0,
                quantity: 1,
            },
        ];

        yield call(processPaymentForAdvertiser, {
            campaignId,
            items,
            coupon,
            installments,
        });
    }

    function* saveCampaign() {
        yield put(ActionsCreators.setUserForCurrentCampaign(currentUser?.id));

        // Save campaign
        const data = {
            coupon: coupon || null,
            installments: installments || 1,
            paymentMethod: paymentMethod,
            ...currentCampaign,
            campaignEditable: false,
            userId: currentUser?.id,
            userCompanyName: currentUser?.company,
            selectedCompanies: [getCompanyById(packageItem.companyId)],
            status: paymentMethod === 'credit-card' ? 'PENDING_APPROVE_INSERTIONS' : 'PENDING_PAYMENT',
            mediaCentral: {
                selectedTVPrograms,
                selectedRadioPrograms,
            },
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        };

        const documentReference = yield call(addCampaign, data);

        yield put(ActionsCreators.setCampaignRef(documentReference));
        return documentReference;
    }

    try {
        yield put(ActionsCreators.setIsAddingCampaign(true));

        let documentReference = ref;

        if (!documentReference) {
            documentReference = yield saveCampaign();
        }

        const paymentByCreditCard = paymentMethod === 'credit-card';

        campaignId = documentReference.id;

        if (paymentByCreditCard) {
            yield processPaymentByCreditCard(campaignId);
        }

        if (action.onSave) {
            action.onSave(documentReference);
        }

        if (action.meta?.onSuccess) {
            yield call(action.meta.onSuccess);
        }
    } catch (error) {
        yield call(deleteCampaign, campaignId);
        Sentry.captureException(error);
        if (action.onSave) {
            action.onSave(null, error);
        }
        if (action.meta?.onFailure) {
            yield call(action.meta.onFailure);
        }
    } finally {
        yield put(ActionsCreators.setIsAddingCampaign(false));
    }
}

function* campaignsRoot() {
    yield all([
        takeEvery(Types.SAVE_CURRENT_CAMPAIGN, saveCurrentCampaignSaga),
        takeLatest(Types.GET_CAMPAIGNS, getCampaignsSaga),
        takeLatest(Types.GET_CAMPAIGN_BY_ID, getCampaignByIdSaga),
        takeLatest(Types.UPDATE_CRIATIVOS_FOR_CAMPAIGN, updateCriativosForCampaignSaga),
        takeLatest(Types.GET_PROGRAMS, getCampaignPrograms),
        takeEvery(Types.SAVE_CAMPAIGN_FROM_PACKAGE, saveCampaignFromPackageSaga),
    ]);
}

export default campaignsRoot;
