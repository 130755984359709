import { v4 } from 'uuid';
import axios from 'axios';
import { firestore, storage } from 'base';

/**
 * Upload program images into storage
 * @param {File} imageFile - The image file
 */
export function uploadUserProfilePhoto(imageFile) {
    return storage.ref(`images/users/${v4()}-${imageFile.name}`).put(imageFile);
}

/**
 * Get user document by ID
 * @param {string} userId = The user document ID
 * @returns {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>}
 */
export function getUserById(userId) {
    return firestore.collection('users').doc(userId);
}

/**
 * Update user profile
 * @param {string} userId - User id
 * @param {Object} userProfile - The user profile data
 * @param {string} [userProfile.displayName] - The display name
 * @param {string} [userProfile.email] - The email
 * @param {string} [userProfile.gender] - The gender
 * @param {string} [userProfile.phone] - The phone
 * @param {string} [userProfile.company] - The company
 * @param {string} [userProfile.cnpj] - The cnpj
 * @param {string} [userProfile.address] - The address
 * @param {string} [userProfile.hasAgency] - The hasAgency
 * @param {string} [userProfile.receiveEmails] - The receiveEmails
 * @param {string} [userProfile.role] - The receiveEmails
 * @returns {Promise<void>}
 */
export function updateUserProfile(userId, userProfile) {
    return getUserById(userId).set(
        {
            id: userId,
            ...userProfile,
        },
        { merge: true },
    );
}

export function confirmEmail(oobCode, apiKey) {
    return axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:update?key=${apiKey}`, {
        oobCode,
    });
}
