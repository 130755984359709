import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { firebase } from 'base';
import UploadIcon from 'icons/UploadIcon';
import { v4 } from 'uuid';
import Loading from 'components/Loading';

function useUploadImage(uploadInputReference, uploadAction, onSelected, onCompleted, onError) {
    const [tasksMap, setTasksMap] = useState(new Map());

    const totalPercentCompleted = useMemo(() => {
        if (tasksMap.size === 0) {
            return;
        }
        let totalPercent = 0;
        for (let task of tasksMap.values()) {
            totalPercent += task.percentCompleted || 0;
        }
        return totalPercent / tasksMap.size;
    }, [tasksMap]);

    const uploadFile = useCallback(
        file => {
            const fileHash = `${v4()}-${file.name}`;
            const onUploadNext = snapshot => {
                const percentCompleted = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                const fileTaskInfo = tasksMap.get(fileHash);
                setTasksMap(
                    new Map(
                        tasksMap.set(fileHash, {
                            ...fileTaskInfo,
                            percentCompleted,
                        }),
                    ),
                );
            };
            const onUploadError = error => {
                if (onError) {
                    onError(file, error);
                }
                const fileTaskInfo = tasksMap.get(fileHash);
                setTasksMap(
                    new Map(
                        tasksMap.set(fileHash, {
                            ...fileTaskInfo,
                            error,
                        }),
                    ),
                );
            };
            const onUploadCompleted = () => {
                const fileTaskInfo = tasksMap.get(fileHash);
                fileTaskInfo.uploadTask
                    .then(imageReference => imageReference.ref.getDownloadURL())
                    .then(downloadUrl => {
                        setTasksMap(
                            new Map(
                                tasksMap.set(fileHash, {
                                    ...fileTaskInfo,
                                    downloadUrl,
                                }),
                            ),
                        );
                        if (onCompleted) {
                            onCompleted(file, downloadUrl);
                        }
                    });
            };

            const uploadTask = uploadAction(file);
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
                next: onUploadNext,
                error: onUploadError,
                complete: onUploadCompleted,
            });
            tasksMap.set(fileHash, {
                uploadTask: uploadTask,
            });
        },
        [onCompleted, onError, tasksMap, uploadAction],
    );

    const handleOnFileSelected = useCallback(
        event => {
            for (let file of event.target.files) {
                if (tasksMap.has(file.name)) {
                    throw new Error(`${file.name} already added into queue`);
                }

                onSelected(file);
                uploadFile(file);
            }
        },
        [onSelected, tasksMap, uploadFile],
    );

    useEffect(() => {
        const inputElement = uploadInputReference.current;
        inputElement.addEventListener('change', handleOnFileSelected, false);

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('change', handleOnFileSelected);
            }
        };
    }, [handleOnFileSelected, uploadInputReference]);

    return {
        percentCompleted: totalPercentCompleted,
        tasks: tasksMap.values(),
    };
}

const UploadButton = memo(({ text, name, uploadAction, onSelected, onCompleted, onError, disabled, ...rest }) => {
    const [loading, setLoading] = useState(false);
    const { register, unregister, setValue, getValues } = useFormContext();
    const inputReference = useRef();

    const handleSelected = useCallback(
        file => {
            setLoading(true);
            const reader = new FileReader();
            reader.addEventListener('load', function (event) {
                if (!rest.multiple) {
                    setValue(name, event.target.result, {
                        shouldValidate: true,
                    });
                }
            });
            reader.readAsDataURL(file);

            if (onSelected) {
                onSelected(file);
            }
        },
        [name, onSelected, rest.multiple, setValue],
    );

    const handleCompleted = useCallback(
        (file, url) => {
            if (onCompleted) {
                onCompleted(file, url);
            }
            if (rest.multiple) {
                const values = getValues(name) || [];
                setValue(name, [...values, url], { shouldValidate: true });
            } else {
                setValue(name, url, { shouldValidate: true });
            }
            setLoading(false);
        },
        [getValues, name, onCompleted, rest.multiple, setValue],
    );

    const { percentCompleted } = useUploadImage(inputReference, uploadAction, handleSelected, handleCompleted, onError);

    useEffect(() => {
        if (name) {
            register({ name });
        }

        return () => {
            unregister(name);
        };
    }, [name, register, unregister]);

    return (
        <>
            {loading && <Loading />}
            <button
                type="button"
                className="tw-flex tw-w-2/3 tw-items-center tw-justify-start tw-rounded-xl tw-bg-gray-200 tw-relative"
                disabled={disabled || (percentCompleted ? percentCompleted < 100 : false)}
            >
                <UploadIcon />
                <span className="tw-block tw-ml-3 tw-text-gray-400 tw-text-sm">{text}</span>

                <input
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                    className="tw-absolute tw-block tw-cursor-pointer tw-opacity-0 tw-left-0 tw-top-0"
                    ref={inputReference}
                    {...rest}
                />
            </button>
        </>
    );
});

UploadButton.displayName = 'UploadButton';
UploadButton.propTypes = {
    text: PropTypes.string.isRequired,
    uploadAction: PropTypes.func.isRequired,
    onCompleted: PropTypes.func,
    onSelected: PropTypes.func,
    onError: PropTypes.func,
};

export default UploadButton;
