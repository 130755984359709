import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

function TVIcon({ className, color, message: Message }) {
    const [showMessage, setShowMessage] = useState(false);

    const toggleMessage = () => setShowMessage(state => !state);

    return (
        <div className="tw-relative tw-cursor-pointer">
            {showMessage && Message && (
                <OutsideClickHandler onOutsideClick={toggleMessage}>
                    <Message />
                </OutsideClickHandler>
            )}
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                onClick={toggleMessage}
            >
                <g clipPath="url(#clip0)">
                    <path
                        d="M10.0021 20.0042C15.5261 20.0042 20.0042 15.5261 20.0042 10.0021C20.0042 4.4781 15.5261 0 10.0021 0C4.4781 0 0 4.4781 0 10.0021C0 15.5261 4.4781 20.0042 10.0021 20.0042Z"
                        fill={color || '#D8D8D8'}
                    />
                    <g clipPath="url(#clip1)">
                        <path
                            d="M14.8981 6.46917H10.6762L12.8154 4.39475L12.4275 4.01325L10.2075 6.15655L7.98743 4L7.60215 4.37885L9.73866 6.45592H5.10462C4.49843 6.45592 4 6.9434 4 7.54215V13.9164C4 14.5125 4.49573 15.0026 5.10462 15.0026H14.8981C15.5043 15.0026 16.0027 14.5152 16.0027 13.9164V7.5395C16 6.95665 15.5043 6.46917 14.8981 6.46917ZM11.4495 12.5176C11.4495 12.9653 11.0777 13.3309 10.6224 13.3309H6.34396C5.88864 13.3309 5.51684 12.9653 5.51684 12.5176V8.99133C5.51684 8.54359 5.88864 8.17799 6.34396 8.17799H10.6197C11.075 8.17799 11.4468 8.54359 11.4468 8.99133V12.5176H11.4495ZM13.5186 13.0077C12.9798 13.0077 12.5541 12.5865 12.5541 12.0592C12.5541 11.532 12.9825 11.1108 13.5186 11.1108C14.0548 11.1108 14.4832 11.532 14.4832 12.0592C14.4832 12.5865 14.0548 13.0077 13.5186 13.0077ZM14.6206 10.2524H12.414V9.7093H14.6206V10.2524ZM14.6206 8.89595H12.414V8.35284H14.6206V8.89595Z"
                            fill="white"
                        />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                    <clipPath id="clip1">
                        <rect width="12" height="11" fill="white" transform="translate(4 4)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

export default TVIcon;
