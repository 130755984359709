/* eslint-disable unicorn/prevent-abbreviations */
import React from 'react';
import { Controller } from 'react-hook-form';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

function MaterialInputCurrency({ name, label, rules, control, getValues, className, defaultValue, ...rest }) {
    return (
        <Controller
            render={props => (
                <CurrencyTextField
                    label={label}
                    currencySymbol="R$"
                    outputFormat="string"
                    value={getValues(name)}
                    decimalCharacter=","
                    digitGroupSeparator="."
                    className={className}
                    onChange={(_, value) => props.onChange(value)}
                    {...rest}
                />
            )}
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue || ''}
        />
    );
}

export default MaterialInputCurrency;
