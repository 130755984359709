/* eslint-disable no-unused-vars */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable unicorn/no-null */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaMars, FaTransgender, FaVenus } from 'react-icons/fa';

import InputField from 'molecules/InputField';

import { useProgram } from 'hooks/program';
import { setProgram } from 'store/modules/program';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';

const segments = [
    { value: 'Agro', label: 'Agro' },
    { value: 'Alimentos', label: 'Alimentos' },
    { value: 'Automotivo', label: 'Automotivo' },
    { value: 'Construção', label: 'Construção' },
    { value: 'Educação', label: 'Educação' },
    { value: 'Entretenimento', label: 'Entretenimento' },
    { value: 'Hotelaria / Turismo', label: 'Hotelaria / Turismo' },
    { value: 'Industria', label: 'Industria' },
    { value: 'Informática', label: 'Informática' },
    { value: 'Moda / Vestuário', label: 'Moda / Vestuário' },
    { value: 'Papelaria', label: 'Papelaria' },
    { value: 'Pet', label: 'Pet' },
    { value: 'Saúde', label: 'Saúde' },
    { value: 'Segurança', label: 'Segurança' },
    { value: 'Serviços', label: 'Serviços' },
    { value: 'Transporte', label: 'Transporte' },
    { value: 'Tech', label: 'Tech' },
];

const schema = yup.object({
    segments: yup.array().required('Informe o segmento!').min(1, 'Selecione ao menos um segmento!'),
    impact: yup
        .number()
        .min(10, 'Informe o número de pessoas alcançadas por inserção')
        .typeError('Informe o número de pessoas alcançadas'),
});

const colourStyles = {
    control: styles => ({
        ...styles,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        border: 0,
        borderRadius: 10,
        padding: 0,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
        color: '#000',
    }),
};

const socialClasses = [
    {
        value: 'A',
        key: 'classA',
    },
    {
        value: 'B',
        key: 'classB',
    },
    {
        value: 'C',
        key: 'classC',
    },
    {
        value: 'D',
        key: 'classD',
    },
    {
        value: 'E',
        key: 'classE',
    },
];

const genders = [
    { value: 'male', label: 'Homem' },
    { value: 'female', label: 'Mulher' },
    { value: 'all', label: 'Todos' },
];

const SelectGenderButton = ({ onSelect, selected, value, label }) => {
    function handleSelectButton(event) {
        event.preventDefault();
        onSelect(value);
    }

    const selectButtonClassNames = clsx(
        'tw-flex tw-items-center tw-cursor-pointer tw-justify-center tw-rounded-md tw-shadow-lg tw-w-1/3 tw-h-24 tw-mx-1',
        {
            'tw-bg-pink': selected,
        },
    );
    const labelClassNames = clsx(`tw-block tw-text-xs tw-mt-2`, {
        'tw-text-white': selected,
        'tw-text-pink': !selected,
    });

    return (
        <button type="button" onClick={handleSelectButton} className={selectButtonClassNames}>
            <div className="tw-flex tw-flex-col">
                <FaMars size={44} className="tw-text-gray-400" />
                <span className={labelClassNames}>{label}</span>
            </div>
        </button>
    );
};

const GenderSelector = ({ selectedGender, ...properties }) => (
    <div className="tw-w-full tw-flex tw-flex-row tw-justify-between">
        {genders.map(({ label, value }) => (
            <SelectGenderButton
                key={value}
                {...properties}
                selected={value === selectedGender}
                value={value}
                label={label}
            />
        ))}
    </div>
);

const Audience = ({ handleNext, handleBack }) => {
    const [gender, setGender] = useState('');
    const [socialClass, setSocialClass] = useState({
        classA: false,
        classB: false,
        classC: false,
        classD: false,
        classE: false,
    });

    const { setValue, errors, control, handleSubmit, register, formState } = useForm({
        resolver: yupResolver(schema),
    });

    const { isSubmitting } = formState;

    const program = useProgram();

    const dispatch = useDispatch();

    const handleSelectGender = value => setGender(value);

    const handleSelectSocialClass = value => {
        switch (value) {
            case 'A':
                setSocialClass(current => ({
                    ...current,
                    classA: !current.classA,
                }));
                break;
            case 'B':
                setSocialClass(current => ({
                    ...current,
                    classB: !current.classB,
                }));
                break;
            case 'C':
                setSocialClass(current => ({
                    ...current,
                    classC: !current.classC,
                }));
                break;
            case 'D':
                setSocialClass(current => ({
                    ...current,
                    classD: !current.classD,
                }));
                break;
            case 'E':
                setSocialClass(current => ({
                    ...current,
                    classE: !current.classE,
                }));
                break;
            default:
                break;
        }
    };

    const onSubmit = useCallback(
        values => {
            if (!gender) {
                toast.error('Selecione um dos gêneros para o programa!');
                return;
            }

            const hasNoSocialClassSelected =
                !socialClass.classA &&
                !socialClass.classB &&
                !socialClass.classC &&
                !socialClass.classD &&
                !socialClass.classE;

            if (hasNoSocialClassSelected) {
                toast.error('Selecione ao menos uma das classes sociais para o programa!');
                return;
            }

            dispatch(
                setProgram({
                    ...program,
                    ...values,
                    gender,
                    socialClass,
                    segments: values.segments.map(item => item.value),
                }),
            );

            handleNext();
        },
        [dispatch, handleNext, socialClass, gender, program],
    );

    useEffect(() => {
        for (const name of Object.keys(errors)) {
            toast.error(errors[name].message);
        }
    }, [errors]);

    useEffect(() => {
        if (program.gender) {
            setGender(program.gender);
        }

        if (program.socialClass) {
            setSocialClass(program.socialClass);
        }
    }, [program, setValue]);

    return (
        <form className="tw-w-full" onSubmit={handleSubmit(onSubmit)}>
            <h2>Defina a segmentação do programa</h2>
            <div className="tw-w-full tw-flex md:tw-flex-row tw-flex-col tw-mt-6">
                <div className="tw-flex tw-flex-col md:tw-w-1/2 tw-w-full">
                    <span className="tw-block tw-my-3">O Programa é voltado para</span>
                    <GenderSelector onSelect={handleSelectGender} selectedGender={gender} />
                    <div className="tw-w-full tw-mt-6">
                        <span className="tw-block tw-my-3">Selecionar segmentos</span>
                        <Controller
                            as={Select}
                            isMulti
                            options={segments}
                            name="segments"
                            control={control}
                            styles={colourStyles}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                            noOptionsMessage={() => 'Nenhuma opção'}
                            placeholder={<span>Selecionar segmentos</span>}
                            defaultValue={segments?.filter(segment =>
                                program?.segments?.find(item => item === segment.value),
                            )}
                        />
                    </div>
                    <div className="tw-w-full tw-mt-6">
                        <span className="tw-block tw-my-3">Classe social</span>
                        <div className="tw-w-full tw-flex tw-flex-row tw-justify-between">
                            {socialClasses.map(({ key, value }) => (
                                <button
                                    key={key}
                                    type="button"
                                    onClick={() => handleSelectSocialClass(value)}
                                    className={`${
                                        socialClass[key] && 'tw-bg-pink'
                                    } tw-flex tw-items-center tw-cursor-pointer tw-justify-center tw-rounded-md tw-shadow-lg tw-w-1/5 tw-h-14 md:tw-h-24 tw-mx-1`}
                                >
                                    <div className="tw-flex tw-flex-col">
                                        <span
                                            className={`tw-block ${
                                                socialClass[key] ? 'tw-text-white' : 'tw-text-pink'
                                            } tw-text-4xl`}
                                        >
                                            {value}
                                        </span>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="tw-flex tw-flex-col md:tw-w-1/2 tw-w-full">
                    <InputField
                        name="impact"
                        type="number"
                        defaultValue={program?.impact}
                        ref={register}
                        label="Pessoas alcançadas por inserção*"
                        className="tw-mt-6 md:tw-ml-6 md:tw-mx-3"
                        inputClassName="tw-w-full tw-border-gray-500"
                    />
                </div>
            </div>
            <div className="tw-mt-10 tw-text-center tw-mb-4 md:tw-mt-24">
                <button
                    className="button button-primary-outlined tw-mr-4 tw-w-full md:tw-w-1/6"
                    onClick={handleBack}
                    type="button"
                    disabled={isSubmitting}
                >
                    Voltar
                </button>
                <button
                    className="button button-primary tw-border-2 tw-border-pink md:tw-mt-0 tw-mt-3 tw-w-full md:tw-w-1/6"
                    type="submit"
                    disabled={isSubmitting}
                >
                    Prosseguir
                </button>
            </div>
        </form>
    );
};

export default Audience;
