import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Creators as ActionCreators } from 'store/modules/regions';
import { useUserCompany } from 'hooks/company';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import RegionRow from './RegionRow';

const RegionsTable = ({ regions, onEdit }) => {
    const dispatch = useDispatch();
    const company = useUserCompany();
    const [regionToDelete, setRegionToDelete] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const toggleDeleteModal = useCallback(() => {
        setShowDeleteModal(previous => !previous);
    }, []);

    const handlePrepareToDeleteRegion = useCallback(
        data => {
            setRegionToDelete(data);
            toggleDeleteModal();
        },
        [toggleDeleteModal],
    );

    const handleDeleteRegion = useCallback(async () => {
        const { id: regionId, state, cities } = regionToDelete;

        function onFinish() {
            dispatch(ActionCreators.getRegions(company.id));
        }

        dispatch(
            ActionCreators.deleteRegion(
                { regionId, state, cities, companyId: company.id },
                { onSuccess: onFinish, onFailure: onFinish },
            ),
        );

        toggleDeleteModal();
    }, [dispatch, regionToDelete, company, toggleDeleteModal]);

    return (
        <>
            <ConfirmDeleteModal
                showDeleteModal={showDeleteModal}
                toggleDeleteModal={toggleDeleteModal}
                handleDeleteRegion={handleDeleteRegion}
            />
            <table className="tw-w-full tw-mt-6">
                <thead>
                    <tr>
                        <th className="tw-text-purple tw-text-left" align="left">
                            Nome
                        </th>
                        <th className="tw-text-purple" align="left">
                            Estado
                        </th>
                        <th className="tw-text-purple" align="left">
                            Cidades
                        </th>

                        <th className="tw-text-purple tw-text-center">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {regions.map(region => (
                        <RegionRow
                            region={region}
                            onEdit={onEdit}
                            onDelete={handlePrepareToDeleteRegion}
                            key={region.id}
                        />
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default RegionsTable;
