import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from 'store/modules/user';
import { addCard, deleteCard, selectDefaultCard } from 'store/modules/wallet';
import * as S from './styles';

const formStyle = {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
};
const formStyleFlex = { display: 'flex', flexDirection: 'column' };
const width70 = { width: '70%' };
const newCardStyle = { color: 'white', background: 'green' };
const initialValueForm = {
    card: '',
    flag: '',
    name: '',
    cpf: '',
    validDate: '',
};
const addButtonStyle = { width: '10%' };
const messageColorStyle = { color: '#ff0000' };

const errorRenderMessage = message => <small style={messageColorStyle}>{message}</small>;

const Wallet = () => {
    // Hooks
    const dispatch = useDispatch();
    const user = useSelector(({ userReducer }) => userReducer.user);

    // State
    const [card, setCard] = useState('');
    const [toggleCreate, setToggleCreate] = useState(false);

    // Get User
    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    const handleChange = event => {
        setCard(event.target.value);
    };

    const handleAddNewCard = ({ card, flag, name, cpf, validDate }) => {
        const uptCard = `**** **** **** ${card.slice(12, 16)}`;
        const uptCpf = cpf.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            function (regex, argumento1, argumento2, argumento3, argumento4) {
                return argumento1 + '.' + argumento2 + '.' + argumento3 + '-' + argumento4;
            },
        );
        const uptValidDate = validDate.slice(0, 2) + '/' + validDate.slice(2, 4);
        const newCard = {
            card: uptCard,
            flag,
            name,
            cpf: uptCpf,
            validDate: uptValidDate,
        };

        dispatch(addCard(newCard));
    };

    const handleDeleteCard = () => {
        const newWallet = user.wallet?.filter(wallet => wallet.card !== card);
        dispatch(deleteCard(newWallet));
    };

    const handleSelectDefaultCard = () => {
        if (card.length > 0) {
            const updateUser = { ...user, defaultPayment: card };
            dispatch(selectDefaultCard(updateUser));
        }
    };

    return user ? (
        <S.Container>
            <S.Title>Carteira</S.Title>

            <Typography variant="h4" color="secondary">
                Formas de Pagamento
            </Typography>

            <FormControl variant="outlined" style={formStyle}>
                <InputLabel id="card-label">Cartão</InputLabel>
                <Select label="Card" labelId="card-label" value={card} onChange={handleChange} style={width70}>
                    <MenuItem value="">
                        <em>Nenhuma</em>
                    </MenuItem>
                    {user.wallet?.map(item => (
                        <MenuItem key={item.card} value={item.card}>
                            {item.card}
                        </MenuItem>
                    ))}
                </Select>
                <Button type="button" variant="contained" color="primary" onClick={() => handleSelectDefaultCard()}>
                    Selecionar
                </Button>
                <Button type="button" variant="contained" color="secondary" onClick={() => handleDeleteCard()}>
                    Deletar
                </Button>
            </FormControl>
            <Button variant="contained" style={newCardStyle} onClick={() => setToggleCreate(!toggleCreate)}>
                Novo Cartão
            </Button>
            {toggleCreate && (
                <Formik
                    initialValues={initialValueForm}
                    validationSchema={validationSchema}
                    onSubmit={values => handleAddNewCard(values)}
                >
                    <FormikForm style={formStyleFlex}>
                        <div>
                            <Field type="text" name="card" placeholder="Número 16 caracteres" maxLength={16} />
                            <ErrorMessage name="card" render={errorRenderMessage} />
                        </div>
                        <div>
                            <Field as="select" name="flag" placeholder="Tipo">
                                <option value=""></option>
                                <option value="visa">Visa</option>
                                <option value="master">Master</option>
                                <option value="elo">Elo</option>
                                <option value="american-express">American Express</option>
                                <option value="outro">Outro</option>
                            </Field>
                            <ErrorMessage name="flag" render={errorRenderMessage} />
                        </div>
                        <div>
                            <Field type="text" name="name" placeholder="Nome impresso no cartão" />
                            <ErrorMessage name="name" render={errorRenderMessage} />
                        </div>
                        <div>
                            <Field type="text" name="cpf" placeholder="CPF só números" maxLength={11} />
                            <ErrorMessage name="cpf" render={errorRenderMessage} />
                        </div>
                        <div>
                            <Field type="text" name="validDate" placeholder="mm/yy" maxLength="4" />
                            <ErrorMessage name="validDate" render={errorRenderMessage} />
                        </div>
                        <Button type="submit" variant="contained" color="primary" style={addButtonStyle}>
                            Adicionar
                        </Button>
                    </FormikForm>
                </Formik>
            )}
        </S.Container>
    ) : (
        <S.Container>
            <S.Title>Nenhum cartão ativo</S.Title>
        </S.Container>
    );
};

const validationSchema = yup.object({
    card: yup.string().length(16, 'Informe os 16 números do cartão').trim().required('Informe o número do cartão'),
    flag: yup.string().required('Informe o tipo do cartão'),
    name: yup.string().trim().required('Informe o nome do titular do cartão'),
    cpf: yup
        .string()
        .length(11, 'Informe apenas os 11 números do cpf')
        .trim()
        .required('Informe o cpf do titular do cartão'),
    validDate: yup
        .string()
        .length(4, 'Informe apenas os 4 números da data mm/yy')
        .trim()
        .required('Informe a data de validade do cartão'),
});

export default Wallet;
