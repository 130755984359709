import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import * as Sentry from '@sentry/react';
import thunk from 'redux-thunk';
import rootReducer from './modules';
import rootSaga from './sagas';

const initialState = {};

const sagaMiddleware = createSagaMiddleware();
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, sagaMiddleware), sentryReduxEnhancer),
);

sagaMiddleware.run(rootSaga);

export default store;
