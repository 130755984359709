import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading';
import EmailConfirmation from 'pages/shared/EmailConfirmation';
import Auth from 'pages/shared/Auth';
import { useUserHasVerifiedEmail } from 'hooks/user';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';
import useRouterListener from './useRouterListener';

const Routes = () => {
    const auth = useSelector(store => store.firebase.auth);
    const profile = useSelector(store => store.firebase.profile);
    useRouterListener();

    const isEmailVerified = useUserHasVerifiedEmail();

    if (!auth.isLoaded || !profile.isLoaded) {
        return <Loading />;
    }

    if (auth.isEmpty) {
        return <PublicRoutes />;
    }

    if (isEmailVerified) {
        return <ProtectedRoutes />;
    }

    return (
        <>
            <Route exact path="/" component={EmailConfirmation} />
            <Route exact path="/auth/email/confirm" component={Auth} />
        </>
    );
};

export default Routes;
