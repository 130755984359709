import React, { useReducer } from 'react';
import Modal from 'react-awesome-modal';
import PlayIcon from 'icons/PlayIcon';

function InfoSection({ creative }) {
    const [openVideo, toggleVideo] = useReducer(state => !state, false);

    return (
        <div className="tw-w-full tw-flex tw-justify-between tw-flex-col md:tw-flex-row">
            <Modal visible={openVideo} width="710" effect="fadeInUp" onClickAway={toggleVideo} key={creative.id}>
                <video src={creative.media?.url} controls />
            </Modal>
            <div className="tw-w-full tw-flex tw-mt-8 md:tw-flex-row tw-flex-col">
                <div className="tw-w-full md:tw-w-1/2">
                    <div className="tw-flex tw-flex-col">
                        <h4 className="tw-text-purple tw-font-bold">Nome do comercial:</h4>
                        <p>{creative.commercialName}</p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-mt-8">
                        <h4 className="tw-text-purple tw-font-bold">Duração do comercial:</h4>
                        <p>{`${creative.durationTime} segundos`}</p>
                    </div>
                </div>
                <div className="tw-w-full md:tw-w-1/2">
                    <div className="tw-flex tw-flex-col">
                        <div className="tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">Nome do seu negócio:</h4>
                            <p>{creative.businessName}</p>
                        </div>
                        <div className="tw-flex tw-flex-col tw-mt-8">
                            <h4 className="tw-text-purple tw-font-bold">Site ou E-mail</h4>
                            <p>{creative.siteOrEmail ? creative.siteOrEmail : '-'}</p>
                        </div>
                    </div>
                </div>
                <div className="tw-w-full md:tw-w-1/2">
                    <div className="tw-flex tw-flex-col">
                        <div className="tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">Slogan:</h4>
                            <p>{creative.slogan}</p>
                        </div>
                        <div className="tw-flex tw-flex-col tw-mt-8">
                            <h4 className="tw-text-purple tw-font-bold">Tipo:</h4>
                            <p>{creative.creativeType || 'TV'}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tw-w-full tw-flex tw-flex-col md:tw-mt-0 tw-mt-6">
                <div className="tw-w-full tw-max-w-xs md:tw-mr-8 tw-flex tw-place-self-center">
                    <div className="tw-flex tw-flex-col tw-relative">
                        <h3 className="tw-text-gray-700 tw-mb-3">Template Escolhido</h3>
                        <div className="tw-w-full tw-shadow-xl tw-rounded-xl">
                            <PlayIcon
                                className="tw-absolute tw-bottom-14 tw-left-4 tw-cursor-pointer tw-z-50"
                                onClick={creative.onClick}
                            />
                            <video src={creative.media?.url} className="tw-rounded-t-xl" />
                            <div className="tw-w-full tw-h-11 tw-flex tw-justify-between tw-items-center tw-px-3">
                                <p>{creative.media?.name}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoSection;
