import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { getCompaniesByIds } from 'store/modules/company';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import { useCompanies, useCurrentCampaign } from 'hooks/campaign';
import { useQuery } from 'hooks/query';
import { useCoverageCitiesCompanyIds } from 'hooks/coverageCities';
import CompaniesList from './CompaniesList';
import ProgramSelector from './ProgramSelector';
import useMediaCentralForm from './useMediaCentralForm';

function useCompaniesByQueryParameter() {
    const tipo = useQuery().get('tipo') || 'TV';
    const allCompanies = useCompanies();
    return allCompanies.filter(company => company.type === tipo.toUpperCase());
}

const MediaCentralStep = () => {
    const [selectedCompany, setSelectedCompany] = useState();

    const dispatch = useDispatch();

    const {
        localization: { code },
        objectivesAndDuration: { city },
    } = useCurrentCampaign();

    const companyIds = useCoverageCitiesCompanyIds(code, city);
    const companies = useCompaniesByQueryParameter();
    useEffect(() => {
        if (companyIds.length > 0) {
            dispatch(getCompaniesByIds(companyIds));
        }
    }, [dispatch, companyIds]);

    const { targetAndSegmentation, campaignEditable } = useCurrentCampaign();

    const methods = useMediaCentralForm();
    const { handleSubmit, isSubmitting, isValidating, control } = methods;

    const handlePreviousClick = event => {
        event.preventDefault;
        dispatch(CampaignCreators.previousStep());
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit}>
                {companies.length > 0 && (
                    <CompaniesList
                        title="Veículos "
                        companies={companies}
                        selectedCompany={selectedCompany}
                        allowSelect={campaignEditable}
                        onSelect={setSelectedCompany}
                    >
                        <p>Escolha os veículos em que deseja distribuir o seu investimento</p>
                    </CompaniesList>
                )}

                <hr className="tw-my-6" />

                {selectedCompany && <ProgramSelector company={selectedCompany} />}

                <div className="tw-mt-10 tw-text-center">
                    <button
                        type="button"
                        onClick={handlePreviousClick}
                        className="button button-primary-outlined tw-mr-4"
                    >
                        Voltar
                    </button>

                    <button
                        type="submit"
                        className="button button-primary"
                        disabled={!campaignEditable || isSubmitting || isValidating}
                    >
                        {targetAndSegmentation?.segment ? 'Salvar' : 'Próximo'}
                    </button>
                </div>

                {__DEVELOPMENT__ && <DevTool control={control} />}
            </form>
        </FormProvider>
    );
};

export default MediaCentralStep;
