import React from 'react';
import PropTypes from 'prop-types';

const SupplierDashboardIcon = ({ className = '' }) => (
    <svg
        width="32"
        height="24"
        viewBox="0 0 32 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`icon ${className}`}
    >
        <path
            d="M26.5372 4.52442C26.1371 4.12435 25.5076 4.06552 25.0428 4.38322C23.2837 5.58344 14.4527 11.6198 13.4996 12.567C12.1229 13.9438 12.1229 16.1853 13.4996 17.5621C14.188 18.2504 15.094 18.5917 15.9942 18.5917C16.9002 18.5917 17.8004 18.2504 18.4887 17.5621C19.436 16.6148 25.4723 7.78383 26.6725 6.01881C26.9961 5.54814 26.9373 4.9245 26.5372 4.52442ZM16.8296 15.8912C16.3707 16.3501 15.6235 16.3501 15.1646 15.8912C14.7057 15.4323 14.7057 14.6851 15.1646 14.2262C15.5353 13.8614 18.0416 12.0728 21.1245 9.93128C18.9829 13.0201 17.1944 15.5264 16.8296 15.8912Z"
            fill="white"
        />
        <path
            d="M6.36298 20.5331L4.90978 21.2391C3.85076 19.3446 3.29184 17.2266 3.29184 15.0615C3.29184 13.473 3.58601 11.9551 4.1214 10.549L5.78641 11.3491C5.95114 11.4256 6.12176 11.4668 6.29238 11.4668C6.73363 11.4668 7.15136 11.2197 7.35139 10.7961C7.6338 10.2077 7.38669 9.50759 6.79835 9.22518L5.15688 8.43681C7.19842 5.1068 10.7344 2.78285 14.8233 2.40631V4.17722C14.8233 4.82439 15.3528 5.3539 16 5.3539C16.6472 5.3539 17.1767 4.82439 17.1767 4.17722V2.40631C18.5769 2.53575 19.936 2.88875 21.2245 3.47121C21.8187 3.73596 22.5129 3.47709 22.7836 2.88287C23.0483 2.28865 22.7895 1.5944 22.1952 1.32377C20.2419 0.447139 18.1592 0 16 0C11.9758 0 8.19272 1.56499 5.35103 4.41256C2.50346 7.25424 0.938477 11.0373 0.938477 15.0615C0.938477 18.0326 1.82099 20.9273 3.49188 23.4218C3.71545 23.7572 4.0861 23.9455 4.46852 23.9455C4.64502 23.9455 4.81564 23.9043 4.98626 23.8278L7.39846 22.6511C7.98092 22.3687 8.22802 21.6627 7.93973 21.0802C7.65145 20.4919 6.95132 20.2507 6.36298 20.5331Z"
            fill="white"
        />
        <path
            d="M29.7318 8.87219C29.467 8.27796 28.7669 8.01909 28.1727 8.28384C27.5784 8.5486 27.3196 9.24872 27.5843 9.84295C28.3256 11.4903 28.7022 13.2436 28.7022 15.0674C28.7022 17.2384 28.1432 19.3564 27.0783 21.2568L25.5604 20.5331C24.9721 20.2566 24.2719 20.5037 23.9895 21.092C23.713 21.6804 23.9601 22.3805 24.5485 22.6629L27.0195 23.8396C27.1842 23.9161 27.3549 23.9514 27.5255 23.9514C27.9079 23.9514 28.2786 23.7631 28.5021 23.4277C30.1789 20.9273 31.0614 18.0326 31.0614 15.0615C31.0614 12.9023 30.6143 10.8196 29.7318 8.87219Z"
            fill="white"
        />
    </svg>
);

SupplierDashboardIcon.propTypes = {
    className: PropTypes.string,
};

export default SupplierDashboardIcon;
