import React from 'react';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';

const CheckboxCardField = ({ name, defaultChecked, label, control, className = '', ...rest }) => {
    return (
        <div className={clsx('card-checkbox', className)}>
            <Controller
                control={control}
                name={name}
                defaultChecked={defaultChecked}
                defaultValue={defaultChecked}
                render={({ onChange, name }) => (
                    <input
                        name={name}
                        defaultChecked={defaultChecked}
                        defaultValue={defaultChecked}
                        type="checkbox"
                        onChange={event => {
                            onChange(event.target.checked);
                        }}
                        {...rest}
                    />
                )}
            />

            <label aria-label={name} htmlFor={name}>
                {label}
            </label>
        </div>
    );
};
CheckboxCardField.displayName = 'CheckboxCardField';

export default CheckboxCardField;
