import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import Slider from 'react-slick';
import ReactPlayer from 'react-player/lazy';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import { useCompanies, useCurrentCampaign } from 'hooks/campaign';
import { getInsertionsCount } from 'services/advertiser/campaign';
import { currencyFormatter } from 'utils';
import Modal from 'atoms/Modal';
import ArrowRightIcon from 'icons/ArrowRightIcon';
import ArrowLeftIcon from 'icons/ArrowLeftIcon';
import { getProgramInsertionValue } from 'src/services/advertiser/campaign';
import { useUser } from 'hooks/user';
import { getCompanyById } from 'api/companies';
import { useState } from 'react';

const ButtonDay = ({ day, onClick }) => {
    const buttonClass = clsx('tw-uppercase tw-shadow-md tw-rounded-md button button-outline button-small', {
        'button-outline__active': day.selected,
    });

    function handleClick(event) {
        event.preventDefault();
        onClick(day);
    }

    return (
        <button className={buttonClass} type="button" disabled={!day.available} onClick={handleClick}>
            {day.label[0]}
        </button>
    );
};

const DaysSelector = ({ program }) => {
    const dispatch = useDispatch();
    function handleDayClick(day) {
        dispatch(CampaignCreators.toggleAsSelectedDay({ program, day }));
    }
    return (
        <>
            <p className="tw-font-bold-semibold">Dias</p>
            <div className="tw-ml-3 tw-mt-2">
                <p className="tw-text-gray-light tw-text-sm">Selecione os dias que deseja anunciar</p>

                <div className="tw-mt-2 tw-flex tw-place-items-start tw-gap-1">
                    {program.showDays.map(day => (
                        <ButtonDay key={day.day} day={day} onClick={handleDayClick} />
                    ))}
                </div>
            </div>
        </>
    );
};

const ButtonInsertion = ({ day, onAdd, onSubtract }) => {
    function handleSubtractClick(event) {
        event.preventDefault();
        onSubtract(day);
    }
    function handleAddClick(event) {
        event.preventDefault();
        onAdd(day);
    }

    return (
        <div className="tw-flex tw-align-middle tw-place-items-center tw-place-content-center tw-justify-evenly tw-text-sm">
            <span>{day.label}</span>

            <div className="tw-flex tw-place-items-center tw-place-content-center tw-justify-end tw-gap-2">
                <button
                    className="button button-outline button-small tw-rounded-md tw-shadow-md"
                    onClick={handleSubtractClick}
                    disabled={!day.insertions || day.insertions <= 0}
                >
                    -
                </button>
                <span>{day.insertions || 0}</span>
                <button
                    className="button button-outline button-small tw-rounded-md tw-shadow-md"
                    onClick={handleAddClick}
                >
                    +
                </button>
                <span>inserções</span>
            </div>
        </div>
    );
};

function getCampaignValue(selectedPrograms) {
    return (
        Number.parseFloat(
            selectedPrograms?.reduce((campaignValue, program) => {
                const insertionCount = program.showDays.reduce((insertionsTotal, showDay) => {
                    // noinspection JSUnusedAssignment
                    return showDay.insertions > 0 ? (insertionsTotal += showDay.insertions) : insertionsTotal;
                }, 0);
                return (campaignValue += program.insertionValue * insertionCount);
            }, 0),
        ) || 0
    );
}

const InsertionsSelector = ({ program, campaignWeeks }) => {
    const [companies, setCompanies] = useState([]);

    const dispatch = useDispatch();
    const selectedDays = program.showDays.filter(d => d.selected);
    const {
        mediaCentral: { selectedTVPrograms, selectedRadioPrograms },
    } = useCurrentCampaign();

    function handleSubtractInsertion(day) {
        const amount = (day.insertions || 0) - 1;
        dispatch(CampaignCreators.setInsertionAmountOnDay({ day, program, amount }));
    }

    async function handleAddInsertion(day) {
        const amountDays = day.insertions || 0;
        let company = companies.find(element => element.id === program.companyId);

        if (!company) {
            company = await getCompanyById(program.companyId).get();

            company = {
                id: company.id,
                ...company.data(),
            };

            setCompanies(current =>
                current?.find(element => element.id === company.id) ? current : [...current, company],
            );
        }

        const allProgramsByCompany = [...(selectedRadioPrograms || []), ...(selectedTVPrograms || [])].filter(
            element => element.companyId === program.companyId,
        );

        const compaignValueByCompany = getCampaignValue(allProgramsByCompany);

        if (compaignValueByCompany + program.insertionValue > company.campaignTVMaxValue) {
            toast.warning('Valor máximo de inserções da emissora atingido!');
            return;
        }

        if (amountDays < campaignWeeks) {
            const amount = amountDays + 1;
            dispatch(
                CampaignCreators.setInsertionAmountOnDay({
                    day,
                    program,
                    amount,
                }),
            );
        } else {
            toast.warn(`Você só pode fazer até ${campaignWeeks} inserções por dia!`);
        }
    }

    return (
        <>
            <p className="tw-font-bold-semibold tw-pt-6">Quantidade</p>
            <div className="tw-ml-3 tw-mt-2">
                <p className="tw-text-gray-light tw-text-sm">Selecione a quantidade de comerciais por dia</p>

                <ul>
                    {selectedDays.map(day => (
                        <li key={day.day} className="tw-py-2">
                            <ButtonInsertion
                                day={day}
                                onAdd={handleAddInsertion}
                                onSubtract={handleSubtractInsertion}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

function totalCommercialsValueFor(totalCommercials, companies, program, duration, userCNPJ, budgetType) {
    let insertionValue = program.insertionValue;
    if (!insertionValue) {
        insertionValue = getProgramInsertionValue(companies, program, duration, userCNPJ, budgetType);
    }
    return insertionValue * totalCommercials;
}

const NextArrow = ({ className, style, onClick }) => {
    const arrowStyle = useMemo(() => ({ ...style, display: 'block', right: 10 }), [style]);
    return <ArrowRightIcon className={`${className} tw-z-30`} style={arrowStyle} onClick={onClick} />;
};
const PreviousArrow = ({ className, style, onClick }) => {
    const arrowStyle = useMemo(() => ({ ...style, display: 'block', left: '10' }), [style]);
    return <ArrowLeftIcon className={`${className} tw-z-30`} style={arrowStyle} onClick={onClick} />;
};
const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
};

const EditProgramModal = ({ program, onClose, ...rest }) => {
    const {
        objectivesAndDuration: { duration, campaignWeeks },
        budget: { budgetType },
    } = useCurrentCampaign();

    const hasSecondsAvailableForProgram = program.availableSeconds >= duration;
    const hasDaysSelected = program.showDays.some(d => d.selected);

    const companies = useCompanies();
    const user = useUser();

    const comercialCount = getInsertionsCount(program);
    const comercialValue = totalCommercialsValueFor(
        comercialCount,
        companies,
        program,
        duration,
        user.cnpj,
        budgetType,
    );

    function handleConcludeClick(event) {
        event.preventDefault();
        onClose();
    }

    return (
        <Modal {...rest} onClose={onClose} className="tw-z-30 tw-w-full md:tw-w-96">
            <Slider {...settings} className=" tw-h-40">
                {program.teaserUrl && (
                    <div className="tw-w-full tw-rounded-tl-md tw-rounded-tr-md tw-h-40">
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            url={program.teaserUrl}
                            controls={true}
                            className="tw-w-full tw-h-40"
                        />
                    </div>
                )}
                {[...program.banners]
                    ?.sort((_, banner) => (banner?.isMain ? 1 : -1))
                    ?.map(image => (
                        <img
                            key={image.id}
                            src={image.image}
                            alt={program.programName}
                            className="tw-w-full tw-rounded-tl-md tw-rounded-tr-md tw-h-40"
                        />
                    ))}
            </Slider>

            <div className="tw-p-4 tw-border-black tw-border-t-2">
                <h3>{program.programName}</h3>
                <p className="tw-py-2 tw-text-sm tw-font-bold-normal">{program.programDescription}</p>

                {hasSecondsAvailableForProgram ? (
                    <>
                        <DaysSelector program={program} />
                    </>
                ) : (
                    <p>{duration} segundos não disponível para o programa</p>
                )}

                {hasSecondsAvailableForProgram && hasDaysSelected && (
                    <>
                        <InsertionsSelector program={program} campaignWeeks={campaignWeeks} />
                        <hr />
                        <p className="tw-text-right tw-pt-4">
                            <strong className="tw-text-pink">Total de comerciais de {duration} segundos:</strong>{' '}
                            {comercialCount}
                        </p>
                        <p className="tw-text-right tw-pb-4">
                            <strong className="tw-text-pink">Total: </strong>
                            {currencyFormatter.format(Number(comercialValue))}
                        </p>
                    </>
                )}
            </div>

            <div className="tw-flex tw-place-content-center tw-p-4 tw-text-sm">
                <button className="button button-primary" onClick={handleConcludeClick}>
                    Concluir
                </button>
            </div>
        </Modal>
    );
};

export default EditProgramModal;
