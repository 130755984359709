/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';

const PercentIcon = () => {
    return (
        <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24.1614" cy="24.5634" r="24.1142" fill="#2CAA60" />
            <path
                d="M31.49 23.1C28.16 23.1 26.03 25.74 26.03 29.22C26.06 32.88 28.4 35.1 31.34 35.1C34.16 35.1 36.74 32.97 36.74 28.89C36.74 25.56 34.85 23.1 31.49 23.1ZM31.43 32.61C30.11 32.61 29.42 31.14 29.45 29.1C29.45 27.15 30.05 25.59 31.43 25.59C32.84 25.59 33.35 27.12 33.35 29.1C33.35 31.29 32.72 32.61 31.43 32.61ZM22.71 20.79C22.71 17.46 20.82 15 17.46 15C14.13 15 12 17.64 12 21.12C12 24.78 14.34 27 17.31 27C20.13 27 22.71 24.87 22.71 20.79ZM17.4 24.51C16.08 24.51 15.39 23.04 15.39 21C15.39 19.05 16.02 17.49 17.37 17.49C18.78 17.49 19.32 19.02 19.32 21C19.32 23.19 18.69 24.51 17.4 24.51ZM30.32 16.58L20.28 34.61C20.09 34.95 19.74 35.16 19.35 35.16C18.54 35.16 18.03 34.29 18.42 33.58L28.43 15.55C28.62 15.21 28.97 15 29.36 15H29.39C30.2 15 30.71 15.87 30.32 16.58Z"
                fill="white"
            />
        </svg>
    );
};

export default PercentIcon;
