/* eslint-disable unicorn/consistent-destructuring */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { currencyFormatter } from 'utils';

function PackageCard({ packageItem }) {
    const { packageImage, packageName, description, packageValue } = packageItem;

    const history = useHistory();

    const handleBuyPackage = useCallback(() => {
        history.push(`/comprar-pacote?id=${packageItem?.id}`);
    }, [packageItem, history]);

    return (
        <div className="tw-w-72 tw-flex tw-justify-items-stretch tw-flex-col md:tw-max-w-xs icon-card tw-rounded-lg tw-h-full tw-mr-4">
            <img src={packageImage} alt="package image" className="tw-w-full tw-h-40" />
            <div className="tw-w-full tw-flex tw-flex-col tw-justify-around tw-p-4">
                <h1 className="tw-text-gray-700 tw-text-lg tw-normal-case">{packageName}</h1>
                <h5 className="tw-text-gray-500 tw-text-base tw-normal-case tw-my-3">{description}</h5>
                <span className="tw-block tw-text-gray-500 tw-text-3xl tw-my-3">
                    {currencyFormatter.format(packageValue)}
                </span>
                <hr />
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-4">
                    <button
                        style={{ padding: 10 }}
                        className="button button-primary tw-text-xs tw-w-full md:tw-mt-0 tw-mt-2"
                        onClick={handleBuyPackage}
                    >
                        COMPRAR
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PackageCard;
