import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

const NoIndicatorComponent = {
    IndicatorSeparator: () => null,
};
const colourStyles = {
    control: styles => ({
        ...styles,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        border: 0,
        borderRadius: 10,
        padding: 0,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.07)',
        color: '#000',
    }),
};

const SelectInput = ({ name, label, control, options, error, className = '', ...rest }) => {
    return (
        <div className={`form-control ${className}`}>
            <label className="tw-font-semibold tw-mb-2" htmlFor={name}>
                {label}
            </label>
            <Controller
                render={({ onChange, onBlur, value, name, ref }) => (
                    <Select
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        options={options}
                        styles={colourStyles}
                        components={NoIndicatorComponent}
                        noOptionsMessage={() => 'Nenhuma opção'}
                        ref={ref}
                        {...rest}
                    />
                )}
                name={name}
                control={control}
            />
            {error && <p className="error-message">{error.message}</p>}
        </div>
    );
};

export default SelectInput;
