import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import { updateCampaign } from 'api/campaign';
import { currencyFormatter } from 'utils';
import { Creators as ActionsCreators } from 'store/modules/campaign';
import { getInsertionsCount, totalInsertionsForPrograms } from 'services/advertiser/campaign';
import ProgramsTableWithApprovedDates from 'components/ProgramsTableWithApprovedDates';
import CampaignInfo from 'organisms/CampaignInfo';
import StepsIconsInfo from './StepsIconsInfo';
import IconsBreadsCrumb from './IconsBreadsCrumb';
import ProgramInsertions from './ProgramInsertions';
import styleConfig from '../../../style_config.json';

const statusTypes = {
    pendingPayment: 'PENDING_PAYMENT',
    pendingApproveInsertions: 'PENDING_APPROVE_INSERTIONS',
    pendingCreative: 'PENDING_CREATIVE',
    pendingApproveCreative: 'PENDING_APPROVE_CREATIVE',
    approved: 'APPROVED',
    refused: 'REFUSED',
    finished: 'FINISHED',
};

const statuses = {
    APPROVED: {
        label: 'Ativa',
        color: 'tw-text-purple',
    },
    REFUSED: {
        label: 'Cancelada',
        color: 'tw-text-gray',
    },
    FINISHED: {
        label: 'Finalizada',
        color: 'tw-text-purple',
    },
};

const CampaignRow = ({ campaign }) => {
    const [showInsertionsDetails, setShowInsertionsDetails] = useState(false);

    const dispatch = useDispatch();

    const toggleShowInsertionsDetails = () => {
        setShowInsertionsDetails(previous => !previous);
    };

    const allPrograms = [
        ...(campaign?.mediaCentral?.selectedTVPrograms || []),
        ...(campaign?.mediaCentral?.selectedRadioPrograms || []),
    ];

    const getInsertionsTotalValue = program => {
        const insertionCount = getInsertionsCount(program);
        return program.insertionValue * insertionCount;
    };

    const handleFinishCampaign = () => {
        function onSuccess() {
            toast.success('CAMPANHA CONCLUÍDA COM SUCESSO!');
            dispatch(ActionsCreators.getCampaigns());
        }

        function onFailure() {
            toast.error('FALHA AO CONCLUIR CAMPANHA!');
        }

        updateCampaign(campaign.id, {
            ...campaign,
            status: 'FINISHED',
        })
            .then(onSuccess)
            .catch(onFailure);
    };

    const startDate = campaign.objectivesAndDuration?.startDate?.toDate() || campaign?.createdAt?.toDate();
    const endDate = campaign?.objectivesAndDuration?.endDate?.toDate() || '';

    const hasInstallments = campaign.installments > 1;
    const amount = hasInstallments ? campaign.campaignValue / campaign.installments : campaign.campaignValue;

    return (
        <>
            <tr
                className={`tw-bg-white tw-border ${
                    showInsertionsDetails ? '' : 'tw-shadow-tablerow'
                } tw-cursor-pointer`}
                onClick={toggleShowInsertionsDetails}
            >
                <td className="border tw-bg-white tw-rounded-l-xl tw-pl-4 tw-py-3 tw-font-bold">
                    <div className="tw-flex tw-flex-row tw-items-center tw-mr-3">
                        <span>{campaign?.objectivesAndDuration.name || campaign?.packageItem?.packageName}</span>
                        {!showInsertionsDetails && <IconsBreadsCrumb campaign={campaign} statusTypes={statusTypes} />}
                    </div>
                </td>
                <td className="border tw-bg-white tw-py-3">{campaign?.userCompanyName || '-'}</td>
                {campaign?.packageItem ? (
                    <td className="border tw-bg-white tw-py-3">{`a partir de ${moment(startDate).format(
                        'DD/MM/YYYY',
                    )}`}</td>
                ) : (
                    <td className="border tw-bg-white tw-py-3">
                        {`${moment(startDate).format('DD/MM/YYYY')} a ${moment(endDate).format('DD/MM/YYYY')}`}
                    </td>
                )}

                <td className="border tw-bg-white tw-py-3 tw-text-center">
                    {totalInsertionsForPrograms(allPrograms, campaign?.packageItem?.programs)}
                </td>
                <td className="border tw-bg-white tw-py-3">
                    {hasInstallments && `${campaign.installments}x `}
                    {currencyFormatter.format(amount)}
                </td>
                <td
                    className={`border tw-bg-white  tw-rounded-r-xl tw-py-3 tw-font-bold ${
                        statuses[campaign?.status]?.color
                    }`}
                >
                    {statuses[campaign?.status]?.label}
                </td>
            </tr>
            {showInsertionsDetails && (
                <>
                    <tr className="tw-bg-white tw-border tw-shadow-tablerow tw-p-3 tw-translate-x-2">
                        <td colSpan={5} className="tw-p-5 border tw-bg-white tw-rounded-xl">
                            <CampaignInfo campaign={campaign} />
                            {campaign?.packageItem ? (
                                <ProgramsTableWithApprovedDates campaign={campaign} />
                            ) : (
                                <table className="tw-w-full tw-mt-6">
                                    <thead>
                                        <tr>
                                            <th className="tw-text-purple tw-text-left" align="left">
                                                Programa
                                            </th>
                                            <th className="tw-text-purple tw-text-left">Dias de Veiculação</th>
                                            <th className="tw-text-purple tw-text-center">Inserções</th>
                                            <th className="tw-text-purple tw-text-center">Duração</th>
                                            <th className="tw-text-purple tw-text-left">Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allPrograms.map((program, index) => (
                                            <tr key={index}>
                                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                                                    <p>{program.programName}</p>
                                                    <p>(NDTV)</p>
                                                </td>
                                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                                                    <ProgramInsertions program={program} />
                                                </td>
                                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1 tw-text-center">
                                                    {getInsertionsCount(program)}
                                                </td>
                                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1 tw-text-center">
                                                    {campaign?.objectivesAndDuration?.duration}
                                                </td>
                                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                                                    {currencyFormatter.format(getInsertionsTotalValue(program))}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-mt-3">
                                <StepsIconsInfo campaign={campaign} statusTypes={statusTypes} />
                                {campaign?.status === statusTypes.approved && (
                                    <button
                                        className="tw-flex tw-items-center tw-border-none tw-rounded-md tw-bg-purple tw-text-white tw-font-bold tw-px-5 tw-py-0 tw-h-10 md:tw-ml-3"
                                        onClick={handleFinishCampaign}
                                    >
                                        <FaCheck size={18} className="tw-text-white" />
                                        <span className="tw-block tw-ml-3">Concluir Campanha</span>
                                    </button>
                                )}
                                {campaign?.status === statusTypes.finished && (
                                    <div className="tw-border tw-border-gray-light tw-bg-gray-100 tw-text-gray-900 tw-rounded-3xl tw-p-2">
                                        <span className="tw-text-green">Campanha Concluída. </span>
                                        <strong>Obrigado por anunciar com a {styleConfig['small_name']}.</strong>
                                    </div>
                                )}
                            </div>
                        </td>
                    </tr>
                </>
            )}
        </>
    );
};

export default CampaignRow;
