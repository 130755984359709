import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useUserId } from 'hooks/user';
import { useCampaignList } from 'hooks/campaign';
import { Creators as ActionsCreators } from 'store/modules/campaign';
import {
    APPROVED_CAMPAIGN_STATUS,
    FINISHED_CAMPAIGN_STATUS,
    PENDING_CAMPAIGN_STATUS,
} from 'services/advertiser/campaign';
import { getAllCampaigns } from 'api/campaign';
import CampaignRow from './CampaignRow';
import PendingCampaignRow from './PendingCampaignRow';

const tableStyle = { borderSpacing: '0 12px' };

const CampaignTable = ({ children, statusTitle = 'Tipo Pend.' }) => (
    <table className="tw-border-separate tw-w-full" style={tableStyle}>
        <thead>
            <tr>
                <th className="tw-text-purple tw-text-left" align="left">
                    Nome
                </th>
                <th className="tw-text-purple tw-pr-6 tw-text-left">Período de Veiculação</th>
                <th className="tw-text-purple tw-px-6 tw-text-center">Inserções</th>
                <th className="tw-text-purple tw-px-6 tw-text-left">Valor Total</th>
                <th className="tw-text-purple tw-text-left">{statusTitle}</th>
            </tr>
        </thead>
        <tbody>{children}</tbody>
    </table>
);

const CampaignSession = ({ title, status, children, statusHeaderTitle }) => {
    const campaigns = useCampaignList();
    const campaignsFilterByStatus = campaigns.filter(campaign => status.has(campaign.status));

    return (
        <div className="tw-w-full tw-flex tw-flex-col tw-mt-9">
            <h1 className="tw-text-pink tw-normal-case tw-font-bold">{title}</h1>
            <div className="tw-w-full tw-overflow-x-auto">
                {campaignsFilterByStatus.length > 0 ? (
                    <CampaignTable statusTitle={statusHeaderTitle}>
                        {campaignsFilterByStatus.map(campaign => children(campaign))}
                    </CampaignTable>
                ) : (
                    <p className="tw-py-2 tw-text-gray">Nenhuma até o momento.</p>
                )}
            </div>
        </div>
    );
};

const ListCampaign = () => {
    const userId = useUserId();
    const dispatch = useDispatch();

    useEffect(() => {
        let unsubscribe = null;
        if (userId) {
            unsubscribe = getAllCampaigns()
                .where('userId', '==', userId)
                .orderBy('createdAt', 'desc')
                .onSnapshot(documents => {
                    const list = [];
                    documents.forEach(document => {
                        list.push({ id: document.id, ...document.data() });
                    });
                    dispatch(ActionsCreators.getCampaignsByUserIdSuccess(list));
                });
        }
        return () => unsubscribe();
    }, [userId, dispatch]);

    return (
        <>
            <CampaignSession title="Pendentes" status={PENDING_CAMPAIGN_STATUS}>
                {campaign => <PendingCampaignRow key={campaign.id} campaign={campaign} />}
            </CampaignSession>
            <CampaignSession title="Ativas" status={APPROVED_CAMPAIGN_STATUS}>
                {campaign => <CampaignRow key={campaign.id} campaign={campaign} />}
            </CampaignSession>
            <CampaignSession title="Finalizadas" status={FINISHED_CAMPAIGN_STATUS}>
                {campaign => <CampaignRow key={campaign.id} campaign={campaign} />}
            </CampaignSession>
        </>
    );
};

export default ListCampaign;
