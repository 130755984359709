import { firestore, remoteConfig, storage } from 'base';
import moment from 'moment';
import { defaultConverter } from 'utils';

/**
 * Get all creatives
 * @returns {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>}
 */
export const getAllCreatives = () => firestore.collection('creatives');

/**
 * Get all user creative filtered by duration
 * @param {string} userId - Owner user ID
 * @param {string} [duration] - The duration of the creative
 * @returns {Promise<firebase.firestore.QuerySnapshot<T>>}
 */
export const getCreatives = (userId, duration) => {
    let query = getAllCreatives().where('userId', '==', userId.trim());
    if (duration) {
        query = query.where('durationTime', '==', String(duration));
    }
    return query.withConverter(defaultConverter).get();
};

/**
 * Get creative by ID
 * @param {string} creativeId - The creative ID
 * @returns {firebase.firestore.DocumentReference<T>}
 */
export const getCreativeById = creativeId => getAllCreatives().withConverter(defaultConverter).doc(creativeId);

export const updateCreative = async ({ creativeId, campaignIds }) =>
    getCreativeById(creativeId).update({ campaignIds });

/**
 * Add new creative with pending payment status
 * @param {Object} creative - Creative data to add
 * @returns {Promise<firebase.firestore.DocumentReference<*&{id: *}>>}
 */
export const addCreative = async creative => {
    const creativeResp = await getAllCreatives().withConverter(defaultConverter).add(creative);
    console.log(creativeResp);
    return creativeResp;
};

/**
 * Delete creative by ID
 * @param {string} creativeId - The creative ID
 * @returns {Promise<void>}
 */
export const deleteCreative = creativeId => getCreativeById(creativeId).delete();

/**
 * Upload logo file into storage
 * @param {File} imageFile - The image file
 * @param {string} userId - The user ID
 */
export function uploadLogo(imageFile, userId) {
    return storage.ref(`creatives/${userId}/logos/${moment().format('DD-MM-YYYY')}/${imageFile.name}`).put(imageFile);
}

/**
 * Upload commercial images into storage
 * @param {File} imageFile - The image file
 */
export function uploadCommercialImage(imageFile, userId) {
    return storage
        .ref(`creatives/${userId}/commercialImages/${moment().format('DD-MM-YYYY')}/${imageFile.name}`)
        .put(imageFile);
}

/**
 * Upload commercial images into storage
 * @param {File} imageFile - The image file
 */
export function uploadOfferImage(imageFile, userId) {
    return storage.ref(`creatives/${userId}/offers/${moment().format('DD-MM-YYYY')}/${imageFile.name}`).put(imageFile);
}

/**
 * Get videosPrime collection
 * @returns {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>}
 */
export function getVideos() {
    return firestore.collection('videosPrime');
}

/**
 * Get the price value for creative
 * @param {string} type - The creative type
 * @param {number} seconds - The seconds of the creative
 * @returns {Promise<number>}
 */
export function getPriceFor(type, seconds) {
    return remoteConfig.ensureInitialized().then(() => {
        const prices = remoteConfig.getValue('creative_prices').asString();
        const pricesJSON = JSON.parse(prices);
        return pricesJSON[type.toLocaleLowerCase()][`${seconds}s`];
    });
}
