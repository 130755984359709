import moment from 'moment';
import React from 'react';

const offerTypes = {
    inCash: 'Valor à vista',
    fromPrice: 'À partir de',
    installments: 'Parcelado',
    cashDiscount: 'Desconto à vista',
};
const regulatedProductTypes = {
    medicines: 'Medicamentos',
    alcool: 'Bebidas alcoólicas',
    others: 'Outro produto regulamentado',
};

function OfferSection({ creative }) {
    return (
        <>
            {creative.offers?.length > 0 && <h3 className="tw-text-gray-700 tw-mt-8">Detalhes da(s) oferta(s)</h3>}
            {creative.offers?.map((item, index) => (
                <div key={index} className="tw-w-full tw-flex-col tw-flex tw-shadow-lg tw-rounded-lg tw-mt-6 tw-p-8">
                    <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
                        <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">Nome do produto ou serviço:</h4>
                            <p>{item?.product}</p>
                        </div>
                        <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">Detalhes do produto ou serviço:</h4>
                            <p>{item?.productDescription}</p>
                        </div>
                        {item?.validity && (
                            <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                                <h4 className="tw-text-purple tw-font-bold">Validade da oferta:</h4>
                                <p>{moment.utc(item?.validity).format('DD/MM/YYYY')}</p>
                            </div>
                        )}
                    </div>
                    <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-6">
                        <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">Tipo de oferta:</h4>
                            <p>{offerTypes[item?.offerType]}</p>
                        </div>
                        <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">Valor em destaque:</h4>
                            <p>{item?.emphasis}</p>
                        </div>

                        <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">
                                Você está anunciando medicamento, bebidas alcoólicas ou algum produto regulamentado?
                            </h4>
                            <p>{item?.regulatedProduct === 'YES' ? 'Sim' : 'Não'}</p>
                        </div>
                    </div>
                    {item.regulatedProduct === 'YES' && (
                        <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-6">
                            <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                                <h4 className="tw-text-purple tw-font-bold">Qual?</h4>
                                <p>{regulatedProductTypes[item?.regulatedProductType]}</p>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </>
    );
}

export default OfferSection;
