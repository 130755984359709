import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DevTool } from '@hookform/devtools';
import { useCurrentCampaign } from 'hooks/campaign';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import Input from 'molecules/InputField';
import Select from 'components/shared/molecules/Select';
import CitySelector from './CitySelector';
import DurationSelector from './DurationSelector';
import ObjectiveSelector from './ObjectiveSelector';
import useObjectivesAndDurationForm from './useObjectivesAndDurationForm';

const weekOptions = [
    { label: '1 semana', value: 1 },
    { label: '2 semanas', value: 2 },
    { label: '3 semanas', value: 3 },
    { label: '4 semanas', value: 4 },
];

const ObjectivesAndDurationStep = () => {
    const { campaignEditable, objectivesAndDuration } = useCurrentCampaign();

    const dispatch = useDispatch();

    const { register, control, formState, onSubmit } = useObjectivesAndDurationForm();
    const { errors, isSubmitting, isValidating } = formState;
    const isLoading = isSubmitting || isValidating;

    const handlePreviousClick = useCallback(() => dispatch(CampaignCreators.previousStep()), [dispatch]);

    return (
        <>
            <h3>Informe os detalhes da sua campanha</h3>
            <form onSubmit={onSubmit} className="tw-w-full tw-flex tw-flex-col tw-mt-6 tw-pb-4 tw-pl-2">
                <div className="tw-w-full tw-flex md:tw-flex-row tw-flex-col">
                    <div className="tw-w-full tw-flex-col md:tw-max-w-md">
                        <Input
                            name="name"
                            type="text"
                            ref={register}
                            label="Dê um nome para sua campanha*"
                            error={errors.name}
                        />
                        <Input
                            name="startDate"
                            type="date"
                            ref={register({ valueAsDate: true })}
                            label="Data de início da campanha*"
                            error={errors.startDate}
                            className="tw-mt-8"
                        />
                    </div>
                    <div className="tw-w-full tw-flex-col md:tw-max-w-md md:tw-ml-8 tw-mt-8 md:tw-mt-0">
                        <CitySelector
                            name="city"
                            control={control}
                            error={errors.city}
                            defaultValue={objectivesAndDuration?.city || ''}
                        />
                        <Select
                            name="campaignWeeks"
                            defaultValue={objectivesAndDuration?.campaignWeeks}
                            ref={register}
                            label="Duração da campanha*"
                            error={errors.campaignWeeks}
                            className="tw-mt-8 tw-w-full"
                        >
                            <option />
                            {weekOptions.map(({ value, label }) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="tw-w-full tw-mt-8 tw-mb-4">
                    <DurationSelector name="duration" error={errors.duration} ref={register} />
                </div>

                <h3 className="tw-my-4">Objetivo da Campanha</h3>
                <p className="tw-mb-4 tw-font-poppins tw-font-semibold">
                    Como você deseja priorizar as visualizações da sua campanha?
                </p>
                <ObjectiveSelector name="objective" error={errors.objective} ref={register} />
                <div className="tw-mt-10 tw-text-center">
                    <button onClick={handlePreviousClick} className="button button-primary-outlined tw-mr-4">
                        Voltar
                    </button>

                    <button type="submit" className="button button-primary" disabled={!campaignEditable || isLoading}>
                        Próximo
                    </button>
                </div>
                {__DEVELOPMENT__ && <DevTool control={control} />}
            </form>
        </>
    );
};

export default ObjectivesAndDurationStep;
