/**
 * @see https://github.com/arfurlaneto/yup-locale-pt
 */

const { toString } = Object.prototype;
const errorToString = Error.prototype.toString;
const regExpToString = RegExp.prototype.toString;
const symbolToString = typeof Symbol !== 'undefined' ? Symbol.prototype.toString : () => '';

const SYMBOL_REGEXP = /^Symbol\((.*)\)(.*)$/;

function printNumber(value) {
    if (value !== +value) return 'NaN';
    const isNegativeZero = value === 0 && 1 / value < 0;
    return isNegativeZero ? '-0' : `${value}`;
}

function printSimpleValue(value, quoteStrings = false) {
    if (value === undefined || value === true || value === false) return `${value}`;

    const typeOf = typeof value;
    if (typeOf === 'number') return printNumber(value);
    if (typeOf === 'string') return quoteStrings ? `"${value}"` : value;
    if (typeOf === 'function') {
        return `[Function ${value.name || 'anonymous'}]`;
    }
    if (typeOf === 'symbol') {
        return symbolToString.call(value).replace(SYMBOL_REGEXP, 'Symbol($1)');
    }

    const tag = toString.call(value).slice(8, -1);
    if (tag === 'Date') {
        return Number.isNaN(value.getTime()) ? `${value}` : value.toISOString(value);
    }
    if (tag === 'Error' || value instanceof Error) {
        return `[${errorToString.call(value)}]`;
    }
    if (tag === 'RegExp') return regExpToString.call(value);
}

function printValue(value, quoteStrings) {
    const result = printSimpleValue(value, quoteStrings);
    if (result !== null) return result;

    return JSON.stringify(
        value,
        function (key, value) {
            const result = printSimpleValue(this[key], quoteStrings);
            if (result !== null) return result;
            return value;
        },
        2,
    );
}

export const mixed = {
    default: '${path} é inválido',
    required: '${path} é obrigatório',
    oneOf: '${path} deve ter um dos seguintes valores: ${values}',
    notOneOf: '${path} não deve ter nenhum dos seguintes valores: ${values}',
    notType: ({ path, type, value, originalValue }) => {
        const isCast = originalValue !== undefined && originalValue !== value;
        let message = `${
            `${path} deve ser do tipo \`${type}\`, ` + `mas o valor final é: \`${printValue(value, true)}\``
        }${isCast ? ` (cast do valor \`${printValue(originalValue, true)}\`)` : ''}`;

        if (value === null) {
            message += '\nse a intenção era usar "null" como um valor em branco marque o esquema como `.nullable()`';
        }

        return message;
    },
    defined: '${path} não deve ser indefinido',
};

export const string = {
    length: '${path} deve ter exatamente ${length} caracteres',
    min: '${path} deve ter pelo menos ${min} caracteres',
    max: '${path} deve ter no máximo ${max} caracteres',
    matches: '${path} deve corresponder ao padrão: "${regex}"',
    email: '${path} deve ser um e-mail válido',
    url: '${path} deve ser uma URL válida',
    trim: '${path} não deve conter espaços adicionais no início nem no fim',
    lowercase: '${path} deve estar em letras minúsculas',
    uppercase: '${path} deve estar em letras maiúsculas',
};

export const number = {
    min: '${path} deve ser maior ou igual a ${min}',
    max: '${path} deve menor ou igual a ${max}',
    lessThan: '${path} deve ser menor que ${less}',
    moreThan: '${path} deve ser maior que ${more}',
    notEqual: '${path} não deve ser igual a ${notEqual}',
    positive: '${path} deve ser um número positivo',
    negative: '${path} deve ser um número negativo',
    integer: '${path} deve ser um número inteiro',
};

export const date = {
    min: '${path} deve ser posterior a ${min}',
    max: '${path} deve ser anterior a ${max}',
};

export const boolean = {};

export const object = {
    noUnknown: '${path} tem chaves desconhecidas: ${unknown}',
};

export const array = {
    min: '${path} deve ter pelo menos ${min} itens',
    max: '${path} deve ter no máximo ${max} itens',
};

export default {
    mixed,
    string,
    number,
    date,
    object,
    array,
    boolean,
};
