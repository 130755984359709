/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, { useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import moment from 'moment';

import Select from 'molecules/Select';
import InputField from 'components/shared/molecules/InputField';
import { useUserCompany } from 'hooks/company';
import InputFieldCurrencyFieldV2 from 'components/shared/molecules/InputField/InputCurrencyFieldV2';
import { useRegions } from 'hooks/regions';
import { useUserId } from 'hooks/user';
import { Creators as ActionCreators } from 'store/modules/regions';
import { Creators as PackageActionCreators } from 'store/modules/packages';
import { getCompanyByOwnerId } from 'store/modules/company';
import AddedPrograms from './AddedPrograms';
import AddProgramModal from './AddProgramModal';
import { usePackage } from 'hooks/packages';
import { uploadPackageImage } from 'api/packages';
import { firebase } from 'base';
import Loading from 'components/Loading';
import UploadIcon from 'components/icons/UploadIcon';
import { FaTimes } from 'react-icons/fa';
import DurationSelector from './DurationSelector';

const mediaTypes = [{ label: 'TV', value: 'TV' }];

const schema = yup.object().shape({
    packageName: yup.string().required('Informe o nome do pacote'),
    availablePackages: yup
        .number()
        .min(0, 'Informe a quantidade de pacotes disponíveis.')
        .typeError('Informe a quantidade pacotes disponíveis'),
    packageValue: yup.number().min(1, 'O valor do pacote não pode ser zero!').typeError('Informe o valor do pacote'),
    packageRegionId: yup.string().required('Informe a região do pacote'),
    packageType: yup.string().oneOf(['TV', 'RADIO'], 'Informe o tipo do pacote'),
    startDate: yup.date().required('Informe a data inicial').typeError('Informe uma data inicial válida'),
    endDate: yup.date().required('Informe a data final').typeError('Informa uma data final válida'),
    duration: yup
        .string()
        .required('Informe a duração do anúncio do pacote')
        .typeError('Informe a duração do anúncio do pacote'),
});

function Package() {
    const [loading, setLoading] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [showAddProgramModal, setShowAddProgramModal] = useState(false);
    const [tasksMap, setTasksMap] = useState(new Map());
    const [packageImage, setPackageImage] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();
    const company = useUserCompany();
    const regions = useRegions();
    const userId = useUserId();
    const packageData = usePackage();

    const { control, register, errors, handleSubmit, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            programType: '',
            programCity: '',
            programState: '',
            cities: [],
        },
    });

    const onSubmit = values => {
        if (programs.length === 0) {
            toast.warn('Informe os programas do pacote!');
            return;
        }

        setLoading(true);

        function onSuccess() {
            setLoading(false);
            dispatch(PackageActionCreators.setPackage(null));
            toast.success('Pacote salvo com sucesso!');
            history.push('/pacotes');
        }

        function onFailure() {
            setLoading(false);
            toast.error('Falha ao salvar o pacote, tente novamente!');
        }

        if (packageData?.id) {
            dispatch(
                PackageActionCreators.updatePackage(
                    { id: packageData?.id, ...values, programs, packageImage },
                    { onSuccess, onFailure },
                ),
            );
        } else {
            dispatch(
                PackageActionCreators.savePackage(
                    company.id,
                    { ...values, programs, packageImage },
                    { onSuccess, onFailure },
                ),
            );
        }
    };

    const toggleAddProgramModal = useCallback(() => setShowAddProgramModal(current => !current), []);

    const handleRemoveProgram = useCallback(
        programId => setPrograms(current => current.filter(program => program.programId !== programId)),
        [],
    );

    const uploadFile = useCallback(
        file => {
            setLoading(true);
            const fileHash = v4();
            const onUploadNext = snapshot => {
                const percentCompleted = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                const fileTaskInfo = tasksMap.get(fileHash);
                setTasksMap(
                    new Map(
                        tasksMap.set(fileHash, {
                            ...fileTaskInfo,
                            percentCompleted,
                        }),
                    ),
                );
            };
            const onUploadError = error => {
                setLoading(false);
                if (error) {
                    toast.error('Falha ao adicionar imagem!');
                }
                const fileTaskInfo = tasksMap.get(fileHash);
                setTasksMap(
                    new Map(
                        tasksMap.set(fileHash, {
                            ...fileTaskInfo,
                            error,
                        }),
                    ),
                );
            };
            const onUploadCompleted = () => {
                const fileTaskInfo = tasksMap.get(fileHash);
                fileTaskInfo.uploadTask
                    .then(imageReference => imageReference.ref.getDownloadURL())
                    .then(downloadUrl => {
                        setTasksMap(
                            new Map(
                                tasksMap.set(fileHash, {
                                    ...fileTaskInfo,
                                    downloadUrl,
                                }),
                            ),
                        );
                        if (downloadUrl) {
                            setPackageImage(downloadUrl);
                        }
                        setLoading(false);
                    });
            };

            const uploadTask = uploadPackageImage(file);
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
                next: onUploadNext,
                error: onUploadError,
                complete: onUploadCompleted,
            });
            tasksMap.set(fileHash, {
                uploadTask: uploadTask,
            });
        },
        [tasksMap],
    );

    const handleChange = async event => {
        for (const file of event.target.files) {
            uploadFile(file);
        }
    };

    useEffect(() => {
        if (company) {
            console.log('company', company);
            dispatch(ActionCreators.getRegions(company.id));
        }
    }, [dispatch, company]);

    useEffect(() => {
        if (userId) {
            dispatch(getCompanyByOwnerId(userId));
        }
    }, [userId, dispatch]);

    useEffect(() => {
        if (packageData) {
            setPrograms(packageData.programs);
            setValue('startDate', moment(packageData?.startDate.toDate()).format('YYYY-MM-DD'));
            setValue('endDate', moment(packageData?.endDate.toDate()).format('YYYY-MM-DD'));
        }

        if (packageData?.packageImage) {
            setPackageImage(packageData.packageImage);
        }
    }, [packageData, setValue]);

    console.log('packageData', packageData);

    return (
        <div className="tw-w-full">
            {loading && <Loading />}
            <AddProgramModal
                showAddProgramModal={showAddProgramModal}
                toggleAddProgramModal={toggleAddProgramModal}
                setPrograms={setPrograms}
                selectedPrograms={programs}
            />
            <h2>Cadastro de pacotes</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="tw-flex tw-flex-col md:tw-flex-row">
                    <InputField
                        name="packageName"
                        ref={register}
                        defaultValue={packageData?.packageName}
                        label="Nome do Pacote"
                        className="tw-mt-6 tw-w-full md:tw-w-1/3 md:tw-mx-3"
                        inputClassName="tw-w-full tw-border-gray-500"
                        error={errors.packageName}
                    />
                    <InputField
                        name="availablePackages"
                        type="number"
                        ref={register}
                        defaultValue={packageData?.availablePackages}
                        min="1"
                        label="Pacotes Disponíveis"
                        className="tw-mt-6 tw-w-full md:tw-w-1/3 md:tw-mx-3"
                        inputClassName="tw-w-full tw-border-gray-500"
                        error={errors.availablePackages}
                    />
                    <InputFieldCurrencyFieldV2
                        name="packageValue"
                        control={control}
                        defaultValue={packageData?.packageValue || ''}
                        label="Valor do Pacote"
                        className="tw-mt-6 tw-w-full md:tw-w-1/3 md:tw-mx-3"
                        inputClassName="tw-w-full tw-border-gray-500"
                        error={errors.packageValue}
                    />
                </div>
                <div className="tw-flex tw-flex-col md:tw-p-3 tw-mt-4">
                    <DurationSelector name="duration" defaultValue={packageData?.duration} ref={register} />
                    <span className="tw-text-red">{errors?.duration?.message}</span>
                </div>
                <div className="tw-flex tw-flex-col md:tw-p-3 tw-mt-4">
                    <span className="tw-block tw-mb-3">Informações do pacote</span>
                    <textarea
                        name="description"
                        ref={register}
                        defaultValue={packageData?.description || ''}
                        className="tw-max-w-md tw-p-3 tw-w-full tw-bg-transparent tw-border-b-2 tw-border-t-0 tw-border-r-0 tw-border-l-0"
                    />
                    <span className="tw-text-gray-400 tw-text-sm">Digite aqui as principais informações do pacote</span>
                </div>
                <div className="tw-flex tw-flex-col md:tw-flex-row">
                    <InputField
                        name="startDate"
                        type="date"
                        ref={register}
                        defaultValue={packageData?.startDate}
                        min="1"
                        label="Data Incial de Venda"
                        className="tw-mt-4 tw-w-full md:tw-w-1/2 md:tw-mx-3"
                        inputClassName="tw-w-full tw-border-gray-500"
                        error={errors.startDate}
                    />
                    <InputField
                        name="endDate"
                        type="date"
                        ref={register}
                        defaultValue={packageData?.endDate}
                        label="Data Final de Venda"
                        className="tw-mt-4 tw-w-full md:tw-w-1/2 md:tw-mx-3"
                        inputClassName="tw-w-full tw-border-gray-500"
                        error={errors.endDate}
                    />
                </div>
                <div className="tw-flex tw-flex-col tw-mt-6">
                    <label htmlFor="logo" className="tw-cursor-pointer">
                        <span className="tw-block tw-mb-3 tw-font-semibold">Adicione uma imagem para o pacote</span>
                        <input
                            id="logo"
                            type="file"
                            multiple
                            accept="image/x-png,image/gif,image/jpeg"
                            className="tw-hidden"
                            onChange={event => handleChange(event)}
                        />
                        <div className="tw-flex tw-max-w-xs tw-items-center tw-justify-start tw-rounded-xl tw-bg-gray-200">
                            <UploadIcon />
                            <span className="tw-block tw-ml-3 tw-text-gray-400 tw-text-sm">Selecione seu arquivo</span>
                        </div>
                    </label>
                    {packageImage && (
                        <div className="tw-relative tw-mt-6" style={{ maxWidth: 234 }}>
                            <img
                                src={packageImage}
                                alt="image"
                                width="217"
                                height="152"
                                className="tw-mr-3 tw-mb-3 tw-rounded-md tw-cursor-pointer tw-border hover:tw-border-pink"
                            />
                            <button
                                className="tw-border-none tw-font-bold tw-bg-transparent tw-cursor-pointer tw-text-red tw-ml-5 tw-absolute tw-top-3 tw-right-7"
                                type="button"
                                onClick={() => setPackageImage(null)}
                            >
                                <FaTimes size={18} className="tw-text-pink" />
                            </button>
                        </div>
                    )}
                </div>
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-mt-6">
                    <Select
                        name="packageType"
                        label="Selecione o tipo do pacote"
                        className="tw-w-full md:tw-w-1/2 md:tw-mx-3"
                        ref={register}
                        error={errors.packageType}
                        defaultValue={packageData?.packageType}
                    >
                        {mediaTypes.map(mediaType => (
                            <option key={mediaType.value} value={mediaType.value}>
                                {mediaType.label}
                            </option>
                        ))}
                    </Select>
                    <Select
                        name="packageRegionId"
                        label="Região de disponibilidade"
                        className="tw-w-full md:tw-w-1/2 md:tw-mx-3"
                        ref={register}
                        error={errors.packageRegionId}
                        defaultValue={packageData?.packageRegionId}
                    >
                        {regions?.map(element => (
                            <option key={element.id} value={element.id}>
                                {element.regionName}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="tw-w-full tw-flex tw-flex-col">
                    <h3 className="tw-mt-6">Programas</h3>
                    <AddedPrograms
                        programs={programs}
                        toggleAddProgramModal={toggleAddProgramModal}
                        handleRemoveProgram={handleRemoveProgram}
                    />
                </div>
                <div className="tw-mt-10 tw-text-center tw-mb-4 md:tw-mt-24">
                    <button
                        className="button button-primary-outlined tw-mr-4 tw-w-full md:tw-w-1/6"
                        onClick={history.goBack}
                        type="button"
                    >
                        Voltar
                    </button>
                    <button
                        className="button button-primary tw-border-2 tw-border-pink md:tw-mt-0 tw-mt-3 tw-w-full md:tw-w-1/6"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Salvando...' : 'Salvar'}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Package;
