/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { useCurrentCreative } from 'hooks/creative';
import { Creators as ActionsCreators } from 'store/modules/creative';

const offerTypes = {
    inCash: 'Valor à vista',
    fromPrice: 'À partir de',
    installments: 'Parcelado',
    cashDiscount: 'Desconto à vista',
};

const regulatedProductTypes = {
    medicines: 'Medicamentos',
    alcool: 'Bebidas alcoólicas',
    others: 'Outro produto regulamentado',
};

function Summary() {
    const dispatch = useDispatch();
    const creative = useCurrentCreative();

    const handleBack = () => dispatch(ActionsCreators.goPreviousStep());

    const handleNext = () => dispatch(ActionsCreators.goNextStep());

    return (
        <div className="tw-w-full">
            <h2 className="tw-text-pink tw-normal-case tw-font-bold">Resumo do comercial</h2>
            <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-8">
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Nome do seu comercial:</h4>
                    <p>{creative?.commercialName}</p>
                </div>
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Nome do seu negócio:</h4>
                    <p>{creative?.businessName}</p>
                </div>
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Slogan:</h4>
                    <p>{creative?.slogan}</p>
                </div>
            </div>
            <h3 className="tw-text-gray-700 tw-mt-8">Detalhes da(s) oferta(s)</h3>
            {creative?.offers?.map((item, index) => (
                <div key={index} className="tw-w-full tw-flex-col tw-flex tw-shadow-lg tw-rounded-lg tw-mt-6 tw-p-8">
                    <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
                        <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">Nome do produto ou serviço:</h4>
                            <p>{item?.product}</p>
                        </div>
                        <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">Detalhes do produto ou serviço:</h4>
                            <p>{item?.productDescription}</p>
                        </div>
                        {item?.validity && (
                            <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                                <h4 className="tw-text-purple tw-font-bold">Validade da oferta:</h4>
                                <p>{moment.utc(item?.validity).format('DD/MM/YYYY')}</p>
                            </div>
                        )}
                    </div>
                    <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-6">
                        <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">Tipo de oferta:</h4>
                            <p>{offerTypes[item?.offerType]}</p>
                        </div>
                        <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">Valor em destaque:</h4>
                            <p>{item?.emphasis}</p>
                        </div>

                        <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                            <h4 className="tw-text-purple tw-font-bold">
                                Você está anunciando medicamento, bebidas alcoólicas ou algum produto regulamentado?
                            </h4>
                            <p>{item?.regulatedProduct === 'YES' ? 'Sim' : 'Não'}</p>
                        </div>
                    </div>
                    {item.regulatedProduct === 'YES' && (
                        <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-6">
                            <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                                <h4 className="tw-text-purple tw-font-bold">Qual?</h4>
                                <p>{regulatedProductTypes[item?.regulatedProductType]}</p>
                            </div>
                        </div>
                    )}
                </div>
            ))}
            <h3 className="tw-text-gray-700 tw-mt-8">Informações adicionais</h3>
            <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-8">
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Endereço</h4>
                    <p>{creative?.addess}</p>
                </div>
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Bairro</h4>
                    <p>{creative?.neighborhood}</p>
                </div>
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Cidade</h4>
                    <p>{creative?.city}</p>
                </div>
            </div>
            <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-8">
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Estado</h4>
                    <p>{creative?.state}</p>
                </div>
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Telefone</h4>
                    <p>{creative?.phone}</p>
                </div>
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Whatsapp</h4>
                    <p>{creative?.whatsapp}</p>
                </div>
            </div>
            {creative?.siteOrEmail && (
                <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-6">
                    <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                        <h4 className="tw-text-purple tw-font-bold">Site ou E-mail</h4>
                        <p>{creative?.siteOrEmail}</p>
                    </div>
                </div>
            )}
            <h3 className="tw-text-gray-700 tw-mt-8">Logo</h3>
            {creative?.logo && (
                <div className="tw-mt-4">
                    <img
                        src={creative.logo}
                        alt="image"
                        width="217"
                        height="152"
                        className="tw-mr-3 tw-mb-3 tw-rounded-md"
                    />
                </div>
            )}
            <h3 className="tw-text-gray-700 tw-mt-8">Imagens anexadas</h3>
            {creative?.commercialImages.length > 0 && (
                <div className="tw-flex tw-items-center tw-mt-4 tw-flex-wrap">
                    {creative?.commercialImages.map(item => (
                        <div key={item} className="tw-relative">
                            <img
                                src={item}
                                alt="image"
                                width="217"
                                height="152"
                                className="tw-mr-3 tw-mb-3 tw-rounded-md"
                            />
                        </div>
                    ))}
                </div>
            )}
            <div className="tw-mt-10 tw-text-center tw-mb-4 md:tw-mt-24">
                <button className="button button-primary-outlined tw-mr-4" onClick={handleBack} type="button">
                    Voltar
                </button>
                <button className="button button-primary" onClick={handleNext}>
                    Prosseguir
                </button>
            </div>
        </div>
    );
}

export default Summary;
