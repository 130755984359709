/* eslint-disable no-unused-vars */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable unicorn/no-useless-undefined */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaPen, FaCheckCircle } from 'react-icons/fa';

import { useUserId } from 'hooks/user';
import { useUserCompany } from 'hooks/company';
import { useDiscountsRange } from 'hooks/discounts';
import { getCompanyByOwnerId } from 'store/modules/company';
import { Creators as ActionsCreators } from 'store/modules/discounts';

import SaveDiscountModal from './SaveDiscountModal';

const schema = yup.object().shape({
    discount: yup.number().min(0).max(100).required(),
});

const defaultDiscounts = [
    {
        discount: 10,
        value: 1500,
        label: 'de R$ 1500,00 a R$ 3499,00',
    },
    {
        discount: 15,
        label: 'de R$ 500,00 a R$ 1499,00',
        value: 500,
    },
    {
        discount: 20,
        label: 'menos de R$ 500,00',
        value: 0,
    },
    {
        discount: 5,
        label: 'de R$ 3500,00 a R$ 4999,00',
        value: 3500,
    },
];

function ValueRangeDiscount() {
    const [editingDiscount, setEditingDiscount] = useState(undefined);
    const [showDiscountModal, setShowDiscountModal] = useState(false);

    const dispatch = useDispatch();

    const userId = useUserId();
    const discounts = useDiscountsRange();
    const company = useUserCompany();

    const { register, errors, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });

    const toggleDiscountModal = useCallback(() => setShowDiscountModal(previous => !previous), []);

    const onSubmit = useCallback(
        values => {
            const discountToSave = { ...editingDiscount, ...values };

            let discountsToSave = (discounts.length > 0 ? discounts : defaultDiscounts).filter(
                item => item.label !== discountToSave.label,
            );

            dispatch(ActionsCreators.saveDiscountsRange(company.id, [...discountsToSave, discountToSave]));

            setEditingDiscount(undefined);
            toggleDiscountModal();
        },
        [toggleDiscountModal, discounts, dispatch, company, editingDiscount],
    );

    const handleEditDiscount = useCallback(
        ({ value, discount, label }) => {
            setEditingDiscount({
                value,
                discount,
                label,
            });
            toggleDiscountModal();
        },
        [toggleDiscountModal],
    );

    const getDiscounts = () => (discounts.length > 0 ? [...discounts] : [...defaultDiscounts]);

    const handleSaveDiscountsFirstTime = useCallback(() => {
        dispatch(ActionsCreators.saveDiscountsRange(company.id, defaultDiscounts));
    }, [dispatch, company]);

    useEffect(() => {
        if (userId) {
            dispatch(getCompanyByOwnerId(userId));
        }
    }, [userId, dispatch]);

    useEffect(() => {
        if (company?.id) {
            dispatch(ActionsCreators.fetchDiscountsRange(company.id));
        }
    }, [dispatch, company]);

    return (
        <div className="tw-w-full tw-overflow-x-auto tw-mb-9">
            {discounts.length === 0 && (
                <button
                    className="tw-flex tw-align-center tw-bg-green tw-rounded-md tw-p-2"
                    onClick={handleSaveDiscountsFirstTime}
                >
                    <FaCheckCircle color="#fff" size={22} />
                    <span className="tw-block tw-ml-2">Confirmar configuração de descontos</span>
                </button>
            )}
            <SaveDiscountModal
                showDiscountModal={showDiscountModal}
                toggleDiscountModal={toggleDiscountModal}
                editingDiscount={editingDiscount}
                onSubmit={onSubmit}
            />
            <table className="tw-w-full tw-mt-6">
                <thead>
                    <tr>
                        <th className="tw-text-purple tw-text-left" align="left">
                            Faixa de desconto
                        </th>
                        <th className="tw-text-purple" align="right">
                            Desconto
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {getDiscounts()
                        ?.sort((a, b) => (a.value > b.value ? 1 : -1))
                        ?.map(({ value, discount, label }, index) => (
                            <tr key={index}>
                                <td className="tw-border-b-2 tw-border-gray-light tw-py-2">
                                    <p>{label}</p>
                                </td>
                                <td
                                    className="tw-border-b-2 tw-border-gray-light tw-py-2"
                                    align="right"
                                >{`${discount}%`}</td>
                                <td className="tw-border-b-2 tw-border-gray-light tw-py-2">
                                    <div className="tw-flex tw-flex-row tw-items-center tw-justify-end">
                                        <FaPen
                                            size={18}
                                            color="#087A99"
                                            className="tw-cursor-pointer"
                                            onClick={() =>
                                                handleEditDiscount({
                                                    value,
                                                    discount,
                                                    label,
                                                })
                                            }
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}

export default ValueRangeDiscount;
