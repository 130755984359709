import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';
import styleConfig from '../../../style_config.json';

const LogInPage = () => (
    <div className="gradient-background">
        <div className="tw-w-screen tw-flex tw-align-middle tw-items-center tw-place-content-center tw-flex-col">
            <div className="tw-w-11/12 md:tw-w-2/4 lg:tw-w-2/5 2xl:tw-w-1/4 tw-mb-6">
                <div className="tw-flex tw-justify-center tw-place-content-center tw-items-center tw-h-60">
                    <img
                        src={styleConfig['logo-vertical.svg']}
                        alt="Logo vertical"
                        className="tw-w-2/6 tw-h-40 tw-max-w-max tw-fill-current tw-text-white"
                    />
                </div>
                <div className="page-card">
                    <h2 className="tw-text-center">Painel Do Anunciante</h2>
                    <p className="tw-text-gray tw-text-center tw-mt-1 tw-mb-12">
                        Realize o log in para entrar na sua conta.
                    </p>

                    <LoginForm />

                    <p className="tw-text-center tw-mt-10">
                        Não possui conta? <Link to="/cadastro">Cadastre-se</Link>
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default LogInPage;
