import React from 'react';
import { useHistory } from 'react-router-dom';
import PlusIcon from '../icons/PlusIcon';

const NewCreativeButton = ({ campaign }) => {
    const history = useHistory();

    const handleNewCreative = event => {
        event.preventDefault();
        history.push(`/central-criativa/criar?campaignId=${campaign.id}`);
    };

    return (
        <button
            type="button"
            className="tw-w-full tw-bg-gray-100 tw-flex tw-justify-center tw-flex-col tw-rounded-md tw-h-24 tw-px-6 tw-cursor-pointer tw-mt-5"
            onClick={handleNewCreative}
        >
            <div className="tw-w-full tw-flex tw-justify-center tw-mb-1">
                <PlusIcon color="purple" />
            </div>
            <p className="tw-text-gray-700 tw-text-center tw-text-sm">
                Iniciar a produção de um <strong>Novo Criativo</strong>
            </p>
        </button>
    );
};

export default NewCreativeButton;
