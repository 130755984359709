import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useCurrentCampaign } from 'hooks/campaign';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import InputCurrencyField from 'molecules/InputCurrencyField';
import scrollTop from 'utils/scrollTop';
import BudgetSelector from './BudgetSelector';
import useBudgetForm from './useBudgetForm';

const BudgetStep = () => {
    const currentCampaign = useCurrentCampaign();
    const editable = currentCampaign.campaignEditable;

    const dispatch = useDispatch();

    const methods = useBudgetForm();
    const { control, onSubmit, watch, formState, register } = methods;
    const { isSubmitting, isValidating, errors } = formState;
    const isLoading = isSubmitting || isValidating;

    const handlePreviousClick = event => {
        event.preventDefault;
        dispatch(CampaignCreators.previousStep());
    };

    const budgetType = watch('budgetType');

    useEffect(() => {
        dispatch(
            CampaignCreators.addMediaCentral({
                selectedTVPrograms: [],
                selectedRadioPrograms: [],
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            scrollTop();
        }
    }, [errors]);

    return (
        <FormProvider {...methods}>
            <h3>Escolha o Modo de Campanha</h3>
            <form onSubmit={onSubmit} className="tw-w-full sm:tw-w-2/3 md:tw-w-1/2">
                {errors.budgetType && <p className="error-message">{errors.budgetType.message}</p>}

                <BudgetSelector />

                {budgetType === 'automatic' && (
                    <InputCurrencyField
                        label="Valor desejado do investimento R$*"
                        className="input-clean"
                        name="amount"
                        error={errors.amount}
                        control={control}
                        ref={register}
                    />
                )}

                <div className="tw-mt-10 tw-text-center">
                    <button
                        type="button"
                        onClick={handlePreviousClick}
                        className="button button-primary-outlined tw-mr-4"
                    >
                        Voltar
                    </button>

                    <button type="submit" className="button button-primary" disabled={!editable || isLoading}>
                        Próximo
                    </button>
                </div>

                {__DEVELOPMENT__ && <DevTool control={control} />}
            </form>
        </FormProvider>
    );
};

export default BudgetStep;
