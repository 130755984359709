/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import React from 'react';
import yup from 'validator';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from 'molecules/InputField';
import Select from 'molecules/Select';

const schema = yup.object().shape({
    name: yup.string().defined().required().label('Nome'),
    email: yup.string().email().defined().required().label('E-mail'),
    gender: yup.string().oneOf(['male', 'female', 'other']).defined().label('Gênero'),
    phone: yup.string().defined().required().label('Telefone'),
    hasAgency: yup.bool().defined().oneOf([true, false]).label('Possui agência?'),
    receiveEmails: yup.bool().defined().label('Receber informações'),
});

const FirstStep = ({ setStep, setRegisterData, registerData }) => {
    const { handleSubmit, register, errors, control } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const history = useHistory();

    const onSubmit = React.useCallback(
        values => {
            setRegisterData(previous => ({
                ...previous,
                ...values,
            }));
            setStep(previous => previous + 1);
        },
        [setRegisterData, setStep],
    );

    const handleBack = () => {
        history.push('/');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="tw-flex tw-w-full tw-flex-col tw-justify-between md:tw-flex-row">
                <Input
                    label="Seu nome"
                    name="name"
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/2 md:tw-mr-2"
                    error={errors.name}
                    defaultValue={registerData.name}
                    placeholder="Digite aqui"
                    ref={register}
                />
                <Select
                    label="Gênero"
                    name="gender"
                    errors={errors.gender}
                    defaultValue={registerData.gender}
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/2 md:tw-ml-2"
                    ref={register}
                >
                    <option value="select">Selecionar</option>
                    <option value="male">Masculino</option>
                    <option value="female">Feminino</option>
                    <option value="other">Outro</option>
                </Select>
            </div>
            <div className="tw-flex tw-w-full tw-flex-col tw-justify-between md:tw-flex-row lg:tw-my-8">
                <Input
                    label="Email"
                    name="email"
                    defaultValue={registerData.email}
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/2 md:tw-mr-2"
                    error={errors.email}
                    type="email"
                    placeholder="seu@email.com"
                    ref={register}
                />
                <Input
                    label="Telefone"
                    name="phone"
                    defaultValue={registerData.phone}
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/2 md:tw-ml-2"
                    error={errors.phone}
                    mask="(99) 9 9999-9999"
                    placeholder="(DD) X XXXX-XXXX"
                    control={control}
                />
            </div>
            <div className="tw-flex tw-w-full tw-flex-col tw-justify-between md:tw-flex-row lg:tw-my-8">
                <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-mt-4 md:tw-mt-0 md:tw-w-5/12 md:tw-max-w-xs">
                    <p className="tw-font-bold tw-text-back" htmlFor="hasAgency">
                        Possui agência?
                    </p>
                    <div className="tw-block">
                        <label className="tw-text-back tw-mr-2" htmlFor="hasAgency">
                            Sim
                        </label>
                        <input
                            id="hasAgency"
                            type="radio"
                            ref={register}
                            value={true}
                            defaultChecked={registerData.hasAgency}
                            name="hasAgency"
                            className="active:tw-bg-pink hover:tw-bg-pink checked:tw-bg-pink checked:border-transparent tw-mb-1"
                        />
                    </div>
                    <div className="tw-block">
                        <label className="tw-text-back tw-mr-2" htmlFor="hasAgency">
                            Não
                        </label>
                        <input
                            id="hasAgency"
                            type="radio"
                            ref={register}
                            value={false}
                            defaultChecked={!registerData.hasAgency}
                            name="hasAgency"
                            className="active:tw-bg-pink hover:tw-bg-pink checked:tw-bg-pink checked:border-transparent tw-mb-1"
                        />
                    </div>
                </div>
                <div className="tw-block tw-mt-4 md:tw-mt-0 md:tw-w-1/2">
                    <label className="tw-font-bold tw-text-back md:tw-ml-2" htmlFor="receiveEmails">
                        Receber informações
                    </label>
                    <input
                        id="receiveEmails"
                        className="checked:tw-bg-pink tw-rounded-sm checked:border-transparent tw-mb-1 tw-ml-2"
                        type="checkbox"
                        defaultChecked={registerData.receiveEmails}
                        ref={register}
                        name="receiveEmails"
                    />
                </div>
            </div>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-mt-14">
                <button
                    className="button button-secondary tw-block tw-w-full md:tw-w-1/4"
                    type="button"
                    onClick={handleBack}
                >
                    Voltar
                </button>
                <button
                    type="submit"
                    className="button button-primary tw-mt-4 md:tw-mt-0 tw-block tw-w-full md:tw-w-1/4 md:tw-ml-4"
                >
                    Prosseguir
                </button>
            </div>
        </form>
    );
};

export default FirstStep;
