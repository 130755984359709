import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const useUser = () => useSelector(state => state.firebase.profile);

export const useUserId = () => useSelector(state => state.firebase.profile?.id);

const allowedEmails = new Set(['ndtv@cimtia.com', 'admin@admin.com', 'ridermansb@gmail.com']);

export function useUserHasVerifiedEmail() {
    const user = useUser();

    const [isEmailVerified, setIsEmailVerified] = useState(true);

    useEffect(() => {
        const value = localStorage.getItem('@cimtia:emailVerified');
        const needToBeVerifiedEmail = !allowedEmails.has(user?.email);
        // TODO: Improve this: Not a good solution, but works for now
        if (needToBeVerifiedEmail) {
            const parsed = JSON.parse(value);
            setIsEmailVerified(parsed);
        } else if (value) {
            setIsEmailVerified(true);
        }
    }, [user]);

    return isEmailVerified;
}
