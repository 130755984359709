import React, { useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import Section from './Section';

const MIN_AGE = 0;
const MAX_AGE = 100;

const defaultValue = `${MIN_AGE},${MAX_AGE}`;
const DEFAULT_CLASS_NAMES = {
    activeTrack: 'input-range__track input-range__track--active',
    disabledInputRange: 'input-range input-range--disabled',
    inputRange: 'input-range',
    labelContainer: 'input-range__label-container',
    maxLabel: 'input-range__label input-range__label--max',
    minLabel: 'input-range__label input-range__label--min',
    slider: 'input-range__slider',
    sliderContainer: 'input-range__slider-container',
    track: 'input-range__track input-range__track--background',
    valueLabel: 'input-range__label input-range__label--value',
};
const rangeClassName = {
    ...DEFAULT_CLASS_NAMES,
    activeTrack: `${DEFAULT_CLASS_NAMES.activeTrack} tw-bg-pink`,
    slider: `${DEFAULT_CLASS_NAMES.slider} tw-bg-purple`,
};

const AgeRangeSelector = () => {
    const { watch, control } = useFormContext();
    const ageRange = watch('ageRange', defaultValue);
    const [minAge, maxAge] = (ageRange || defaultValue).split(',');

    const value = useMemo(() => ({ min: minAge, max: maxAge }), [maxAge, minAge]);

    return (
        <Section title="Faixa etária do seu público *">
            <Controller
                name="ageRange"
                render={({ name, onChange }) => (
                    <InputRange
                        name={name}
                        minValue={MIN_AGE}
                        maxValue={MAX_AGE}
                        classNames={rangeClassName}
                        formatLabel={() => ''}
                        onChange={range => onChange(`${range.min},${range.max}`)}
                        value={value}
                    />
                )}
                defaultValue={defaultValue}
                control={control}
            />
            <span className="tw-text-sm">
                {minAge || 0} a {maxAge || 100} anos
            </span>
        </Section>
    );
};

AgeRangeSelector.propTypes = {};

export default AgeRangeSelector;
