import React from 'react';
import gear from 'assets/gifs/gear.gif';

function LoadingCampaign() {
    return (
        <div className="tw-w-full tw-flex tw-justify-center tw-flex-col">
            <div className="tw-w-full tw-flex tw-justify-center">
                <img src={gear} alt="loading" />
            </div>
            <div className="tw-w-full tw-flex tw-justify-center tw-mt-4">
                <h3>Aguarde um momento, estamos procurando a melhor programação para sua campanha...</h3>
            </div>
        </div>
    );
}

export default LoadingCampaign;
