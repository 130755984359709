import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, FormGroup, FormControlLabel, Checkbox, TextField, Button } from '@material-ui/core';

import { useProgram } from 'hooks/program';
import { useUserCompany } from 'hooks/company';
import { createProgram, setNewProgram, setProgram, setStep, updateProgram } from 'store/modules/program';

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: '#fff',
        padding: '0 2rem 2rem 2rem',
    },
    selected: {
        display: 'block',
        padding: '1rem',
        backgroundColor: 'red',
    },
    formGroup: {
        padding: '1rem 0',
        marginBottom: '1.25rem',
    },
    item: {
        background: '#fcfcfc',
        boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.05)',
        padding: '1rem',
        borderRadius: '0.75rem',
    },
    buttons: {
        position: 'absolute',
        bottom: 40,
    },
}));

const schema = yup.object({
    period: yup.string().required('Informe o período!'),
});

const periods = ['Madrugada', 'Manhã', 'Tarde', 'Noite'];

const weekDays = [
    {
        available: false,
        checked: false,
        day: 'monday',
        label: 'Segunda-feira',
    },
    {
        available: false,
        checked: false,
        day: 'tuesday',
        label: 'Terça-feira',
    },
    {
        available: false,
        checked: false,
        day: 'wednesday',
        label: 'Quarta-feira',
    },
    {
        available: false,
        checked: false,
        day: 'thursday',
        label: 'Quinta-feira',
    },
    {
        available: false,
        checked: false,
        day: 'friday',
        label: 'Sexta-feira',
    },
    {
        available: false,
        checked: false,
        day: 'saturday',
        label: 'Sábado',
    },
    {
        available: false,
        checked: false,
        day: 'sunday',
        label: 'Domingo',
    },
];

function Schedule({ handleBack }) {
    const [showDays, setShowDays] = useState(weekDays);
    const { control, setValue, errors, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();
    const program = useProgram();
    const company = useUserCompany();

    const handlePeriodChange = (event, period) => setValue('period', period);

    const onSubmit = useCallback(
        async values => {
            function onSuccess() {
                toast.success('Programa salvo com sucesso!');
                dispatch(setNewProgram());
                dispatch(setStep(0));
                history.push('/programas');
            }

            function onFailure(error) {
                toast.error('Falha ao salvar programa ' + error?.message);
            }

            if (!showDays.some(showDay => showDay.available)) {
                toast.error('Selecione ao menos um dia de exibição do programa');
                return;
            }

            const data = {
                ...program,
                ...values,
                showDays,
                companyId: company?.id || null,
            };

            if (program.id) {
                dispatch(updateProgram(program.id, data, { onSuccess, onFailure }));
            } else {
                dispatch(createProgram(data, { onSuccess, onFailure }));
            }

            dispatch(setProgram({}));
        },
        [dispatch, program, showDays, history, company],
    );

    const handleShowDayChange = useCallback(day => {
        setShowDays(previous =>
            previous.map(showDay => {
                if (showDay.day === day) {
                    return {
                        ...showDay,
                        available: !showDay.available,
                    };
                }
                return showDay;
            }),
        );
    }, []);

    useEffect(() => {
        for (const name of Object.keys(errors)) {
            toast.error(errors[name].message);
        }
    }, [errors]);

    useEffect(() => {
        if (program.showDays) {
            setShowDays(program.showDays);
        }
    }, [program]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Controller
                        name="period"
                        defaultValue={program.period}
                        control={control}
                        render={({ value, name, ref }) => (
                            <Autocomplete
                                options={periods}
                                fullWidth
                                noOptionsText="Selecione um período válido"
                                id="period"
                                name={name}
                                value={value}
                                ref={ref}
                                onChange={handlePeriodChange}
                                renderInput={parameters => (
                                    <TextField
                                        {...parameters}
                                        label="Selecione um período de exibição"
                                        margin="normal"
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle2" gutterBottom>
                        Dias de exibição
                    </Typography>
                    <FormGroup row>
                        {weekDays.map(week => (
                            <FormControlLabel
                                key={week.day}
                                control={
                                    <Checkbox
                                        name={week.day}
                                        color="primary"
                                        onChange={() => handleShowDayChange(week.day)}
                                        checked={showDays.find(showDay => showDay.day === week.day).available}
                                    />
                                }
                                label={week.label}
                            />
                        ))}
                    </FormGroup>
                </Grid>
            </Grid>
            <div className={classes.buttons}>
                <Button onClick={handleBack}>Voltar</Button>
                <Button type="submit" variant="contained" color="primary">
                    Salvar programa
                </Button>
            </div>
        </form>
    );
}
export default Schedule;
