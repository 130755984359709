import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Main from 'components/Layout/Main';
import { DashboardAdmin, EditProfileAdmin } from 'pages/admin';
import NoMatchRoute from 'routes/NoMatchRoute';

const SentryRoute = Sentry.withSentryRouting(Route);

const AdminRoutes = () => (
    <Switch>
        <Main>
            <SentryRoute exact path="/" component={DashboardAdmin} />
            <SentryRoute exact path="/perfil/editar" component={EditProfileAdmin} />
            <SentryRoute path="*">
                <NoMatchRoute />
            </SentryRoute>
        </Main>
    </Switch>
);

export default AdminRoutes;
