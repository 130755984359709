import React from 'react';
import Ellipse from './icons/Ellipse';

const PINK = '#e83e76';
const GRAY = '#c4c4c4';

function Dots({ step }) {
    return (
        <div className="tw-flex tw-flex-row tw-justify-between tw-w-10">
            <Ellipse color={step === 0 ? PINK : GRAY} />
            <Ellipse color={step === 1 ? PINK : GRAY} />
            <Ellipse color={step === 2 ? PINK : GRAY} />
            <Ellipse color={step === 3 ? PINK : GRAY} />
        </div>
    );
}

export default Dots;
