import * as yup from 'yup';

const schema = yup.object().shape({
    programName: yup.string().required('Informe o nome do programa'),
    availableSeconds: yup
        .number()
        .min(0, 'Informe a quantidade de segundos disponíveis.')
        .typeError('Informe os segundos disponíveis'),

    insertionCost: yup
        .number()
        .min(1, 'O valor de investimento não pode ser zero!')
        .typeError('Informe o valor da inserção'),
    insertionDiscount: yup
        .number()
        .min(0, 'Valor de desconto mínimo inválido!')
        .max(100, 'Valor de desconto máximo inválido!')
        .typeError('Informe um valor de desconto!'),
    programState: yup.string().required('Informe a UF do programa'),
    programCity: yup.string().required('Informe a cidade do programa'),
    programType: yup.string().oneOf(['TV', 'RADIO'], 'Informe o tipo do programa'),
});

export default schema;
