import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { getCreativeById } from 'api/creative';
import CreativeDetails from './CreativeDetails';

/**
 * Get creative by ID
 * @param {string} id - The creative ID to get
 */
function useQueryCreativeById(id) {
    return useQuery(['getCreativeById', id], () => getCreativeById(id).get(), {
        enabled: !!id,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        select(data) {
            if (data.exists) {
                return data.data ? data.data() : data;
            }
            throw new Error('Criativo não encontrado!');
        },
        onError(error) {
            Sentry.captureException(error);
            toast.error(error.message || 'Falha ao recuperar criativo!');
        },
    });
}

function CreativeDetailsPage() {
    const { id } = useParams();

    const { data: creative, isLoading, error, refetch } = useQueryCreativeById(id);

    function handleTryAgain(event) {
        event.preventDefault();
        return refetch();
    }

    return (
        <div>
            {isLoading && <p className="tw-text-gray">Carregando...</p>}
            {error && (
                <div>
                    <p className="error-message">{error.message}</p>
                    <div className="tw-mt-2">
                        <button type="button" className="button button-secondary button-small" onClick={handleTryAgain}>
                            Tentar novamente
                        </button>
                        <Link className="button button-secondary button-small tw-ml-5" to="/central-criativa">
                            Selecionar outro criativo
                        </Link>
                    </div>
                </div>
            )}
            {creative && <CreativeDetails creative={creative} />}
        </div>
    );
}

export default CreativeDetailsPage;
