import React, { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { AnalyticsContext } from 'pages/advertiser/Campaign/Create/analyticsContext';
import styleConfig from '../../../../../../style_config.json';
import CursorClickIcon from './CursorClickIcon';

function BudgetSelector() {
    const { setValue, register, unregister, watch } = useFormContext();

    const budgetType = watch('budgetType');
    const { logEvent } = useContext(AnalyticsContext);

    function handleAutomaticClick(event) {
        event.preventDefault();
        setValue('budgetType', 'automatic');
        logEvent('budgetType-automatic');
    }

    function handleManualClick(event) {
        event.preventDefault();
        setValue('budgetType', 'manual');
        logEvent('budgetType-manual');
    }

    useEffect(() => {
        register('budgetType');
        return () => {
            unregister('budgetType');
        };
    }, [register, unregister]);

    return (
        <>
            <div className="tw-my-10">
                <p className="tw-mb-6 tw-text-sm">
                    <strong>Manual:</strong> Selecione caso já saiba em quais veículos e programas você vai anunciar.
                </p>

                <button
                    className={clsx('icon-card tw-p-4 tw-pt-6 tw-h-28 tw-w-44', {
                        'icon-card__active': budgetType === 'manual',
                    })}
                    onClick={handleManualClick}
                    type="button"
                >
                    <div>
                        <CursorClickIcon className="tw-bg-purple" />
                    </div>
                    <div>
                        <span className="tw-text-sm tw-mt-2">Manual</span>
                    </div>
                </button>
            </div>

            <div className="tw-my-10">
                <p className="tw-mb-6 tw-mt-4 tw-text-sm">
                    <strong>Automático</strong> Selecione para usar a inteligência artificial da{' '}
                    <span className="tw-uppercase">${styleConfig['small_name']}</span> para sugerir o plano ideal de
                    veiculações para a sua campanha.
                </p>

                <button
                    className={clsx('icon-card tw-p-4 tw-pt-6 tw-h-28 tw-w-44', {
                        'icon-card__active': budgetType === 'automatic',
                    })}
                    onClick={handleAutomaticClick}
                    type="button"
                >
                    <div>
                        <CursorClickIcon className="tw-bg-pink" />
                    </div>
                    <div>
                        <span className="tw-text-sm tw-mt-2">Automático</span>
                    </div>
                </button>
            </div>
        </>
    );
}

export default BudgetSelector;
