import { analytics, auth, firebase, firestore } from 'base';
import * as Sentry from '@sentry/react';
import { getUser } from 'store/modules/user';
import { updateUserProfile } from 'api/users';

// Actions
const RECOVERY_START = 'auth/RECOVERY_START';
const RECOVERY_SUCCESS = 'auth/RECOVERY_SUCCESS';
const RECOVERY_FAIL = 'auth/RECOVERY_FAIL';

const PROFILE_EDIT_START = 'auth/PROFILE_EDIT_START';
const PROFILE_EDIT_SUCCESS = 'auth/PROFILE_EDIT_SUCCESS';
const PROFILE_EDIT_FAIL = 'auth/PROFILE_EDIT_FAIL';

const DELETE_START = 'auth/DELETE_START';
const DELETE_FAIL = 'auth/DELETE_FAIL';

const LOGIN_ERROR = 'auth/LOGIN_ERROR';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const SIGNOUT_SUCCESS = 'auth/SIGNOUT_SUCCESS';
const SIGNUP_SUCCESS = 'auth/SIGNUP_SUCCESS';
const SIGNUP_ERROR = 'auth/SIGNUP_ERROR';

// Action Creators
export const signUp = (newUser, meta) => async dispatch => {
    const { name, email, password, gender, phone, company, cnpj, address, hasAgency, receiveEmails } = newUser;

    try {
        const userResponse = await auth.createUserWithEmailAndPassword(email, password);

        analytics.logEvent(firebase.analytics.EventName.SIGN_UP, {
            method: userResponse.credential?.signInMethod || 'createUserWithEmailAndPassword',
        });

        await updateUserProfile(userResponse.user.uid, {
            displayName: name,
            email,
            gender,
            phone,
            company,
            cnpj,
            address,
            hasAgency,
            receiveEmails,
            role: 'advertiser',
        });

        dispatch({ type: SIGNUP_SUCCESS, payload: newUser });
        if (meta.onSuccess) meta.onSuccess();
    } catch (error) {
        if (meta.onFailure) meta.onFailure(error);
        dispatch({
            type: SIGNUP_ERROR,
            error: error.message,
        });
    }
};

export const signIn = (credentials, callback) => async dispatch => {
    try {
        const userCredential = await auth.signInWithEmailAndPassword(credentials.email, credentials.password);
        const user = userCredential.user;

        if (callback) {
            callback(undefined, userCredential);
        }

        localStorage.setItem('@cimtia:emailVerified', JSON.stringify(user.emailVerified));
        dispatch({ type: LOGIN_SUCCESS, role: 'teste' });
        dispatch(getUser());

        analytics.logEvent(firebase.analytics.EventName.LOGIN, {
            method: userCredential.credential?.signInMethod || 'signInWithEmailAndPassword',
        });

        return user;
    } catch (error) {
        Sentry.captureException(error);
        if (callback) {
            callback({ message: 'E-mail e/ou senha inválidos' });
        }
        dispatch({ type: LOGIN_ERROR, payload: 'Invalid login credentials' });
    }
};

export const signOut = callback => async dispatch => {
    try {
        return auth.signOut().then(() => {
            dispatch({ type: SIGNOUT_SUCCESS });
            callback && callback();
            const hidden = localStorage.getItem('welcome');
            localStorage.clear();
            if (hidden) {
                setTimeout(() => {
                    localStorage.setItem('welcome', hidden);
                }, 1000);
            }
        });
    } catch (error) {
        Sentry.captureException(error);
        dispatch({ type: SIGNUP_ERROR, error: error.message });
    }
};

export const recoverPassword = (email, meta) => async dispatch => {
    dispatch({ type: RECOVERY_START });
    try {
        await auth.sendPasswordResetEmail(email);
        dispatch({ type: RECOVERY_SUCCESS });
        if (meta?.onSuccess) meta.onSuccess();
    } catch (error) {
        Sentry.captureException(error);
        console.log('error', error);
        if (meta?.onFailure) meta.onFailure();
        dispatch({ type: RECOVERY_FAIL, payload: error.message });
    }
};

export const editProfile = data => async (dispatch, getState) => {
    const user = auth.currentUser;
    const { uid: userId, email: userEmail } = getState().auth;

    dispatch({ type: PROFILE_EDIT_START });

    try {
        // Edit the user profile
        if (data.email !== userEmail) {
            await user.updateEmail(data.email);
        }

        await firestore
            .collection('users')
            .doc(userId)
            .set({
                name: data.name,
                email: data.email,
                profile: {
                    gender: data.gender,
                    phone: data.phone,
                    cnpj: data.cnpj,
                    city: data.city,
                },
                company: data.company,
            });

        if (data.password.length > 0) {
            await user.updatePassword(data.password);
        }
        dispatch({ type: PROFILE_EDIT_SUCCESS });
    } catch (error) {
        dispatch({
            type: PROFILE_EDIT_FAIL,
            payload: error.message,
        });
    }
};

export const deleteUser = () => async (dispatch, getState) => {
    const user = auth.currentUser;
    const userId = getState().auth.uid;

    dispatch({ type: DELETE_START });

    try {
        await firestore.collection('users').doc(userId).delete();

        await user.delete();
    } catch (error) {
        dispatch({ type: DELETE_FAIL, payload: error.message });
    }
};

const initialState = {
    authError: undefined,
    role: '',
};

// Reducer
export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_ERROR:
            return {
                ...state,
                authError: 'Falha ao Logar',
                role: 'supplier' || 'advertiser',
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                authError: undefined,
                role: action.role,
            };
        case SIGNOUT_SUCCESS:
            return {
                ...state,
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                authError: undefined,
            };
        case SIGNUP_ERROR:
            return {
                ...state,
                authError: action.error,
            };
        default:
            return state;
    }
}
