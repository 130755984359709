import React from 'react';
import { useHistory } from 'react-router-dom';
import * as PropTypes from 'prop-types';

const MenuItem = ({ title = false, icon, to, onClick, handleMouseLeave }) => {
    const history = useHistory();

    function handleClick(event) {
        handleMouseLeave();
        event.preventDefault();
        if (to?.includes('http')) {
            window.open(to);
        } else {
            history.push(to);
        }
    }

    return (
        <button
            className="tw-text-white tw-flex tw-place-self-stretch tw-align-middle tw-place-items-center tw-place-content-evenly hover:tw-bg-pink-dark tw-rounded-xl tw-mx-4 tw-my-2 tw-px-4 tw-py-2"
            onClick={onClick || handleClick}
        >
            {icon}
            {title && <span className="tw-text-left tw-ml-2 tw-flex-grow">{title}</span>}
        </button>
    );
};

MenuItem.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.node]),
    to: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.element.isRequired,
};
MenuItem.defaultProps = {
    title: false,
};

export default MenuItem;
