import React from 'react';
import * as PropTypes from 'prop-types';
import { getColor } from './utils';

const AlagoasPathIcon = ({ active = false, available = false }) => {
    const color = getColor(active, available);
    return (
        <>
            <path
                fill={color}
                stroke="#FFFFFF"
                strokeWidth="1.0404"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M413.953,169.018
        c3.78,3.313,9.424,5.505,12.547,5.491c3.229-0.013,5.009-3.328,7.421-4.794c1.177-0.712,10.297-1.93,9.174,1.042
        c-1.807,4.848-7.122,8.585-10.024,12.789c-2.792,2-3.423,7.093-6.354,1.864c-3.259,0.424-3.722-4.424-6.957-4.477
        c-3.668-2.261-7.998-3.769-11.201-6.342C410.615,172.646,412.751,171.359,413.953,169.018z"
            />
            <path
                fill={color}
                className="circle"
                d="M436.423,168.763c6.236,0,11.258,5.054,11.258,11.278c0,6.207-5.02,11.259-11.258,11.259
        c-6.241,0-11.263-5.052-11.263-11.259C425.16,173.816,430.182,168.763,436.423,168.763z"
            />
        </>
    );
};

AlagoasPathIcon.propTypes = { active: PropTypes.bool };

export default AlagoasPathIcon;
