/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';

function CheckIcon({ checked, onClick }) {
    return (
        <svg
            width="38"
            height="34"
            viewBox="0 0 38 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: 'pointer' }}
            onClick={onClick}
        >
            <circle cx="16.72" cy="16.72" r="15.72" stroke={checked ? '#7F4895' : '#d8d8d8'} strokeWidth="2" />
            {checked && (
                <path
                    d="M16.1712 25.0805L4.56006 12.6529H11.5267L16.1712 18.4332L31.2656 4.56055H38.0001L16.1712 25.0805Z"
                    fill="#7F4895"
                />
            )}
        </svg>
    );
}

export default CheckIcon;
