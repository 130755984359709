/* eslint-disable no-unused-vars */
import React from 'react';

import { useQuery } from 'hooks/query';

import ChangePassword from './ChangePassword';
import ConfirmAccount from './ConfirmAccount';

const Auth = () => {
    const mode = useQuery().get('mode');
    console.log('mode', mode);
    return mode === 'resetPassword' ? <ChangePassword /> : <ConfirmAccount />;
};

export default Auth;
