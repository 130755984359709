import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

import Container from '@material-ui/core/Container';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    button: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const EditProfileAdmin = () => {
    const classes = useStyles();

    const user = useSelector(state => state.firebase.profile);
    const uid = useSelector(state => state.firebase.auth.uid);
    const [name, setName] = useState(user.displayName);
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone);
    const [company, setCompany] = useState(user.company);
    const [city, setCity] = useState(user.city);
    const firestore = useFirestore();

    const handleUpdateProfile = useCallback(
        event => {
            event.preventDefault();
            let userData = {
                displayName: name,
                email: email,
                phone: phone,
                company: company,
                city: city,
            };
            console.log('update profile request');
            firestore.update({ collection: 'users', doc: uid }, userData);
        },
        [city, company, email, firestore, name, phone, uid],
    );

    const handleInputChange = useCallback(event => {
        switch (event.target.id) {
            case 'name':
                setName(event.target.value);
                break;
            case 'email':
                setEmail(event.target.value);
                break;
            case 'phone':
                setPhone(event.target.value);
                break;
            case 'city':
                setCity(event.target.value);
                break;
            case 'company':
                setCompany(event.target.value);
                break;
            default:
                break;
        }
    }, []);

    return user ? (
        <Container component="main" maxWidth="lg">
            <Typography component="h1" variant="h5">
                Editar Perfil
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleUpdateProfile}>
                <TextField
                    onChange={handleInputChange}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Seu Nome"
                    name="name"
                    value={name}
                    autoFocus
                />
                <TextField
                    onChange={handleInputChange}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Seu e-mail"
                    name="email"
                    value={email}
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    onChange={handleInputChange}
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="Telefone"
                    name="phone"
                    value={phone}
                    autoComplete="phone"
                    autoFocus
                />
                <TextField
                    onChange={handleInputChange}
                    margin="normal"
                    required
                    fullWidth
                    id="company"
                    label="Empresa"
                    name="company"
                    autoComplete="company"
                    value={company}
                    autoFocus
                />
                <TextField
                    onChange={handleInputChange}
                    margin="normal"
                    required
                    fullWidth
                    id="city"
                    label="Cidade"
                    name="city"
                    value={city}
                    autoComplete="city"
                    autoFocus
                />
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                    Salvar
                </Button>

                <RouterLink className={classes.button} to="/">
                    Cancelar
                </RouterLink>
            </form>
        </Container>
    ) : (
        <h1>Carregando dados do perfil</h1>
    );
};
export default EditProfileAdmin;
