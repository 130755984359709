import React, { useCallback, useEffect, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useDispatch } from 'react-redux';
import Loading from 'components/Loading';
import { usePackages } from 'hooks/packages';
import { Creators as ActionCreators } from 'store/modules/packages';
import PackageCard from './PackageCard';
import { useUser } from 'hooks/user';
import { getRegionByCityAndState } from 'api/regions';

const PackagesShop = () => {
    const [userRegion, setUserRegion] = useState(null);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const packages = usePackages();
    const user = useUser();

    const fetchPackages = useCallback(
        regionId => {
            const onFinishRequest = () => setLoading(false);
            dispatch(
                ActionCreators.fetchPackagesByRegionIdAndAvailability(regionId, {
                    onSuccess: onFinishRequest,
                    onFailure: onFinishRequest,
                }),
            );
        },
        [dispatch],
    );

    useEffect(() => {
        const fetchRegion = async () => {
            const { city, state } = user.address;
            const region = await getRegionByCityAndState(city, state);
            setUserRegion(region?.docs[0]?.data());
        };

        if (user?.address) {
            fetchRegion();
        }
        fetchPackages();
    }, [dispatch, fetchPackages, user]);

    useEffect(() => {
        if (userRegion?.id) {
            fetchPackages(userRegion.id);
        }
    }, [fetchPackages, userRegion]);

    return loading ? (
        <Loading />
    ) : (
        <ScrollMenu className="tw-flex md:tw-flex-row tw-flex-col tw-justify-start tw-w-full tw-h-full">
            {packages?.map(packageItem => (
                <PackageCard itemId={packageItem.id} key={packageItem.id} packageItem={packageItem} />
            ))}
        </ScrollMenu>
    );
};

export default PackagesShop;
