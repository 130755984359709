import styled from 'styled-components';
import { darken } from 'polished';
export const Container = styled.div`
    padding: 2rem;
    width: 100%;
    min-height: 100%;
    h1 {
        padding: 1.25rem 0;
        position: relative;
        span {
            font-size: 0.65rem;
            text-transform: uppercase;

            font-weight: 600;
            display: block;
        }
        margin-bottom: 2rem;
    }
    h2 {
        margin-bottom: 2rem;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;
export const Subtitle = styled.span`
    margin: 1.25rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 256px;
    text-transform: uppercase;
    padding: 0.25rem;
    background: #eee;
    font-weight: 600;
    margin-bottom: 1rem;
`;
export const CampaignDetails = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DeleteButton = styled.button`
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    color: #fff;
    background: #bd1816;
    font-weight: 800;
    font-family: Montserrat, sans-serif;

    box-shadow: unset;
    border: unset;
    border-radius: 0.75rem;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: ${darken(0.3, '#bd1816')};
    }
`;

export const Indicator = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: #eee;
    align-items: center;
    justify-content: center;
    min-height: 175px;
    min-width: 256px;
    box-shadow: 0px 0px 5px 3px rgba(189, 195, 199, 1);
    border-radius: 0.75rem;
    h3 {
        font-size: 0.75rem;
        text-transform: uppercase;
    }
    strong {
        font-size: 3rem;
    }
`;

export const Footer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    a {
        display: block;
        background: #2d3765;
        color: #fff;
        padding: 1rem;
        border-radius: 0.25rem;
    }
`;
