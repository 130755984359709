import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import { useCompanies, useCurrentCampaign } from 'hooks/campaign';
import { useUser } from 'hooks/user';
import { useCoverageCitiesCompanyIds } from 'hooks/coverageCities';
import { grantTotalForPrograms, totalInsertionsForPrograms } from 'services/advertiser/campaign';
import { getCompaniesByIds } from 'store/modules/company';
import ProgramCard from './ProgramCard';
import AddProgramCard from './AddProgramCard';
import useAutomaticMode from './useAutomaticMode';
import LoadingCampaign from './LoadingCampaign';
import CampaignOverview from '../../CampaignOverview';
import CampaignTotal from '../../CampaignTotal';
import { AnalyticsContext } from '../../analyticsContext';

const CIMTIA_TIME = 5000;

const SuppliersList = ({ companies, programs }) => {
    const suppliers = programs
        .reduce(
            (list, item) =>
                !companies.map(element => element.id)?.includes(item.campanyId)
                    ? [...list, companies.find(_element => _element.id === item.companyId)?.logo]
                    : list,
            [],
        )
        .reduce((logos, logo) => (!logos.includes(logo) ? [...logos, logo] : logos), []);

    return (
        <div className="tw-flex tw-flex-wrap">
            {suppliers.map(url => (
                <img key={url} src={url} alt={url} className="tw-w-1/5 tw-mr-2 tw-mb-2" />
            ))}
        </div>
    );
};

const MediaPlanStep = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const {
        mediaCentral,
        budget,
        localization: { code },
        objectivesAndDuration: { duration, city },
    } = useCurrentCampaign();
    const user = useUser();

    const companyIds = useCoverageCitiesCompanyIds(code, city);
    useEffect(() => {
        if (companyIds.length > 0) {
            dispatch(getCompaniesByIds(companyIds));
        }
    }, [dispatch, companyIds]);
    const companies = useCompanies();

    useEffect(() => {
        dispatch(CampaignCreators.fillProgramInsertions({ user, companies }));
    }, [companies, dispatch, user]);

    const selectedPrograms = useMemo(
        () => [...mediaCentral.selectedTVPrograms, ...mediaCentral.selectedRadioPrograms],
        [mediaCentral.selectedRadioPrograms, mediaCentral.selectedTVPrograms],
    );

    const { viewItemsList } = useContext(AnalyticsContext);
    useEffect(() => {
        viewItemsList();
    }, [viewItemsList, selectedPrograms]);

    useAutomaticMode(budget.budgetType === 'automatic' && selectedPrograms.length <= 0);

    const totalInsertions = totalInsertionsForPrograms(selectedPrograms);
    const hasInsertions = totalInsertions > 0;

    function handlePreviousClick(event) {
        event.preventDefault;
        if (budget.budgetType === 'automatic') {
            dispatch(
                CampaignCreators.addMediaCentral({
                    selectedTVPrograms: [],
                    selectedRadioPrograms: [],
                }),
            );
        }
        dispatch(CampaignCreators.previousStep());
    }

    function handleNextClick(event) {
        event.preventDefault();
        const grantTotalValue = grantTotalForPrograms(
            selectedPrograms,
            companies,
            duration,
            user?.cnpj,
            budget?.budgetType,
        );
        dispatch(CampaignCreators.unlockStep('mediaPlan'));
        dispatch(CampaignCreators.setCampaignValue(grantTotalValue));
        dispatch(CampaignCreators.nextStep());
    }

    useEffect(() => {
        if (budget.budgetType === 'automatic') {
            setLoading(true);
            const timeoutToken = setTimeout(() => {
                setLoading(false);
            }, CIMTIA_TIME);
            return () => clearTimeout(timeoutToken);
        }
    }, [budget.budgetType]);

    return (
        <div>
            {loading ? (
                <LoadingCampaign />
            ) : (
                <div className="tw-mt-4 tw-flex md:tw-justify-between tw-justify-center md:tw-flex-row tw-flex-col tw-align-top tw-gap-x-4">
                    <div>
                        <h3>Programa(s) Selecionado(s)</h3>
                        <p>Clique nos programas para editar os dias de veiculação e a quantidade de inserções</p>
                        <div className="tw-flex tw-gap-4 tw-mt-8 tw-flex-wrap tw-place-items-stretch tw-place-content-stretch">
                            {selectedPrograms.map(program => (
                                <div className="tw-w-full tw-max-w-xs" key={program.id}>
                                    <ProgramCard program={program} />
                                </div>
                            ))}

                            <div className="tw-w-full md:tw-w-1/3 lg:tw-w-2/5">
                                <AddProgramCard />
                            </div>
                        </div>

                        {companies.length > 0 && (
                            <>
                                <h3 className="tw-mt-10">Emissora(s) Selecionada(s)</h3>
                                <SuppliersList companies={companies} programs={selectedPrograms} />
                            </>
                        )}
                    </div>

                    <div className="tw-w-full md:tw-w-2/3 lg:tw-w-4/5 xl:tw-w-3/5 2xl:tw-w-1/5/5">
                        <CampaignOverview programs={selectedPrograms} vertical={true} />
                        <p className="tw-font-bold tw-my-3">Investimento Total</p>
                        <CampaignTotal programs={selectedPrograms} />
                    </div>
                </div>
            )}
            {!loading && (
                <div className="tw-mt-10 tw-text-center">
                    <button
                        type="button"
                        onClick={handlePreviousClick}
                        className="button button-primary-outlined tw-mr-4"
                    >
                        Voltar
                    </button>

                    <button
                        type="button"
                        className="button button-primary"
                        onClick={handleNextClick}
                        disabled={!hasInsertions}
                    >
                        Próximo
                    </button>
                </div>
            )}
        </div>
    );
};

export default MediaPlanStep;
