import React from 'react';
import { useSelector } from 'react-redux';
import AdvertiserRoutes from 'routes/AdvertiserRoutes';
import SupplierRoutes from 'routes/SupplierRoutes';
import AdminRoutes from 'routes/AdminRoutes';
import OwnerRoutes from 'routes/OwnerRoutes';

const ProtectedRoutes = () => {
    const profile = useSelector(store => store.firebase.profile);
    const { role } = profile;

    switch (role) {
        case 'supplier':
            return <SupplierRoutes />;
        case 'admin':
            return <AdminRoutes />;
        case 'owner':
            return <OwnerRoutes />;
        default:
            return <AdvertiserRoutes />;
    }
};

export default ProtectedRoutes;
