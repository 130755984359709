import yup from 'validator';

const schema = yup.object({
    ageRange: yup
        .string()
        // .object({
        //     min: yup.number().positive().required(),
        //     max: yup.number().positive().max(.required(),
        // })
        .required()
        .label('Faixa etária do seu público'),
    segment: yup.string().required().label('Segmento'),
    bestTime: yup.string().required().label('Horário'),
    gender: yup.string().required().label('Gênero'),
    socialClass: yup
        .object({
            classA: yup.boolean(),
            classB: yup.boolean(),
            classC: yup.boolean(),
            classD: yup.boolean(),
            classE: yup.boolean(),
        })
        .test('socialClass', null, function (value) {
            if (value.classA || value.classB || value.classC || value.classD || value.classE) {
                return true;
            }

            const { path, createError } = this;

            return createError({
                path,
                message: 'Selecione uma ou mais ${path}',
            });
        })
        .label('Classes Sociais'),
});

export default schema;
