/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import yup from 'validator';
import { getCompaniesByOwner, updateCompany } from 'api/companies';
import { useUserId } from 'src/hooks/user';
import * as Sentry from '@sentry/react';
import InputCurrencyField from 'components/shared/molecules/InputCurrencyField';

const schema = yup.object().shape({
    campaignTVMaxValue: yup.number().defined().required().label('Valor'),
});

function CampaignConfig() {
    const [company, setCompany] = useState(null);

    const { goBack } = useHistory();
    const userId = useUserId();

    const {
        register,
        errors,
        handleSubmit,
        control,
        setValue,
        formState: { isSubmitting },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async values => {
        try {
            await updateCompany(company?.id, values);
            toast.success('Dados salvos com sucesso!');
        } catch (error) {
            Sentry.captureException(error);
            toast.error('Falha ao atualizar dados!');
        }
    };

    useEffect(() => {
        async function getCompany() {
            const response = await getCompaniesByOwner(userId).get();
            if (!response.empty) {
                setCompany({ id: response.docs[0].id, ...response.docs[0].data() });
            }
        }
        getCompany();
    }, [userId]);

    useEffect(() => {
        if (company?.campaignTVMaxValue) {
            setValue('campaignTVMaxValue', company?.campaignTVMaxValue);
        }
    }, [company, setValue]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="tw-w-full tw-max-w-xs tw-flex tw-flex-col tw-mb-4">
                <div className="tw-w-full tw-max-w-2xl tw-flex-col">
                    <div className="tw-flex md:tw-flex-row tw-flex-col tw-w-full">
                        <InputCurrencyField
                            label="Valor máximo da campanha por usuário"
                            className="input-clean"
                            name="campaignTVMaxValue"
                            error={errors.campaignTVMaxValue}
                            control={control}
                            defaultValue={company?.campaignTVMaxValue}
                            ref={register}
                        />
                    </div>
                </div>
                <div className="tw-mt-10 tw-mb-4 md:tw-mt-8">
                    <button className="button button-primary-outlined tw-mr-4" type="button" onClick={goBack}>
                        Voltar
                    </button>
                    <button className="button button-primary" type="submit" disabled={isSubmitting}>
                        Salvar
                    </button>
                </div>
            </form>
        </>
    );
}

export default CampaignConfig;
