import React from 'react';

const TrashIcon = ({ className = '' }) => (
    <svg
        width="11"
        height="14"
        viewBox="0 0 11 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
        className={`icon ${className}`}
    >
        <path
            d="M0.410697 3.96484C0.120934 3.96484 -0.078619 3.67227 0.0279913 3.4043C0.416163 2.45 1.35106 1.77734 2.43903 1.77734H2.94202C3.13063 0.784766 3.99992 0 5.06329 0H5.93805C7.00142 0 7.87071 0.784766 8.05933 1.77734H8.56231C9.65028 1.77734 10.5852 2.45 10.9706 3.4043C11.08 3.67227 10.8777 3.96484 10.5879 3.96484H0.410697ZM5.93805 0.820312H5.06329C4.46463 0.820312 3.95618 1.22227 3.7867 1.77734H7.21464C7.04516 1.22227 6.53671 0.820312 5.93805 0.820312Z"
            fill="#B4B4B4"
        />
        <path
            d="M9.46105 12.857C9.41458 13.4969 8.87606 14 8.23367 14H2.76374C2.12134 14 1.58282 13.4969 1.53635 12.857L0.962295 4.78516H10.0378L9.46105 12.857ZM4.37929 6.98086C4.36836 6.75391 4.17427 6.57891 3.95012 6.59258C3.72323 6.60352 3.55101 6.79766 3.56195 7.02187L3.78063 11.3969C3.79157 11.6156 3.97199 11.7879 4.19067 11.7879C4.42576 11.7879 4.61165 11.591 4.60071 11.3586L4.37929 6.98086ZM7.05002 6.58984C6.82313 6.57891 6.63178 6.75391 6.62084 6.97812L6.40216 11.3531C6.39122 11.5855 6.57711 11.7824 6.8122 11.7824C7.02815 11.7824 7.2113 11.6129 7.22224 11.3914L7.44092 7.01641C7.45186 6.79492 7.27691 6.60078 7.05002 6.58984Z"
            fill="#B4B4B4"
        />
    </svg>
);

export default TrashIcon;
