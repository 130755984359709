import React from 'react';
import PropTypes from 'prop-types';

const CheckedIcon = ({ className = '' }) => (
    <svg className={className} fill="none" viewBox="0 0 38 34" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16.72" cy="16.72" r="15.72" stroke="#7F4895" strokeWidth="2" />
        <path
            d="M16.1712 25.0801L4.56006 12.6525H11.5267L16.1712 18.4327L31.2656 4.56006H38.0001L16.1712 25.0801Z"
            fill="#7F4895"
        />
    </svg>
);

CheckedIcon.propTypes = {
    className: PropTypes.string,
};
CheckedIcon.defaultProps = {
    className: '',
};

export default CheckedIcon;
