import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

function DolarIcon({ className, color, message: Message }) {
    const [showMessage, setShowMessage] = useState(false);

    const toggleMessage = () => setShowMessage(state => !state);

    return (
        <div className="tw-relative tw-cursor-pointer">
            {showMessage && Message && (
                <OutsideClickHandler onOutsideClick={toggleMessage}>
                    <Message />
                </OutsideClickHandler>
            )}
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                onClick={toggleMessage}
            >
                <g clipPath="url(#clip0)">
                    <path
                        d="M10.0021 20.0042C15.5261 20.0042 20.0042 15.5261 20.0042 10.0021C20.0042 4.4781 15.5261 0 10.0021 0C4.4781 0 0 4.4781 0 10.0021C0 15.5261 4.4781 20.0042 10.0021 20.0042Z"
                        fill={color || '#D8D8D8'}
                    />
                    <path
                        d="M14.0009 12.832C14.0009 13.3653 13.8675 13.8667 13.6009 14.336C13.3449 14.8053 12.9609 15.2 12.4489 15.52C11.9475 15.84 11.3502 16.032 10.6569 16.096V17.392H9.61688V16.096C8.61421 16 7.80354 15.6853 7.18488 15.152C6.56621 14.6187 6.25688 13.9147 6.25688 13.04H8.20888C8.24088 13.456 8.37421 13.8027 8.60888 14.08C8.84354 14.3467 9.17954 14.5173 9.61688 14.592V11.072C8.90221 10.8907 8.32088 10.7093 7.87288 10.528C7.42488 10.336 7.04088 10.0373 6.72088 9.632C6.41154 9.22667 6.25688 8.672 6.25688 7.968C6.25688 7.08267 6.56621 6.352 7.18488 5.776C7.80354 5.18933 8.61421 4.848 9.61688 4.752V3.456H10.6569V4.752C11.5955 4.83733 12.3475 5.136 12.9129 5.648C13.4889 6.14933 13.8142 6.84267 13.8889 7.728H11.9369C11.9049 7.39733 11.7769 7.104 11.5529 6.848C11.3289 6.58133 11.0302 6.4 10.6569 6.304V9.76C11.3715 9.93067 11.9529 10.112 12.4009 10.304C12.8489 10.4853 13.2275 10.7787 13.5369 11.184C13.8462 11.5787 14.0009 12.128 14.0009 12.832ZM8.11288 7.872C8.11288 8.30933 8.24088 8.65067 8.49688 8.896C8.76354 9.14133 9.13688 9.33867 9.61688 9.488V6.256C9.15821 6.30933 8.79021 6.47467 8.51288 6.752C8.24621 7.02933 8.11288 7.40267 8.11288 7.872ZM10.6569 14.592C11.1262 14.5173 11.4942 14.3307 11.7609 14.032C12.0275 13.7227 12.1609 13.3547 12.1609 12.928C12.1609 12.5013 12.0275 12.1707 11.7609 11.936C11.5049 11.6907 11.1369 11.4933 10.6569 11.344V14.592Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

export default DolarIcon;
