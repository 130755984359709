import React from 'react';
import { useFormContext } from 'react-hook-form';
import Select from 'molecules/Select';
import Section from './Section';
import segments from './segments.json';

const SegmentSelector = () => {
    const { register, errors } = useFormContext();
    return (
        <Section title="Qual segmento do seu negócio:">
            <Select name="segment" ref={register()} error={errors.segment}>
                {segments.map(segment => (
                    <option key={segment.value} value={segment.value}>
                        {segment.label}
                    </option>
                ))}
            </Select>
        </Section>
    );
};

export default SegmentSelector;
