import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import BaseInfo from './Steps/BaseInfo';
import Audience from './Steps/Audience';
import Schedule from './Steps/Schedule';
import { useCurrentStep } from 'hooks/program';
import { setStep } from 'store/modules/program';
import { getCompanyByUserId } from 'store/modules/company';
import { useUserId } from 'hooks/user';
import useStyles from './styles';

const steps = ['Informações sobre o Programa', 'Segmentação do Programa', 'Programação do Programa'];

function RegisterMedia() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userId = useUserId();
    const step = useCurrentStep();

    useEffect(() => {
        if (userId) {
            dispatch(getCompanyByUserId(userId));
        }
    }, [userId, dispatch]);

    const handleNext = useCallback(() => dispatch(setStep(step + 1)), [dispatch, step]);
    const handleBack = useCallback(() => dispatch(setStep(step - 1)), [dispatch, step]);

    const stepContent = useMemo(() => {
        switch (step) {
            case 0:
                return <BaseInfo handleNext={handleNext} />;
            case 1:
                return <Audience handleNext={handleNext} handleBack={handleBack} />;
            case 2:
                return <Schedule handleNext={handleNext} handleBack={handleBack} />;
            default:
                throw new Error('Etapa não cadastrada');
        }
    }, [handleBack, handleNext, step]);

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Toolbar className={classes.toolBar}>Cadastro de Programas</Toolbar>

            <Stepper activeStep={step} className={classes.stepper}>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {step === steps.length ? (
                <Grid container className={classes.thanks}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            Obrigado por cadastrar seu programa.
                        </Typography>
                        <Typography variant="subtitle1">
                            Nossa equipe avaliará e retornará em breve o status de aprovação de seu pedido.
                        </Typography>
                    </Grid>
                    <Button variant="contained" color="primary" className={classes.button}>
                        Adicionar outro programa
                    </Button>
                </Grid>
            ) : (
                stepContent
            )}
        </Container>
    );
}

export default RegisterMedia;
