import { firestore } from 'base';

export function getCompanyById(id) {
    return firestore.collection('company').doc(id);
}

export function getCompaniesByOwner(id) {
    return firestore.collection('company').where('ownerIds', 'array-contains', id);
}

export function updateCompany(id, data) {
    return firestore.collection('company').doc(id).update(data);
}
