import React from 'react';
import * as PropTypes from 'prop-types';
import { getColor } from './utils';

const SergipePathIcon = ({ active = false, available = false }) => {
    const color = getColor(active, available);
    return (
        <>
            <path
                fill={color}
                stroke="#FFFFFF"
                strokeWidth="1.0404"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M408.561,191.735
        c0.521-1.505,2.465-0.725,3.533-0.794c2.273-0.164,0.494-2.738,1.095-3.778c2.026-3.793-2.738-5.999-1.998-10.408
        c4.024,1.931,9.448,3.397,12.408,6.89c1.343,1.533,5.504,2.656,5.832,4.847c-6.822,0.384-6.901,8.819-11.942,11.572
        C413.545,202.212,407.055,193.721,408.561,191.735z"
            />
            <path
                fill={color}
                className="circle"
                d="M417.324,182.854c6.214,0,11.266,5.035,11.266,11.262c0,6.208-5.052,11.261-11.266,11.261
        c-6.238,0-11.258-5.053-11.258-11.261C406.063,187.89,411.084,182.854,417.324,182.854z"
            />
        </>
    );
};

SergipePathIcon.propTypes = { active: PropTypes.bool };

export default SergipePathIcon;
