import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getDiscounts, saveDiscounts, getDiscountsRange, saveDiscountsRange } from 'api/discounts';
import { Types, Creators } from 'store/modules/discounts';

function* fetchDiscountsSaga(action) {
    const { companyId } = action;
    const discounts = yield call(getDiscounts, companyId);
    yield put(Creators.setDiscounts(discounts));
}

function* saveDiscountsSaga(action) {
    const { companyId, discounts } = action;
    yield call(saveDiscounts, companyId, discounts);
}

function* fetchDiscountsRangeSaga(action) {
    const { companyId } = action;
    const discountsRange = yield call(getDiscountsRange, companyId);
    yield put(Creators.setDiscountsRange(discountsRange));
}

function* saveDiscountsRangeSaga(action) {
    const { companyId, discountsRange } = action;
    yield call(saveDiscountsRange, companyId, discountsRange);
}

function* discountsRoot() {
    yield all([
        takeLatest(Types.FETCH_DISCOUNTS, fetchDiscountsSaga),
        takeLatest(Types.SAVE_DISCOUNTS, saveDiscountsSaga),
        takeLatest(Types.FETCH_DISCOUNTS_RANGE, fetchDiscountsRangeSaga),
        takeLatest(Types.SAVE_DISCOUNTS_RANGE, saveDiscountsRangeSaga),
    ]);
}

export default discountsRoot;
