import React from 'react';
import { Link } from 'react-router-dom';
import PaymentInfoSection from './PaymentInfoSection';
import PaymentFormSection from './PaymentFormSection';
import InfoSection from './InfoSection';
import AdditionalInfoSection from './AdditionalInfoSection';
import OfferSection from './OfferSection';

const CreativeDetails = ({ creative }) => (
    <div className="tw-w-full">
        <InfoSection creative={creative} />

        {creative.payment ? <PaymentInfoSection creative={creative} /> : <PaymentFormSection creative={creative} />}

        <h2 className="tw-text-pink tw-normal-case tw-font-bold tw-mt-4">Resumo do comercial</h2>
        <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-8">
            <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                <h4 className="tw-text-purple tw-font-bold">Nome do seu comercial:</h4>
                <p>{creative.commercialName}</p>
            </div>
            <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                <h4 className="tw-text-purple tw-font-bold">Nome do seu negócio:</h4>
                <p>{creative.businessName}</p>
            </div>
            <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                <h4 className="tw-text-purple tw-font-bold">Slogan:</h4>
                <p>{creative.slogan}</p>
            </div>
        </div>

        <OfferSection creative={creative} />

        <AdditionalInfoSection creative={creative} />

        <h3 className="tw-text-gray-700 tw-mt-8">Logo</h3>
        {creative.logo && (
            <div className="tw-mt-4">
                <img
                    src={creative.logo}
                    alt="image"
                    width="217"
                    height="152"
                    className="tw-mr-3 tw-mb-3 tw-rounded-md"
                />
            </div>
        )}
        <h3 className="tw-text-gray-700 tw-mt-8">Imagens anexadas</h3>
        {creative.commercialImages?.length > 0 && (
            <div className="tw-flex tw-items-center tw-mt-4 tw-flex-wrap">
                {creative.commercialImages.map(item => (
                    <div key={item} className="tw-relative">
                        <img
                            src={item}
                            alt="image"
                            width="217"
                            height="152"
                            className="tw-mr-3 tw-mb-3 tw-rounded-md"
                        />
                    </div>
                ))}
            </div>
        )}
        <div className="tw-mt-10 tw-text-center tw-mb-4 md:tw-mt-24">
            <Link to="/central-criativa" className="button button-primary-outlined tw-mr-4" type="button">
                Voltar
            </Link>
        </div>
    </div>
);

export default CreativeDetails;
