import React from 'react';
import PropTypes from 'prop-types';

const InfoIcon = ({ className = '' }) => (
    <svg className={className} fill="none" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16.72" cy="16.72" r="16.22" stroke="#E5195A" />
        <path
            d="M20.2383 22.5586L20.3672 23.7422C19.25 24.0078 18.0469 24.1406 16.7578 24.1406C16.4688 24.1406 16.1992 24.1211 15.9492 24.082C15.707 24.043 15.4922 23.9805 15.3047 23.8945C15.125 23.8086 14.9805 23.6953 14.8711 23.5547C14.7695 23.4062 14.7188 23.2266 14.7188 23.0156C14.7188 22.8672 14.7227 22.6953 14.7305 22.5C14.7383 22.3047 14.7461 22.1055 14.7539 21.9023C14.7695 21.6992 14.7812 21.4961 14.7891 21.293C14.7969 21.082 14.8008 20.8828 14.8008 20.6953V14.6133C14.8008 14.4727 14.7969 14.3281 14.7891 14.1797C14.7812 14.0312 14.7695 13.8906 14.7539 13.7578C14.6289 13.7188 14.4961 13.6953 14.3555 13.6875C14.2148 13.6719 14.0664 13.6641 13.9102 13.6641H12.6797V12.3633C12.9141 12.3164 13.1914 12.2695 13.5117 12.2227C13.8398 12.1758 14.1797 12.1367 14.5312 12.1055C14.8828 12.0742 15.2383 12.0508 15.5977 12.0352C15.957 12.0117 16.2891 12 16.5938 12C16.9609 12 17.2852 12.0234 17.5664 12.0703C17.8555 12.1094 18.125 12.2266 18.375 12.4219C18.3984 12.5312 18.418 12.6445 18.4336 12.7617C18.4492 12.8789 18.457 12.9961 18.457 13.1133V20.6484C18.457 21.0469 18.4688 21.4062 18.4922 21.7266C18.5234 22.0469 18.5625 22.3242 18.6094 22.5586H20.2383ZM14.4375 8.35547C14.4375 8.07422 14.4922 7.82812 14.6016 7.61719C14.7188 7.39844 14.875 7.21875 15.0703 7.07812C15.2734 6.92969 15.5039 6.82031 15.7617 6.75C16.0273 6.67187 16.3086 6.63281 16.6055 6.63281C16.9102 6.63281 17.1953 6.67187 17.4609 6.75C17.7266 6.82813 17.957 6.94141 18.1523 7.08984C18.3477 7.23828 18.5 7.42187 18.6094 7.64062C18.7266 7.85156 18.7852 8.08984 18.7852 8.35547C18.7852 8.63672 18.7227 8.88672 18.5977 9.10547C18.4805 9.31641 18.3203 9.49219 18.1172 9.63281C17.9219 9.77344 17.6914 9.88281 17.4258 9.96094C17.1602 10.0312 16.8867 10.0664 16.6055 10.0664C16.3008 10.0664 16.0156 10.0273 15.75 9.94922C15.4922 9.87109 15.2656 9.75781 15.0703 9.60938C14.875 9.46094 14.7188 9.28125 14.6016 9.07031C14.4922 8.85938 14.4375 8.62109 14.4375 8.35547Z"
            fill="#E5195A"
        />
    </svg>
);

InfoIcon.propTypes = {
    className: PropTypes.string,
};
InfoIcon.defaultProps = {
    className: '',
};

export default InfoIcon;
