import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box; 
}

  body {
    color:#313131;
    background: #2d3765;
    font-family: 'Poppins', 'Montserrat',  sans-serif;
    max-width: 100%;
    -web-kit-smoothing: antialiased;
  }

  h1 { 
    text-transform: uppercase;
    font-weight: 200;
    font-size: 2rem;
    color: #515151;
  }
  html, body, #root {
    margin: 0 auto;

    background-color: #fff;
    min-height: 100%;
    /* background: linear-gradient(90deg, #5BC5C8 -1.41%, #1188BC 99.97%, #1087BC 99.98%, #1188BC 99.99%, rgba(255, 255, 255, 0) 100%); */
  }

  button{
    cursor: pointer; 
  }

`;

export default GlobalStyles;
