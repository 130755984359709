import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { getCoverageCitiesByProgram } from 'api/coverageCities';
import { getProgramById } from 'api/programs';

function useCitiesForPrograms(programs) {
    const [cities, setCities] = useState([]);

    useEffect(() => {
        async function fetchCities() {
            const allCities = await Promise.all(
                programs.map(program => getCoverageCitiesByProgram(getProgramById(program.id)).get()),
            );
            const allNonEmptyCitiesPerPrograms = allCities.filter(cityQuery => cityQuery.size > 0);
            const uniqueCities = new Map();
            allNonEmptyCitiesPerPrograms.forEach(programCities => {
                programCities.docs.forEach(programCity => {
                    if (!uniqueCities.has(programCity.id)) {
                        uniqueCities.set(programCity.id, { id: programCity.id, ...programCity.data() });
                    }
                });
            });
            setCities(Array.from(uniqueCities.values()));
        }

        if (programs.length > 0) {
            fetchCities().catch(error => Sentry.captureException(error));
        }
    }, [programs]);

    return cities;
}

const CitiesList = ({ programs, className = '' }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const cities = useCitiesForPrograms(programs);

    const citiesDisplayed = isExpanded ? cities : cities.slice(0, 5);

    function handleToggleExpanded(event) {
        event.preventDefault();
        setIsExpanded(!isExpanded);
    }

    if (cities.length <= 0) {
        return <span>--</span>;
    }

    return (
        <div className={'tw-overflow-hidden tw-overflow-ellipsis ' + className}>
            {citiesDisplayed.map(city => (
                <span className="tw-mr-2" key={city.id}>
                    {city.name}
                </span>
            ))}

            {isExpanded ? (
                <button type="button" className="tw-text-purple" onClick={handleToggleExpanded}>
                    Recolher
                </button>
            ) : (
                <button type="button" className="tw-text-purple" onClick={handleToggleExpanded}>
                    Mais {cities.length - 5}
                </button>
            )}
        </div>
    );
};

export default CitiesList;
