import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCampaignList } from 'hooks/campaign';
import { Creators as ActionsCreators } from 'store/modules/campaign';
import { getCampaignsWithinStatus } from 'api/campaign';
import { handlePromiseError } from 'utils/handles';
import Campaigns from './Campaigns';
import * as S from './styles';

const defaultConverter = {
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return {
            id: snapshot.id,
            ...data,
        };
    },
};

function useCampaignsByStatus(status) {
    const dispatch = useDispatch();
    const campaigns = useCampaignList();

    useEffect(() => {
        const unsubscribe = getCampaignsWithinStatus(status)
            .orderBy('createdAt', 'desc')
            .withConverter(defaultConverter)
            .onSnapshot(documents => {
                const list = documents.docs.map(campaignDocument => campaignDocument.data());
                dispatch(ActionsCreators.getCampaignsSuccess(list));
            }, handlePromiseError('Erro ao listar campanhas'));

        return () => unsubscribe();
    }, [dispatch, status]);

    return campaigns;
}

const DashboardAdmin = () => {
    const campaigns = useCampaignsByStatus(['PENDING_APPROVE_CREATIVE']);

    return (
        <S.Container>
            <S.Title>Campanhas Pendentes</S.Title>
            <Campaigns campaigns={campaigns} />
        </S.Container>
    );
};

export default DashboardAdmin;
