import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { saveUserCreditCard } from 'base';
import schema from './schema';

const defaults = schema.getDefaultFromShape();

function useCreditCardForm(mutationOptions) {
    const { mutate: saveCreditCard, isLoading: isMutating } = useMutation(
        creditCard => saveUserCreditCard(creditCard),
        mutationOptions,
    );

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: defaults,
    });

    const onSubmit = (values, event) => {
        event.preventDefault();
        const { expireDate, ...creditCardData } = values;
        return saveCreditCard({
            expireDate: expireDate.getTime(),
            ...creditCardData,
        });
    };

    const { isSubmitting: isSubmittingForm } = methods.formState;

    return {
        ...methods,
        isLoading: isSubmittingForm || isMutating,
        onSubmit: methods.handleSubmit(onSubmit),
    };
}

export default useCreditCardForm;
