import { useEffect } from 'react';
import { useUserPrograms } from 'hooks/program';
import { useDispatch } from 'react-redux';
import { useUserId } from 'hooks/user';
import { useUserCompany } from 'hooks/company';
import { useRegions } from 'hooks/regions';
import { getProgramsByCompany } from 'store/modules/program';
import { getCompanyByOwnerId } from 'store/modules/company';
import { Creators as ActionCreators } from 'store/modules/regions';

function sortByProgramName(a, b) {
    return a.programName < b.programName ? -1 : 1;
}

export function useProgramsByCompany(companyId) {
    const programs = useUserPrograms();
    const dispatch = useDispatch();

    useEffect(() => {
        if (companyId) {
            dispatch(getProgramsByCompany(companyId));
        }
    }, [dispatch, companyId]);

    return programs.sort(sortByProgramName);
}

export function useCompanyForCurrentUser() {
    const dispatch = useDispatch();
    const userId = useUserId();
    const company = useUserCompany();

    useEffect(() => {
        if (userId) {
            dispatch(getCompanyByOwnerId(userId));
        }
    }, [userId, dispatch]);

    return company;
}

export function useRegiosByCompany(companyId) {
    const dispatch = useDispatch();
    const regions = useRegions();

    useEffect(() => {
        dispatch(ActionCreators.getRegions(companyId));
    }, [dispatch, companyId]);

    return regions;
}
