/* eslint-disable unicorn/no-useless-undefined */
import React from 'react';
import { FaCogs, FaPercent, FaTags } from 'react-icons/fa';
import CardIcon from './icons/CardIcon';
import PercentIcon from './icons/PercentIcon';

function Commercial() {
    return (
        <div className="tw-flex tw-gap-4 tw-gap-y-4 tw-mt-10 tw-flex-wrap tw-w-full md:tw-w-2/3">
            <CardIcon
                title="Descontos"
                to="/descontos"
                customClassName="tw-p-4 md:tw-w-1/5 tw-w-full"
                icon={<PercentIcon />}
            />
            <CardIcon
                title="Cupons"
                to="/cupons"
                customClassName="tw-p-4 md:tw-w-1/5 tw-w-full"
                icon={<FaPercent size={44} color="red" />}
            />
            <CardIcon
                title="Campanhas"
                to="/configuracao-de-campanha"
                customClassName="tw-p-4 md:tw-w-1/5 tw-w-full"
                icon={<FaCogs size={44} color="#7f4895" />}
            />
            <CardIcon
                title="Pacotes"
                to="/pacotes"
                customClassName="tw-p-4 md:tw-w-1/5 tw-w-full"
                icon={<FaTags size={44} color="#f59c16" />}
            />
        </div>
    );
}

export default Commercial;
