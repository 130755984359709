/* eslint-disable no-unused-vars */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-awesome-modal';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useWindowSize } from 'hooks/windowSize';
import welcomeSide from 'assets/welcome-side.png';
import Laptop from 'assets/images/laptop.png';
import Tv from 'assets/images/tv.png';
import Play from 'assets/images/play.png';
import Card from 'assets/images/card.png';
import CloseIcon from './icons/Close';
import Steps from './Steps';
import Dots from './Dots';
import One from './icons/One';
import Two from './icons/Two';
import Three from './icons/Three';
import Four from './icons/Four';
import styleConfig from '../../../../style_config.json';

const steps = [
    {
        id: 0,
        image: Laptop,
        title: 'Bem-vindo!',
        description: `Que bom que você está aqui na ${styleConfig['name']}! Veja como é fácil e prático anunciar na TV através da nossa plataforma.`,
    },
    {
        id: 1,
        image: Tv,
        title: 'Compra de mídia',
        description: `Compre o seu espaço na TV. Reserve uma parte da sua verba para a compra de mídia. Com investimentos a partir de R$400,00 você já pode ter o seu comercial na TV. \nDica da ${styleConfig['small_name']}: Para a sua campanha ter um resultado efetivo tenha frequência, como nas redes sociais você precisa marcar presença, na TV é da mesma forma. O ideal é que você renove suas campanhas mensalmente.`,
    },
    {
        id: 2,
        image: Play,
        title: 'Compra de criativo',
        description: `Compre o seu criativo. Reserve uma parte da sua verba para a compra do criativo. A nossa plataforma conta com uma equipe de editores. Basta escolher o template, mandar os seus arquivos e preencher o formulário de briefing.`,
    },
    {
        id: 3,
        image: Card,
        title: 'Pagamento',
        description: `Pague pelo seu comercial na TV de forma segura com o seu cartão de crédito.\nE aí, vamos lá fazer o seu anúncio na TV com a ${styleConfig['small_name']}?`,
    },
];

function Welcome() {
    const [step, setStep] = useState(0);
    const [openWelcome, setOpenWelcome] = useState(false);

    const [size] = useWindowSize();

    const toggleWelcomeModal = useCallback(() => setOpenWelcome(false), []);

    const goNextStep = useCallback(() => setStep(current => current + 1), []);

    const goPreviousStep = useCallback(() => setStep(current => current - 1), []);

    const handleChange = ({ target: { checked } }) => {
        if (checked) {
            localStorage.setItem('welcome', 'hidden');
        } else {
            localStorage.removeItem('welcome');
        }
    };

    useEffect(() => {
        const welcome = localStorage.getItem('welcome');
        if (!welcome) {
            setOpenWelcome(true);
        }
    }, []);

    return !size ? (
        ''
    ) : (
        <Modal
            visible={openWelcome}
            effect="fadeInUp"
            onClickAway={toggleWelcomeModal}
            width="720px"
            height={size > 768 ? '568px' : '100%'}
        >
            <div className="tw-w-full tw-flex tw-flex-col tw-h-full">
                <div className="tw-w-full tw-h-full tw-overflow-auto tw-flex tw-flex-row tw-relative">
                    <img className="tw-h-full tw-fixed tw-top-0 tw-bottom-0-0 tw-left-0" src={welcomeSide} alt="side" />
                    <CloseIcon className="tw-absolute tw-right-4 tw-top-4" onClick={toggleWelcomeModal} />
                    {step === 0 && <One className="tw-absolute tw-left-4 tw-top-4" />}
                    {step === 1 && <Two className="tw-absolute tw-left-4 tw-top-4" />}
                    {step === 2 && <Three className="tw-absolute tw-left-4 tw-top-4" />}
                    {step === 3 && <Four className="tw-absolute tw-left-4 tw-top-4" />}
                    <div className="tw-w-full tw-flex-1 tw-bg-white tw-p-4 tw-flex tw-items-center tw-justify-between">
                        <FaChevronLeft
                            size={25}
                            color="#C4C4C4"
                            onClick={goPreviousStep}
                            className={`tw-cursor-pointer ${step === 0 ? 'tw-invisible' : ''}`}
                        />
                        <div className="tw-w-full tw-max-w-lg">
                            <Steps steps={steps} activeStep={step} />
                        </div>
                        <FaChevronRight
                            size={25}
                            color="#C4C4C4"
                            onClick={goNextStep}
                            className={`tw-cursor-pointer ${step === 3 ? 'tw-invisible' : ''}`}
                        />
                    </div>
                </div>
                <div className="tw-w-full tw-flex tw-justify-center tw-flex-row tw-items-center tw-h-12">
                    <Dots step={step} />
                </div>
                <div className="tw-w-full tw-flex tw-justify-center tw-flex-row tw-items-center tw-h-12">
                    <input
                        id="welcome"
                        className="checked:tw-bg-pink tw-rounded-sm checked:border-transparent tw-border-gray-400"
                        type="checkbox"
                        name="welcome"
                        onChange={handleChange}
                    />
                    <p className="tw-ml-2 tw-text-gray-400">Não mostrar novamente</p>
                </div>
            </div>
        </Modal>
    );
}

export default Welcome;
