import React from 'react';

function Four({ className }) {
    return (
        <svg
            width="60"
            height="70"
            viewBox="0 0 60 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M0.992 59.44V41.68L29.6 0.879997H52.544V41.008H59.456V59.44H52.544V70H31.04V59.44H0.992ZM32.768 25.168L22.4 41.008H32.768V25.168Z"
                fill="#7DC5AF"
                fillOpacity="0.15"
            />
        </svg>
    );
}

export default Four;
