import React from 'react';
import { Link } from 'react-router-dom';
import PlusIcon from 'icons/PlusIcon';
import CreativeItem from './CreativeItem';

const CreativesList = ({ creatives }) => (
    <div className="tw-w-full tw-flex tw-flex-wrap">
        {creatives.map(creative => (
            <CreativeItem key={creative.id} creative={creative} />
        ))}
        <Link
            to="/central-criativa/criar"
            className="tw-w-full tw-max-w-xs tw-h-40 tw-bg-gray-100 tw-flex tw-place-items-center tw-justify-center tw-flex-col tw-rounded-md tw-px-6 tw-mt-5 tw-shadow-md hover:tw-shadow-lg"
        >
            <div className="tw-w-full tw-flex tw-justify-center tw-mb-1">
                <PlusIcon color="purple" />
            </div>
            <p className="tw-text-gray-700 tw-text-center tw-text-sm tw-font-normal">Comprar Novo Criativo</p>
        </Link>
    </div>
);
export default CreativesList;
