import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { updateCampaign, uploadCriativoFor } from 'api/campaign';
import { handlePromiseError } from 'utils/handles';
import UploadButton from 'components/UploadButton/UploadButton';

const CampaignItem = ({ campaign }) => {
    const [uploading, setUploading] = useState(false);
    const formHook = useForm({
        mode: 'onChange',
    });

    const onSubmit = useCallback(values => {
        console.log(values);
    }, []);

    const uploadCriativoForCampaign = useCallback(
        file => {
            setUploading(true);
            return uploadCriativoFor(campaign.id, file);
        },
        [campaign],
    );
    const handleUploadCompleted = useCallback(
        async (file, downloadedUrl) => {
            try {
                await updateCampaign(campaign.id, {
                    criativoDownloadUrl: downloadedUrl,
                    status: 'PENDING_APPROVE_CREATIVE',
                });

                toast.success('Criativo enviado!');
            } catch (error) {
                handlePromiseError('Erro ao enviar criativo')(error);
            } finally {
                setUploading(false);
            }
        },
        [campaign],
    );

    const startCampaignDate = campaign.objectivesAndDuration.startDate.toDate().toLocaleDateString();

    return (
        <TableRow>
            <TableCell scope="row">{campaign.objectivesAndDuration?.name}</TableCell>
            <TableCell scope="row">
                <span>{startCampaignDate}</span>
            </TableCell>
            <TableCell>
                <FormProvider {...formHook}>
                    <form onSubmit={formHook.handleSubmit(onSubmit)}>
                        <UploadButton
                            name="criativoFile"
                            text="Selecione arquivo"
                            uploadAction={uploadCriativoForCampaign}
                            onCompleted={handleUploadCompleted}
                            disabled={uploading}
                        />
                    </form>
                </FormProvider>
            </TableCell>
        </TableRow>
    );
};

const Campaigns = ({ campaigns }) => (
    <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableBody>
                {campaigns.map(campaign => (
                    <CampaignItem key={campaign.id} campaign={campaign} />
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);
export default Campaigns;
