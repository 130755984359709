import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeftIcon = ({ className = '', ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 18"
        fill="none"
        stroke="currentColor"
        className={`icon ${className}`}
        {...rest}
    >
        <path
            fill="#0a191e"
            fillOpacity="0.6"
            d="M16,4a1.07,1.07,0,0,1,.73.29.94.94,0,0,1,0,1.39L9.54,12.54l7.19,6.83a1,1,0,0,1,0,1.39,1.07,1.07,0,0,1-1.47,0L7.34,13.25a1,1,0,0,1,0-1.4l7.92-7.51A1.06,1.06,0,0,1,16,4Z"
            transform="translate(-7.04 -3.04)"
        />
        <path
            fill="#fff"
            fillOpacity="0.9"
            d="M16,3a1.07,1.07,0,0,1,.73.29.94.94,0,0,1,0,1.39L9.54,11.54l7.19,6.83a1,1,0,0,1,0,1.39,1.07,1.07,0,0,1-1.47,0L7.34,12.25a1,1,0,0,1,0-1.4l7.92-7.51A1.06,1.06,0,0,1,16,3Z"
            transform="translate(-7.04 -3.04)"
        />
    </svg>
);

ArrowLeftIcon.propTypes = {
    className: PropTypes.string,
};

export default ArrowLeftIcon;
