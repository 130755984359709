import React, { memo, useMemo, useReducer } from 'react';
import moment from 'moment';
import { currencyFormatter } from 'utils';
import { CAMPAIGN_STATUS, getInsertionsCount } from 'services/advertiser/campaign';
import CampaignInfo from 'organisms/CampaignInfo';
import StepsIconsInfo from './StepsIconsInfo';
import ClipIcon from './icons/ClipIcon';
import PaymentModal from './PaymentModal';
import CreativeModal from './CreativeModal';
import AlertIcon from './icons/AlertIcon';
import ProgramInsertions from './ProgramInsertions';
import ProgramsTableWithApprovedDates from 'components/ProgramsTableWithApprovedDates';

const PendingMessage = memo(({ status }) => {
    switch (status) {
        case CAMPAIGN_STATUS.pendingPayment:
            return (
                <div className="tw-flex tw-item-center tw-p-3 tw-w-auto tw-bg-white tw-border tw-border-orange tw-rounded-md tw-mb-3">
                    <AlertIcon className="tw-mr-3" />
                    <p>
                        <span className="tw-font-bold tw-text-gray-dark">Ação Necessária</span>: Campanha com pendência
                        de&nbsp;
                        <span className="tw-text-pink tw-font-bold">PAGAMENTO</span>. Clique no botão{' '}
                        <span className="tw-text-pink tw-font-bold">{`"Pagar Agora"`}</span> para efetuar o pagamento.
                    </p>
                </div>
            );
        case CAMPAIGN_STATUS.pendingCreative:
            return (
                <div className="tw-flex tw-item-center tw-p-3 tw-w-auto tw-bg-white tw-border tw-border-orange tw-rounded-md tw-mb-3">
                    <AlertIcon className="tw-mr-3" />
                    <p>
                        <span className="tw-font-bold tw-text-gray-dark">Ação Necessária</span>: Campanha com pendência
                        de&nbsp;
                        <span className="tw-text-pink tw-font-bold">CRIATIVO</span>. Clique no botão{' '}
                        <span className="tw-text-pink tw-font-bold">{`"Anexar Criativo"`}</span> para anexar um
                        criativo.
                    </p>
                </div>
            );
        default:
            return <></>;
    }
});
PendingMessage.displayName = 'PendingMessage';

const PendingCampaignRow = ({ campaign, program, objectivesAndDuration }) => {
    const totalInsertionsOnProgram = getInsertionsCount(program);
    const getInsertionsTotalValue = program => {
        return program.insertionValue * totalInsertionsOnProgram;
    };
    const insertionsTotalValue = getInsertionsTotalValue(program);

    const hasInstallments = campaign.installments > 1;
    const amount = hasInstallments ? insertionsTotalValue / campaign.installments : insertionsTotalValue;

    const getProgramsPackageInsertions = () =>
        campaign?.packageItem?.programs?.find(programItem => programItem.programId === program.id)?.insertionQuantity;

    return (
        <tr>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                <p>{program.programName}</p>
            </td>
            {!campaign?.packageItem && (
                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                    <ProgramInsertions program={program} />
                </td>
            )}
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1 tw-text-center">
                {totalInsertionsOnProgram || getProgramsPackageInsertions()}
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1 tw-text-center">
                {objectivesAndDuration?.duration}
            </td>
            {!campaign?.packageItem && (
                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                    {hasInstallments && `${campaign.installments}x `}
                    {currencyFormatter.format(amount)}
                </td>
            )}
        </tr>
    );
};

const ProgramsTable = ({ children, campaign }) => {
    return (
        <table className="tw-w-full tw-mt-6">
            <thead>
                <tr>
                    <th className="tw-text-purple tw-text-left" align="left">
                        Programa
                    </th>
                    {!campaign?.packageItem && <th className="tw-text-purple tw-text-left">Dias de Veiculação</th>}
                    <th className="tw-text-purple tw-text-center">Inserções</th>
                    <th className="tw-text-purple tw-text-center">Duração</th>
                    {!campaign?.packageItem && <th className="tw-text-purple tw-text-left">Valor</th>}
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    );
};

const CampaignDetails = ({ campaign }) => {
    const [openPaymentModal, toggleOpenPaymentModal] = useReducer(state => !state, false);
    const [openCreativeModal, toggleOpenCreativeModal] = useReducer(state => !state, false);

    const allPrograms = useMemo(
        () => [
            ...(campaign.mediaCentral?.selectedTVPrograms || []),
            ...(campaign.mediaCentral?.selectedRadioPrograms || []),
        ],
        [campaign.mediaCentral?.selectedRadioPrograms, campaign.mediaCentral?.selectedTVPrograms],
    );

    const campaignIsPendingApproveCreative = campaign.status === CAMPAIGN_STATUS.pendingApproveCreative;
    const campaignIsPendingPayment = campaign.status === CAMPAIGN_STATUS.pendingPayment;
    const campaignIsPendingCreative = campaign.status === CAMPAIGN_STATUS.pendingCreative;
    const campaignIsPendingApproveInsertions = campaign.status === CAMPAIGN_STATUS.pendingApproveInsertions;

    function onPayment() {
        window.location.reload();
    }

    return (
        <tr className="tw-bg-white tw-border tw-shadow-md tw-p-3 tw-translate-x-2">
            <td colSpan={5} className="tw-p-5 border tw-bg-white tw-rounded-xl">
                <PendingMessage status={campaign.status} />
                <CampaignInfo campaign={campaign} />
                {campaign?.approvedDates?.length ? (
                    <ProgramsTableWithApprovedDates campaign={campaign} />
                ) : (
                    <ProgramsTable campaign={campaign}>
                        {allPrograms.map(program => (
                            <PendingCampaignRow
                                key={program.id}
                                campaign={campaign}
                                program={program}
                                objectivesAndDuration={campaign.objectivesAndDuration}
                            />
                        ))}
                    </ProgramsTable>
                )}
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-mt-3">
                    <StepsIconsInfo campaign={campaign} />

                    {campaignIsPendingApproveInsertions && (
                        <div className="tw-border tw-border-gray-light tw-bg-gray-100 tw-text-gray-900 tw-rounded-3xl tw-p-2">
                            Aguardando Aprovação das Inserções. Prazo:{' '}
                            <strong>
                                {`${moment.utc(campaign?.createdAt?.toDate()).add(2, 'd').format('DD/MM')} - ${moment
                                    .utc(campaign?.createdAt?.toDate())
                                    .format('HH:mm')} `}
                            </strong>
                        </div>
                    )}

                    {campaignIsPendingApproveCreative && (
                        <div className="tw-flex">
                            {campaign.creativeName && (
                                <div className="tw-flex tw-items-center tw-justify-between tw-border tw-border-gray-light tw-bg-white tw-text-gray-900 tw-rounded-3xl tw-p-2">
                                    <ClipIcon color="gray" />
                                    <span className="tw-ml-2">{campaign.creativeName}</span>
                                </div>
                            )}
                            <div className="tw-ml-3 tw-border tw-border-gray-light tw-bg-gray-100 tw-text-gray-900 tw-rounded-3xl tw-p-2">
                                Aguardando Aprovação do Criativo. Prazo:{' '}
                                <strong>
                                    {`${moment
                                        .utc(campaign?.createdAt?.toDate())
                                        .add(4, 'd')
                                        .format('DD/MM')} - ${moment
                                        .utc(campaign?.createdAt?.toDate())
                                        .format('HH:mm')} `}
                                </strong>
                            </div>
                        </div>
                    )}

                    {campaignIsPendingPayment && !campaign?.packageItem && (
                        <>
                            <button
                                className="tw-border-none tw-rounded-md tw-bg-pink tw-text-white tw-font-bold tw-px-5 tw-py-0 tw-h-10"
                                onClick={toggleOpenPaymentModal}
                            >
                                $ Pagar Agora
                            </button>
                        </>
                    )}

                    {campaign?.packageItem && campaignIsPendingPayment && <h3>Aguardando pagamento via PIX</h3>}

                    {campaignIsPendingCreative && (
                        <button
                            className="tw-flex tw-items-center tw-border-none tw-rounded-md tw-bg-pink tw-text-white tw-font-bold tw-px-5 tw-py-0 tw-h-10"
                            onClick={toggleOpenCreativeModal}
                        >
                            <ClipIcon />
                            <span className="tw-ml-3">Anexar Criativo</span>
                        </button>
                    )}

                    {openPaymentModal && (
                        <PaymentModal onClose={toggleOpenPaymentModal} onPayment={onPayment} campaign={campaign} />
                    )}
                    <CreativeModal
                        openCreativeModal={openCreativeModal}
                        toggleOpenCreativeModal={toggleOpenCreativeModal}
                        campaign={campaign}
                    />
                </div>
            </td>
        </tr>
    );
};

export default CampaignDetails;
