import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { useCurrentCampaign, usePrograms } from 'hooks/campaign';
import { useUser } from 'hooks/user';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import { useCimtia } from '../../../../../../context/cimtia';

function filterProgramsByType(programsToFilter, type) {
    return programsToFilter?.filter(program => program.programType === type) || [];
}

function useAutomaticMode(enabled) {
    const {
        targetAndSegmentation,
        objectivesAndDuration: { duration, city, campaignWeeks, objective },
        localization,
        budget,
    } = useCurrentCampaign();
    const { filterPrograms } = useCimtia();
    const programs = usePrograms();
    const user = useUser();
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchPrograms() {
            const targered = await filterPrograms(
                {
                    targetAndSegmentation: {
                        segment: targetAndSegmentation.segment,
                        bestTime: targetAndSegmentation.bestTime,
                        gender: targetAndSegmentation.gender,
                        socialClass: targetAndSegmentation.socialClass,
                        ageRange: `${targetAndSegmentation?.ageRange?.min || 0},${
                            targetAndSegmentation?.ageRange?.max || 100
                        }`,
                    },
                    objectivesAndDuration: {
                        duration,
                        city,
                        campaignWeeks,
                        objective,
                    },
                    localization,
                    budget,
                },
                programs,
                user.cnpj,
            );

            const mediaCentral = {
                selectedTVPrograms: filterProgramsByType(targered, 'TV'),
                selectedRadioPrograms: filterProgramsByType(targered, 'RADIO'),
            };
            dispatch(CampaignCreators.addMediaCentral(mediaCentral));

            if (targered.length === 0) {
                dispatch(
                    CampaignCreators.addMediaCentral({
                        selectedTVPrograms: [],
                        selectedRadioPrograms: [],
                    }),
                );
            }
        }

        if (enabled) {
            fetchPrograms().catch(error => {
                Sentry.captureException(error);
            });
        }
    }, [
        user,
        dispatch,
        programs,
        filterPrograms,
        enabled,
        duration,
        city,
        localization,
        budget,
        targetAndSegmentation,
        campaignWeeks,
        objective,
    ]);
}

export default useAutomaticMode;
