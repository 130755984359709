import React, { useState } from 'react';

function AlertIcon({ className, message }) {
    const [showMessage, setShowMessage] = useState(false);

    const toggleMessage = () => setShowMessage(state => !state);

    return (
        <div className="tw-relative tw-cursor-pointer">
            {showMessage && <h1>{message}</h1>}
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                onClick={toggleMessage}
            >
                <g clipPath="url(#clip0)">
                    <path
                        d="M10.0021 20.0042C15.5261 20.0042 20.0042 15.5261 20.0042 10.0021C20.0042 4.4781 15.5261 0 10.0021 0C4.4781 0 0 4.4781 0 10.0021C0 15.5261 4.4781 20.0042 10.0021 20.0042Z"
                        fill="#E79E2D"
                    />
                    <path
                        d="M9.04828 10.652L8.76552 6.42325C8.71065 5.60029 8.68533 5.00945 8.68533 4.65073C8.68533 4.16117 8.81194 3.78134 9.06938 3.50703C9.32682 3.23271 9.66444 3.09766 10.078 3.09766C10.5845 3.09766 10.9221 3.27491 11.0951 3.62097C11.2639 3.97126 11.3526 4.47769 11.3526 5.13606C11.3526 5.52433 11.3315 5.92104 11.2892 6.31774L10.9094 10.6689C10.8672 11.188 10.7786 11.5847 10.6435 11.859C10.5085 12.1333 10.2848 12.2726 9.97252 12.2726C9.656 12.2726 9.43232 12.1375 9.30994 11.8717C9.18755 11.6058 9.09892 11.2006 9.04828 10.652ZM10.0274 16.4591C9.66866 16.4591 9.35636 16.3452 9.08626 16.1088C8.82038 15.8767 8.68533 15.5518 8.68533 15.134C8.68533 14.771 8.81194 14.4587 9.06938 14.2013C9.32682 13.9438 9.63912 13.8172 10.0105 13.8172C10.3819 13.8172 10.6984 13.9438 10.9601 14.2013C11.2217 14.4587 11.3526 14.7668 11.3526 15.134C11.3526 15.5475 11.2217 15.8683 10.9558 16.1046C10.69 16.341 10.3819 16.4591 10.0274 16.4591Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

export default AlertIcon;
