import React from 'react';
import BahiaPathIcon from './icons/BahiaPathIcon';
import TocantinsPathIcon from './icons/TocantinsPathIcon';
import SergipePathIcon from './icons/SergipePathIcon';
import PernambucoPathIcon from './icons/PernambucoPathIcon';
import AlagoasPathIcon from './icons/AlagoasPathIcon';
import RioGrandeDoNortePathIcon from './icons/RioGrandeDoNortePathIcon';
import CearaPathIcon from './icons/CearaPathIcon';
import PiauiPathIcon from './icons/PiauiPathIcon';
import MaranhaoPathIcon from './icons/MaranhaoPathIcon';
import AmapaPathIcon from './icons/AmapaPathIcon';
import ParaPathIcon from './icons/ParaPathIcon';
import RoraimaPathIcon from './icons/RoraimaPathIcon';
import AmazonasPathIcon from './icons/AmazonasPathIcon';
import AcrePathIcon from './icons/AcrePathIcon';
import RondoniaPathIcon from './icons/RondoniaPathIcon';
import MatoGrossoPathIcon from './icons/MatoGrossoPathIcon';
import MatoGrossoDoSulPathIcon from './icons/MatoGrossoDoSulPathIcon';
import GoiasPathIcon from './icons/GoiasPathIcon';
import ParanaPathIcon from './icons/ParanaPathIcon';
import SantaCatarinaPathIcon from './icons/SantaCatarinaPathIcon';
import RioGrandeDoSulPathIcon from './icons/RioGrandeDoSulPathIcon';
import SaoPauloPathIcon from './icons/SaoPauloPathIcon';
import MinasGeraisPathIcon from './icons/MinasGeraisPathIcon';
import RioDeJaneiroPathIcon from './icons/RioDeJaneiroPathIcon';
import EspiritoSantoPathIcon from './icons/EspiritoSantoPathIcon';
import DistritoFederalPathIcon from './icons/DistritoFederalPathIcon';
import ParaibaPathIcon from './icons/ParaibaPathIcon';

const icons = {
    BA: BahiaPathIcon,
    TO: TocantinsPathIcon,
    SE: SergipePathIcon,
    PE: PernambucoPathIcon,
    AL: AlagoasPathIcon,
    RN: RioGrandeDoNortePathIcon,
    CE: CearaPathIcon,
    PI: PiauiPathIcon,
    MA: MaranhaoPathIcon,
    AP: AmapaPathIcon,
    PA: ParaPathIcon,
    RR: RoraimaPathIcon,
    AM: AmazonasPathIcon,
    AC: AcrePathIcon,
    RO: RondoniaPathIcon,
    MT: MatoGrossoPathIcon,
    MS: MatoGrossoDoSulPathIcon,
    GO: GoiasPathIcon,
    PR: ParanaPathIcon,
    SC: SantaCatarinaPathIcon,
    RS: RioGrandeDoSulPathIcon,
    SP: SaoPauloPathIcon,
    MG: MinasGeraisPathIcon,
    RJ: RioDeJaneiroPathIcon,
    ES: EspiritoSantoPathIcon,
    DF: DistritoFederalPathIcon,
    PB: ParaibaPathIcon,
};

const StateLink = ({ name, code, transform, active, onSelect, disabled, availableStates }) => {
    const Icon = icons[code];
    const isAvailable = availableStates.has(code);
    const handleClick = event => {
        event.preventDefault();
        if (!disabled) {
            onSelect(name, code);
        }
    };
    return (
        <a href="#" onClick={handleClick}>
            <Icon active={active} available={isAvailable} />
            <text transform={transform} fill="#FFFFFF">
                {code}
            </text>
        </a>
    );
};

export default StateLink;
