import { firebase, firestore, storage } from 'base';

export function getCampaignsByUserId(id) {
    return firestore.collection('campaigns').where('userId', '==', id).orderBy('createdAt', 'desc').get();
}

/**
 * Get all campaigns
 * @returns {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>}
 */
export function getAllCampaigns() {
    return firestore.collection('campaigns');
}

/**
 * Get all campaigns for companies
 * @param {firebase.firestore.DocumentReference[]} companies
 * @returns {firebase.firestore.Query<T>}
 */
export function getAllCampaignsForCompanies(companies) {
    return getAllCampaigns().where('selectedCompanies', 'array-contains-any', companies);
}

/**
 * Get all campaigns that has one of status
 * @param {string[]} status - List of allowed status
 * @returns {firebase.firestore.Query<firebase.firestore.DocumentData>}
 */
export function getCampaignsWithinStatus(status) {
    return getAllCampaigns().where('status', 'in', status);
}

export function getCampaignsByStatus(status) {
    let query = getAllCampaigns();
    if (status) {
        query = query.where('status', '==', status);
    }
    return query;
}

/**
 * Get campaigns within status      'PENDING_APPROVE_INSERTIONS', 'PENDING_APPROVE_CREATIVE', 'PENDING_CREATIVE', 'APPROVED', 'REFUSED' and 'FINISHED'
 * @returns {Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>}
 */
export function getCampaigns() {
    return getCampaignsWithinStatus([
        'PENDING_APPROVE_INSERTIONS',
        'PENDING_APPROVE_CREATIVE',
        'PENDING_CREATIVE',
        'APPROVED',
        'REFUSED',
        'FINISHED',
    ])
        .orderBy('createdAt', 'desc')
        .get();
}

export async function addCampaign(data) {
    const documentReference = firestore.collection('campaigns').doc();
    await documentReference.set(data, { merge: true });
    return documentReference;
}

/**
 * Update campaign
 * @param {string} id - The campaign ID
 * @param {Object} data - The data to update campaign
 * @returns {Promise<void>}
 */
export function updateCampaign(id, data) {
    return firestore
        .collection('campaigns')
        .doc(id)
        .set({ ...data, updatedAt: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true });
}

export function updateCriativosForCampaign(id, criativos) {
    return firestore.collection('campaigns').doc(id).update('creativeIds', criativos);
}

/**
 * Upload criativo into campaign
 * @param {string} campaignId - The campaign ID
 * @param {File} imageFile - The image file
 */
export function uploadCriativoFor(campaignId, imageFile) {
    return storage.ref(`campaign/${campaignId}/${imageFile.name}`).put(imageFile);
}

/**
 * Upload criativo file
 * @param {string} category - The category TV or RADIO
 * @param {File} imageFile - The image file
 */
export function uploadCriativoFile(category, imageFile) {
    return storage.ref(`criativo/${category}/${imageFile.name}`).put(imageFile);
}

export const deleteCampaign = async id => {
    return firestore.collection('campaigns').doc(id).delete();
};

export function getCampaignById(id) {
    return firestore.collection('campaigns').doc(id).get();
}
