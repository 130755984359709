import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loading from 'react-fullscreen-loading';
import { useQuery } from 'hooks/query';
import { confirmEmail } from 'api/users';
import { signOut } from 'store/modules/auth';
import styleConfig from '../../../style_config.json';

function ConfirmAccount() {
    const [loading, setLoading] = useState(true);
    const [failedToVerify, setFailedToVerify] = useState(false);

    const dispatch = useDispatch();

    const handleSignOut = () => {
        dispatch(signOut());
        window.open('/', '_self');
    };

    const handleConfirmEmail = useCallback(async (code, key) => {
        try {
            await confirmEmail(code, key);
            setLoading(false);
        } catch {
            setLoading(false);
            setFailedToVerify(true);
        }
    }, []);

    const oobCode = useQuery().get('oobCode');
    const apiKey = useQuery().get('apiKey');

    useEffect(() => {
        if (oobCode && apiKey) {
            handleConfirmEmail(oobCode, apiKey);
        }
    }, [handleConfirmEmail, oobCode, apiKey]);

    return (
        <div className="tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center">
            <Loading loading={loading} background="rgba(0,0,0,0.1)" loaderColor="#E5195A" tex />
            {!loading && (
                <div className="tw-max-w-sm tw-flex tw-flex-col">
                    <div className="tw-flex tw-justify-center tw-place-content-center tw-items-center">
                        <img
                            src={styleConfig['logo-vertical.svg']}
                            alt="Logo vertical"
                            className="tw-w-2/6 tw-h-20 tw-max-w-max tw-fill-current tw-text-white"
                        />
                    </div>
                    <div className="tw-p-4 tw-flex tw-flex-col tw-justify-between tw-h-full">
                        <h2 className="tw-text-center">
                            {!failedToVerify
                                ? 'Conta verificada com sucesso!'
                                : 'Ops, não foi possível verificar a conta'}
                        </h2>
                    </div>
                    <button
                        className="tw-w-full tw-max-w-xs button button-primary tw-self-center tw-mt-4"
                        onClick={handleSignOut}
                    >
                        Voltar ao login
                    </button>
                </div>
            )}
        </div>
    );
}

export default ConfirmAccount;
