/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentCampaign } from 'hooks/campaign';
import StateLink from './StateLink';
import brazilianStates from './BrazilianStates.json';
import './styles.css';

const BrazilMap = ({ onSelect, children, className = '', disabled, availableStates }) => {
    const {
        localization: { code },
    } = useCurrentCampaign();

    return (
        <div className={`tw-relative ${className}`}>
            <svg
                version="1.1"
                id="svg-map"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                style={{ width: '100%' }}
                height="460px"
                viewBox="0 0 450 460"
                enableBackground="new 0 0 450 460"
                space="preserve"
            >
                <g>
                    {brazilianStates.map(state => (
                        <StateLink
                            key={state.code}
                            name={state.name}
                            code={state.code}
                            className="estado"
                            onSelect={onSelect}
                            disabled={disabled}
                            active={code === state.code}
                            transform={state.transform}
                            availableStates={availableStates}
                        />
                    ))}
                </g>
            </svg>
            {children}
        </div>
    );
};

BrazilMap.propTypes = {
    /**
     * Trigger once user select a state
     */
    onSelect: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    className: PropTypes.string,
};

BrazilMap.defaultProps = {
    className: '',
};

export default BrazilMap;
