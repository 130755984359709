import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

function CheckIcon({ className, color, message: Message }) {
    const [showMessage, setShowMessage] = useState(false);

    const toggleMessage = () => setShowMessage(state => !state);

    return (
        <div className="tw-relative tw-cursor-pointer">
            {showMessage && Message && (
                <OutsideClickHandler onOutsideClick={toggleMessage}>
                    <Message />
                </OutsideClickHandler>
            )}
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                onClick={setShowMessage}
            >
                <g clipPath="url(#clip0)">
                    <path
                        d="M10.0021 20.0042C15.5261 20.0042 20.0042 15.5261 20.0042 10.0021C20.0042 4.4781 15.5261 0 10.0021 0C4.4781 0 0 4.4781 0 10.0021C0 15.5261 4.4781 20.0042 10.0021 20.0042Z"
                        fill={color || '#D8D8D8'}
                    />
                    <path
                        d="M14.8417 5L8.25 11.7158L5.15833 8.82105L3 10.9632L8.25 16L17 7.14211L14.8417 5Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

export default CheckIcon;
