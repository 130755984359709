import styled from 'styled-components';

import { darken } from 'polished';

export const Column = styled.div`
    width: 100%;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const EditProfileFormWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const Title = styled.h1`
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 2rem;
    span {
        font-weight: 800;
    }
`;

export const LoginForm = styled.form`
    width: 100%;
    display: flex;
`;

export const FormGroup = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0 1.75rem 0;
`;

export const Checkbox = styled.input``;

export const Input = styled.input`
    font-weight: 400;
    border-radius: 0.45rem;
    font-size: 16px;
    padding: 10px 10px 10px 5px;
    display: block;
    border: solid 1px #eee;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    &:focus {
        outline: none;
    }
`;

export const Label = styled.label`
    color: #4163a7;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 1rem;
    pointer-events: none;

    transition: 0.2s ease all;
`;

export const ErrorMessage = styled.small`
    color: #ff0000;
`;

export const CheckboxGroup = styled.div`
    max-width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;

    input {
        color: green;
        width: 20px;
        height: 20px;
        ~ label {
            position: absolute;
            left: 2.25rem;
            font-size: 1.5rem;
            top: 0;
        }
    }
`;

export const Button = styled.button`
    border-radius: 0.45rem;
    width: 100%;
    padding: 10px 10px 10px 5px;
    margin-bottom: 3rem;
    background-color: #4163a7;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: unset;
    transition: all 0.3s ease-in;
    &:hover {
        background-color: ${darken(0.1, '#4163a7')};
    }
`;

export const ButtonSecondary = styled.button`
    border-radius: 0.45rem;
    width: 100%;
    padding: 10px 10px 10px 5px;
    margin-bottom: 3rem;
    background: unset;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: solid 1px #fff;
    transition: all 0.3s ease-in;
    &:hover {
        color: ${darken(0.3, '#4163a7')};
        border: solid 1px ${darken(0.3, '#4163a7')};
    }
`;

export const PaymentSelect = styled.div`
    background: #eee;
    padding: 3rem;
    margin: 1rem;
    border-radius: 1.75rem;
    box-shadow: 1px 1px rba(0, 0, 0, 0.1);
`;
