import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import schema from './schema';

const schemaDefaults = schema.getDefaultFromShape();

function useTargetAndSegmentationForm() {
    const dispatch = useDispatch();

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: schemaDefaults,
    });

    const onSubmit = values => {
        dispatch(CampaignCreators.addTargetAndSegmentation(values));
        dispatch(CampaignCreators.unlockStep('targetAndSegmentation'));
        dispatch(CampaignCreators.nextStep());
        dispatch(CampaignCreators.nextStep());
    };

    return { ...methods, onSubmit: methods.handleSubmit(onSubmit) };
}

export default useTargetAndSegmentationForm;
