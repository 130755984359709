import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { useCampaignList } from 'hooks/campaign';
import { useUser } from 'hooks/user';
import { Creators as ActionsCreators } from 'store/modules/campaign';
import { defaultConverter } from 'utils';
import { getAllCampaignsForCompanies } from 'api/campaign';
import { getCompaniesByOwner } from 'api/companies';
import CampaignRow from './CampaignRow';
import PendingCampaignRow from './PendingCampaignRow';

const statusTypes = {
    pendingPayment: 'PENDING_PAYMENT',
    pendingApproveInsertions: 'PENDING_APPROVE_INSERTIONS',
    pendingCreative: 'PENDING_CREATIVE',
    pendingApproveCreative: 'PENDING_APPROVE_CREATIVE',
    approved: 'APPROVED',
    refused: 'REFUSED',
    finished: 'FINISHED',
};

const SUPPLIER_STATUS = [
    statusTypes.pendingApproveInsertions,
    statusTypes.pendingApproveCreative,
    statusTypes.pendingCreative,
    statusTypes.approved,
    statusTypes.refused,
    statusTypes.finished,
];

const tableStyle = { borderSpacing: '0 12px' };

function useCampaigns(status) {
    const dispatch = useDispatch();
    const campaigns = useCampaignList();
    const user = useUser();

    useEffect(() => {
        let unsubscribe;
        async function fetchCompanies() {
            const currentCompanyReferences = getCompaniesByOwner(user.id);
            const currentCompanySnapshot = await currentCompanyReferences.get();
            const companies = currentCompanySnapshot.docs.map(snap => snap.ref);
            console.log('companies', companies);
            unsubscribe = getAllCampaignsForCompanies(companies)
                .orderBy('createdAt', 'desc')
                .withConverter(defaultConverter)
                .onSnapshot(querySnapshot => {
                    const list = querySnapshot.docs.map(document_ => document_.data());
                    dispatch(ActionsCreators.getCampaignsSuccess(list));
                });
        }

        fetchCompanies().catch(error => {
            Sentry.captureException(error);
            toast.error(error.message || 'Falha ao buscar campanhas');
        });

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [dispatch, status, user.id]);

    return campaigns;
}

const CampingTable = ({ title, children, colStatusTitle = 'Status' }) => (
    <div className="tw-w-full tw-flex tw-flex-col tw-mb-10">
        <h1 className="tw-text-pink tw-normal-case tw-font-bold">{title}</h1>
        <div className="tw-w-full tw-overflow-x-auto">
            <table className="tw-border-separate tw-w-full" style={tableStyle}>
                <thead>
                    <tr>
                        <th className="tw-text-purple tw-text-left" align="left">
                            Nome da Campanha
                        </th>
                        <th className="tw-text-purple tw-text-left" align="left">
                            Nome da Empresa
                        </th>
                        <th className="tw-text-purple tw-text-left">Período de Veiculação</th>
                        <th className="tw-text-purple tw-text-center">Inserções</th>
                        <th className="tw-text-purple tw-text-left">Valor Total</th>
                        <th className="tw-text-purple tw-text-left">{colStatusTitle}</th>
                    </tr>
                </thead>
                {children}
            </table>
        </div>
    </div>
);

const PENDING_STATUS = new Set([
    statusTypes.pendingApproveCreative,
    statusTypes.pendingApproveInsertions,
    statusTypes.pendingCreative,
]);
const APPROVED_STATUS = new Set([statusTypes.approved]);
const FINISHED_STATUS = new Set([statusTypes.finished, statusTypes.refused]);

const ListCampaign = () => {
    const campaigns = useCampaigns(SUPPLIER_STATUS);

    const pendingCampaigns = campaigns.filter(campaign => PENDING_STATUS.has(campaign.status));
    const approvedCampaigns = campaigns.filter(campaign => APPROVED_STATUS.has(campaign.status));
    const finishedCampaigns = campaigns.filter(campaign => FINISHED_STATUS.has(campaign.status));

    return (
        <>
            <CampingTable title="Pendentes" colStatusTitle="Tipo Pend.">
                {pendingCampaigns.length > 0 ? (
                    <tbody>
                        {pendingCampaigns.map(campaign => (
                            <PendingCampaignRow key={campaign.id} campaign={campaign} />
                        ))}
                    </tbody>
                ) : (
                    <p className="tw-py-2 tw-text-gray">Nenhuma até o momento.</p>
                )}
            </CampingTable>

            <CampingTable title="Ativas">
                {approvedCampaigns.length > 0 ? (
                    <tbody>
                        {approvedCampaigns.map(campaign => (
                            <CampaignRow key={campaign.id} campaign={campaign} />
                        ))}
                    </tbody>
                ) : (
                    <p className="tw-py-2 tw-text-gray">Nenhuma até o momento.</p>
                )}
            </CampingTable>

            <CampingTable title="Finalizadas">
                {finishedCampaigns.length > 0 ? (
                    <tbody>
                        {finishedCampaigns.map(campaign => (
                            <CampaignRow key={campaign.id} campaign={campaign} />
                        ))}
                    </tbody>
                ) : (
                    <p className="tw-py-2 tw-text-gray">Nenhuma até o momento.</p>
                )}
            </CampingTable>
        </>
    );
};

export default ListCampaign;
