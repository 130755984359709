export const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

export function cleanCnpj(cnpj) {
    if (!cnpj) return '';
    return cnpj.replace(/[^\s\w]/gi, '');
}

export function isCnpjValid(cnpj) {
    if (!cnpj) return false;

    cnpj = cnpj.replace(/\D+/g, '');

    if (!cnpj) return false;

    if (cnpj.length !== 14) return false;

    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    )
        return false;

    // Valida DVs
    let size = cnpj.length - 2;
    let numbers = cnpj.slice(0, Math.max(0, size));
    const digits = cnpj.slice(Math.max(0, size));
    let sum = 0;
    let pos = size - 7;
    for (let index = size; index >= 1; index--) {
        sum += numbers.charAt(size - index) * pos--;
        if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result != digits.charAt(0)) return false;

    size = size + 1;
    numbers = cnpj.slice(0, Math.max(0, size));
    sum = 0;
    pos = size - 7;
    for (let index = size; index >= 1; index--) {
        sum += numbers.charAt(size - index) * pos--;
        if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result != digits.charAt(1)) return false;

    return true;
}

/**
 * Validate CPF
 * @see https://gist.github.com/joaohcrangel/8bd48bcc40b9db63bef7201143303937#gistcomment-3781326
 * @param {string} cpf - CPF to validate
 * @returns {boolean}
 */
export function isCPFValid(cpf) {
    // Validar se é String
    if (typeof cpf !== 'string') return false;

    // Tirar formatação
    cpf = cpf.replace(/\D+/g, '');

    // Validar se tem tamanho 11 ou se é uma sequência de digitos repetidos
    if (cpf.length !== 11 || !!/(\d)\1{10}/.test(cpf)) return false;

    // String para Array
    cpf = cpf.split('');

    const validator = cpf
        // Pegar os últimos 2 digitos de validação
        .filter((digit, index, array) => index >= array.length - 2 && digit)
        // Transformar digitos em números
        .map(element => +element);

    const toValidate = pop =>
        cpf
            // Pegar Array de items para validar
            .filter((digit, index, array) => index < array.length - pop && digit)
            // Transformar digitos em números
            .map(element => +element);

    const rest = (count, pop) =>
        ((toValidate(pop)
            // Calcular Soma dos digitos e multiplicar por 10
            .reduce((soma, element, index) => soma + element * (count - index), 0) *
            10) %
            // Pegar o resto por 11
            11) %
        // transformar de 10 para 0
        10;

    return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
}

/**
 * Format string with CNPJ mask
 * @param {string} cnpj - The raw CNPJ
 * @returns {string}
 */
export function formatCNPJ(cnpj) {
    if (!cnpj) return '';
    return cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}

/**
 * Remove all accepts from string
 * @param {string} value - The raw string with accents to remove
 * @returns {*}
 */
export function normalizeString(value) {
    return value?.normalize('NFD').replace(/[\u0300-\u036F]/g, '');
}

/**
 * Remove all non letters and characters from string
 * @param {string} value - The raw value
 * @returns {*|string}
 */
export function clearString(value) {
    return value ? value.replace(/[^\da-z]/gi, '') : '';
}

/**
 * Transform file to base64
 * @param {File} file
 * @returns {Promise<string>}
 */
export function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', () => resolve(reader.result));
        reader.addEventListener('error', error => reject(error));
    });
}

export const defaultConverter = {
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return {
            id: snapshot.id,
            ...data,
        };
    },
    toFirestore(data) {
        return data;
    },
};

export function capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}
