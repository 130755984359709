import React from 'react';

import * as S from './styles';

import MainHeader from '../MainHeader';

function Main({ children }) {
    return (
        <S.Container>
            <MainHeader />

            <S.Wrapper>{children}</S.Wrapper>
        </S.Container>
    );
}

export default Main;
