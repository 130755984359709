import React from 'react';
import linkFunction from 'linkfunction';

const PaymentMethodSelector = ({ paymentMethod, onPaymentMethodSelect }) => (
    <div>
        <span className="tw-mr-4 tw-inline-flex tw-align-middle tw-place-items-start tw-mt-4 tw-max-w-sm">
            <input
                name="paymentBy"
                id="paymentBy-creditCard"
                type="radio"
                value="credit-card"
                checked={paymentMethod === 'credit-card'}
                onChange={linkFunction(onPaymentMethodSelect, 'target.value')}
                className="tw-mt-1 tw-mr-1"
            />
            <label htmlFor="paymentBy-creditCard" className="tw-ml-2">
                Cartão de crédito
            </label>
        </span>
        <span className="tw-mr-4 tw-inline-flex tw-align-middle tw-place-items-start tw-mt-4 tw-max-w-sm">
            <input
                name="paymentBy"
                id="paymentBy-pix"
                type="radio"
                value="pix"
                checked={paymentMethod === 'pix'}
                onChange={linkFunction(onPaymentMethodSelect, 'target.value')}
                className="tw-mt-1 tw-mr-1"
            />
            <label htmlFor="paymentBy-pix" className="tw-ml-2">
                PIX
            </label>
        </span>
    </div>
);

export default PaymentMethodSelector;
