/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { useCurrentCampaign } from 'hooks/campaign';
import { useCoverageCities } from 'hooks/coverageCities';

const colourStyles = {
    control: styles => ({
        ...styles,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        border: 0,
        borderRadius: 10,
        padding: 0,
        boxShadow: '0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06)',
        color: '#000',
    }),
};

const CitySelector = ({ name, control, error, defaultValue }) => {
    const {
        localization: { code },
    } = useCurrentCampaign();

    const options = useCoverageCities(code);

    return (
        <>
            <label className="tw-font-semibold tw-mb-2">Selecione a cidade em que deseja anunciar*</label>
            <Controller
                as={Select}
                options={options}
                name={name}
                control={control}
                styles={colourStyles}
                error={!!error}
                components={{
                    IndicatorSeparator: () => null,
                }}
                defaultValue={options?.find(item => item.value === defaultValue)}
                noOptionsMessage={() => 'Nenhuma opção'}
                placeholder={<span>Selecionar cidade</span>}
            />
            <p className="error-message">{error?.message}</p>
        </>
    );
};

CitySelector.displayName = 'CitySelector';

export default CitySelector;
