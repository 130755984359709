import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Main from 'components/Layout/Main';
import { DashboardOwner } from 'pages/owner';
import NoMatchRoute from 'routes/NoMatchRoute';

const SentryRoute = Sentry.withSentryRouting(Route);

const OwnerRoutes = () => (
    <Switch>
        <Main>
            <SentryRoute exact path="/" component={DashboardOwner} />
            <SentryRoute path="*">
                <NoMatchRoute />
            </SentryRoute>
        </Main>
    </Switch>
);

export default OwnerRoutes;
