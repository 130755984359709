import styled from 'styled-components';
import { darken } from 'polished';
export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 3rem;
    margin-bottom: 3rem;

    background-color: #454f79;
`;

export const Menu = styled.div``;

export const Logo = styled.img`
    width: 55%;
`;

export const Title = styled.h2`
    color: #fff;
    text-transform: uppercase;
`;

export const Profile = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    a {
        text-decoration: none;
        transition: all 0.2s ease-in;

        &:link {
            color: #5bc5c8;
        }
        &:visited {
            color: ${darken(0.1, '#1188bc')};
        }
        &:hover {
            color: #fff;
        }
    }
    small {
        &:first-child {
            font-weight: 800;
        }
        text-transform: uppercase;
        font-size: 1rem;
        color: #fff;

        display: block;
        flex-direction: column;
        span {
            font-weight: 600;
        }
        a {
            font-weight: 400;
        }
    }
    figure {
        margin-left: 1.25rem;

        img {
            width: 48px;
            height: auto;
        }
    }
`;
