import React from 'react';
import CompanyCard from './CompanyCard';

const CompaniesList = ({ title, children, companies, selectedCompany, onSelect, allowSelect }) => (
    <>
        <h3>{title}</h3>
        {children}
        <div className="tw-flex tw-mt-6">
            {companies.map(company => (
                <CompanyCard
                    key={company.id}
                    company={company}
                    onClick={onSelect}
                    isSelected={selectedCompany?.id === company.id}
                    disabled={!allowSelect}
                />
            ))}
        </div>
    </>
);

export default CompaniesList;
