import React, { useCallback, useEffect, useState } from 'react';
import { FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

import { useUserId } from 'hooks/user';
import { useUserCompany } from 'hooks/company';
import { useDiscounts } from 'hooks/discounts';
import { getCompanyByOwnerId } from 'store/modules/company';
import { Creators as ActionsCreators } from 'store/modules/discounts';

import DeleteDiscountModal from './DeleteDiscountModal';
import AddDiscountModal from './AddDiscountModal';
import { formatCNPJ } from 'utils';

const discountDefaultValues = { name: '', cnpj: '', discount: '' };

function CNPJCustomDiscount() {
    const [editingDiscount, setEditingDiscount] = useState(discountDefaultValues);
    const [showDiscountModal, setShowDiscountModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const dispatch = useDispatch();

    const userId = useUserId();
    const discounts = useDiscounts();
    const company = useUserCompany();

    const setEditingDefaultValues = () => setEditingDiscount(discountDefaultValues);

    const toggleDiscountModal = useCallback(() => setShowDiscountModal(previous => !previous), []);

    const toggleDeleteModal = useCallback(() => setShowDeleteModal(previous => !previous), []);

    const handleDeleteDiscount = useCallback(
        cnpj => {
            toggleDeleteModal();
            setEditingDiscount(discounts.find(item => item.cnpj === cnpj));
        },
        [discounts, toggleDeleteModal],
    );

    const handleConfirmDeleteDiscount = useCallback(() => {
        const discountsToSave = discounts.filter(item => item.cnpj !== editingDiscount.cnpj);
        dispatch(ActionsCreators.saveDiscounts(company.id, discountsToSave));
        setEditingDefaultValues();
        toggleDeleteModal();
    }, [discounts, toggleDeleteModal, editingDiscount, dispatch, company]);

    const onSubmit = useCallback(
        values => {
            if (values.cnpj?.trim().length < 18) {
                return;
            }

            let discountsToSave = [...discounts.filter(item => item.cnpj !== values.cnpj), values];

            dispatch(ActionsCreators.saveDiscounts(company.id, discountsToSave));

            toggleDiscountModal();
            dispatch(ActionsCreators.fetchDiscounts(company.id));
            setEditingDiscount({ cnpj: '' });
        },
        [toggleDiscountModal, discounts, dispatch, company],
    );

    const handleEditDiscount = useCallback(
        ({ name, cnpj, discount }) => {
            setEditingDiscount({
                name,
                cnpj,
                discount,
            });
            toggleDiscountModal();
        },
        [toggleDiscountModal],
    );

    const handleAddNewDiscount = useCallback(() => {
        setEditingDiscount();
        toggleDiscountModal();
    }, [toggleDiscountModal]);

    useEffect(() => {
        if (userId) {
            dispatch(getCompanyByOwnerId(userId));
        }
    }, [userId, dispatch]);

    useEffect(() => {
        if (company?.id) {
            dispatch(ActionsCreators.fetchDiscounts(company.id));
        }
    }, [dispatch, company]);

    return (
        <div>
            <button className="tw-flex tw-align-center tw-bg-green tw-rounded-md tw-p-2" onClick={handleAddNewDiscount}>
                <FaPlus size={22} color="#fff" />
                <span className="tw-block tw-ml-2 tw-text-white">Novo Desconto por CNPJ</span>
            </button>
            <DeleteDiscountModal
                showDeleteModal={showDeleteModal}
                toggleDeleteModal={toggleDeleteModal}
                handleDeleteDiscount={handleConfirmDeleteDiscount}
            />
            <AddDiscountModal
                showDiscountModal={showDiscountModal}
                toggleDiscountModal={toggleDiscountModal}
                editingDiscount={editingDiscount}
                onSubmit={onSubmit}
            />
            <div className="tw-w-full tw-overflow-x-auto tw-mb-9">
                <table className="tw-w-full tw-mt-6">
                    <thead>
                        <tr>
                            <th className="tw-text-purple tw-text-left" align="left">
                                Nome
                            </th>
                            <th className="tw-text-purple" align="right">
                                CNPJ
                            </th>
                            <th className="tw-text-purple" align="right">
                                Desconto
                            </th>

                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {discounts?.map(data => (
                            <tr key={data.cnpj}>
                                <td className="tw-border-b-2 tw-border-gray-light tw-py-2">
                                    <p>{data.name}</p>
                                </td>
                                <td className="tw-border-b-2 tw-border-gray-light tw-py-2" align="right">
                                    {formatCNPJ(data.cnpj)}
                                </td>
                                <td className="tw-border-b-2 tw-border-gray-light tw-py-2" align="right">
                                    {`${data.discount}%`}
                                </td>
                                <td className="tw-border-b-2 tw-border-gray-light tw-py-2" align="right">
                                    <div className="tw-flex tw-flex-row tw-items-center tw-justify-end">
                                        <FaPen
                                            size={18}
                                            color="#087A99"
                                            className="tw-cursor-pointer"
                                            onClick={() => handleEditDiscount(data)}
                                        />
                                        <FaTrash
                                            size={18}
                                            color="#BDBDBD"
                                            className="tw-cursor-pointer tw-ml-3"
                                            onClick={() => handleDeleteDiscount(data.cnpj)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CNPJCustomDiscount;
