import React from 'react';
import * as PropTypes from 'prop-types';
import { getColor } from './utils';

const RoraimaPathIcon = ({ active = false, available = false }) => {
    const color = getColor(active, available);
    return (
        <path
            fill={color}
            stroke="#FFFFFF"
            strokeWidth="1.0404"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M113.18,24.107
        c-0.972-2.753-7.861-5.889-6.999-8.984c0.068-0.232,13.229,6.053,12.79,2.808c0.398,1.329,1.219,1.889,2.439,1.685
        c1.889-1.301,7.148,4.204,8.216,1.889c0.438-0.959-1.657-3.753,0.74-3.848c1.026,0.438,1.534,0.164,1.52-0.822
        c0.835-1.752,3.575,0.219,4.793,0.083c0.767-1.056,10.625-3.026,9.037-5.094c1.37,0.438,4.574,0.808,4.63-1.547
        c4.546-2.054,1.15-4.409,2.644-6.354c2.177-2.82,9.791,0.809,7.327,5.738c-1.972,3.93,7.121,4.027,5.724,9.366
        c-0.452,1.686-2.479,2.724-3.423,3.971c-1.179,1.546-1.836,9.243-1.356,11.53c1.041,4.889,3.231,8.695,6.134,12.16
        c1.712,2.027,5.614,2.261,5.724,4.369c0.164,2.945,1.165,6.177,0.329,9.092c-1.547,5.424-36.618,30.471-36.618,30.471
        s-12.517-52.736-20.335-54.063C115.261,36.417,111.523,25.682,113.18,24.107z"
        />
    );
};

RoraimaPathIcon.propTypes = { active: PropTypes.bool };

export default RoraimaPathIcon;
