import React, { useReducer } from 'react';
import moment from 'moment';
import { FaTv } from 'react-icons/fa';
import { currencyFormatter } from 'utils';
import { useCampaignGrantTotal } from 'hooks/campaign';
import Microphone from 'icons/Microphone';
import { CAMPAIGN_STATUS, totalInsertionsForPrograms } from 'services/advertiser/campaign';
import AlertIcon from './icons/AlertIcon';
import IconsBreadsCrumb from './IconsBreadsCrumb';
import CampaignDetails from './CampaignDetails';

const statuses = {
    PENDING_APPROVE_INSERTIONS: {
        label: 'Inserção',
        color: 'tw-text-gray',
    },
    PENDING_CREATIVE: {
        label: 'Criativo',
        color: 'tw-text-pink',
    },
    PENDING_PAYMENT: {
        label: 'Pagamento',
        color: 'tw-text-pink',
    },
    PENDING_APPROVE_CREATIVE: {
        label: 'A. Criativo',
        color: 'tw-text-gray',
    },
};

const PendingCampaignRow = ({ campaign }) => {
    const [showInsertionsDetails, toggleShowInsertionsDetails] = useReducer(state => !state, false);

    const allPrograms = [
        ...(campaign.mediaCentral?.selectedTVPrograms || []),
        ...(campaign.mediaCentral?.selectedRadioPrograms || []),
    ];

    const startDateFormatted = moment(
        campaign.objectivesAndDuration?.startDate?.toDate() || campaign.createdAt.toDate(),
    )?.format('DD/MM/YYYY');
    const endDateFormatted = moment(campaign.objectivesAndDuration?.endDate?.toDate())?.format('DD/MM/YYYY');

    const { coupon, objectivesAndDuration, status, campaignValue, installments, packageItem, kind = 'TV' } = campaign;
    const totalToPay = useCampaignGrantTotal(campaign, coupon);

    let amount = campaignValue >= 0 ? campaignValue : totalToPay;
    const hasInstallments = installments > 1;
    amount = hasInstallments ? amount / installments : amount;

    return (
        <>
            <tr
                className={`tw-bg-white tw-border ${
                    showInsertionsDetails ? '' : 'tw-shadow-tablerow'
                } tw-cursor-pointer`}
                onClick={toggleShowInsertionsDetails}
            >
                <td className="border tw-bg-white tw-rounded-l-xl tw-pl-4 tw-py-3 tw-font-bold">
                    <div className="tw-flex tw-flex-row tw-items-center tw-mr-3 tw-relative">
                        {kind === 'TV' && <FaTv size={24} className="tw-mr-1" />}
                        {kind === 'RADIO' && <Microphone className="tw-w-6 tw-mr-1" />}
                        {[CAMPAIGN_STATUS.pendingPayment, CAMPAIGN_STATUS.pendingCreative].includes(status) && (
                            <AlertIcon className="tw-mr-3" />
                        )}
                        <span>{objectivesAndDuration.name || packageItem?.packageName}</span>
                        {!showInsertionsDetails && <IconsBreadsCrumb campaign={campaign} />}
                    </div>
                </td>
                {packageItem ? (
                    <td className="border tw-bg-white tw-py-3">{`a partir de ${startDateFormatted}`}</td>
                ) : (
                    <td className="border tw-bg-white tw-py-3">{`${startDateFormatted} a ${endDateFormatted}`}</td>
                )}
                <td className="border tw-bg-white tw-py-3 tw-text-center">
                    {totalInsertionsForPrograms(allPrograms, packageItem?.programs)}
                </td>
                <td className="border tw-bg-white tw-py-3 tw-text-center">
                    {hasInstallments && `${installments}x `}
                    {currencyFormatter.format(amount)}
                </td>
                <td className={`border tw-bg-white tw-rounded-r-xl tw-py-3 tw-font-bold ${statuses[status]?.color}`}>
                    {statuses[status]?.label}
                </td>
            </tr>
            {campaign && showInsertionsDetails && <CampaignDetails campaign={campaign} />}
        </>
    );
};

export default PendingCampaignRow;
