import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import LogInPage from 'pages/shared/LogIn';
import Register from 'pages/shared/Register';
import Recovery from 'pages/shared/RecoveryPassword';
import Auth from 'pages/shared/Auth';
import NoMatchRoute from 'routes/NoMatchRoute';

const SentryRoute = Sentry.withSentryRouting(Route);

const PublicRoutes = () => {
    return (
        <Switch>
            <SentryRoute exact path="/" component={LogInPage} />
            <SentryRoute exact path="/cadastro" component={Register} />
            <SentryRoute exact path="/recuperar-senha" component={Recovery} />
            <SentryRoute exact path="/auth/email/confirm" component={Auth} />
            <SentryRoute path="*">
                <NoMatchRoute />
            </SentryRoute>
        </Switch>
    );
};
export default PublicRoutes;
