/* eslint-disable no-debugger */
/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable unicorn/no-object-as-default-parameter */
import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

const initialState = {
    regions: [],
    region: {},
    loading: false,
};

// Actions
export const { Types, Creators } = createActions(
    {
        setLoading: ['loading'],
        getRegions: ['companyId', 'meta'],
        setRegion: ['data'],
        getRegionsSuccess: ['data'],
        createRegion: ['data', 'meta'],
        updateRegion: ['data', 'meta'],
        deleteRegion: ['params', 'meta'],
    },
    { prefix: 'region/' },
);

// Reducer
function setLoading(state = initialState, action) {
    return produce(state, draft => {
        draft.loading = action.loading;
    });
}

function getRegionsSuccess(state = initialState, action) {
    return produce(state, draft => {
        draft.regions = action.data;
        draft.loading = false;
    });
}

function setRegion(state = initialState, action) {
    return produce(state, draft => {
        draft.region = action.data;
    });
}

export default createReducer(initialState, {
    [Types.SET_LOADING]: setLoading,
    [Types.GET_REGIONS_SUCCESS]: getRegionsSuccess,
    [Types.SET_REGION]: setRegion,
});
