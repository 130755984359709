import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Types, Creators } from 'store/modules/regions';
import { createRegion, deleteRegion, getRegions, updateRegion } from 'api/regions';
import { removeProgramReferences } from 'src/services/coverageCities';

function* fetchRegionsSaga(action) {
    const { companyId, meta } = action;
    try {
        yield put(Creators.setLoading(true));
        const snapshot = yield call(getRegions, companyId);
        const documents = snapshot.docs.map(document_ => {
            return {
                id: document_.id,
                ...document_.data(),
            };
        });
        yield put(Creators.getRegionsSuccess(documents));
        if (meta?.onSuccess) yield call(meta.onSuccess);
    } catch {
        if (meta?.onFailure) yield call(meta.onFailure);
    } finally {
        yield put(Creators.setLoading(false));
    }
}

function* createRegionSaga(action) {
    const { data, meta } = action;
    try {
        yield put(Creators.setLoading(true));
        const response = yield call(createRegion, data);
        if (meta?.onSuccess) yield call(meta.onSuccess, response);
    } catch (error) {
        if (meta?.onFailure) yield call(meta.onFailure, error);
    } finally {
        yield put(Creators.setLoading(false));
    }
}

function* updateRegionSaga(action) {
    const { data, meta } = action;
    try {
        yield put(Creators.setLoading(true));
        const response = yield call(updateRegion, data);
        if (meta?.onSuccess) yield call(meta.onSuccess, response);
    } catch (error) {
        if (meta?.onFailure) yield call(meta.onFailure, error);
    } finally {
        yield put(Creators.setLoading(false));
    }
}

function* deleteRegionSaga(action) {
    const {
        params: { regionId, cities, state, companyId },
        meta,
    } = action;

    try {
        yield put(Creators.setLoading(true));
        yield call(removeProgramReferences, state, cities, companyId);
        yield call(deleteRegion, regionId);
        if (meta?.onSuccess) yield call(meta.onSuccess);
    } catch {
        if (meta?.onFailure) yield call(meta.onFailure);
    } finally {
        yield put(Creators.setLoading(false));
    }
}

function* regionsRoot() {
    yield all([
        takeLatest(Types.GET_REGIONS, fetchRegionsSaga),
        takeLatest(Types.CREATE_REGION, createRegionSaga),
        takeLatest(Types.UPDATE_REGION, updateRegionSaga),
        takeLatest(Types.DELETE_REGION, deleteRegionSaga),
    ]);
}

export default regionsRoot;
