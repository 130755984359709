/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Creators as ActionsCreators } from 'store/modules/campaign';
import { updateCampaign } from 'api/campaign';
import InputField from 'components/shared/molecules/InputField';

function RefuseCreativeModal({ campaign, openRefuseCreativeModal, toggleOpenRefuseCreativeModal }) {
    const [refusedReason, setRefusedReason] = useState('');

    const handleCancel = () => toggleOpenRefuseCreativeModal();

    const dispatch = useDispatch();

    const handleRefuseCreative = () => {
        if (!refusedReason) {
            return;
        }

        function onSuccess() {
            toast.success('CRIATIVO RECUSADO COM SUCESSO!');
            dispatch(ActionsCreators.getCampaigns());
            setRefusedReason('');
            toggleOpenRefuseCreativeModal();
        }

        function onFailure() {
            toast.success('FALHA AO RECUSAR CRIATIVO!');
        }

        updateCampaign(campaign.id, {
            ...campaign,
            status: 'PENDING_CREATIVE',
            refusedReason,
        })
            .then(onSuccess)
            .catch(onFailure);
    };

    const handleChange = event => setRefusedReason(event.target.value);

    return (
        <Modal open={openRefuseCreativeModal} onClose={toggleOpenRefuseCreativeModal}>
            <div className="tw-p-1">
                <h3 className="tw-text-pink">Criativo Reprovado</h3>
                <InputField
                    label="Por favor, informe o motivo:"
                    value={refusedReason}
                    className="tw-mt-6"
                    onChange={handleChange}
                    inputClassName="tw-w-full tw-border-gray-500"
                />
                <div className="tw-flex md:tw-flex-row tw-flex-col tw-justify-center md:tw-items-center tw-mt-4">
                    <div className="tw-flex md:tw-flex-row tw-flex-col">
                        <button
                            className="button tw-w-full md:tw-w-1/2 tw-text-pink tw-border-2 tw-border-pink tw-mr-4"
                            onClick={handleCancel}
                        >
                            Cancelar
                        </button>
                        <button
                            className="button tw-w-full md:tw-w-1/2 tw-border-2 tw-border-pink button-primary md:tw-mt-0 tw-mt-3"
                            onClick={handleRefuseCreative}
                        >
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default RefuseCreativeModal;
