import { useQuery as useReactQuery } from 'react-query';
import * as Sentry from '@sentry/react';
import { getFeatureFlagPaymentByPIX, getFeatureRadio, getPaymentPhoneNumber, getStyleConfig } from 'api/config';
import styleConfig from '../style_config.json';

/**
 * Get phone number used to request payment (usually by pix)
 * @param {UseQueryOptions} [config] - React query config
 * @returns {UseQueryResult<firebase.remoteConfig.Value, unknown>}
 */
export function usePaymentPhoneNumberFromConfig(config = {}) {
    return useReactQuery(['api.getPaymentPhoneNumber'], () => getPaymentPhoneNumber(), {
        refetchInterval: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        onError(error) {
            Sentry.captureException(error);
        },
        ...config,
    });
}

/**
 * Get feature flag for payment by PIX
 * @param {UseQueryOptions} [config] - React query config
 * @returns {UseQueryResult<firebase.remoteConfig.Value, unknown>}
 */
export function useFeatureFlagPaymentByPIXConfig(config = {}) {
    return useReactQuery(['api.getFeatureFlagPaymentByPIX'], () => getFeatureFlagPaymentByPIX(), {
        refetchInterval: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        onError(error) {
            Sentry.captureException(error);
        },
        ...config,
    });
}

/**
 * Get feature flag for radio
 * @param {UseQueryOptions} [config] - React query config
 * @returns {UseQueryResult<firebase.remoteConfig.Value, unknown>}
 */
export function useFeatureFlagRadio(config = {}) {
    return useReactQuery(['api.getFeatureRadio'], () => getFeatureRadio(), {
        refetchInterval: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        onError(error) {
            Sentry.captureException(error);
        },
        ...config,
    });
}

/**
 * Get style configuration
 * @param {UseQueryOptions} [config] - React query config
 * @returns {UseQueryResult<firebase.remoteConfig.Value, unknown>}
 */
export function useStyleConfiguration(config = {}) {
    return useReactQuery(['api.getStyleConfig'], () => getStyleConfig(), {
        refetchInterval: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        initialData: styleConfig,
        placeholderData: styleConfig,
        onError(error) {
            Sentry.captureException(error);
        },
        ...config,
    });
}
