import React from 'react';
import PropTypes from 'prop-types';

const DolarIcon = ({ className = '' }) => (
    <svg
        width="21"
        height="40"
        viewBox="0 0 21 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`icon ${className}`}
    >
        <path
            d="M16.1366 18.1703C14.5462 17.3029 12.8553 16.642 11.1812 15.9563C10.2102 15.5597 9.28108 15.0971 8.46077 14.4527C6.84526 13.1805 7.15497 11.1152 9.04671 10.2973C9.58242 10.066 10.1432 9.99161 10.7124 9.95857C12.9055 9.84291 14.9898 10.2395 16.9736 11.1812C17.9613 11.6521 18.2878 11.5034 18.6226 10.4873C18.9742 9.41332 19.2671 8.32283 19.5936 7.24059C19.8112 6.5136 19.5434 6.03444 18.8486 5.72877C17.5763 5.17526 16.2705 4.77872 14.8977 4.56392C13.1064 4.2913 13.1064 4.28304 13.098 2.50685C13.0897 0.00367188 13.0897 0.0036717 10.545 0.0036717C10.1767 0.0036717 9.80843 -0.00458963 9.44012 0.0036717C8.25151 0.036717 8.05062 0.24325 8.01713 1.42462C8.00039 1.95334 8.01713 2.48207 8.00876 3.01906C8.00039 4.58871 7.99202 4.56392 6.46858 5.10917C2.78555 6.43098 0.508762 8.90938 0.266017 12.8748C0.048383 16.3859 1.90664 18.7569 4.82796 20.4835C6.62762 21.5492 8.61981 22.1771 10.5283 23.0115C11.2733 23.3337 11.9848 23.7054 12.6042 24.2176C14.4373 25.7129 14.1025 28.1996 11.9262 29.1414C10.7627 29.6453 9.5322 29.7692 8.26825 29.6123C6.31791 29.3727 4.45128 28.8687 2.69347 27.9683C1.6639 27.4395 1.36256 27.58 1.01099 28.6787C0.709655 29.6288 0.441798 30.5871 0.173941 31.5454C-0.185992 32.8342 -0.0520635 33.1398 1.19515 33.7429C2.78555 34.503 4.48477 34.8912 6.21747 35.1639C7.57349 35.3787 7.61535 35.4365 7.63209 36.8244C7.64046 37.4523 7.64046 38.0884 7.64883 38.7162C7.6572 39.5093 8.04224 39.972 8.87093 39.9885C9.80843 40.005 10.7543 40.005 11.6918 39.9802C12.4619 39.9637 12.8553 39.5506 12.8553 38.7823C12.8553 37.9232 12.8972 37.0557 12.8637 36.1965C12.8218 35.3208 13.2069 34.8747 14.0607 34.6434C16.0277 34.1147 17.7018 33.0738 18.9909 31.5206C22.5735 27.2247 21.2091 20.9379 16.1366 18.1703Z"
            fill="white"
        />
    </svg>
);

DolarIcon.propTypes = {
    className: PropTypes.string,
};

export default DolarIcon;
