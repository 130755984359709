import Yup from 'validator';
import valid from 'card-validator';

function validateCreditCardNumber(message) {
    return this.test({
        name: 'creditCardNumber',
        message,
        test: value => {
            if (!value) {
                return true;
            }
            const isValidCreditCardNumber = valid.number(value);
            return isValidCreditCardNumber.isValid ? value : undefined;
        },
    });
}
Yup.addMethod(Yup.number, 'creditCardNumber', validateCreditCardNumber);

function validateCreditCardHolderName(message) {
    return this.test({
        name: 'creditCardHolderName',
        message,
        test: value => {
            if (!value) {
                return true;
            }

            const isValidCreditCardHolderName = valid.cardholderName(value);
            return isValidCreditCardHolderName.isValid ? value : undefined;
        },
    });
}
Yup.addMethod(Yup.string, 'creditCardHolderName', validateCreditCardHolderName);

function validateCreditCardCVV(message) {
    return this.test({
        name: 'creditCardCVV',
        message,
        test: value => {
            if (!value) {
                return true;
            }

            const isValidCreditCardCVV = valid.cvv(value && value.toString());
            return isValidCreditCardCVV.isValid ? value : undefined;
        },
    });
}
Yup.addMethod(Yup.string, 'creditCardCVV', validateCreditCardCVV);

function validateCreditCardExpirationDate(message) {
    return this.test({
        name: 'creditCardExpirationDate',
        message,
        test: value => {
            if (!value) {
                return true;
            }

            const formattedValue = value ? { month: value.getMonth() + 1, year: value.getFullYear() } : {};
            const creditCardDateValid = valid.expirationDate(formattedValue);
            return creditCardDateValid.isValid ? value : undefined;
        },
    });
}
Yup.addMethod(Yup.date, 'creditCardExpirationDate', validateCreditCardExpirationDate);

function upperCaseTransform(value) {
    return this.isType(value) && value !== null ? value.toUpperCase() : value;
}

const schema = Yup.object().shape({
    number: Yup.number()
        .required()
        .creditCardNumber('${path} não é um valor válido')
        .typeError('${path} inválido')
        .label('Número do Cartão'),
    holderName: Yup.string()
        .required()
        .creditCardHolderName('${path} não é um valor válido')
        .typeError('${path} inválido')
        .label('Nome'),
    expireDate: Yup.date()
        .transform((currentValue, originalValue) => {
            if (!originalValue || !originalValue.split) {
                return currentValue;
            }
            const [month, year] = originalValue.split('/');
            return new Date(Number(`20${year}`), Number(month) - 1, 1);
        })
        .min(new Date(), '${path} deve ser uma data futura')
        .required()
        .creditCardExpirationDate('${path} não é uma data válida')
        .typeError('${path} inválido')
        .label('Data de expiração'),
    cvv: Yup.string()
        .required()
        .creditCardCVV('${path} não é um valor válido')
        .typeError('${path} inválido')
        .label('CVV'),

    identifier: Yup.object({
        kind: Yup.mixed().transform(upperCaseTransform).oneOf(['CPF', 'CNPJ']).label('Tipo de Pessoa').required(),
        value: Yup.string().when('kind', {
            is: value => value === 'CPF',
            // eslint-disable-next-line unicorn/no-thenable
            then: Yup.string().cpf('Não é um ${path} válido').required().label('CPF'),
            otherwise: Yup.string().cnpj('Não é um ${path} válido').required().label('CNPJ'),
        }),
    })
        .default({})
        .required()
        .defined(),
});

export default schema;
