import yup from 'validator';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';

export const ALLOWED_OBJECTIVES = [
    {
        name: 'brandAwareness',
        title: 'Reconhecimento',
        description:
            'Dá preferência para a afinidade com seu público, levando mais em consideração as características fornecidas.',
    },
    {
        name: 'reach',
        title: 'Alcance',
        description:
            'Tenta encontrar o maior número de pessoas possíveis, mesmo que estas não se encaixem exatamente no seu público.',
    },
];

const MIN_WORKING_DAY_START_DATE = 3;
const today = new Date();
function isWorkingDaysFromNow(value) {
    if (!value) {
        return true;
    }
    const diff = differenceInBusinessDays(value, today);
    return diff > MIN_WORKING_DAY_START_DATE;
}

const schema = yup.object({
    name: yup.string().required().label('Nome'),
    startDate: yup
        .date()
        .min(today)
        .test({
            name: 'workingDays',
            message: 'A ${path} deve ser a partir do ${minWorkingDay}º dia útil da data de hoje.',
            exclusive: true,
            params: { minWorkingDay: MIN_WORKING_DAY_START_DATE },
            test: isWorkingDaysFromNow,
        })
        .required()
        .typeError('${path} incorreto!')
        .label('data de início'),
    campaignWeeks: yup
        .number()
        .min(1)
        .required()
        .default(1)
        .label('Duração em semanas')
        .typeError('Duração mínima de 1 semana'),
    duration: yup.number().positive().required().typeError('${path} incorreto!').label('Duração'),
    city: yup.object().required().label('Cidade'),
    objective: yup
        .mixed()
        .oneOf(
            ALLOWED_OBJECTIVES.map(objective => objective.name),
            ({ label }) => `${label} não é um valor válido`,
        )
        .required()
        .typeError('$path incorreto!')
        .label('Objetivo'),
});

export default schema;
