import React, { useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from 'store/modules/auth';
import logoUrl from 'assets/images/logo-white.svg';
import userUrl from 'assets/images/user.svg';
import * as S from './styles';

const MainHeader = ({ signOut, role, name, auth }) => {
    const history = useHistory();

    const handleLogout = useCallback(() => {
        signOut(() => history.push('/'));
    }, [history, signOut]);

    const GetTitle = ({ userRole }) => {
        switch (userRole) {
            case 'advertiser':
                return 'Painel do Anunciante';
            case 'supplier':
                return 'Painel do Parceiro';
            case 'admin':
                return 'Painel do Administrador';
            case 'owner':
                return 'Painel do Superusuário';
            default:
                return 'Painel';
        }
    };
    return auth ? (
        <S.Header>
            <Link to="/">
                <S.Logo src={logoUrl} alt="" />
            </Link>
            <S.Title>
                <GetTitle userRole={role} />
            </S.Title>
            <S.Profile>
                <div>
                    <small>{name}</small>

                    <small>
                        <a href="#teste" onClick={handleLogout}>
                            Logout
                        </a>
                    </small>
                </div>
                <figure>
                    <Link to="perfil/editar">
                        <img src={userUrl} alt="" />
                    </Link>
                </figure>
            </S.Profile>
        </S.Header>
    ) : (
        <>Carregando</>
    );
};

const mapStateToProperties = state => {
    return {
        ...state,
        authError: state.authReducer.authError,
        name: state.firebase.profile.displayName,
        role: state.firebase.profile.role,
        auth: state.firebase.auth.isLoaded,
    };
};

const mapDispatchToProperties = dispatch => ({
    signOut: callback => dispatch(signOut(callback)),
});

export default connect(mapStateToProperties, mapDispatchToProperties)(MainHeader);
