import { useEffect, useState } from 'react';
import { getVideos } from 'api/creative';
import { useQuery } from 'hooks/creative';
import { useCurrentCampaign } from 'hooks/campaign';
import { useDispatch } from 'react-redux';
import { Creators as CampaignCreators } from 'store/modules/campaign';

/**
 * Watch all videos prime filtering by
 * @param {number} [duration] - Duration to filter by
 * @param {string} kind - Kind of videos
 * @returns {*[]}
 */
export function useVideosPrime(duration, kind) {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        function onVideos(snapshot) {
            const data = snapshot.docs.map(videoDocument => ({
                id: videoDocument.id,
                ...videoDocument.data(),
            }));
            setVideos(data);
        }

        let query = getVideos();
        if (duration) {
            query = query.where('durationInSeconds', '==', duration);
        }
        if (kind) {
            query = query.where('kind', '==', kind);
        }
        const unsubscribe = query.onSnapshot(onVideos);
        return () => unsubscribe();
    }, [duration, kind]);

    return videos;
}

export function useCampaignByQueryString() {
    const campaignId = useQuery().get('campaignId');
    const currentCampaign = useCurrentCampaign();
    const dispatch = useDispatch();

    useEffect(() => {
        if (campaignId) {
            dispatch(CampaignCreators.getCampaignById(campaignId));
        }
    }, [campaignId, dispatch]);

    return currentCampaign;
}
