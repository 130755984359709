import React from 'react';

function Two({ className }) {
    return (
        <svg
            width="51"
            height="72"
            viewBox="0 0 51 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M0.688 55.008C9.264 48.608 16.048 42.816 21.04 37.632C26.032 32.384 28.528 27.488 28.528 22.944C28.528 21.408 28.176 20.224 27.472 19.392C26.832 18.56 25.968 18.144 24.88 18.144C23.6 18.144 22.576 18.816 21.808 20.16C21.104 21.44 20.848 23.424 21.04 26.112H0.4C0.592 20.16 1.872 15.264 4.24 11.424C6.672 7.584 9.808 4.768 13.648 2.976C17.488 1.184 21.712 0.287996 26.32 0.287996C34.512 0.287996 40.496 2.272 44.272 6.23999C48.112 10.144 50.032 15.168 50.032 21.312C50.032 27.776 47.952 33.856 43.792 39.552C39.696 45.184 34.544 49.952 28.336 53.856H50.32V71.04H0.688V55.008Z"
                fill="#7DC5AF"
                fillOpacity="0.15"
            />
        </svg>
    );
}

export default Two;
