import { useQuery } from 'react-query';
import { getPaymentInfoFor } from 'api/paymentInfo';
import { useUserId } from 'hooks/user';

export const usePaymentInfoForUser = () => {
    const userId = useUserId();
    return useQuery('getPaymentInfoFor', () => getPaymentInfoFor(userId.trim()).get(), {
        enabled: !!userId,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        select(data) {
            return data.data ? data.data() : data;
        },
    });
};
