import { v4 } from 'uuid';
import { firestore, storage } from 'base';

export const uploadBanner = async imageFile => {
    return storage.ref(`images/images/${imageFile.name}`).put(imageFile);
};

/**
 * Upload program images into storage
 * @param {File} imageFile - The image file
 */
export function uploadProgramImage(imageFile) {
    return storage.ref(`images/programImages/${v4()}-${imageFile.name}`).put(imageFile);
}

export function getAllPrograms() {
    return firestore.collection('programs');
}

export function addProgram(programToAdd) {
    return getAllPrograms().add(programToAdd);
}

/**
 * Get the program reference
 * @param {string} programId - The program ID
 * @returns {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>}
 */
export function getProgramById(programId) {
    return getAllPrograms().doc(programId);
}
export function getProgramByState(state) {
    return getAllPrograms().where('programState', '==', state);
}
export function getProgramByCompanyId(companyId) {
    return getAllPrograms().where('companyId', '==', companyId);
}

export function getProgramsReferenceByRegionId(programRegionId) {
    return getAllPrograms()
        .where('programRegionId', '==', programRegionId)
        .get()
        .then(programsSnapshot => programsSnapshot.docs.map(document => document.ref));
}

export async function getProgramReferenceById(programId) {
    const response = await firestore.collection('programs').doc(programId).get();
    return response.ref;
}
