/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { updateCampaign } from 'api/campaign';
import { Creators as ActionsCreators } from 'store/modules/campaign';
import InputField from 'components/shared/molecules/InputField';

const RefuseInsertionsModal = ({ campaign, openRefuseInsertionsModal, toggleOpenRefuseInsertionsModal }) => {
    const [refusedReason, setRefusedReason] = useState('');

    const handleCancel = () => toggleOpenRefuseInsertionsModal();

    const dispatch = useDispatch();

    const handleRefuseInsertions = () => {
        if (!refusedReason) {
            return;
        }

        function onSuccess() {
            toast.success('INSERÇÕES RECUSADAS COM SUCESSO!');
            dispatch(ActionsCreators.getCampaigns());
            setRefusedReason('');
            toggleOpenRefuseInsertionsModal();
        }

        function onFailure() {
            toast.success('FALHA AO APROVAR INSERÇÕES!');
        }

        updateCampaign(campaign.id, {
            ...campaign,
            status: 'REFUSED',
            refusedReason,
        })
            .then(onSuccess)
            .catch(onFailure);
    };

    const handleChange = event => setRefusedReason(event.target.value);

    return (
        <Modal open={openRefuseInsertionsModal} onClose={toggleOpenRefuseInsertionsModal}>
            <div className="tw-p-1">
                <h3 className="tw-text-pink">Pedido de Inserção Reprovado</h3>
                <InputField
                    label="Por favor, informe o motivo:"
                    value={refusedReason}
                    className="tw-mt-6"
                    onChange={handleChange}
                    inputClassName="tw-w-full tw-border-gray-500"
                />
                <div className="tw-flex md:tw-flex-row tw-flex-col tw-justify-center md:tw-items-center tw-mt-4">
                    <div className="tw-flex md:tw-flex-row tw-flex-col">
                        <button
                            className="button tw-w-full md:tw-w-1/2 tw-text-pink tw-border-2 tw-border-pink tw-mr-4"
                            onClick={handleCancel}
                        >
                            Cancelar
                        </button>
                        <button
                            className="button tw-w-full md:tw-w-1/2 tw-border-2 tw-border-pink button-primary md:tw-mt-0 tw-mt-3"
                            onClick={handleRefuseInsertions}
                        >
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default RefuseInsertionsModal;
