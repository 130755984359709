import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { analytics } from 'base';

function useRouterListener() {
    const location = useLocation();
    useEffect(() => {
        analytics.setCurrentScreen(location.pathname);
    }, [location]);
}

export default useRouterListener;
