import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { DevTool } from '@hookform/devtools';
import { Creators as ActionsCreators } from 'store/modules/creative';
import Modal from 'atoms/Modal';
import { useCreative } from 'hooks/creative';
import { useUserId } from 'hooks/user';
import { useCurrentCampaign } from 'hooks/campaign';
import useSelectCriativoForm from './useSelectCriativoForm';
import { useQuery } from 'hooks/query';

function filterAvailableCreative(type, duration, budget, creatives) {
    if (type === 'TV' && budget.tvBudget === 0) return [];
    if (type === 'RADIO' && budget.radioBudget === 0) return [];
    return creatives.filter(
        creative => creative.creativeType === type && Number(duration) === Number(creative.durationTime),
    );
}

function useFetchCreatives() {
    const dispatch = useDispatch();
    const creatives = useCreative().filter(c => c.payment);
    const userId = useUserId();
    const {
        budget,
        objectivesAndDuration: { duration },
    } = useCurrentCampaign();

    useEffect(() => {
        dispatch(ActionsCreators.getCreatives(userId, duration));
    }, [dispatch, duration, userId]);

    const tvCriativos = filterAvailableCreative('TV', duration, budget, creatives);
    const radioCriativos = filterAvailableCreative('RADIO', duration, budget, creatives);

    return { tvCriativos, radioCriativos };
}

const SelectCriativo = ({ register, name, label, criativos, children }) => (
    <div className="form-control tw-my-4 tw-flex tw-place-items-center tw-gap-x-4">
        <label htmlFor={name}>{label}</label>
        {criativos.length > 0 && (
            <select id={name} name={name} ref={register}>
                <option value="">Selecione um criativo</option>
                {criativos.map(criativo => (
                    <option key={criativo.id} value={criativo.id}>
                        {criativo.slogan}
                    </option>
                ))}
            </select>
        )}

        {children}
    </div>
);

const SelectCriativoModal = ({ onClose, ...rest }) => {
    const { id: campaignId, ref: campaignReference } = useCurrentCampaign();
    const id = campaignId || campaignReference?.id;
    const { tvCriativos, radioCriativos } = useFetchCreatives();
    const { register, onSubmit, control } = useSelectCriativoForm(onClose);

    const queryTipo = useQuery().get('tipo') || 'TV';
    const tipo = queryTipo.toLowerCase();

    const hasCriativos = tvCriativos.length > 0 || radioCriativos.length > 0;

    return (
        <Modal className="tw-w-1/2 md:tw-w-2/3 lg:tw-w-1/3" {...rest}>
            <form className="tw-p-4" onSubmit={onSubmit}>
                <h3 className="tw-text-center">Selecione o criativo para sua campanha!</h3>

                {tipo === 'tv' && (
                    <SelectCriativo criativos={tvCriativos} register={register} label="TV" name="tvCreativeId">
                        <Link
                            className="button button-small button-primary-outlined"
                            to={`/central-criativa/criar?tipo=tv&campaignId=${id}`}
                        >
                            Cadastrar Criativo
                        </Link>
                    </SelectCriativo>
                )}

                {tipo === 'radio' && (
                    <SelectCriativo criativos={radioCriativos} register={register} label="Radio" name="radioCreativeId">
                        <Link
                            className="button button-small button-primary-outlined"
                            to={`/central-criativa/criar?tipo=radio&campaignId=${id}`}
                        >
                            Cadastrar Criativo
                        </Link>
                    </SelectCriativo>
                )}

                <hr className="tw-my-4" />
                <div className="tw-flex tw-place-content-center tw-text-sm">
                    {hasCriativos ? (
                        <button className="button button-primary" type="submit">
                            Concluir
                        </button>
                    ) : (
                        <button className="button button-primary" onClick={onClose}>
                            Listar campanhas
                        </button>
                    )}
                </div>

                {__DEVELOPMENT__ && <DevTool control={control} />}
            </form>
        </Modal>
    );
};

export default SelectCriativoModal;
