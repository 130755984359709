import React, { forwardRef, memo } from 'react';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import InputCurrency from 'atoms/InputCurrency';

const InputCurrencyField = forwardRef(
    ({ name, label, control, defaultValue, error, className = '', ...rest }, reference) => {
        function handleChange(value) {
            control.setValue(name, value);
        }

        return (
            <div className="form-control">
                <label htmlFor={name} className="tw-block tw-font-bold tw-text-sm">
                    {label}
                </label>

                <Controller
                    control={control}
                    defaultValue={defaultValue}
                    name={name}
                    render={({ value }) => (
                        <InputCurrency
                            onChange={handleChange}
                            name={name}
                            value={value}
                            className={clsx({ 'with-error': !!error?.message }, className)}
                            ref={reference}
                            {...rest}
                        />
                    )}
                />

                {error?.message && <p className="error-message">{error.message}</p>}
            </div>
        );
    },
);
InputCurrencyField.displayName = 'InputCurrencyField';

export default memo(InputCurrencyField);
