export const getColor = (active = false, available = false) => {
    if (active) {
        return '#7F4895';
    }
    return available ? '#E5195A' : '#E0E0E0';
};

export const hasProgramOnState = (programs, stateName, stateCode) => {
    return programs.some(program => program.programState === stateName || program.programState === stateCode);
};
