import React from 'react';
import * as PropTypes from 'prop-types';
import { getColor } from './utils';

const PiauiPathIcon = ({ active = false, available = false }) => {
    const color = getColor(active, available);
    return (
        <path
            fill={color}
            stroke="#FFFFFF"
            strokeWidth="1.0404"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M320.781,185.478
        c2.465-5.149-7.505-20.801-7.505-20.801s47.354-65.868,54.285-66.841c0.299-0.042,6.243,1.768,6.463,2.219
        c0.438,0.863-0.821,5.244-0.685,6.587c0.275,2.629,2.879,6.587,2.328,8.684c-1.15,4.736-1.863,6.134,1.369,9.901
        c2.794,3.245,0.325,10.16,2.544,14.269c-1.778,4.23,4.768,3.656,3.943,7.613c-0.655,3.163-5.424,7.655-1.176,10.312
        c0.274,4.642-4.685,4.983-6.79,7.818c-2.631,2.835-5.535,5.013-7.999,7.888c-0.55,0.671-8.821,4.096-9.998,4.082
        c0.302-0.301-17.665-6.449-11.967,2.354c2.463,3.808-1.505,5.56-3.177,8.778c-0.633,2.164-5.836,0.958-7.836,3.205
        C328.176,198.748,327.409,180.727,320.781,185.478z"
        />
    );
};

PiauiPathIcon.propTypes = { active: PropTypes.bool };

export default PiauiPathIcon;
