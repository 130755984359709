import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';
import { getInsertionsCount, getProgramInsertionValue } from 'services/advertiser/campaign';
import { getCampaignsByStatus } from 'api/campaign';

const initialState = {
    campaigns: [],
    programs: [],
    isAddingCampaign: false,
    currentCampaign: {
        localization: {
            state: '',
            code: '',
        },
        budget: {
            budgetType: 'automatic',
            range: 50,
        },
        objectivesAndDuration: {},
        mediaCentral: {
            selectedTVPrograms: [],
            selectedRadioPrograms: [],
        },
        campaignValue: 0,
        campaignEditable: true,
        coupon: false,
        installments: 1,
        paymentMethod: 'credit-card',
    },
    unlockedSteps: [],
    currentStep: 0,
};

export const { Types, Creators } = createActions(
    {
        // New campaign
        setIsAddingCampaign: ['isAddingCampaign'],
        setNewCurrentCampaign: ['payload'],
        setCampaignID: ['id'],
        setCampaignRef: ['documentReference'],
        setUserForCurrentCampaign: ['userId'],
        nextStep: [],
        previousStep: [],
        setStep: ['step'],
        unlockStep: ['stepName'],

        // - Location Step
        addState: ['payload'],
        setKind: ['kind'],

        // - Objective and Duration Step
        addObjectivesAndDuration: ['payload'],

        // - Budget
        addBudget: ['payload'],

        // - Target And Segmentation Step
        addTargetAndSegmentation: ['payload'],

        // - Media Central Step
        addMediaCentral: ['payload'],

        // - Media Plan Step
        fillProgramInsertions: ['payload'],
        toggleAsSelectedDay: ['payload'],
        setInsertionAmountOnDay: ['payload'],
        removeFromSelectedProgram: ['payload'],
        setCampaignValue: ['payload'],

        // - Overview step
        setCampaignEditable: ['payload'],
        setCoupon: ['payload'],
        setInstallments: ['payload'],
        setPaymentMethod: ['paymentMethod'],
        saveCurrentCampaign: ['onSave'], // SAGA
        saveCampaignFromPackage: ['onSave', 'meta'], // SAGA

        // Sagas
        getCampaignsSuccess: ['payload'],
        getCampaignsByUserIdSuccess: ['payload'],
        getCampaigns: [],
        getCampaignsByUserId: ['id'],
        getCampaignById: ['id', 'meta'],
        updateCriativosForCampaign: ['id', 'criativos'],
        deleteCampaign: ['id'],
        getPrograms: ['city'],
        getProgramsSuccess: ['programs'],
    },
    {
        prefix: 'campaign/',
    },
);

// Reducers
const setIsAddingCampaign = (state = initialState, action) =>
    produce(state, draft => {
        draft.isAddingCampaign = action.isAddingCampaign;
    });
const setNewCurrentCampaign = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentCampaign = action?.payload ? action.payload : { ...initialState.currentCampaign };
        draft.unlockedSteps = [];
        draft.currentStep = 0;
    });
const setCampaignID = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentCampaign.id = action.id;
    });
const setCampaignReference = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentCampaign.ref = action.documentReference;
        draft.currentCampaign.id = action.documentReference.id;
    });

const setUserForCurrentCampaign = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentCampaign.userId = action.userId;
    });

const nextStep = (state = initialState) => {
    return produce(state, draft => {
        // if (step > 6) {
        //     step = 6;
        // }
        draft.currentStep = state.currentStep + 1;
    });
};

const previousStep = (state = initialState) =>
    produce(state, draft => {
        let step = state.currentStep - 1;
        if (step < 0) {
            step = 0;
        }
        draft.currentStep = step;
    });

const setStep = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentStep = action.step;
    });

const unlockStep = (state = initialState, action) =>
    produce(state, draft => {
        const isIncluded = state.unlockedSteps.includes(action.stepName);
        if (!isIncluded) {
            draft.unlockedSteps = [...state.unlockedSteps, action.stepName];
        }
    });

// - Location Step
const addState = (state = initialState, action) => {
    return produce(state, draft => {
        const { state, code, programs } = action.payload;
        draft.currentCampaign.localization = { state, code };
        draft.availablePrograms = programs;
    });
};
const setKind = (state = initialState, action) => {
    return produce(state, draft => {
        draft.currentCampaign.kind = action.kind;
    });
};

// - Objectives and Durations Step
const addObjectivesAndDuration = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentCampaign.objectivesAndDuration = action.payload;
    });

// - Budget
export const addBudget = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentCampaign.budget = action.payload;
    });

// - Target And Segmentation Step
export const addTargetAndSegmentation = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentCampaign.targetAndSegmentation = action.payload;
    });

// - Media Central Step
export const addMediaCentral = (state = initialState, action) =>
    produce(state, draft => {
        const { selectedTVPrograms, selectedRadioPrograms } = action.payload;
        function sortByName(a, b) {
            return a.programName > b.programName ? 1 : -1;
        }
        const sortedTVPrograms = [...(selectedTVPrograms || [])].sort(sortByName);
        const sortedTVRadio = [...(selectedRadioPrograms || [])].sort(sortByName);
        draft.currentCampaign.mediaCentral = {
            selectedTVPrograms: sortedTVPrograms,
            selectedRadioPrograms: sortedTVRadio,
        };
    });

// - Media Plan Step
export const fillProgramInsertions = (state = initialState, action) =>
    produce(state, draft => {
        const { user, companies } = action.payload;
        const { budgetType } = state.currentCampaign.budget;
        const { duration } = state.currentCampaign.objectivesAndDuration;

        function insertionsForProgram(program) {
            const insertionValue =
                budgetType === 'manual'
                    ? getProgramInsertionValue(companies, program, duration, user.cnpj, budgetType)
                    : program.insertionValue;
            const insertionCount = getInsertionsCount(program);

            program.insertionValue = insertionValue;
            program.insertionCount = insertionCount;
        }

        const { selectedTVPrograms, selectedRadioPrograms } = draft.currentCampaign.mediaCentral;

        [...selectedTVPrograms, ...selectedRadioPrograms].forEach(program => insertionsForProgram(program));
    });
export const toggleAsSelectedDay = (state = initialState, action) =>
    produce(state, draft => {
        const { day, program } = action.payload;
        const programs =
            program.programType === 'TV'
                ? draft.currentCampaign.mediaCentral.selectedTVPrograms
                : draft.currentCampaign.mediaCentral.selectedRadioPrograms;
        const selectedProgram = programs.find(p => p.id === program.id);
        const selectedDay = selectedProgram.showDays.find(d => d.day === day.day);
        selectedDay.selected = !selectedDay.selected;

        if (!selectedDay.selected) {
            selectedProgram.insertionCount -= selectedDay.insertions;
            selectedDay.insertions = 0;
        }
    });
export const setInsertionAmountOnDay = (state = initialState, action) =>
    produce(state, draft => {
        const { amount, day, program } = action.payload;
        const programs =
            program.programType === 'TV'
                ? draft.currentCampaign.mediaCentral.selectedTVPrograms
                : draft.currentCampaign.mediaCentral.selectedRadioPrograms;
        const selectedProgram = programs.find(p => p.id === program.id);
        const selectedDay = selectedProgram.showDays.find(d => d.day === day.day);

        selectedDay.insertions = amount;
        selectedProgram.insertionCount = selectedProgram.showDays.filter(d => d.selected).length;

        // const { duration } = state.currentCampaign.objectivesAndDuration;
        // selectedProgram.availableSeconds -= amount * duration;
    });
export const removeFromSelectedProgram = (state = initialState, action) =>
    produce(state, draft => {
        const { program } = action.payload;
        const programs =
            program.programType === 'TV'
                ? draft.currentCampaign.mediaCentral.selectedTVPrograms
                : draft.currentCampaign.mediaCentral.selectedRadioPrograms;

        const programIndex = programs.findIndex(p => p.id === program.id);
        if (programIndex >= 0) {
            programs.splice(programIndex, 1);
        }
    });
export const setCampaignValue = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentCampaign.campaignValue = action.payload;
    });

// - Overview Step
const setCampaignEditable = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentCampaign.campaignEditable = action.payload;
    });

const setCoupon = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentCampaign.coupon = action.payload;
    });
const setInstallments = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentCampaign.installments = action.payload;
    });

const setPaymentMethod = (state = initialState, action) =>
    produce(state, draft => {
        draft.currentCampaign.paymentMethod = action.paymentMethod;
    });

// ##############################

// Campaigns by user
const getCampaignsByUserIdSuccess = (state = initialState, action) => {
    return produce(state, draft => {
        draft.campaigns = action.payload;
    });
};
const getCampaignsSuccess = (state = initialState, action) => {
    return produce(state, draft => {
        draft.campaigns = action.payload;
    });
};

export const getCampaigns = status => async dispatch => {
    const query = getCampaignsByStatus(status);
    const snapshot = await query.get();

    return dispatch({
        type: Types.GET_CAMPAIGNS_SUCCESS,
        payload: snapshot.docs.map(document_ => ({
            id: document_.id,
            ...document_.data(),
        })),
    });
};

const getProgramsSuccess = (state = initialState, action) => {
    return produce(state, draft => {
        draft.programs = action.programs;
    });
};

// Reducer
export default createReducer(initialState, {
    [Types.SET_IS_ADDING_CAMPAIGN]: setIsAddingCampaign,
    [Types.SET_NEW_CURRENT_CAMPAIGN]: setNewCurrentCampaign,
    [Types.SET_CAMPAIGN_ID]: setCampaignID,
    [Types.SET_CAMPAIGN_REF]: setCampaignReference,
    [Types.SET_USER_FOR_CURRENT_CAMPAIGN]: setUserForCurrentCampaign,
    [Types.NEXT_STEP]: nextStep,
    [Types.PREVIOUS_STEP]: previousStep,
    [Types.SET_STEP]: setStep,
    [Types.UNLOCK_STEP]: unlockStep,
    [Types.GET_CAMPAIGNS_BY_USER_ID_SUCCESS]: getCampaignsByUserIdSuccess,
    // - Location Step
    [Types.ADD_STATE]: addState,
    [Types.SET_KIND]: setKind,
    // - Objectives and Durations Step
    [Types.ADD_OBJECTIVES_AND_DURATION]: addObjectivesAndDuration,
    // - Budget
    [Types.ADD_BUDGET]: addBudget,
    // - Target And Segmentation Step
    [Types.ADD_TARGET_AND_SEGMENTATION]: addTargetAndSegmentation,
    // - Media Central Step
    [Types.ADD_MEDIA_CENTRAL]: addMediaCentral,
    // - Media Plan Step
    [Types.FILL_PROGRAM_INSERTIONS]: fillProgramInsertions,
    [Types.TOGGLE_AS_SELECTED_DAY]: toggleAsSelectedDay,
    [Types.SET_INSERTION_AMOUNT_ON_DAY]: setInsertionAmountOnDay,
    [Types.REMOVE_FROM_SELECTED_PROGRAM]: removeFromSelectedProgram,
    [Types.SET_CAMPAIGN_VALUE]: setCampaignValue,

    // - Overview Step
    [Types.SET_CAMPAIGN_EDITABLE]: setCampaignEditable,
    [Types.SET_COUPON]: setCoupon,
    [Types.SET_INSTALLMENTS]: setInstallments,
    [Types.SET_PAYMENT_METHOD]: setPaymentMethod,

    [Types.GET_CAMPAIGNS_SUCCESS]: getCampaignsSuccess,
    [Types.GET_PROGRAMS_SUCCESS]: getProgramsSuccess,
});
