import * as yup from 'yup';
import { isCnpjValid, isCPFValid } from 'utils';
import yupPortuguese from './yupPortuguese';

yup.setLocale(yupPortuguese);

/**
 * Validate string against CPF
 * @param {string} message - Error message
 */
function validateCPF(message) {
    return this.test({
        name: 'CPF',
        message,
        test: value => {
            if (!value) {
                return true;
            }
            return isCPFValid(value);
        },
    });
}
yup.addMethod(yup.string, 'cpf', validateCPF);

/**
 * Validate string against CNPJ
 * @param {string} message - Error message
 */
function validateCNPJ(message) {
    return this.test({
        name: 'CNPJ',
        message,
        test: value => {
            if (!value) {
                return true;
            }
            return isCnpjValid(value);
        },
    });
}
yup.addMethod(yup.string, 'cnpj', validateCNPJ);

export default yup;
