import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import states from 'utils/states';
import { syncCoveragedCitiesOnModifyRegion } from 'services/coverageCities';
import { Creators as ActionCreators } from 'store/modules/regions';
import regionSchema from './regionSchema';
import { useCompanyForCurrentUser } from '../hooks';

function addNewRegion(payload, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch(
            ActionCreators.createRegion(payload, {
                onSuccess: resolve,
                onFailure: reject,
            }),
        );
    });
}

function updateRegion(oldRegion, newRegion, companyId, dispatch) {
    return new Promise((resolve, reject) => {
        const oldCities = new Set((oldRegion?.cities || []).map(city => city.value));
        const newCities = newRegion.cities || [];
        const citiesDiff = newCities.filter(newCity => !oldCities.has(newCity));

        return syncCoveragedCitiesOnModifyRegion(newRegion.state, citiesDiff, companyId, newRegion.cities, oldRegion.id)
            .then(() => {
                dispatch(
                    ActionCreators.updateRegion(newRegion, {
                        onSuccess: resolve,
                        onFailure: reject,
                    }),
                );
            })
            .catch(reject);
    });
}

function useRegionForm(region, mutateOptions) {
    const dispatch = useDispatch();
    const company = useCompanyForCurrentUser();

    const { mutate: mutateRegion, isLoading: isMutating } = useMutation(payload => {
        if (region?.id) {
            payload = {
                ...region,
                ...payload,
            };
            return updateRegion(region, payload, company.id, dispatch);
        } else {
            return addNewRegion(payload, dispatch);
        }
    }, mutateOptions);

    const methods = useForm({
        mode: 'all',
        context: {
            companyId: company.id,
        },
        resolver: yupResolver(regionSchema),
        defaultValues: region,
    });

    const { formState } = methods;
    const { isSubmitting } = formState;

    const onSubmit = ({ cities, state, ...others }) => {
        let payload = {
            companyId: company.id,
            ...others,
            state: states.find(item => item.value === state.value)?.value,
            cities: cities.map(({ value }) => value),
        };

        return mutateRegion(payload);
    };

    return {
        ...methods,
        isLoading: isMutating || isSubmitting,
        onSubmit: methods.handleSubmit(onSubmit),
    };
}

export default useRegionForm;
