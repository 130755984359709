import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import WizardStep from './WizardStep';
import ChevronRightIcon from './ChevronRightIcon';

const WizardTitle = ({ title, active, isLastOne, concluded }) => {
    const hasMoreTitles = !isLastOne;
    const rootClass = clsx('tw-flex tw-align-middle tw-place-items-center tw-font-bold tw-text-sm', {
        'tw-text-purple': active,
        'tw-text-pink': concluded,
    });
    return (
        <span key={title} className={rootClass}>
            {title}
            {hasMoreTitles && <ChevronRightIcon className="tw-w-4 tw-h-3 tw-inline tw-font-extrabold" />}
        </span>
    );
};
WizardTitle.propTypes = {
    title: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    isLastOne: PropTypes.bool.isRequired,
    concluded: PropTypes.bool.isRequired,
};

function useHandleChange(value, onValueChange) {
    const [currentValue, setCurrentValue] = useState();
    useEffect(() => {
        if (onValueChange && value >= 0 && currentValue !== value) {
            setCurrentValue(value);
            onValueChange(value);
        }
    }, [currentValue, onValueChange, value]);
}

const Wizard = ({ children, step, onStepChange }) => {
    const steps = children.filter(step => step.props.visible !== false);
    const titles = steps.map(step => step.props.title);
    const lastIndexStep = titles.length - 1;

    useHandleChange(step, onStepChange);

    return (
        <>
            <div
                className="tw-flex tw-flex-wrap tw-align-middle tw-place-items-center tw-text-gray-lighter"
                aria-label="breadcrumb"
            >
                {titles.map((title, index) => (
                    <WizardTitle
                        key={title}
                        title={title}
                        active={index === step}
                        isLastOne={index >= lastIndexStep}
                        concluded={step > index}
                    />
                ))}
            </div>
            {steps[step]}
        </>
    );
};

Wizard.propTypes = {
    /**
     * List of WizardSteps
     */
    children: PropTypes.arrayOf(PropTypes.any).isRequired,
    /**
     * The current step
     */
    step: PropTypes.number.isRequired,
};

Wizard.Step = WizardStep;

export default Wizard;
