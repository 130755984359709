/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import yup from 'validator';
import { formatCNPJ } from 'utils';
import { useUserId } from 'src/hooks/user';
import Select from 'components/shared/molecules/Select';
import InputField from 'components/shared/molecules/InputField';
import { Creators as ActionsCreators } from 'store/modules/coupons';
import WhiteListForm from './WhiteListForm';

const schema = yup.object().shape({
    code: yup.string().defined().required().label('Código'),
    value: yup.number().defined().required().label('Valor'),
    startDate: yup.date().defined().required().label('Data Inicial').typeError('Data inválida'),
    endDate: yup.date().defined().required().label('Data Final').typeError('Data inválida'),
    restrictionType: yup.string().oneOf(['byQuantity', 'byCnpj']),
    couponsCount: yup
        .number()
        .positive()
        .when('restrictionType', {
            is: 'byQuantity',
            // eslint-disable-next-line unicorn/no-thenable
            then: yup.number().positive().required().typeError('Quantidade inválida'),
        })
        .label('Quantidade')
        .typeError('Quantidade inválida'),
});

const options = [
    {
        label: 'R$',
        value: 'cash',
    },
    {
        label: '%',
        value: 'percent',
    },
];

function CouponForm() {
    const [whiteList, setWhiteList] = useState([]);

    const { goBack, push } = useHistory();
    const dispatch = useDispatch();
    const userId = useUserId();

    const { register, errors, handleSubmit, watch, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = values => {
        if (values.couponsCount <= 0 && whiteList.length === 0) {
            toast.warn('Informe a quantidade de cupons ou uma lista de CNPJs');
            return;
        }
        dispatch(
            ActionsCreators.saveCoupon(userId, { ...values, whiteList, userId }, { onSuccess: () => push('/cupons') }),
        );
    };

    const handleAddCnpj = cnpj => {
        setWhiteList(current => {
            if (!current.includes(cnpj)) {
                return [...current, cnpj];
            }
            return current;
        });
    };

    const handleRemoveCNPJ = cnpj => {
        setWhiteList(current => current.filter(item => item !== cnpj));
    };

    return (
        <>
            <h3>Cupons de Desconto</h3>
            <form onSubmit={handleSubmit(onSubmit)} className="tw-w-full tw-flex tw-flex-col">
                <div className="tw-w-full tw-max-w-2xl tw-flex-col">
                    <div className="tw-flex md:tw-flex-row tw-flex-col tw-w-full">
                        <InputField
                            label="Código do Cupom"
                            name="code"
                            ref={register}
                            className="tw-mt-6 tw-w-full md:tw-w-1/2"
                            inputClassName="tw-w-full"
                            error={errors.code}
                        />
                        <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-w-1/2 tw-w-full md:tw-ml-4">
                            <InputField
                                label="Valor do Cupom"
                                name="value"
                                ref={register({ valueAsNumber: true })}
                                className="tw-mt-6 tw-w-full md:tw-w-2/3"
                                inputClassName="tw-w-full"
                                error={errors.value}
                            />
                            <Select
                                name="type"
                                label="Tipo"
                                ref={register}
                                error={errors.type}
                                defaultValue="nominal"
                                className="tw-mt-6 tw-w-full md:tw-w-1/3 md:tw-ml-4"
                                inputClassName="tw-w-full"
                            >
                                {options.map(({ value, label }) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="tw-flex md:tw-flex-row tw-flex-col tw-w-full">
                        <InputField
                            label="Data Inicial"
                            type="date"
                            name="startDate"
                            ref={register}
                            className="tw-mt-6 tw-w-full md:tw-w-1/2"
                            inputClassName="tw-w-full"
                            error={errors.startDate}
                        />
                        <InputField
                            label="Data Final"
                            type="date"
                            name="endDate"
                            ref={register({ valueAsDate: true })}
                            className="tw-mt-6 tw-w-full md:tw-w-1/2 md:tw-ml-4"
                            inputClassName="tw-w-full"
                            error={errors.endDate}
                        />
                    </div>
                    {watch('restrictionType') !== 'byCnpj' && (
                        <div className="tw-flex md:tw-flex-row tw-flex-col tw-w-full">
                            <InputField
                                label="Quantidade de Uso"
                                type="number"
                                name="couponsCount"
                                ref={register}
                                className="tw-mt-6 tw-w-full md:tw-w-1/2"
                                inputClassName="tw-w-full"
                                error={errors.couponsCount}
                            />
                            <div className="tw-mt-6 tw-w-full md:tw-w-1/2 md:tw-ml-4" />
                        </div>
                    )}
                    <div className="tw-block tw-mt-6 md:tw-w-1/2">
                        <label className="tw-font-bold tw-text-back" htmlFor="whitelist">
                            Restringir Número de Cupons
                        </label>
                        <input
                            id="byQuantity"
                            className="checked:tw-bg-pink checked:border-transparent tw-mb-1 tw-ml-2"
                            type="radio"
                            value="byQuantity"
                            ref={register}
                            defaultChecked={true}
                            name="restrictionType"
                        />
                    </div>
                    <div className="tw-block tw-mt-6 md:tw-w-1/2">
                        <label className="tw-font-bold tw-text-back" htmlFor="whitelist">
                            Restringir por CNPJ
                        </label>
                        <input
                            id="byCnpj"
                            className="checked:tw-bg-pink checked:border-transparent tw-mb-1 tw-ml-2"
                            type="radio"
                            value="byCnpj"
                            ref={register}
                            defaultChecked={false}
                            name="restrictionType"
                        />
                    </div>
                </div>
                <div className="tw-w-full">
                    {watch('restrictionType') === 'byCnpj' && <WhiteListForm handleAddCnpj={handleAddCnpj} />}
                </div>
                <div className="tw-w-full">
                    {whiteList.map((cnpj, index) => (
                        <div key={index} className="tw-w-full tw-flex tw-flex-row tw-items-center tw-my-2">
                            <p>{formatCNPJ(cnpj)}</p>
                            <button className="tw-border-none tw-rounded-full tw-p-2 tw-shadow-sm tw-ml-2">
                                <FaTrash size={26} color="#e5195a" onClick={() => handleRemoveCNPJ(cnpj)} />
                            </button>
                        </div>
                    ))}
                </div>
                <div className="tw-mt-10 tw-text-center tw-mb-4 md:tw-mt-8">
                    <button className="button button-primary-outlined tw-mr-4" type="button" onClick={goBack}>
                        Voltar
                    </button>
                    <button className="button button-primary" type="submit">
                        Salvar
                    </button>
                </div>
            </form>

            <br />
        </>
    );
}

export default CouponForm;
