import React, { useState } from 'react';
import moment from 'moment';
import { currencyFormatter } from 'utils';
import { FaTv } from 'react-icons/fa';
import Microphone from 'icons/Microphone';
import { CAMPAIGN_STATUS, getInsertionsCount, totalInsertionsForPrograms } from 'services/advertiser/campaign';
import CampaignInfo from 'organisms/CampaignInfo';
import StepsIconsInfo from './StepsIconsInfo';
import IconsBreadsCrumb from './IconsBreadsCrumb';
import ProgramInsertions from './ProgramInsertions';
import ProgramsTableWithApprovedDates from 'components/ProgramsTableWithApprovedDates';
import styleConfig from '../../../../style_config.json';

const statuses = {
    APPROVED: {
        label: 'Ativa',
        color: 'tw-text-purple',
    },
    REFUSED: {
        label: 'Cancelada',
        color: 'tw-text-gray',
    },
    FINISHED: {
        label: 'Finalizada',
        color: 'tw-text-purple',
    },
};

const CampaignRow = ({ campaign }) => {
    const [showInsertionsDetails, setShowInsertionsDetails] = useState(false);

    const toggleShowInsertionsDetails = () => {
        setShowInsertionsDetails(previous => !previous);
    };

    const allPrograms = [
        ...(campaign?.mediaCentral?.selectedTVPrograms || []),
        ...(campaign?.mediaCentral?.selectedRadioPrograms || []),
    ];

    const getInsertionsTotalValue = program => {
        const insertionCount = getInsertionsCount(program);
        return program.insertionValue * insertionCount;
    };
    const startDate = moment(
        campaign.objectivesAndDuration?.startDate?.toDate() || campaign.createdAt.toDate(),
    )?.format('DD/MM/YYYY');
    const endDate = moment(campaign.objectivesAndDuration?.endDate?.toDate())?.format('DD/MM/YYYY');

    const hasInstallments = campaign.installments > 1;
    const campaignValue = hasInstallments ? campaign.campaignValue / campaign.installments : campaign.campaignValue;

    const kind = campaign.kind || 'TV';

    return (
        <>
            <tr
                className={`tw-bg-white tw-border ${
                    showInsertionsDetails ? '' : 'tw-shadow-tablerow'
                } tw-cursor-pointer`}
                onClick={toggleShowInsertionsDetails}
            >
                <td className="border tw-bg-white tw-rounded-l-xl tw-pl-4 tw-py-3 tw-font-bold">
                    <div className="tw-flex tw-flex-row tw-items-center tw-mr-3">
                        {kind === 'TV' && <FaTv size={24} className="tw-mr-1" />}
                        {kind === 'RADIO' && <Microphone className="tw-w-6 tw-mr-1" />}
                        <span>{campaign?.objectivesAndDuration?.name || campaign.packageItem?.packageName}</span>
                        {!showInsertionsDetails && <IconsBreadsCrumb campaign={campaign} />}
                    </div>
                </td>
                {campaign?.packageItem ? (
                    <td className="border tw-bg-white tw-py-3">{`a partir de ${startDate}`}</td>
                ) : (
                    <td className="border tw-bg-white tw-py-3">{`${startDate} a ${endDate}`}</td>
                )}
                <td className="border tw-bg-white tw-py-3 tw-text-center">
                    {totalInsertionsForPrograms(allPrograms, campaign?.packageItem?.programs)}
                </td>
                <td className="border tw-bg-white tw-py-3">
                    {hasInstallments && `${campaign.installments}x `}
                    {currencyFormatter.format(campaignValue)}
                </td>
                <td
                    className={`border tw-bg-white  tw-rounded-r-xl tw-py-3 tw-font-bold ${
                        statuses[campaign.status]?.color
                    }`}
                >
                    {statuses[campaign.status]?.label}
                </td>
            </tr>
            {showInsertionsDetails && (
                <>
                    <tr className="tw-bg-white tw-border tw-shadow-tablerow tw-p-3 tw-translate-x-2">
                        <td colSpan={5} className="tw-p-5 border tw-bg-white tw-rounded-xl">
                            <CampaignInfo campaign={campaign} />
                            {campaign?.approvedDates?.length ? (
                                <ProgramsTableWithApprovedDates campaign={campaign} />
                            ) : (
                                <table className="tw-w-full tw-mt-6">
                                    <thead>
                                        <tr>
                                            <th className="tw-text-purple tw-text-left" align="left">
                                                Programa
                                            </th>
                                            <th className="tw-text-purple tw-text-left">Dias de Veiculação</th>
                                            <th className="tw-text-purple tw-text-center">Inserções</th>
                                            <th className="tw-text-purple tw-text-center">Duração</th>
                                            <th className="tw-text-purple tw-text-left">Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allPrograms.map((program, index) => (
                                            <tr key={index}>
                                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                                                    <p>{program.programName}</p>
                                                    <p>(NDTV)</p>
                                                </td>
                                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                                                    <ProgramInsertions program={program} />
                                                </td>
                                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1 tw-text-center">
                                                    {getInsertionsCount(program)}
                                                </td>
                                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1 tw-text-center">
                                                    {campaign.objectivesAndDuration?.duration}
                                                </td>
                                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                                                    {currencyFormatter.format(getInsertionsTotalValue(program))}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-mt-3">
                                <StepsIconsInfo campaign={campaign} />
                                {campaign.status === CAMPAIGN_STATUS.approved && (
                                    <div className="tw-border tw-border-gray-light tw-bg-gray-100 tw-text-gray-900 tw-rounded-3xl tw-p-2">
                                        Aguardando Veiculação da Campanha. Prazo:{' '}
                                        <strong>
                                            {`${moment
                                                .utc(campaign.createdAt?.toDate())
                                                .add(2, 'd')
                                                .format('DD/MM')} - ${moment
                                                .utc(campaign.createdAt?.toDate())
                                                .format('HH:mm')} `}
                                        </strong>
                                    </div>
                                )}
                                {campaign.status === CAMPAIGN_STATUS.finished && (
                                    <div className="tw-border tw-border-gray-light tw-bg-gray-100 tw-text-gray-900 tw-rounded-3xl tw-p-2">
                                        <span className="tw-text-green">Campanha Concluída. </span>
                                        <strong>Obrigado por anunciar com a {styleConfig['small_name']}.</strong>
                                    </div>
                                )}
                            </div>
                        </td>
                    </tr>
                </>
            )}
        </>
    );
};

export default CampaignRow;
