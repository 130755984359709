import React, { useState } from 'react';
import yup from 'validator';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Loading from 'react-fullscreen-loading';
import { yupResolver } from '@hookform/resolvers/yup';
import { auth } from 'base';
import { useQuery } from 'hooks/query';
import Input from 'molecules/InputField';
import styleConfig from '../../../style_config.json';

const schema = yup.object().shape({
    password: yup.string().defined().required().min(6).label('Senha'),
});

const ChangePassword = () => {
    const [isLoading, setIsLoading] = useState(false);

    const code = useQuery().get('oobCode');

    const { register, errors, handleSubmit } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const history = useHistory();

    const handleBack = () => history.push('/');

    const onSubmit = async ({ password }) => {
        try {
            setIsLoading(true);
            await auth.verifyPasswordResetCode(code);
            await auth.confirmPasswordReset(code, password);
            toast.success('Senha redefinida com sucesso');
        } catch {
            toast.error('Falha ao redefinir a senha, tente novamente!');
        } finally {
            setIsLoading(false);
            handleBack();
        }
    };

    return isLoading ? (
        <Loading loading background="rgba(0,0,0,0.1)" loaderColor="#E5195A" tex />
    ) : (
        <div className="tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center">
            <form className="tw-max-w-sm tw-flex tw-flex-col" onSubmit={handleSubmit(onSubmit)}>
                <div className="tw-flex tw-justify-center tw-place-content-center tw-items-center">
                    <img
                        src={styleConfig['logo-vertical.svg']}
                        alt="Logo vertical"
                        className="tw-w-2/6 tw-h-20 tw-max-w-max tw-fill-current tw-text-white"
                    />
                </div>
                <Input
                    label="Nova Senha"
                    name="password"
                    error={errors.password}
                    type="password"
                    placeholder="Digite sua nova senha"
                    className="tw-my-8"
                    ref={register}
                />
                <button className="button button-primary tw-mt-4 tw-mx-4" type="submit">
                    Redefinir a senha
                </button>
                <button className="button tw-mt-4 tw-self-center" onClick={handleBack}>
                    Voltar
                </button>
            </form>
        </div>
    );
};

export default ChangePassword;
