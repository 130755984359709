import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { currencyFormatter } from 'utils';
import { useCoupons } from 'src/hooks/coupons';
import { useUserId } from 'src/hooks/user';
import { Creators as ActionsCreators } from 'store/modules/coupons';
import DeleteCouponModal from './DeleteCouponModal';

const CouponRow = ({ coupon, onDeleteClick }) => {
    const handleDeleteClick = () => onDeleteClick(coupon.id);
    return (
        <tr>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                <p>{coupon.code}</p>
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="center">
                {`${moment(coupon.startDate?.toDate()).format('DD/MM/YYYY')} - ${moment(
                    coupon.endDate?.toDate(),
                ).format('DD/MM/YYYY')}`}
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="right">
                {coupon.type === 'cash' ? currencyFormatter.format(coupon.value) : `${coupon.value}%`}
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="right">
                <FaTimes size={22} color="#cd0e4b" className="tw-cursor-pointer tw-ml-3" onClick={handleDeleteClick} />
            </td>
        </tr>
    );
};

const CouponsTable = ({ onDelete, coupons }) => (
    <table className="tw-w-full tw-mt-6">
        <thead>
            <tr>
                <th className="tw-text-purple tw-text-left" align="left">
                    Código
                </th>
                <th className="tw-text-purple" align="center">
                    Período
                </th>
                <th className="tw-text-purple" align="right">
                    Desconto
                </th>
                <th className="tw-text-purple tw-text-right">Ações</th>
            </tr>
        </thead>
        <tbody>
            {coupons.map((coupon, index) => (
                <CouponRow key={index} coupon={coupon} onDeleteClick={onDelete} />
            ))}
        </tbody>
    </table>
);

function List() {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [couponIdToDelete, setCouponIdToDelete] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();

    const coupons = useCoupons();
    const userId = useUserId();

    const toggleDeleteModal = useCallback(() => {
        setShowDeleteModal(previous => !previous);
    }, []);

    const handleDeleteCoupon = useCallback(() => {
        function onSuccess() {
            toast.success('Cupom excluído com sucesso!');
            dispatch(ActionsCreators.fetchCoupons(userId));
        }

        function onFailure() {
            toast.error('Falha ao excluir cupom');
        }

        if (couponIdToDelete) {
            dispatch(
                ActionsCreators.deleteCoupon(couponIdToDelete, {
                    onSuccess,
                    onFailure,
                }),
            );
            toggleDeleteModal();
        }
    }, [couponIdToDelete, dispatch, toggleDeleteModal, userId]);

    const handlePrepareToDeleteCoupon = useCallback(
        couponId => {
            setCouponIdToDelete(couponId);
            toggleDeleteModal();
        },
        [toggleDeleteModal],
    );

    const handleAddNewCoupon = () => {
        history.push('/cupom');
    };

    useEffect(() => {
        dispatch(ActionsCreators.fetchCoupons(userId));
    }, [userId, dispatch]);

    return (
        <div className="tw-w-full tw-overflow-x-auto tw-mb-9">
            <DeleteCouponModal
                showDeleteModal={showDeleteModal}
                toggleDeleteModal={toggleDeleteModal}
                handleDeleteCoupon={handleDeleteCoupon}
            />
            <div className="tw-w-full tw-flex md:tw-justify-end tw-justify-center tw-my-4">
                <button
                    className="tw-flex tw-items-center tw-border-none tw-rounded-md tw-bg-pink tw-text-white tw-font-bold tw-px-5 tw-py-0 tw-h-10 md:tw-ml-3"
                    onClick={handleAddNewCoupon}
                >
                    <FaPlus size={18} className="tw-text-white" />
                    <span className="tw-block tw-ml-3">Adicionar Cupom</span>
                </button>
            </div>
            <CouponsTable coupons={coupons} onDelete={handlePrepareToDeleteCoupon} />
        </div>
    );
}

export default List;
