import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import Modal from 'react-awesome-modal';
import { FaVideo, FaWhatsapp } from 'react-icons/fa';
import PlusIcon from 'icons/PlusIcon';
import PencilIcon from 'icons/PencilIcon';
import MenuAltIcon from 'icons/MenuAltIcon';
import UserIconSmall from 'icons/UserIconSmall';
import CardIcon from './CardIcon';
import Welcome from './Welcome';
import PackagesShop from './PackagesShop';
import styleConfig from '../../../style_config.json';

const tutorialStyles = { backgroundColor: '#7f4895' };
const Dashboard = () => {
    const [open, setOpen] = useState(false);
    const userProfile = useSelector(store => store.firebase.profile);

    const toggleModal = () => setOpen(current => !current);

    return (
        <div className="tw-w-full tw-h-full">
            <Modal visible={open} effect="fadeInUp" onClickAway={toggleModal} width="720px" height="480px">
                <ReactPlayer
                    width="100%"
                    height="100%"
                    url="https://www.youtube.com/watch?v=8mtgAeqtgH0"
                    controls={true}
                    playing={open}
                    className="tw-w-full tw-h-40"
                />
            </Modal>

            <Welcome />

            <h2 className="tw-mb-4">
                Olá {userProfile.displayName}, seja bem vindo ao {styleConfig['name']}!
            </h2>

            <PackagesShop />
            <div className="tw-flex tw-w-full md:tw-flex-row tw-flex-col tw-justify-start tw-mt-4">
                <CardIcon
                    className="tw-mr-4"
                    title="Meu perfil"
                    to="/perfil"
                    icon={<UserIconSmall className="tw-bg-blue" />}
                />
                <CardIcon
                    className="tw-mr-4"
                    title="Criar Campanha"
                    to="/campanhas/criar"
                    icon={<PlusIcon className="tw-bg-purple" />}
                />
                <CardIcon
                    className="tw-mr-4"
                    title="Listar Campanhas"
                    to="/campanhas"
                    icon={<MenuAltIcon className="tw-bg-yellow" />}
                />
                <CardIcon
                    className="tw-mr-4"
                    title="Central de Criativo"
                    to="/central-criativa"
                    icon={<PencilIcon className="tw-bg-green" />}
                />
                <CardIcon
                    className="tw-mr-4"
                    title="Suporte Whatsapp"
                    to={styleConfig['whatsAppSupportLink']}
                    icon={<FaWhatsapp size={40} color="#06d755" />}
                />
                <CardIcon
                    title="Tutorial"
                    onClick={toggleModal}
                    to="/central-criativa"
                    className="tw-text-white tw-font-bold tw-mr-4"
                    style={tutorialStyles}
                    icon={<FaVideo size={26} color="#fff" />}
                />
            </div>
        </div>
    );
};

export default Dashboard;
