import React, { useCallback, useEffect } from 'react';
import yup from 'validator';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';
import InputField from 'molecules/InputField';
import { useCurrentCreative } from 'hooks/creative';
import { Creators as ActionsCreators } from 'store/modules/creative';
import { uploadCommercialImage, uploadLogo } from 'api/creative';
import UploadImage from './UploadImage';
import UploadComercialImages from './UploadComercialImages';
import { toast } from 'react-toastify';
import { useUserId } from 'hooks/user';

const schema = yup.object().shape({
    logo: yup.string().defined().required().label('Logomarca'),
    commercialImages: yup.array(yup.string()).min(1).default([]).label('Imagens do comercial'),
    commercialName: yup.string().defined().required().label('Nome do comercial'),
    businessName: yup.string().defined().required().label('Nome do negócio'),
    slogan: yup.string().defined().required().label('Slogan'),
});

const defaults = schema.getDefaultFromShape();

function useComercialForm() {
    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: defaults,
    });

    const dispatch = useDispatch();

    const onSubmit = values => {
        dispatch(ActionsCreators.setCommercial(values));
        dispatch(ActionsCreators.goNextStep());
    };

    return { ...methods, onSubmit: methods.handleSubmit(onSubmit) };
}

const Comercial = () => {
    const dispatch = useDispatch();
    const creative = useCurrentCreative();
    const methods = useComercialForm();
    const { register, formState, onSubmit, control } = methods;
    const { errors } = formState;
    const userId = useUserId();

    const handleBack = () => dispatch(ActionsCreators.goPreviousStep());

    useEffect(() => {
        if (errors?.commercialImages) {
            toast.error('Insira ao menos uma imagem para o comercial');
        }

        if (errors?.logo) {
            toast.error('Insira o logo para o comercial');
        }
    }, [errors]);

    const handleUploadComercialImages = useCallback(file => uploadCommercialImage(file, userId), [userId]);

    return (
        <div className="tw-w-full">
            <h2 className="tw-text-pink tw-normal-case tw-font-bold">Construindo o seu comercial</h2>
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                    <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row">
                        <div className="tw-flex tw-flex-col tw-w-full">
                            <InputField
                                label="Qual o nome do seu comercial?*"
                                name="commercialName"
                                ref={register}
                                maxLength={54}
                                className="tw-mt-6"
                                defaultValue={creative?.commercialName}
                                inputClassName="tw-w-full tw-border-gray-500"
                                error={errors.commercialName}
                            />
                            <h3 className="tw-mt-6">Sobre o seu tipo de negócio</h3>
                            <InputField
                                label="Qual o nome do seu negócio?*"
                                name="businessName"
                                ref={register}
                                className="tw-mt-6"
                                defaultValue={creative?.businessName}
                                inputClassName="tw-w-full tw-border-gray-500"
                                error={errors.businessName}
                            />
                            <InputField
                                label="Qual o slogan do seu negócio?*"
                                name="slogan"
                                ref={register}
                                className="tw-mt-6"
                                defaultValue={creative?.slogan}
                                inputClassName="tw-w-full tw-border-gray-500"
                                error={errors.slogan}
                            />
                        </div>
                        <div className="tw-flex tw-flex-col tw-w-full tw-ml-8 tw-mt-8 md:tw-mt-0">
                            <UploadImage
                                label="Vocês tem uma logomarca?"
                                name="logo"
                                uploadAction={file => uploadLogo(file, userId)}
                                defaultValue={creative?.logo}
                            />
                            <UploadComercialImages
                                label="Adicione as imagens para o comercial"
                                name="commercialImages"
                                defaultValue={creative?.commercialImages}
                                uploadAction={handleUploadComercialImages}
                            />
                        </div>
                    </div>
                    <div className="tw-mt-10 tw-text-center tw-mb-4 md:tw-mt-24">
                        <button className="button button-primary-outlined tw-mr-4" onClick={handleBack}>
                            Voltar
                        </button>
                        <button className="button button-primary" type="submit">
                            Prosseguir
                        </button>
                    </div>
                    {__DEVELOPMENT__ && <DevTool control={control} />}
                </form>
            </FormProvider>
        </div>
    );
};

export default Comercial;
