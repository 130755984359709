import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuery as useReactQuery } from 'react-query';
import { getPriceFor } from 'api/creative';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';

export const useCreative = () => useSelector(state => state.creative.creatives);

export const useQuery = () => new URLSearchParams(useLocation().search);

export const useCurrentCreative = () => useSelector(state => state.creative.creative);

/**
 *
 * @param {string} type - The creative type
 * @param {number} duration - The seconds of the creative
 * @returns {UseQueryResult<firebase.remoteConfig.Value, unknown>}
 */
export function useCreativePriceFor(type, duration) {
    return useReactQuery(['creative.getPriceFor', type, duration], () => getPriceFor(type, duration), {
        refetchInterval: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        onError(error) {
            Sentry.captureException(error);
            toast.error(error?.message || 'Falha ao tentar recuperar valores do criativo.');
        },
    });
}
