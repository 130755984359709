import produce from 'immer';
import { createReducer, createActions } from 'reduxsauce';

const initialState = {
    discounts: [],
    discountsRange: [],
};

// Actions
export const { Types, Creators } = createActions(
    {
        setDiscounts: ['discounts'],
        saveDiscounts: ['companyId', 'discounts'],
        fetchDiscounts: ['companyId'], // saga
        setDiscountsRange: ['discountsRange'],
        saveDiscountsRange: ['companyId', 'discountsRange'],
        fetchDiscountsRange: ['companyId'],
    },
    { prefix: 'discounts/' },
);

// Reducer
function setDiscounts(state = initialState, action) {
    return produce(state, draft => {
        draft.discounts = action.discounts;
    });
}
function saveDiscounts(state = initialState, action) {
    return produce(state, draft => {
        draft.discounts = action.discounts;
    });
}
function setDiscountsRange(state = initialState, action) {
    return produce(state, draft => {
        draft.discountsRange = action.discountsRange;
    });
}
function saveDiscountsRange(state = initialState, action) {
    return produce(state, draft => {
        draft.discountsRange = action.discountsRange;
    });
}

export default createReducer(initialState, {
    [Types.SET_DISCOUNTS]: setDiscounts,
    [Types.SAVE_DISCOUNTS]: saveDiscounts,
    [Types.SET_DISCOUNTS_RANGE]: setDiscountsRange,
    [Types.SAVE_DISCOUNTS_RANGE]: saveDiscountsRange,
});
