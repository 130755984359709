import React, { useReducer } from 'react';
import Modal from 'react-awesome-modal';
import { FaInfoCircle, FaTv } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import PlayIcon from 'icons/PlayIcon';
import Microphone from 'icons/Microphone';

const CreativeItem = ({ creative }) => {
    const [showVideo, toggleVideo] = useReducer(state => !state);

    return (
        <div className="tw-w-full tw-max-w-xs tw-mb-8 md:tw-mr-8">
            <Modal visible={showVideo} width="710" effect="fadeInUp" onClickAway={toggleVideo} key={creative.id}>
                <video src={creative.media?.url} controls />
            </Modal>
            <div className="tw-flex tw-flex-col tw-relative">
                <h3 className="tw-text-gray-700 tw-mb-3">{creative.name?.split(' - ')[0]}</h3>
                <div className="tw-w-full tw-shadow-xl tw-rounded-xl">
                    <PlayIcon
                        className="tw-absolute tw-bottom-20 tw-left-4 tw-cursor-pointer tw-z-50"
                        onClick={toggleVideo}
                    />
                    <video src={creative.media?.url} className="tw-rounded-t-xl" />
                    <div className="tw-w-full tw-px-3 tw-pb-3 tw-h-14">
                        <p className="tw-break-all tw-mt-2 tw-flex tw-gap-x-2 tw-h-full tw-align-middle tw-items-center">
                            {creative.creativeType === 'TV' && <FaTv size={24} />}
                            {creative.creativeType === 'RADIO' && <Microphone className="tw-w-6" />}
                            <span className="tw-block">{creative.commercialName}</span> -{' '}
                            <span className="tw-block">{creative.durationTime}s</span>
                        </p>
                        {!creative.payment && (
                            <p className="tw-text-sm  tw-flex tw-gap-x-2">
                                <FaInfoCircle size={18} color="orange" />
                                Pagamento pendente!
                            </p>
                        )}
                    </div>
                    <Link
                        to={`/central-criativa/${creative.id}/detalhes`}
                        className="tw-absolute tw-bottom-20 tw-right-4 tw-cursor-pointer tw-z-50"
                    >
                        <FaInfoCircle size={47} color="purple" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CreativeItem;
