import React from 'react';

function ClipIcon({ color }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.7648 6.55555C17.4523 6.26343 16.947 6.26473 16.6362 6.5584L7.04338 15.6231C5.79883 16.7928 3.77749 16.7928 2.53062 15.6215C1.28469 14.4499 1.28469 12.5501 2.53077 11.379L12.4075 2.0474C13.1846 1.31716 14.4478 1.31716 15.2271 2.04898C16.0061 2.78116 16.0061 3.96831 15.2269 4.7007L7.04488 12.3906C7.04435 12.3911 7.0439 12.3916 7.04338 12.3921C6.73167 12.6835 6.22792 12.6831 5.91685 12.3907C5.60525 12.0979 5.60525 11.6232 5.91685 11.3304L9.86622 7.61771C10.1778 7.32478 10.1778 6.84988 9.86611 6.55702C9.55444 6.26417 9.04915 6.2642 8.73756 6.55713L4.78822 10.2697C3.85339 11.1483 3.85339 12.5727 4.78829 13.4514C5.72316 14.33 7.23872 14.33 8.17362 13.4514C8.1747 13.4504 8.17556 13.4493 8.17661 13.4483L16.3554 5.76132C17.7579 4.44314 17.7579 2.30623 16.3554 0.988052C14.9527 -0.329351 12.6792 -0.329351 11.2775 0.988052L1.40072 10.3197C-0.467132 12.0752 -0.467132 14.9245 1.40207 16.6822C3.27251 18.4392 6.30415 18.4392 8.17347 16.6823L17.7678 7.61623C18.0786 7.32253 18.0772 6.84767 17.7648 6.55555Z"
                fill={color || '#fff'}
            />
        </svg>
    );
}

export default ClipIcon;
