import React from 'react';
import PropTypes from 'prop-types';

const ChevronRightIcon = ({ className = '' }) => (
    <svg
        className={`icon ${className}`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
    </svg>
);

ChevronRightIcon.propTypes = {
    className: PropTypes.string,
};

export default ChevronRightIcon;
