import React, { useCallback, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { FaPlus } from 'react-icons/fa';
import { useLoading } from 'hooks/regions';
import { Creators as ActionCreators } from 'store/modules/regions';
import Loading from 'components/Loading';
import CreateRegionModal from 'pages/supplier/Programs/Regions/CreateRegionModal';
import RegionsTable from './RegionsTable';
import { useCompanyForCurrentUser, useRegiosByCompany } from '../hooks';

const AddRegionButton = ({ ...rest }) => (
    <button
        className="tw-flex tw-items-center tw-border-none tw-rounded-md tw-bg-pink tw-text-white tw-font-bold tw-px-5 tw-py-0 tw-h-10 md:tw-ml-3"
        {...rest}
    >
        <FaPlus size={18} className="tw-text-white" />
        <span className="tw-block tw-ml-3">Adicionar Região</span>
    </button>
);

const Regions = () => {
    const dispatch = useDispatch();

    const [showModal, toggleModal] = useReducer(state => !state, false);

    const handleEdit = useCallback(
        data => {
            dispatch(ActionCreators.setRegion(data));
            toggleModal();
        },
        [dispatch, toggleModal],
    );

    const company = useCompanyForCurrentUser();
    const regions = useRegiosByCompany(company?.id);
    const loading = useLoading();

    const handleAddNewRegion = () => {
        dispatch(ActionCreators.setRegion(null));
        toggleModal();
    };

    const hasRegion = regions?.length > 0;

    return (
        <div>
            {loading && <Loading />}
            <CreateRegionModal showModal={showModal} toggleModal={toggleModal} />

            {hasRegion && <AddRegionButton onClick={handleAddNewRegion} />}
            {hasRegion ? (
                <RegionsTable regions={regions} onEdit={handleEdit} />
            ) : (
                <div className="tw-flex tw-flex-col tw-place-content-center tw-items-center">
                    <p className="tw-text-gray">Nenhuma região cadastrada!</p>
                    <div className="tw-mt-2">
                        <AddRegionButton onClick={handleAddNewRegion} />
                    </div>
                </div>
            )}
        </div>
    );
};
export default Regions;
