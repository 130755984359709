import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';

const campaignDuration = [
    // { value: '10', label: '10 Segundos' },
    { value: '15', label: '15 Segundos' },
    { value: '30', label: '30 Segundos' },
    { value: '60', label: '60 Segundos' },
];

const DurationSelector = forwardRef(({ name, error }, reference) => (
    <div className="form-control">
        <span className="label tw-mb-4 tw-font-semibold">Duração do anúncio*</span>
        {error && <p className="error-message">{error.message}</p>}
        {campaignDuration.map(duration => (
            <span key={duration.value} className="tw-mr-4 tw-inline-flex tw-align-middle tw-place-items-center">
                <input
                    name={name}
                    id={`${name}${duration.value}`}
                    type="radio"
                    value={duration.value}
                    ref={reference}
                />
                <label htmlFor={`${name}${duration.value}`} className="tw-ml-2">
                    {duration.label}
                </label>
            </span>
        ))}
    </div>
));

DurationSelector.displayName = 'DurationSelector';

DurationSelector.propTypes = {
    name: PropTypes.string.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
    }),
};

export default DurationSelector;
