import * as Sentry from '@sentry/react';
import { defaultConverter } from 'utils';
import { syncCoverageCities } from 'src/services/coverageCities';
import { addProgram, getAllPrograms, getProgramByCompanyId, getProgramByState } from 'api/programs';

// Actions creator
const GET_PROGRAM = 'program/GET_PROGRAM';
const UPDATE_PROGRAM = 'program/UPDATE_PROGRAM';
const CREATE_PROGRAM = 'program/CREATE_PROGRAM';
const GET_PROGRAMS = 'program/GET_PROGRAMS';
const GET_PROGRAMS_BY_IDS = 'program/GET_PROGRAMS_BY_IDS';
const GET_PROGRAMS_BY_COMPANY = 'GET_PROGRAMS_BY_COMPANY';
const GET_PROGRAMS_BY_STATE = 'program/GET_PROGRAMS_BY_STATE';
const GET_PROGRAMS_BY_FILTERS = 'program/GET_PROGRAMS_BY_FILTERS';
const SET_PROGRAM = 'SET_PROGRAM';
const SET_STEP = 'SET_STEP';
const SET_NEW_PROGRAM = 'SET_NEW_PROGRAM';

// Actions
export const getPrograms = () => async dispatch => {
    let firestorePrograms = [];
    await getAllPrograms()
        .withConverter(defaultConverter)
        .get()
        .then(snapshot => snapshot.forEach(document_ => firestorePrograms.push(document_.data())));

    return dispatch({
        type: 'GET_PROGRAMS',
        payload: firestorePrograms,
    });
};

export const getProgramsByCompanyId = companyId => async dispatch => {
    let firestorePrograms = [];
    await getAllPrograms()
        .withConverter(defaultConverter)
        .where('companyId', '==', companyId)
        .get()
        .then(snapshot => snapshot.forEach(document_ => firestorePrograms.push(document_.data())));
    return dispatch({
        type: GET_PROGRAMS_BY_COMPANY,
        payload: firestorePrograms,
    });
};

export const createProgram = (data, meta) => async dispatch => {
    try {
        const {
            region: { cities, state },
            ...payload
        } = data;

        const programReference = await addProgram(payload);
        await programReference.update({ id: programReference.id });
        const programDocument = await programReference.get();

        await syncCoverageCities(state, cities, programReference, true);

        if (meta.onSuccess) meta.onSuccess(programDocument.data());

        return dispatch({
            type: 'CREATE_PROGRAM',
            payload: programDocument.data(),
        });
    } catch (error) {
        if (meta?.onFailure) meta.onFailure(error);
    }
};

export const deleteProgram = (programId, meta) => async dispatch => {
    try {
        await getAllPrograms().doc(programId).delete();
        if (meta.onSuccess) meta.onSuccess();
        return dispatch({
            type: 'DELETE_PROGRAM',
        });
    } catch (error) {
        Sentry.captureException(error);
        if (meta?.onFailure) meta.onFailure(error);
    }
};

export const updateProgram = (programId, data, meta) => async dispatch => {
    try {
        const {
            region: { cities, state },
            ...payload
        } = data;

        const programReference = getAllPrograms().doc(programId);
        await programReference.update(payload);

        const program = await programReference.get();

        await syncCoverageCities(state, cities, program.ref);

        if (meta.onSuccess) meta.onSuccess();

        return dispatch({
            type: 'UPDATE_PROGRAM',
            payload: program.data(),
        });
    } catch {
        if (meta?.onFailure) meta.onFailure();
    }
};

export const getProgramsByState = state => async dispatch => {
    let firestorePrograms = [];
    await getProgramByState(state)
        .withConverter(defaultConverter)
        .get()
        .then(snapshot => snapshot.forEach(document_ => firestorePrograms.push(document_.data())));

    return dispatch({
        type: 'GET_PROGRAMS_BY_STATE',
        payload: firestorePrograms,
    });
};

export const getProgramsByCompany = companyId => async dispatch => {
    let firestorePrograms = [];
    await getProgramByCompanyId(companyId)
        .withConverter(defaultConverter)
        .get()
        .then(snapshot => snapshot.forEach(document_ => firestorePrograms.push(document_.data())));

    return dispatch({
        type: GET_PROGRAMS_BY_COMPANY,
        payload: firestorePrograms,
    });
};

export const getProgramsByFilters = (state, segment, bestTime, gender, duration, income, age) => async dispatch => {
    let programsByState = [];
    let programs = [];

    await getProgramByState(state)
        .withConverter(defaultConverter)
        .get()
        .then(snapshot => snapshot.forEach(document_ => programsByState.push(document_.data())));

    programsByState.map(pbs =>
        pbs.audience === segment
            ? !programs.some(program => program.id === pbs.id)
                ? programs.push(pbs)
                : undefined
            : undefined,
    );

    if (gender !== 'other') {
        programsByState.map(pbs =>
            pbs.gender === gender
                ? !programs.some(program => program.id === pbs.id)
                    ? programs.push(pbs)
                    : undefined
                : undefined,
        );
    }

    if (income !== 'All') {
        programsByState.map(pbs =>
            pbs.income === income
                ? !programs.some(program => program.id === pbs.id)
                    ? programs.push(pbs)
                    : undefined
                : undefined,
        );
    }

    programsByState.map(pbs =>
        pbs.ageRange === age
            ? !programs.some(program => program.id === pbs.id)
                ? programs.push(pbs)
                : undefined
            : undefined,
    );

    // programsByState.map(pbs => pbs.duration === duration
    //   ? !programs.find(program => program.id === pbs.id) ? programs.push(pbs) : null
    //   : null)

    // programsByState.map(pbs => pbs.bestTime === bestTime
    //   ? !programs.find(program => program.id === pbs.id) ? programs.push(pbs) : null
    //   : null)

    return dispatch({
        type: 'GET_PROGRAMS_BY_FILTERS',
        payload: programs,
    });
};

export const setProgram = payload => async dispatch => {
    return dispatch({
        type: SET_PROGRAM,
        payload,
    });
};

export const setStep = payload => async dispatch => {
    let step = payload;
    if (step < 0) step = 0;
    if (step > 2) step = 2;
    return dispatch({
        type: SET_STEP,
        payload: step,
    });
};

export const setNewProgram = () => async dispatch => {
    return dispatch({
        type: SET_NEW_PROGRAM,
    });
};

const initState = {
    program: {},
    programs: [],
    currentStep: 0,
};

// Reducer
const programReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_PROGRAM:
            return {
                ...state,
                program: action.payload,
            };
        case UPDATE_PROGRAM:
            return {
                ...state,
                program: action.payload,
            };
        case CREATE_PROGRAM:
            return {
                ...state,
                program: action.payload,
                programs: [...state.programs, action.payload],
            };
        case GET_PROGRAMS:
            return {
                ...state,
                programs: action.payload,
            };
        case GET_PROGRAMS_BY_IDS:
            return {
                ...state,
                programs: action.payload,
            };
        case GET_PROGRAMS_BY_COMPANY:
            return {
                ...state,
                programs: action.payload,
            };
        case GET_PROGRAMS_BY_STATE:
            return {
                ...state,
                programs: action.payload,
            };
        case GET_PROGRAMS_BY_FILTERS:
            return {
                ...state,
                programs: action.payload,
            };
        case SET_PROGRAM:
            return {
                ...state,
                program: action.payload,
            };
        case SET_STEP:
            return {
                ...state,
                currentStep: action.payload,
            };
        case SET_NEW_PROGRAM: {
            return {
                ...state,
                program: {},
            };
        }
        default:
            return state;
    }
};

export default programReducer;
