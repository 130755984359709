import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { Creators as ActionsCreators, Types } from 'store/modules/creative';
import { addCreative, getCreatives, updateCreative } from 'api/creative';
import { processPaymentForCreative } from 'base';

function* getCreativesSaga(action) {
    const { meta, duration, userId } = action;

    const onSuccess = meta?.onSuccess;
    const onFailure = meta?.onFailure;

    try {
        yield put(ActionsCreators.setIsLoadingCreatives(true));
        const snapshot = yield call(getCreatives, userId, duration);
        const creativeDocuments = snapshot.docs.map(document_ => document_.data());

        yield put(ActionsCreators.getCreativesSuccess(creativeDocuments));
        if (onSuccess) yield call(onSuccess);
    } catch (error) {
        Sentry.captureException(error);
        if (onFailure) yield call(onFailure);
    } finally {
        yield put(ActionsCreators.setIsLoadingCreatives(false));
    }
}

function* creativeSubmitSaga(action) {
    const { meta, creative, paymentMethod, coupon } = action;

    const onSuccess = meta?.onSuccess;
    const onFailure = meta?.onFailure;

    try {
        let { reference: documentReference, ...creativeData } = creative;
        if (!documentReference) {
            documentReference = yield call(addCreative, creativeData);
            yield put(ActionsCreators.setCreativeReference(documentReference));
        }

        const hasCreditCardAsPaymentMethod = paymentMethod === 'credit-card';

        if (hasCreditCardAsPaymentMethod) {
            yield call(processPaymentForCreative, {
                creativeId: documentReference.id,
                coupon: coupon || creative.coupon,
            });
        }

        if (onSuccess) yield call(onSuccess, documentReference);
    } catch (error) {
        Sentry.captureException(error);
        if (onFailure) yield call(onFailure, error);
    }
}

function* setCreativeCampaignIdsSaga(action) {
    const {
        meta,
        data: { creativeId, campaignIds },
    } = action;

    const onSuccess = meta?.onSuccess;
    const onFailure = meta?.onFailure;

    try {
        yield call(updateCreative, { creativeId, campaignIds });
        if (onSuccess) yield call(onSuccess);
    } catch {
        if (onFailure) yield call(onFailure);
    }
}

function* creativesRoot() {
    yield all([
        takeLatest(Types.GET_CREATIVES, getCreativesSaga),
        takeLatest(Types.CREATIVE_SUBMIT, creativeSubmitSaga),
        takeLatest(Types.SET_CREATIVE_CAMPAIGN_IDS, setCreativeCampaignIdsSaga),
    ]);
}

export default creativesRoot;
