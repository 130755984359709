import React, { forwardRef, memo } from 'react';
import { PropTypes } from 'prop-types';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';

const InputField = forwardRef(
    ({ label, name, error, className = '', inputClassName = '', mask, control, ...rest }, reference) => (
        <div className={`form-control ${className}`}>
            <label className="tw-font-semibold" htmlFor={name}>
                {label}
            </label>
            <div>
                {mask && control ? (
                    <Controller
                        as={InputMask}
                        mask={mask}
                        className={`${
                            inputClassName ? inputClassName : ''
                        } tw-py-2 tw-px-0 tw-w-full tw-bg-transparent tw-border-b-2 tw-border-t-0 tw-border-r-0 tw-border-l-0`}
                        control={control}
                        name={name}
                        maskChar=""
                        {...rest}
                    />
                ) : (
                    <input
                        name={name}
                        className={`${
                            inputClassName ? inputClassName : ''
                        } tw-py-2 tw-px-0 tw-w-full tw-bg-transparent tw-border-b-2 tw-border-t-0 tw-border-r-0 tw-border-l-0`}
                        ref={reference}
                        {...rest}
                    />
                )}
            </div>
            {error && <p className="error-message">{error.message}</p>}
        </div>
    ),
);

InputField.displayName = 'Input';

InputField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
    }),
};

export default memo(InputField);
