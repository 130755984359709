import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ALLOWED_OBJECTIVES } from './schema';

const ObjectiveSelector = forwardRef(({ name, error }, reference) => (
    <div className="form-control">
        {error && <p className="error-message">{error.message}</p>}
        <div className="tw-flex tw-justify-start md:tw-flex-row tw-flex-col">
            {ALLOWED_OBJECTIVES.map(objetive => (
                <span
                    key={objetive.name}
                    className="tw-mr-4 tw-inline-flex tw-align-middle tw-place-items-start tw-mt-4 tw-max-w-sm"
                >
                    <input
                        name={name}
                        id={`${name}${objetive.name}`}
                        type="radio"
                        value={objetive.name}
                        className="tw-mt-1 tw-mr-1"
                        ref={reference}
                    />
                    <label htmlFor={`${name}${objetive.name}`} className="tw-ml-2">
                        <strong>{objetive.title}</strong> - {objetive.description}
                    </label>
                </span>
            ))}
        </div>
    </div>
));

ObjectiveSelector.displayName = 'ObjectiveSelector';

ObjectiveSelector.propTypes = {
    name: PropTypes.string.isRequired,
    error: PropTypes.shape({
        message: PropTypes.string.isRequired,
    }),
};

export default ObjectiveSelector;
