import React, { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import yup from 'validator';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'molecules/InputField';
import { Creators as ActionsCreators } from 'store/modules/creative';

const schema = yup.object().shape({
    addess: yup.string().defined().required().label('Endereço'),
    neighborhood: yup.string().defined().required().label('Bairro'),
    city: yup.string().defined().required().label('Cidade'),
    state: yup.string().defined().required().label('Estado'),
    phone: yup.string().defined().required().label('Telefone'),
});

const InputCheckbox = forwardRef(({ name, label }, reference) => (
    <div className="tw-flex tw-items-center ">
        <input
            type="checkbox"
            className="tw-border tw-border-pink tw-rounded-sm"
            ref={reference}
            name={name}
            id={name}
        />
        <label htmlFor={name} className="tw-block tw-ml-3">
            {label}
        </label>
    </div>
));
InputCheckbox.displayName = 'InputCheckbox';

const MoreInfo = () => {
    const dispatch = useDispatch();

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        defaultValues: schema.getDefaultFromShape(),
    });

    const handleBack = () => dispatch(ActionsCreators.goPreviousStep());
    const onSubmit = values => {
        dispatch(ActionsCreators.setMoreInfo(values));
        dispatch(ActionsCreators.goNextStep());
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="tw-w-full">
            <h2 className="tw-text-pink tw-normal-case tw-font-bold">Informações adicionais</h2>
            <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
                <InputField
                    label="Endereço"
                    name="addess"
                    autoComplete="street-address"
                    ref={register}
                    className="tw-w-full md:tw-w-1/3 tw-mt-6"
                    inputClassName="tw-w-full tw-border-gray-500"
                    error={errors.addess}
                />
                <InputField
                    label="Bairro"
                    name="neighborhood"
                    autoComplete="address-level3"
                    ref={register}
                    className="tw-w-full md:tw-w-2/3 tw-mt-6 md:tw-ml-8"
                    inputClassName="tw-w-full tw-border-gray-500"
                    error={errors.neighborhood}
                />
            </div>
            <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
                <InputField
                    label="Cidade"
                    name="city"
                    autoComplete="address-level2"
                    ref={register}
                    className="tw-w-full md:tw-w-1/3 tw-mt-6"
                    inputClassName="tw-w-full tw-border-gray-500"
                    error={errors.city}
                />
                <InputField
                    label="Estado"
                    name="state"
                    autoComplete="address-level1"
                    ref={register}
                    className="tw-w-full md:tw-w-2/3 tw-mt-6 md:tw-ml-8"
                    inputClassName="tw-w-full tw-border-gray-500"
                    error={errors.state}
                />
            </div>
            <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-center">
                <InputField
                    label="Telefone"
                    name="phone"
                    autoComplete="tel-local"
                    ref={register}
                    mask="(99) 9999-9999"
                    className="tw-w-full md:tw-w-1/3 tw-mt-6"
                    inputClassName="tw-w-full tw-border-gray-500"
                    error={errors.phone}
                />
                <InputField
                    label="Whatsapp"
                    name="whatsapp"
                    autoComplete="mobile"
                    ref={register}
                    mask="(99) 99999-9999"
                    className="tw-w-full md:tw-w-1/3 tw-mt-6 md:tw-ml-8"
                    inputClassName="tw-w-full tw-border-gray-500"
                />
                <InputField
                    label="Site ou E-mail"
                    name="siteOrEmail"
                    ref={register}
                    className="tw-w-full md:tw-w-1/3 tw-mt-6 md:tw-ml-8"
                    inputClassName="tw-w-full tw-border-gray-500"
                />
            </div>
            <InputField
                label="Informe aqui o que você gostaria de ver no seu comercial"
                name="wouldLike"
                ref={register}
                className="tw-w-ful tw-mt-6"
                inputClassName="tw-w-full tw-border-gray-500"
            />
            <div className="tw-w-1/2 tw-flex tw-flex-col tw-justify-center tw-mt-8">
                <p>Gostaria de destacar algum serviço?</p>
                <div className="tw-flex tw-flex-row">
                    <div className="tw-w-flex">
                        <InputCheckbox name="delivery" label="Delivery" ref={register} />
                    </div>
                    <div className="tw-w-flex tw-ml-6">
                        <InputCheckbox name="pickup" label="Retirada" ref={register} />
                    </div>
                </div>
            </div>
            <div className="tw-mt-10 tw-text-center tw-mb-4 md:tw-mt-24">
                <button className="button button-primary-outlined tw-mr-4" onClick={handleBack} type="button">
                    Voltar
                </button>
                <button className="button button-primary" type="submit">
                    Prosseguir
                </button>
            </div>
        </form>
    );
};

export default MoreInfo;
