import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { getCommercialCount } from 'services/advertiser/creative';
import { useCurrentCreative } from 'hooks/creative';
import { Creators as ActionsCreators } from 'store/modules/creative';
import PlusIcon from 'icons/PlusIcon';
import OfferModal from './OfferModal';
import { toast } from 'react-toastify';

const Offers = () => {
    const [offers, setOffers] = useState([]);
    const [openOfferModal, toggleOfferModal] = useReducer(state => !state, false);

    const dispatch = useDispatch();
    const creative = useCurrentCreative();

    const handleBack = () => dispatch(ActionsCreators.goPreviousStep());

    const handleNext = () => {
        dispatch(
            ActionsCreators.setOffers({
                offers,
            }),
        );
        dispatch(ActionsCreators.goNextStep());
    };

    const allowAddMoreOffer = offers.length + 1 <= getCommercialCount(creative.durationTime);

    function handleAddOffer(offer) {
        toggleOfferModal();
        if (offers.length === getCommercialCount(creative.durationTime)) {
            toast.error('O número máximo de ofertas foi atingido!');
            return;
        }
        setOffers(previous => {
            return [...previous, offer];
        });
    }
    const handleRemoveOffer = useCallback(
        product => setOffers(previous => previous.filter(item => item.product !== product)),
        [],
    );

    useEffect(() => {
        if (creative?.offers) {
            setOffers(creative.offers);
        }
    }, [creative]);

    return (
        <div className="tw-w-full">
            <OfferModal visible={openOfferModal} onClose={toggleOfferModal} onAdd={handleAddOffer} />
            <h2 className="tw-text-pink tw-normal-case tw-font-bold">Adicione Ofertas ao Seu Comercial</h2>
            <p>
                Caso queira dar destaque a um produto ou serviço, você pode incluí-los como ofertas em seu comercial
                clicando no botão abaixo.
            </p>

            <div className="tw-w-ful tw-mt-9">
                <div className="tw-w-full tw-max-w-md tw-p-4 tw-rounded-lg tw-shadow-lg tw-bg-white">
                    Duração Selecionada:{' '}
                    <span className="tw-font-bold tw-text-pink">{`${creative?.durationTime} Segundos: `}</span>
                    <span className="tw-text-purple">{`Até ${getCommercialCount(creative.durationTime)} Ofertas`}</span>
                </div>
            </div>

            <div className="tw-w-full tw-flex tw-flex-wrap tw-mt-12 tw-justify-start">
                {offers?.map(offer => (
                    <div
                        key={offer.product}
                        className="tw-w-full tw-max-w-xs tw-h-40 tw-mb-8 md:tw-mr-8 tw-mt-5 tw-relative"
                    >
                        <FaTrash
                            size={18}
                            className="tw-absolute tw-top-2 tw-right-2 tw-z-1 tw-cursor-pointer"
                            color="#e5195a"
                            onClick={() => handleRemoveOffer(offer.product)}
                        />
                        <div className="tw-flex tw-flex-col tw-relative">
                            <div className="tw-w-full tw-shadow-xl tw-rounded-xl">
                                {offer.offerImage ? (
                                    <img
                                        src={offer.offerImage}
                                        className="tw-h-32 tw-rounded-t-xl tw-object-cover tw-w-full"
                                        alt="offerr"
                                    />
                                ) : (
                                    <div className="tw-h-32 tw-bg-gray-300 tw-rounded-t-xl" />
                                )}
                                <div className="tw-w-full tw-h-11 tw-flex tw-justify-between tw-items-center tw-px-3">
                                    <p>{offer?.product}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <button
                    type="button"
                    className="tw-w-full tw-max-w-xs tw-h-40 tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-flex-col tw-rounded-md tw-px-6 tw-cursor-pointer tw-mt-5"
                    onClick={toggleOfferModal}
                    disabled={!allowAddMoreOffer}
                >
                    <div className="tw-w-full tw-flex tw-justify-center tw-mb-1">
                        <PlusIcon color={allowAddMoreOffer ? 'purple' : 'gray'} />
                    </div>
                    <p className="tw-text-gray-700 tw-text-center tw-text-sm">Adicionar oferta</p>
                </button>
            </div>
            <div className="tw-mt-10 tw-text-center tw-mb-4 md:tw-mt-24">
                <button type="button" className="button button-primary-outlined tw-mr-4" onClick={handleBack}>
                    Voltar
                </button>
                <button type="button" className="button button-primary" onClick={handleNext}>
                    Prosseguir
                </button>
            </div>
        </div>
    );
};

export default Offers;
