import React from 'react';
import * as PropTypes from 'prop-types';
import { getColor } from './utils';

const DistritoFederalPathIcon = ({ active = false, available = false }) => {
    const color = getColor(active, available);
    return (
        <>
            <path
                fill={color}
                stroke="#FFFFFF"
                strokeWidth="1.0404"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M292.461,246.197
        c0,0,12.929-2.903,14.188,0c1.233,2.903,0.659,10.683-1.424,11.504c-2.08,0.849-14.296-1.806-14.023-3.313
        C291.503,252.853,292.461,246.197,292.461,246.197z"
            />
            <path
                fill={color}
                className="circle"
                d="M300.735,238.34c6.212,0,11.26,5.035,11.26,11.258c0,6.21-5.048,11.263-11.26,11.263
        c-6.209,0-11.261-5.053-11.261-11.263C289.475,243.377,294.523,238.34,300.735,238.34z"
            />
        </>
    );
};

DistritoFederalPathIcon.propTypes = { active: PropTypes.bool };

export default DistritoFederalPathIcon;
