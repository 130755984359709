import React from 'react';
import { FaEnvelope } from 'react-icons/fa';

const RequestPIButton = properties => (
    <button
        type="submit"
        className="tw-flex tw-flex-col tw-bg-transparent tw-border-none md:tw-mt-0 tw-mt-3"
        {...properties}
    >
        <div className="tw-w-full tw-flex tw-justify-center">
            <FaEnvelope size={28} color="gray" />
        </div>
        <div className="tw-w-full tw-flex tw-justify-center">
            <span className="tw-text-center tw-text-sm tw-text-gray">Solicitar Pi</span>
        </div>
    </button>
);

export default RequestPIButton;
