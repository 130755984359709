import React from 'react';
import PropTypes from 'prop-types';

const MenuAltIcon = ({ className = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className={`icon ${className}`}
    >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h8m-8 6h16" />
    </svg>
);

MenuAltIcon.propTypes = {
    className: PropTypes.string,
};

export default MenuAltIcon;
