import React from 'react';
import PropTypes from 'prop-types';

const UncheckedIcon = ({ className = '' }) => (
    <svg className={className} fill="none" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16.72" cy="16.72" r="16.22" stroke="#BDBDBD" />
    </svg>
);

UncheckedIcon.propTypes = {
    className: PropTypes.string,
};
UncheckedIcon.defaultProps = {
    className: '',
};

export default UncheckedIcon;
