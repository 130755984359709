import yup from 'validator';

const schema = yup.object({
    budgetType: yup.string().required().oneOf(['automatic', 'manual']).label('Modo de campanha'),
    amount: yup
        .number()
        .positive()
        .when('budgetType', {
            is: 'automatic',
            // eslint-disable-next-line unicorn/no-thenable
            then: yup.number().positive().required(),
        })
        .label('Valor de investimento'),
    radioBudget: yup.number().default(0),
    tvBudget: yup.number().default(0),
});

export default schema;
