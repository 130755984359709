import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { useFirestoreConnect } from 'react-redux-firebase';
import { hot } from 'react-hot-loader/root';
import { useIntercom } from 'react-use-intercom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createBrowserHistory } from 'history';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { RewriteFrames } from '@sentry/integrations';
import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CryptoJS from 'crypto-js';
import Routes from 'routes';
import app, { analytics, auth, firebase } from 'base';
import GlobalStyles from 'helpers/styles/global';
import CalculationState from './store/modules/calculation/CalculationState';
import './assets/app.css';
import './assets/style.css';
import styleConfig from './style_config.json';

const history = createBrowserHistory();

if (__SENTRY_DSN__) {
    const environment = __PRODUCTION__ ? 'production' : 'development';
    Sentry.init({
        dsn: __SENTRY_DSN__,
        environment,
        release: __VERSION__,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),
            new RewriteFrames(),
        ],
        tracingOrigins: styleConfig['domains'],
        attachStacktrace: true,
        tracesSampleRate: 1,
        ignoreUrls: [
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /firestore.googleapis.com/i,
        ],
        normalizeDepth: 10,
        beforeSend(event, hint) {
            if (__DEVELOPMENT__) {
                return null;
            }

            const error = hint?.originalException;
            analytics.logEvent(firebase.analytics.EventName.EXCEPTION, error);
            return event;
        },
    });
} else {
    console.warn('Sentry was not loaded!');
}

if (__DEVELOPMENT__ && INTERCOM_APP_ID) {
    console.warn('Using INTERCOM in development mode');
}

const queryClient = new QueryClient();

function App() {
    useFirestoreConnect([
        { collection: 'users' },
        { collection: 'programs' },
        { collection: 'company' },
        { collection: 'campaigns' },
    ]);

    const { boot, shutdown, update } = useIntercom();

    useEffect(() => {
        if (INTERCOM_APP_ID) {
            boot({
                app_id: INTERCOM_APP_ID,
                customAttributes: {
                    product: styleConfig['small_name'],
                },
            });
        }

        return () => {
            if (INTERCOM_APP_ID) {
                shutdown();
            }
        };
    }, [boot, shutdown]);

    useEffect(() => {
        function handleAuthStateChange(user) {
            if (user) {
                const userHash = CryptoJS.HmacSHA256(user.uid, '1VTFpPr1aQ9i0gg6Z4XooBPZq2Q_diM3nvM6i-Qd');
                const hashInHex = CryptoJS.enc.Hex.stringify(userHash);
                update({
                    name: user.displayName,
                    email: user.email || 'Não informado',
                    phone: user.phoneNumber || '000000000',
                    customAttributes: {
                        user_id: user.uid,
                        user_hash: hashInHex,
                        product: styleConfig['small_name'],
                    },
                });
            }
        }

        let authStateChangedToken;
        if (INTERCOM_APP_ID) {
            authStateChangedToken = auth.onAuthStateChanged(handleAuthStateChange);
        }

        return () => {
            if (authStateChangedToken) {
                authStateChangedToken();
            }
        };
    }, [shutdown, update]);

    const role = useSelector(store => store.firebase.profile.role);
    useEffect(() => {
        function fetchUserClaims() {
            auth.currentUser.getIdTokenResult().then(userToken => {
                if (userToken) {
                    analytics.setUserProperties({
                        role: userToken.claims.role,
                    });
                }
            });
        }

        function handleAuthStateChange(user) {
            if (user) {
                analytics.setUserId(user.uid);
                app.installations()
                    .getId()
                    .then(installationId => {
                        console.log('Installation ID is "%s"', installationId);
                        analytics.setUserProperties({
                            installationId,
                            appVersion: __VERSION__,
                            role,
                        });
                    })
                    .catch(error => {
                        Sentry.captureException(error);
                        analytics.setUserProperties({
                            appVersion: __VERSION__,
                            role,
                        });
                    });

                if (auth.currentUser) {
                    fetchUserClaims();
                }
            }
        }
        const authStateChangedToken = auth.onAuthStateChanged(handleAuthStateChange);

        return () => authStateChangedToken();
    }, [role]);

    return (
        <QueryClientProvider client={queryClient}>
            <GlobalStyles />
            <CalculationState>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <Sentry.ErrorBoundary fallback={'Erro interno!'}>
                        <Router history={history}>
                            <Routes />
                        </Router>
                    </Sentry.ErrorBoundary>
                </MuiPickersUtilsProvider>
            </CalculationState>
            {__DEVELOPMENT__ && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    );
}

export default Sentry.withProfiler(hot(App));
