import React from 'react';
import * as PropTypes from 'prop-types';
import { getColor } from './utils';

const ParaibaPathIcon = ({ active = false, available = false }) => {
    const color = getColor(active, available);
    return (
        <>
            <path
                fill={color}
                stroke="#FFFFFF"
                strokeWidth="1.0404"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M401.575,141.096
        c2.081-3.081,16.791-6.82,19.117-4.616c0,1.918,7.259,1.686,10.133,2.712c-0.492,3.038,12.652,1.533,14.408,2.259
        c1.421,0.589,3.833,11.983,1.421,12.202c-0.874-1.124-2.083-1.739-3.586-1.835c-2.957-0.027-2.546,1.863-4.383,3.108
        c-2.626,1.767-6.571,1.917-9.558,2.109c-0.162,1.232-3.943,4.438-5.259,4.916c-3.122,1.149-2.657-2.727-5.095-3.602
        c0.713-1.124,4.082-5.203,3.725-6.205c-1.423-3.846-12.051,5.52-14.981,3.506c-1.396-0.973-6.218,1.493-3.476-2.588
        C405.574,150.776,400.398,142.889,401.575,141.096z"
            />
            <path
                fill={color}
                className="circle"
                d="M433.797,133.597c6.237,0,11.26,5.051,11.26,11.261c0,6.226-5.022,11.262-11.26,11.262
        c-6.208,0-11.257-5.036-11.257-11.262C422.54,138.647,427.589,133.597,433.797,133.597z"
            />
        </>
    );
};

ParaibaPathIcon.propTypes = { active: PropTypes.bool };

export default ParaibaPathIcon;
