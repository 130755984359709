import React, { useState } from 'react';

import CnpjDiscount from './cnpjCustomDiscount';
import RangeDiscount from './valueRangeDiscount';

function Discounts() {
    const [tab, setTab] = useState(0);
    return (
        <div className="tw-w-full">
            <div className="tw-flex md:tw-flex-row tw-flex-col">
                <div
                    className="tw-flex tw-flex-col tw-bg-transparent tw-border-none tw-outline-none tw-cursor-pointer"
                    onClick={() => setTab(0)}
                >
                    <p
                        className={`${
                            tab === 0 ? 'tw-text-pink tw-text-bolder' : 'tw-text-purple'
                        } tw-my-1 tw-text-center`}
                    >
                        Descontos por CNPJ
                    </p>
                    {tab === 0 && <div className="tw-bg-pink tw-h-1" />}
                </div>
                <div
                    className="tw-flex tw-flex-col tw-bg-transparent tw-border-none tw-outline-none tw-cursor-pointer md:tw-ml-6"
                    onClick={() => setTab(1)}
                >
                    <p
                        className={`${
                            tab === 1 ? 'tw-text-pink tw-text-bolder' : 'tw-text-purple'
                        } tw-my-1 tw-text-center`}
                    >
                        Descontos por Valor
                    </p>
                    {tab === 1 && <div className="tw-bg-pink tw-h-1" />}
                </div>
            </div>
            <br />
            {tab === 0 ? <CnpjDiscount /> : <RangeDiscount />}
        </div>
    );
}

export default Discounts;
