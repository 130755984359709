import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const CardIcon = ({ title, icon, to, className, ...rest }) => {
    const history = useHistory();
    function handleClick(event) {
        event.preventDefault();
        if (to?.includes('http')) {
            window.open(to);
        } else {
            history.push(to);
        }
    }
    return (
        <button
            className={`${className} icon-card tw-p-4 tw-pt-6 tw-h-28 md:tw-w-44 tw-w-full md:tw-mb-0 tw-mb-6`}
            onClick={handleClick}
            {...rest}
        >
            <div>{icon}</div>
            <div>
                <span className="tw-text-sm tw-mt-2">{title}</span>
            </div>
        </button>
    );
};

CardIcon.propTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.element.isRequired,
};

export default CardIcon;
