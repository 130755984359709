import * as yup from 'yup';

// function rangeToString(value) {
//     return this.isType(value) && value !== null ? `${value.min},${value.max}` : value;
// }
//
// const ageRangeSchema = yup.number().min(0).max(100).required();

const programBaseInfoSchema = yup.object().shape({
    // ageRange: yup
    //     .object({
    //         min: ageRangeSchema,
    //         max: ageRangeSchema,
    //     })
    //     .transform(rangeToString)
    //     .typeError('${path} inválido')
    //     .required()
    //     .label('Faixa etária'),
    programName: yup.string().required().label('Nome do programa'),
    availableSeconds: yup.number().min(0).typeError('${path} inválido').required().label('Segundos disponíveis'),
    insertionCost: yup.number().typeError('${path} inválido').min(1).required().label('Valor da inserção'),
    insertionDiscount: yup.number().typeError('${path} inválido').min(0).max(100).label('Valor de desconto'),
    programRegionId: yup.string().required().label('Região do programa'),
    programType: yup.string().oneOf(['TV', 'RADIO']).required().label('Tipo de programa'),
    teaserUrl: yup.string().url().label('Link Teaser'),
    programDescription: yup.string().required().label('Descrição do programa'),
});

export default programBaseInfoSchema;
