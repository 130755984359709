import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, getUser } from 'store/modules/user';

import * as S from './styles';

const EditProfile = () => {
    const dispatch = useDispatch();
    const user = useSelector(({ userReducer }) => userReducer.user);
    const [name, setName] = useState(user.displayName);
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone);
    const [company, setCompany] = useState(user.company);

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    const handleUpdateProfile = useCallback(
        event => {
            event.preventDefault();
            let userData = {
                id: user.id,
                displayName: name,
                email: email,
                phone: phone,
                company: company,
            };
            dispatch(updateUser(userData));
        },
        [company, dispatch, email, name, phone, user.id],
    );

    const handleInputChange = useCallback(event => {
        switch (event.target.id) {
            case 'name':
                setName(event.target.value);
                break;
            case 'email':
                setEmail(event.target.value);
                break;
            case 'phone':
                setPhone(event.target.value);
                break;
            case 'company':
                setCompany(event.target.value);
                break;
            default:
                break;
        }
    }, []);

    return user ? (
        <S.Container>
            <S.Title>Editar Perfil</S.Title>

            <S.EditProfileFormWrapper>
                <S.LoginForm onSubmit={handleUpdateProfile}>
                    <S.Column>
                        <S.FormGroup>
                            <S.Label htmlFor="name">Seu nome</S.Label>
                            <S.Input
                                onChange={handleInputChange}
                                id="name"
                                value={user.displayName}
                                type="text"
                                name="name"
                            />
                        </S.FormGroup>
                        <S.FormGroup>
                            <S.Label htmlFor="email">Seu email</S.Label>
                            <S.Input
                                onChange={handleInputChange}
                                id="email"
                                value={user.email}
                                type="email"
                                name="email"
                            />
                        </S.FormGroup>

                        <S.FormGroup>
                            <S.Label htmlFor="phone">Telefone</S.Label>
                            <S.Input
                                onChange={handleInputChange}
                                id="phone"
                                value={user.phone}
                                type="tel"
                                name="phone"
                            />
                        </S.FormGroup>
                        <S.FormGroup>
                            <S.Label htmlFor="company">Empresa</S.Label>
                            <S.Input
                                id="company"
                                value={user.company}
                                type="text"
                                name="company"
                                onChange={handleInputChange}
                            />
                        </S.FormGroup>
                        <S.Button type="submit">SALVAR PERFIL</S.Button>
                    </S.Column>
                </S.LoginForm>
            </S.EditProfileFormWrapper>
        </S.Container>
    ) : (
        <h1>Carregando dados do perfil</h1>
    );
};

export default EditProfile;
