import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as S from './styles';

function DetailCampaign(properties) {
    const { id } = properties.match.params;

    const uid = useSelector(state => state.firebase.auth.uid);
    const campaign = useSelector(state => state.firestore.ordered.campaigns).filter(campaign => campaign.id === id);

    return campaign && campaign[0].userId === uid ? (
        <S.Container>
            <S.Subtitle> Detalhe da campanha </S.Subtitle>
            <h1>
                {campaign[0].nome}

                <span>id: {campaign[0].id}</span>
            </h1>
            <h2>Sobre a campanha</h2>
            <S.CampaignDetails>
                <div>
                    Nome da Campanha: <strong>{campaign[0].nome}</strong>
                    <br />
                    Reconhecimento de Marca: <strong>{campaign[0].brandAwareness}</strong> <br />
                    Alcance: <strong>{campaign[0].reach}</strong> <br />
                    <br />
                </div>
                <div>
                    Início da Campanha:{' '}
                    <strong>
                        {new Date(campaign[0].periodoInicioCampanha.seconds * 1000).toLocaleDateString()} às{' '}
                        {new Date(campaign[0].periodoInicioCampanha.seconds * 1000).toLocaleTimeString()}
                    </strong>{' '}
                    <br />
                    Fim da Campanha:{' '}
                    <strong>
                        {new Date(campaign[0].periodoFimCampanha.seconds * 1000).toLocaleDateString()} às{' '}
                        {new Date(campaign[0].periodoFimCampanha.seconds * 1000).toLocaleTimeString()}
                    </strong>{' '}
                </div>

                <div>
                    Segmento: <strong>{campaign[0].segmento}</strong> <br />
                    Duração do anúncio: <strong>{campaign[0].duracao}</strong> <br />
                    Horário Preferencial: <strong>{campaign[0].melhorPeriodo}</strong>
                </div>
            </S.CampaignDetails>
            <h2>Status da Campanha:</h2>
            <S.CampaignDetails>
                <S.Indicator>
                    <h3>Dias de campanha restantes</h3>
                    <strong>10</strong>
                </S.Indicator>

                <S.Indicator>
                    <h3>Inserções em rádio</h3>
                    <strong>10</strong>
                </S.Indicator>

                <S.Indicator>
                    <h3>Inserções em TV</h3>
                    <strong>10</strong>
                </S.Indicator>

                <S.Indicator>
                    <h3>Total de anúncios veiculados</h3>
                    <strong>10</strong>
                </S.Indicator>
            </S.CampaignDetails>
            <S.Footer>
                <Link to="/campanhas">Visualizar todas campanhas</Link>
            </S.Footer>
        </S.Container>
    ) : (
        <S.Container>
            <h1>Campanha não cadastrada</h1>
        </S.Container>
    );
}

export default DetailCampaign;
