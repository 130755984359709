/**
 * CWV metric.
 * @external Metric
 * @see {@link https://gist.github.com/herablog/f04f473b9d9a8f63848f63ce0aec3eff}
 * @see {@link https://github.com/GoogleChrome/web-vitals#metric}
 */

import { getCLS, getFID, getLCP, getTTFB } from 'web-vitals';
import * as Sentry from '@sentry/react';
import { firebasePerformance } from 'base';

/**
 * Combine element with its ID and class names
 * @pamam {HTMLElement} element
 * @return {string|null} element name - e.g. div#id.classname
 */
function combineElementWithIdAndClass(element) {
    if (!element) {
        return null;
    }
    const idName = element.id ? `#${element.id}` : '';
    const className = element.className ? `.${element.className.split(' ').join('.')}` : '';
    return `${element.tagName.toLowerCase()}${idName}${className}`;
}

/**
 * Extract element names from list of elements
 * @param {HTMLElement[]|HTMLElement} sources
 * @return {string} element name - e.g. div#id.classname,img#hero
 */
function extractElementNames(sources) {
    if (Array.isArray(sources)) {
        return sources
            .map(source => combineElementWithIdAndClass(source.node))
            .filter(Boolean)
            .join(', ');
    }
    return combineElementWithIdAndClass(sources);
}

// function sendToAnalytics(metric) {
//     const body = JSON.stringify(metric);
//     // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
//     (navigator.sendBeacon && navigator.sendBeacon('/analytics', body)) ||
//         fetch('/analytics', { body, method: 'POST', keepalive: true });
// }
//
// getCLS(sendToAnalytics);
// getFID(sendToAnalytics);
// getLCP(sendToAnalytics);

getCLS(sendWebVitalsToFirebase);
getFID(sendWebVitalsToFirebase);
getLCP(sendWebVitalsToFirebase);
getTTFB(sendWebVitalsToFirebase);

/**
 * Send CWV to Firebase
 * @param {Metric}
 * @return {void}
 */
export default function sendWebVitalsToFirebase({ name, delta, entries }) {
    const metricNameMap = {
        CLS: 'Cumulative Layout Shift',
        LCP: 'Largest Contentful Paint',
        FID: 'First Input Delay',
        TTFB: 'Time To First Byte',
    };

    try {
        const startTime = Date.now();
        const value = Math.round(name === 'CLS' ? delta * 1000 : delta);

        entries.forEach(({ element, target, sources }) => {
            const elements = element || target || sources; // LCP, FID, CLS
            const elementName = elements ? extractElementNames(elements) : '';
            const attributes = elementName ? { element: elementName } : {};
            firebasePerformance.trace(metricNameMap[name]).record(startTime, value, attributes);
        });
    } catch (error) {
        Sentry.captureException(error);
    }
}
