import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useUser } from 'hooks/user';
import { grantTotalForPrograms } from 'services/advertiser/campaign';

export const useCurrentCampaign = () => useSelector(state => state.campaignsReducer.currentCampaign);

export const useCurrentStep = () => useSelector(state => state.campaignsReducer.currentStep);

function groupByKey(key, values) {
    const grouped = {};
    for (const value of values) {
        const groupedValues = grouped[value[key]] || [];
        grouped[value[key]] = [...groupedValues, value];
    }
    return grouped;
}

export const useAvailableProgramsByType = () =>
    useSelector(state => {
        return groupByKey('programType', state.campaignsReducer.availablePrograms);
    });

export const usePrograms = () => useSelector(state => state.campaignsReducer.programs);

export const useCompanies = () => useSelector(state => state.companyReducer.companies);

export const useCampaignList = () => useSelector(state => state.campaignsReducer.campaigns);

export const useCampaignGrantTotal = (campaign, coupon) => {
    const user = useUser();

    const companies = useCompanies();
    const { objectivesAndDuration, budget, mediaCentral } = campaign;

    const programs = useMemo(
        () => [...(mediaCentral?.selectedTVPrograms || []), ...(mediaCentral?.selectedRadioPrograms || [])],
        [mediaCentral],
    );

    let grantTotal = grantTotalForPrograms(
        programs,
        companies,
        objectivesAndDuration?.duration,
        user?.cnpj,
        budget?.budgetType,
    );

    if (coupon) {
        grantTotal = grantTotal - coupon.value;
    }

    return grantTotal;
};
