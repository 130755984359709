import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import TrashIcon from 'icons/TrashIcon';
import { currencyFormatter } from 'utils';
import { getInsertionsCount, getProgramInsertionValue } from 'services/advertiser/campaign';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import EditProgramModal from './EditProgramModal';
import DayView from '../../DayView';
import { useUser } from 'hooks/user';
import { useCompanies, useCurrentCampaign } from 'hooks/campaign';
import { AnalyticsContext } from '../../analyticsContext';

function totalCommercialsValueFor(insertions, program, companies, duration, userCNPJ, budgetType) {
    let insertionValue = program.insertionValue;
    if (!insertionValue) {
        insertionValue = getProgramInsertionValue(companies, program, duration, userCNPJ, budgetType);
    }
    return insertionValue * insertions;
}

const ProgramCard = ({ program }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    const {
        objectivesAndDuration: { duration },
        budget: { budgetType },
    } = useCurrentCampaign();

    const companies = useCompanies();
    const user = useUser();

    function handleDeleteProgramClick(event) {
        event.preventDefault();
        const confirmMessage = `Tem certeza que deseja remover o programa "${program.programName}"`;
        if (confirm(confirmMessage)) {
            dispatch(CampaignCreators.removeFromSelectedProgram({ program }));
        }
    }

    const { viewItem } = useContext(AnalyticsContext);

    function handleOpenModalClick(event) {
        event.preventDefault();
        setIsModalOpen(true);
        viewItem(program);
    }

    function handleModalClose() {
        setIsModalOpen(false);
    }

    const comercialCount = getInsertionsCount(program);
    const comercialValue = totalCommercialsValueFor(
        comercialCount,
        program,
        companies,
        duration,
        user?.cnpj,
        budgetType,
    );

    return (
        <div className="tw-rounded-md tw-bg-white tw-relative">
            <button
                type="button"
                onClick={handleOpenModalClick}
                className="tw-flex tw-w-full tw-flex-col tw-items-stretch tw-shadow-md"
            >
                <img
                    src={
                        program?.banners?.find(banner => banner.isMain)?.image || program?.banners?.length > 0
                            ? program?.banners[0]?.image
                            : ''
                    }
                    alt={program.name}
                    className="tw-block tw-rounded-tl-md tw-rounded-tr-md tw-h-40 tw-object-cover tw-w-full tw-max-w-xs"
                />

                <div className="tw-p-4 tw-text-left tw-w-full">
                    <p className="tw-truncate tw-text-xs">{program.programName}</p>
                    <div className="tw-py-2">
                        {program.showDays.map(day => (
                            <DayView day={day} key={day.day} />
                        ))}
                    </div>
                    <p className="tw-text-xs">
                        <strong className=" tw-text-pink">Inserções:</strong> {comercialCount}
                    </p>
                    <p className="tw-text-sm">
                        <strong className="tw-text-pink">Total:</strong>{' '}
                        {currencyFormatter.format(Number(comercialValue))}
                    </p>
                </div>
            </button>
            <button className="tw-absolute tw-right-4 tw-bottom-2" type="button" onClick={handleDeleteProgramClick}>
                <TrashIcon className="icon tw-inline tw-text-gray-light" />
            </button>

            {isModalOpen && <EditProgramModal key={program.id} program={program} onClose={handleModalClose} />}
        </div>
    );
};

export default ProgramCard;
