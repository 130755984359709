/* eslint-disable no-unused-vars */
import React from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';

function AddedPrograms({ programs, toggleAddProgramModal, handleRemoveProgram }) {
    return (
        <div className="tw-w-full tw-overflow-x-auto tw-mb-9">
            {programs?.length > 0 && (
                <table className="tw-w-full tw-mt-6">
                    <thead>
                        <tr>
                            <th className="tw-text-purple tw-text-left">Nome do Programa</th>
                            <th className="tw-text-purple tw-text-left">Período</th>
                            <th className="tw-text-purple" align="center">
                                Quantidade de inserções
                            </th>
                            <th className="tw-text-purple tw-text-left">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {programs?.map(({ programId, programName, insertionQuantity, period }) => (
                            <tr key={programId}>
                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                                    <p>{programName}</p>
                                </td>
                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">{period}</td>
                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="center">
                                    {insertionQuantity}
                                </td>
                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                                    <div className="tw-flex tw-flex-row tw-items-center">
                                        <FaTimes
                                            size={22}
                                            color="#cd0e4b"
                                            className="tw-cursor-pointer tw-ml-3"
                                            onClick={() => handleRemoveProgram(programId)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <div className="tw-w-full tw-flex md:tw-justify-start tw-justify-center tw-my-4">
                <button
                    className="tw-flex tw-items-center tw-border-none tw-rounded-md tw-bg-pink tw-text-white tw-font-bold tw-px-5 tw-py-0 tw-h-10"
                    onClick={toggleAddProgramModal}
                    type="button"
                >
                    <FaPlus size={18} className="tw-text-white" />
                    <span className="tw-block tw-ml-3">Adicionar Programa</span>
                </button>
            </div>
        </div>
    );
}

export default AddedPrograms;
