import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';
import addMinutes from 'date-fns/addMinutes';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validator';
import { Creators as ActionsCreators } from 'store/modules/campaign';
import { getProgramById } from 'api/programs';
import { updateCampaign } from 'api/campaign';
import { sendPiMail } from 'base';
import { handlePromiseError } from 'utils/handles';
import addDays from 'date-fns/addDays';

const formatDateAsPtBR = date =>
    format(date, 'dd/MM/yyyy', {
        locale: ptBR,
    });

function getProgramSchemaForCampaign(minDate, maxDate) {
    return yup.object({
        dates: yup
            .array(
                yup.object({
                    program: yup
                        .mixed()
                        .required()
                        .transform(function (value) {
                            if (value && this.isType(value)) {
                                return getProgramById(value);
                            }
                            return value;
                        })
                        .label('Programa'),
                    date: yup
                        .date()
                        .required()
                        .transform(function (value) {
                            if (value && this.isType(value)) {
                                return addMinutes(value, 1);
                            }
                            return value;
                        })
                        .min(startOfDay(minDate), 'Deve ser posterior a ' + formatDateAsPtBR(minDate))
                        .max(endOfDay(maxDate), 'Deve ser inferior a ' + formatDateAsPtBR(maxDate))
                        .typeError('Selecione uma data válida')
                        .label('Data'),
                }),
            )
            .required()
            .ensure()
            .default([]),
    });
}

function useApproveInsertionsForm(campaign, onApprove) {
    const dispatch = useDispatch();

    function onSubmit(values, event) {
        const requestingPI = event.nativeEvent?.submitter.dataset.buttonType === 'pi';
        const approving = event.nativeEvent?.submitter.dataset.buttonType === 'approve';

        async function onSaveDatesSuccess() {
            if (requestingPI) {
                await requestPI();
                dispatch(ActionsCreators.getCampaigns());
            }
            if (approving) {
                await approveDates();
                toast.success('INSERÇÕES APROVADAS COM SUCESSO!');
                onApprove();
            }
        }

        function requestPI() {
            return sendPiMail({ campaignId: campaign.id })
                .then(resp => {
                    toast.success('PI SOLICITADO COM SUCESSO!!');
                    onApprove();
                    return resp;
                })
                .catch(handlePromiseError('FALHA AO SOLICITAR PI!'));
        }
        function approveDates() {
            return updateCampaign(campaign.id, {
                status: 'PENDING_CREATIVE',
                // status: 'PENDING_APPROVE_INSERTIONS',
            }).catch(handlePromiseError('Falha ao aprovar datas'));
        }
        function saveApprovedDates() {
            return updateCampaign(campaign.id, {
                approvedDates: values.dates,
            }).then(onSaveDatesSuccess);
        }

        return saveApprovedDates().catch(handlePromiseError('Falha ao salvar datas'));
    }

    const schema = useMemo(() => {
        const minDate = campaign.objectivesAndDuration?.startDate?.toDate() || campaign?.createdAt?.toDate();
        const maxDate = campaign.objectivesAndDuration?.endDate?.toDate() || addDays(campaign?.createdAt.toDate(), 30);
        return getProgramSchemaForCampaign(minDate, maxDate);
    }, [campaign.objectivesAndDuration.endDate, campaign.objectivesAndDuration.startDate, campaign?.createdAt]);

    const defaultDates = (campaign.approvedDates || []).map(approvedDate => ({
        ...approvedDate,
        date: approvedDate.date.toDate(),
    }));

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            dates: defaultDates,
        },
    });

    return { ...methods, onSubmit: methods.handleSubmit(onSubmit) };
}

export default useApproveInsertionsForm;
