import React from 'react';
import clsx from 'clsx';

const DayView = ({ day }) => {
    const dayClass = clsx('tw-uppercase tw-mr-2', {
        'tw-text-pink tw-font-bold': day.selected,
        'tw-text-gray': day.available && !day.selected,
        'tw-text-gray-light': !day.available,
    });
    return <span className={dayClass}>{day.label[0]}</span>;
};

export default DayView;
