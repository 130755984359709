import React from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { DevTool } from '@hookform/devtools';
import InputField from 'molecules/InputField';
import useCreditCardForm from './useCreditCardForm';

const today = new Date();

const AddCreditCardForm = ({ onCancel, onSave, className = 'tw-px-8 tw-py-4' }) => {
    const queryClient = useQueryClient();
    const { onSubmit, formState, isLoading, control, register, reset, watch } = useCreditCardForm({
        onMutate: async data => {
            await queryClient.cancelQueries('getPaymentInfoFor');

            const previousValue = queryClient.getQueryData('getPaymentInfoFor');

            const lengthNumber = data.number.toString().length;
            const lastDigits = data.number.toString().slice(lengthNumber - 4, lengthNumber);
            const creditCard = {
                ...data,
                expireDate: new Date(data.expireDate),
                lastDigits,
            };
            queryClient.setQueryData('getPaymentInfoFor', { creditCard });

            return previousValue;
        },
        onSuccess({ data }) {
            onSave(data);
            toast.success('Cartão salvo com sucesso!');
            reset();
        },
        onError(error, variables, previousValue) {
            queryClient.setQueryData('getPaymentInfoFor', previousValue);
            toast.error(error.message);
        },
    });
    const { errors, isValid } = formState;

    const identifierKind = watch('identifier.kind', null);

    function handleCancelClick(event) {
        event.preventDefault();
        onCancel();
    }

    return (
        <form onSubmit={onSubmit} className={className}>
            <h3>Cadastro de Cartão De Crédito</h3>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-mt-12">
                <InputField
                    label="Número do cartão"
                    name="number"
                    control={control}
                    mask="9999 9999 9999 9999"
                    className="md:tw-w-7/12 tw-border-gray-500 tw-border-b-2 tw-border-t-0 tw-border-r-0 tw-border-l-0"
                    error={errors.number}
                    disabled={isLoading}
                />
                <InputField
                    label="CVV"
                    name="cvv"
                    control={control}
                    mask="999"
                    className="tw-w-full md:tw-w-2/12 tw-border-gray-500 tw-border-b-2 tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-mt-4 md:tw-mt-0 md:tw-ml-4"
                    error={errors.cvv}
                    disabled={isLoading}
                />
                <InputField
                    label="Validade"
                    name="expireDate"
                    control={control}
                    mask="99/99"
                    className="tw-w-full md:tw-w-3/12 tw-border-gray-500 tw-border-b-2 tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-mt-4 md:tw-mt-0 md:tw-ml-4"
                    min={today}
                    error={errors.expireDate}
                    disabled={isLoading}
                />
            </div>
            <InputField
                label="Nome conforme o cartão"
                name="holderName"
                ref={register}
                className="tw-w-full tw-border-gray-500 tw-border-b-2 tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-mt-4"
                error={errors.holderName}
                disabled={isLoading}
            />

            <div className="tw-mt-4 tw-flex tw-gap-x-4">
                <span className="tw-font-bold">Tipo de pessoa: </span>
                <label htmlFor="identifier.kind">
                    <input
                        id="identifier.kind"
                        name="identifier.kind"
                        className="checked:tw-bg-pink checked:border-transparent tw-mr-2"
                        type="radio"
                        value="CNPJ"
                        ref={register}
                        defaultChecked={false}
                    />
                    CNPJ
                </label>
                <label htmlFor="identifier.kind">
                    <input
                        id="identifier.kind"
                        name="identifier.kind"
                        className="checked:tw-bg-pink checked:border-transparent tw-mr-2"
                        type="radio"
                        value="CPF"
                        ref={register}
                        defaultChecked={false}
                    />
                    CPF
                </label>
            </div>
            {identifierKind === 'CPF' && (
                <InputField
                    name="identifier.value"
                    className="tw-w-full tw-mt-2"
                    error={errors.identifier?.value}
                    placeholder="XXX.XXX.XXX-XX"
                    mask="999.999.999-99"
                    control={control}
                />
            )}
            {identifierKind === 'CNPJ' && (
                <InputField
                    name="identifier.value"
                    className="tw-w-full tw-mt-2"
                    error={errors.identifier?.value}
                    placeholder="XX.XXX.XXX/XXXX-XX"
                    mask="99.999.999/9999-99"
                    control={control}
                />
            )}

            <div className="tw-flex md:tw-flex-row tw-flex-col tw-mt-10 tw-text-center">
                <button
                    type="button"
                    onClick={handleCancelClick}
                    className="button button-primary-outlined tw-mr-0 md:tw-mr-4 md:tw-w-full"
                >
                    Cancelar
                </button>

                <button
                    type="submit"
                    className="button button-primary md:tw-mt-0 tw-mt-2 md:tw-w-full"
                    disabled={isLoading || !isValid}
                >
                    Salvar
                </button>
            </div>

            {__DEVELOPMENT__ && <DevTool control={control} />}
        </form>
    );
};

export default AddCreditCardForm;
