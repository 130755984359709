import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from 'components/Menu';
import TopNav from 'components/TopNav';
import MenuIcon from 'icons/MenuIcon';
import SupplierMenu from 'components/Menu/SupplierMenu';

const PageWrapper = ({ children, pageTitle, isSupplierDashboard = false }) => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    function handleMenuClick(event) {
        event.preventDefault();
        setShowMobileMenu(!showMobileMenu);
    }

    const handleMouseLeave = () => setShowMobileMenu(false);

    return (
        <div className="tw-flex tw-flex-row">
            {isSupplierDashboard ? (
                <SupplierMenu mobile={showMobileMenu} onMouseLeave={handleMouseLeave} />
            ) : (
                <Menu mobile={showMobileMenu} onMouseLeave={handleMouseLeave} />
            )}

            <div className="tw-w-full tw-bg-gray-xlighter tw-min-h-screen">
                <TopNav pageTitle={pageTitle}>
                    <button className="tw-block sm:tw-hidden" onClick={handleMenuClick}>
                        <MenuIcon className="tw-w-7 tw-h-7" />
                    </button>
                </TopNav>

                <div className="tw-px-10 tw-pt-10 tw-mt-20 md:tw-mt-20 md:tw-ml-28">{children}</div>
            </div>
        </div>
    );
};

PageWrapper.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isSupplierDashboard: PropTypes.bool,
};
PageWrapper.defaultsProps = {
    isSupplierDashboard: false,
};

export default PageWrapper;
