import React from 'react';
import PropType from 'prop-types';

const WizardStep = ({ children }) => {
    return <div className="tw-mt-6">{children}</div>;
};

WizardStep.propTypes = {
    children: PropType.oneOfType([
        PropType.element,
        PropType.node,
        PropType.arrayOf(PropType.element),
        PropType.arrayOf(PropType.node),
    ]),
};

export default WizardStep;
