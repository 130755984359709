import React from 'react';

const CameraIcon = ({ onClick }) => (
    <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <circle cx="29.5" cy="29.5" r="29.5" fill="#E5195A" />
        <path
            d="M28.7027 35.4007C31.5209 35.4007 33.8054 33.1162 33.8054 30.298C33.8054 27.4799 31.5209 25.1953 28.7027 25.1953C25.8846 25.1953 23.6 27.4799 23.6 30.298C23.6 33.1162 25.8846 35.4007 28.7027 35.4007Z"
            fill="white"
        />
        <path
            d="M23.9189 14.3516L21.0008 17.5408H15.9459C14.1919 17.5408 12.7568 18.9759 12.7568 20.7299V39.8651C12.7568 41.6191 14.1919 43.0543 15.9459 43.0543H41.4595C43.2135 43.0543 44.6487 41.6191 44.6487 39.8651V20.7299C44.6487 18.9759 43.2135 17.5408 41.4595 17.5408H36.4046L33.4865 14.3516H23.9189ZM28.7027 38.2705C24.3016 38.2705 20.7297 34.6986 20.7297 30.2975C20.7297 25.8964 24.3016 22.3245 28.7027 22.3245C33.1038 22.3245 36.6757 25.8964 36.6757 30.2975C36.6757 34.6986 33.1038 38.2705 28.7027 38.2705Z"
            fill="white"
        />
    </svg>
);

export default CameraIcon;
