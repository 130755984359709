/* eslint-disable no-unused-vars */
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validator';
import { clearString, isCnpjValid } from 'utils';
import InputField from 'components/shared/molecules/InputField';

const schema = yup.object().shape({
    cnpj: yup.string().defined().required().label('CNPJ'),
});

function WhiteListForm({ handleAddCnpj }) {
    const { handleSubmit, control, setValue, watch, getValues } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const onSubmit = () => {
        const values = getValues();

        if (!isCnpjValid(values.cnpj)) {
            return;
        }
        handleAddCnpj(clearString(values.cnpj));
        setValue('cnpj', '');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="tw-w-full tw-flex tw-flex-row tw-items-end">
            <InputField
                label="CNPJ"
                name="cnpj"
                control={control}
                mask="99.999.999/9999-99"
                className="tw-mt-6 tw-w-full md:tw-w-1/2 tw-max-w-xs"
                inputClassName="tw-w-full"
            />
            <button
                className={`tw-border-none tw-rounded-full ${
                    clearString(watch('cnpj'))?.length !== 14 ? 'tw-bg-gray-400' : 'tw-bg-pink'
                }  tw-p-2 tw-shadow-sm tw-ml-2`}
                type="button"
                onClick={onSubmit}
                disabled={!(clearString(watch('cnpj'))?.length === 14)}
            >
                <FaPlus size={26} color="#fff" />
            </button>
        </form>
    );
}

export default WhiteListForm;
