import React from 'react';
import { Link } from 'react-router-dom';
import RecoveryForm from './RecoveryForm';
import styleConfig from '../../../style_config.json';

const Recovery = () => (
    <div className="gradient-background">
        <div className="tw-w-screen min-h-screen tw-py-12 tw-flex tw-align-middle tw-items-center tw-place-content-center tw-flex-col">
            <div className="tw-text-center tw-mb-12 md:tw-mr-10">
                <img src={styleConfig['logo-vertical.svg']} alt="Cimtia logo" className="tw-w-48 tw-inline" />
            </div>
            <div className="tw-w-4/5 md:tw-w-2/4 lg:tw-w-2/5 2xl:tw-w-1/4">
                <div className="page-card">
                    <h2>Recuperar senha</h2>
                    <RecoveryForm />
                    <p className="tw-text-center tw-mt-7">
                        <Link to="/">Voltar ao login</Link>
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default Recovery;
