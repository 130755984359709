import React from 'react';

function AdditionalInfoSection({ creative }) {
    return (
        <>
            <h3 className="tw-text-gray-700 tw-mt-8">Informações adicionais</h3>
            <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-8">
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Endereço</h4>
                    <p>{creative.addess}</p>
                </div>
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Bairro</h4>
                    <p>{creative.neighborhood}</p>
                </div>
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Cidade</h4>
                    <p>{creative.city}</p>
                </div>
            </div>
            <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-8">
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Estado</h4>
                    <p>{creative.state}</p>
                </div>
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Telefone</h4>
                    <p>{creative.phone}</p>
                </div>
                <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                    <h4 className="tw-text-purple tw-font-bold">Whatsapp</h4>
                    <p>{creative.whatsapp}</p>
                </div>
            </div>
            {creative.siteOrEmail && (
                <div className="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-mt-6">
                    <div className="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col">
                        <h4 className="tw-text-purple tw-font-bold">Site ou E-mail</h4>
                        <p>{creative.siteOrEmail}</p>
                    </div>
                </div>
            )}
        </>
    );
}

export default AdditionalInfoSection;
