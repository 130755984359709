import React, { memo, useCallback, useMemo, useReducer, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import Modal from 'react-awesome-modal';
import { useDispatch } from 'react-redux';
import { FaCheck, FaPaperclip, FaTimes } from 'react-icons/fa';
import { updateCampaign } from 'api/campaign';
import { currencyFormatter } from 'utils';
import { Creators as ActionsCreators } from 'store/modules/campaign';
import { CAMPAIGN_STATUS, getInsertionsCount, totalInsertionsForPrograms } from 'services/advertiser/campaign';
import AlertIcon from './icons/AlertIcon';
import PlayIcon from './icons/PlayIcon';
import CampaignInfo from 'organisms/CampaignInfo';
import StepsIconsInfo from './StepsIconsInfo';
import IconsBreadsCrumb from './IconsBreadsCrumb';
import ProgramInsertions from './ProgramInsertions';
import RefuseCreativeModal from './RefuseCreativeModal';
import RefuseInsertionsModal from './RefuseInsertionsModal';
import ApproveInsertionsModal from './ApproveInsertionsModal';
import PackageInsertionsModal from './PackageInsertionsModal';

const statuses = {
    PENDING_APPROVE_INSERTIONS: {
        label: 'Inserção',
        color: 'tw-text-pink',
    },
    PENDING_CREATIVE: {
        label: 'Criativo',
        color: 'tw-text-gray',
    },
    PENDING_APPROVE_CREATIVE: {
        label: 'A. Criativo',
        color: 'tw-text-pink',
    },
};

const PendingMessage = memo(({ pendingType }) => {
    switch (pendingType) {
        case CAMPAIGN_STATUS.pendingApproveInsertions:
            return (
                <div className="tw-flex tw-item-center tw-p-3 tw-w-auto tw-bg-white tw-border tw-border-orange tw-rounded-md tw-mb-3">
                    <AlertIcon className="tw-mr-3" />
                    <p>
                        <span className="tw-font-bold tw-text-gray-dark">Ação Necessária</span>: Campanha com pendência
                        de&nbsp;
                        <span className="tw-text-pink tw-font-bold">INSERÇÃO. </span>
                        Clique nos botões abaixo para Aprovar/Reprovar.
                    </p>
                </div>
            );
        case CAMPAIGN_STATUS.pendingApproveCreative:
            return (
                <div className="tw-flex tw-item-center tw-p-3 tw-w-auto tw-bg-white tw-border tw-border-orange tw-rounded-md tw-mb-3">
                    <AlertIcon className="tw-mr-3" />
                    <p>
                        <span className="tw-font-bold tw-text-gray-dark">Ação Necessária</span>: Aprovar&nbsp;
                        <span className="tw-text-pink tw-font-bold">CRIATIVO.</span>
                    </p>
                </div>
            );
        default:
            return <></>;
    }
});
PendingMessage.displayName = 'PendingMessage';

const getInsertionsTotalValue = program => {
    const insertionCount = getInsertionsCount(program);
    return program.insertionValue * insertionCount;
};
const toggleState = state => !state;

const PendingCreativeProductionStatusLabel = ({ updatedAt }) => (
    <div className="tw-border tw-border-gray-light tw-bg-gray-100 tw-text-gray-900 tw-rounded-3xl tw-p-2">
        Aguardando Produção do Criativo. Prazo:{' '}
        <strong>
            {`${moment
                .utc(updatedAt || new Date())
                .add(2, 'd')
                .format('DD/MM')} - ${moment.utc(updatedAt || new Date()).format('HH:mm')} `}
        </strong>
    </div>
);

const CampaignRow = ({ campaign }) => {
    const [video, setVideo] = useState('');
    const [openApproveInsertionsModal, toggleOpenApproveInsertionsModal] = useReducer(toggleState, false);
    const [openRefuseInsertionsModal, toggleOpenRefuseInsertionsModal] = useReducer(toggleState, false);
    const [openRefuseCreativeModal, toggleOpenRefuseCreativeModal] = useReducer(toggleState, false);
    const [showInsertionsDetails, toggleShowInsertionsDetails] = useReducer(toggleState, false);
    const [openPackageInsertionsModal, toggleOpenPackageInsertionsModal] = useReducer(toggleState, false);

    const dispatch = useDispatch();

    const closeVideo = useCallback(() => setVideo(''), []);

    const allPrograms = useMemo(
        () => [
            ...(campaign.mediaCentral?.selectedTVPrograms || []),
            ...(campaign.mediaCentral?.selectedRadioPrograms || []),
        ],
        [campaign.mediaCentral?.selectedRadioPrograms, campaign.mediaCentral?.selectedTVPrograms],
    );

    const handleApproveCreative = () => {
        function onSuccess() {
            toast.success('CRIATIVO APROVADO COM SUCESSO!');
            dispatch(ActionsCreators.getCampaigns());
        }

        function onFailure() {
            toast.success('FALHA AO APROVAR CRIATIVO!');
        }

        updateCampaign(campaign.id, {
            ...campaign,
            status: 'APPROVED',
        })
            .then(onSuccess)
            .catch(onFailure);
    };

    const startDateFormatted = moment(
        campaign.objectivesAndDuration?.startDate?.toDate() || campaign?.createdAt?.toDate(),
    )?.format('DD/MM/YYYY');
    const endDateFormatted = moment(campaign.objectivesAndDuration?.endDate?.toDate())?.format('DD/MM/YYYY');

    const isCampaignPendingCreative = campaign.status === CAMPAIGN_STATUS.pendingCreative;
    const isCampaignPendingApproveInsertions = campaign.status === CAMPAIGN_STATUS.pendingApproveInsertions;
    const isCampaignPendingApproveCreative = campaign.status === CAMPAIGN_STATUS.pendingApproveCreative;

    const getProgramsPackageInsertions = program =>
        campaign?.packageItem?.programs?.find(programItem => programItem.programId === program.id)?.insertionQuantity;

    return (
        <>
            <ApproveInsertionsModal
                open={openApproveInsertionsModal}
                onClose={toggleOpenApproveInsertionsModal}
                campaign={campaign}
                programs={allPrograms}
            />
            <RefuseInsertionsModal
                openRefuseInsertionsModal={openRefuseInsertionsModal}
                campaign={campaign}
                toggleOpenRefuseInsertionsModal={toggleOpenRefuseInsertionsModal}
            />
            <RefuseCreativeModal
                openRefuseCreativeModal={openRefuseCreativeModal}
                campaign={campaign}
                toggleOpenRefuseCreativeModal={toggleOpenRefuseCreativeModal}
            />
            <PackageInsertionsModal
                open={openPackageInsertionsModal}
                onClose={toggleOpenPackageInsertionsModal}
                campaign={campaign}
            />
            <Modal visible={!!video} width="710" effect="fadeInUp" onClickAway={closeVideo}>
                <video className="tw-w-full" src={video} controls />
            </Modal>
            <tr
                className={`tw-bg-white tw-border ${
                    showInsertionsDetails ? '' : 'tw-shadow-tablerow'
                } tw-cursor-pointer`}
                onClick={toggleShowInsertionsDetails}
            >
                <td className="border tw-bg-white tw-rounded-l-xl tw-pl-4 tw-py-3 tw-font-bold">
                    <div className="tw-flex tw-flex-row tw-items-center tw-mr-3 tw-relative">
                        {[CAMPAIGN_STATUS.pendingApproveInsertions, CAMPAIGN_STATUS.pendingApproveCreative].includes(
                            campaign.status,
                        ) && <AlertIcon className="tw-mr-3" onClick={() => alert('oi')} />}
                        <span>{campaign.objectivesAndDuration.name || campaign?.packageItem?.packageName}</span>
                        {!showInsertionsDetails && <IconsBreadsCrumb campaign={campaign} />}
                    </div>
                </td>
                <td className="border tw-bg-white tw-py-3">{campaign.userCompanyName || '-'}</td>
                {campaign?.packageItem ? (
                    <td className="border tw-bg-white tw-py-3">{`a partir de ${startDateFormatted}`}</td>
                ) : (
                    <td className="border tw-bg-white tw-py-3">{`${startDateFormatted} a ${endDateFormatted}`}</td>
                )}
                <td className="border tw-bg-white tw-py-3 tw-text-center">
                    {totalInsertionsForPrograms(allPrograms, campaign?.packageItem?.programs)}
                </td>
                <td className="border tw-bg-white tw-py-3">{currencyFormatter.format(campaign.campaignValue)}</td>
                <td
                    className={`border tw-bg-white tw-rounded-r-xl tw-py-3 tw-font-bold ${
                        statuses[campaign.status]?.color
                    }`}
                >
                    {statuses[campaign.status]?.label}
                </td>
            </tr>
            {showInsertionsDetails && (
                <>
                    <tr className="tw-bg-white tw-border tw-shadow-md tw-p-3 tw-translate-x-2">
                        <td colSpan={5} className="tw-p-5 border tw-bg-white tw-rounded-xl">
                            <PendingMessage pendingType={campaign.status} />
                            <CampaignInfo campaign={campaign} />
                            <table className="tw-w-full tw-mt-6">
                                <thead>
                                    <tr>
                                        <th className="tw-text-purple tw-text-left" align="left">
                                            Programa
                                        </th>
                                        {!campaign?.packageItem && (
                                            <th className="tw-text-purple tw-text-left">Dias de Veiculação</th>
                                        )}
                                        <th className="tw-text-purple tw-text-center">Inserções</th>
                                        <th className="tw-text-purple tw-text-center">Duração</th>
                                        {!campaign?.packageItem && (
                                            <th className="tw-text-purple tw-text-left">Valor</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {allPrograms.map((program, index) => (
                                        <tr key={index}>
                                            <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                                                <p>{program.programName}</p>
                                            </td>
                                            {!campaign?.packageItem && (
                                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                                                    <ProgramInsertions program={program} />
                                                </td>
                                            )}
                                            <td className="tw-border-b-2 tw-border-gray-light tw-py-1 tw-text-center">
                                                {getInsertionsCount(program) || getProgramsPackageInsertions(program)}
                                            </td>
                                            <td className="tw-border-b-2 tw-border-gray-light tw-py-1 tw-text-center">
                                                {campaign?.objectivesAndDuration?.duration}
                                            </td>
                                            {!campaign?.packageItem && (
                                                <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                                                    {currencyFormatter.format(getInsertionsTotalValue(program))}
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-mt-3">
                                <StepsIconsInfo campaign={campaign} />

                                {isCampaignPendingCreative && (
                                    <PendingCreativeProductionStatusLabel updatedAt={campaign.updatedAt?.toDate()} />
                                )}

                                {isCampaignPendingApproveInsertions && (
                                    <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-center md:tw-justify-end">
                                        <button
                                            type="button"
                                            className="tw-flex tw-items-center tw-border-none tw-rounded-md tw-bg-gray-300 tw-text-gray tw-font-bold tw-px-5 tw-py-0 tw-h-10"
                                            onClick={toggleOpenRefuseInsertionsModal}
                                        >
                                            <FaTimes size={18} className="tw-text-gray" />
                                            <span className="tw-block tw-text-gray tw-ml-3">Reprovar Inserção</span>
                                        </button>
                                        <button
                                            className="tw-flex tw-items-center tw-border-none tw-rounded-md tw-bg-pink tw-text-white tw-font-bold tw-px-5 tw-py-0 tw-h-10 md:tw-ml-3"
                                            onClick={
                                                campaign?.packageItem
                                                    ? toggleOpenPackageInsertionsModal
                                                    : toggleOpenApproveInsertionsModal
                                            }
                                        >
                                            <FaCheck size={18} className="tw-text-white" />
                                            <span className="tw-block tw-ml-3">
                                                {campaign.piSentAt ? 'Aprovar Inserção' : 'Solicitar PI'}
                                            </span>
                                        </button>
                                    </div>
                                )}
                                {isCampaignPendingApproveCreative && (
                                    <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-center md:tw-justify-end">
                                        {campaign.criativoDownloadUrl && (
                                            <div className="tw-flex tw-flex-row tw-items-center">
                                                <PlayIcon
                                                    onClick={() => setVideo(campaign.criativoDownloadUrl)}
                                                    className="tw-cursor-pointer tw-mr-2"
                                                />
                                                <div className="tw-flex tw-flex-row tw-mr-2 tw-border tw-border-gray-light tw-bg-gray-100 tw-text-gray-900 tw-rounded-3xl tw-p-2">
                                                    <FaPaperclip size={22} />
                                                    <a
                                                        className="tw-block tw-ml-2"
                                                        href={campaign.criativoDownloadUrl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {campaign.creative?.commercialName || 'Assistir criativo'}
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                        <button
                                            className="tw-flex tw-items-center tw-border-none tw-rounded-md tw-bg-gray-300 tw-text-gray tw-font-bold tw-px-5 tw-py-0 tw-h-10"
                                            type="button"
                                            onClick={toggleOpenRefuseCreativeModal}
                                        >
                                            <FaTimes size={18} className="tw-text-gray" />
                                            <span className="tw-block tw-text-gray tw-ml-3">Reprovar Criativo</span>
                                        </button>
                                        <button
                                            className="tw-flex button tw-items-center tw-border-none tw-rounded-md tw-bg-pink tw-text-white tw-font-bold tw-px-5 tw-py-0 tw-h-10 md:tw-ml-3"
                                            type="button"
                                            disabled={!campaign.criativoDownloadUrl}
                                            onClick={handleApproveCreative}
                                        >
                                            <FaCheck size={18} className="tw-text-white" />
                                            <span className="tw-block tw-ml-3">Aprovar Criativo</span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </td>
                    </tr>
                </>
            )}
        </>
    );
};

export default CampaignRow;
