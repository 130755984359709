import React, { useEffect } from 'react';
import Modal from 'react-awesome-modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import yup from 'validator';

import Select from 'molecules/Select';
import Input from 'molecules/InputField';
import { useUserCompany } from 'hooks/company';
import { useDispatch } from 'react-redux';
import { getProgramsByCompany } from 'store/modules/program';
import { useUserId } from 'hooks/user';
import { getCompanyByUserId } from 'store/modules/company';
import { useUserPrograms } from 'hooks/program';

const schema = yup.object().shape({
    programId: yup.string().defined().required().label('Programa'),
    insertionQuantity: yup
        .number()
        .min(1, 'A quantidade de inserções não pode ser zero!')
        .typeError('Informe a quantidade de inserções'),
});

const AddProgramModal = ({ showAddProgramModal, toggleAddProgramModal, setPrograms, selectedPrograms }) => {
    const { register, errors, handleSubmit, reset, clearErrors, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();
    const company = useUserCompany();
    const programs = useUserPrograms();
    const userId = useUserId();

    const onSubmit = ({ programId, insertionQuantity }) => {
        setPrograms(current => {
            let foundProgram = current?.find(program => program.id === programId);
            if (foundProgram) {
                return current;
            }
            foundProgram = programs?.find(program => program.id === programId);
            return [
                ...current,
                {
                    programId,
                    insertionQuantity,
                    programName: foundProgram.programName,
                    period: foundProgram.period,
                },
            ];
        });
        setValue('programId', '');
        setValue('insertionQuantity', '');
        toggleAddProgramModal();
    };

    useEffect(() => {
        if (company?.id) {
            dispatch(getProgramsByCompany(company.id));
        }
    }, [dispatch, company]);

    useEffect(() => {
        if (userId) {
            dispatch(getCompanyByUserId(userId));
        }
    }, [userId, dispatch]);

    useEffect(() => {
        return function cleanup() {
            reset();
            clearErrors();
        };
    }, [reset, clearErrors]);

    return (
        <Modal visible={showAddProgramModal} width="568" effect="fadeInUp" onClickAway={toggleAddProgramModal}>
            <form className="tw-p-9 tw-flex tw-flex-col" onSubmit={handleSubmit(onSubmit)}>
                <h3>Adicionar Programa</h3>
                <Select
                    name="programId"
                    label="Selecione o Programa"
                    className="tw-w-full tw-mt-6"
                    ref={register}
                    error={errors.programId}
                >
                    {programs
                        ?.filter(({ id }) => !selectedPrograms.some(({ programId }) => programId === id))
                        ?.map(program => (
                            <option key={program.id} value={program.id}>
                                {program.programName}
                            </option>
                        ))}
                </Select>
                <Input
                    label="Quantidade de Inserções"
                    name="insertionQuantity"
                    className="tw-w-full tw-mt-4"
                    type="number"
                    ref={register}
                    error={errors?.insertionQuantity}
                />
                <div className="tw-w-full tw-flex tw-justify-center md:tw-flex-row tw-flex-col tw-mt-3 md:tw-mt-16 tw-text-center">
                    <button className="button button-primary-outlined" type="button" onClick={toggleAddProgramModal}>
                        Cancelar
                    </button>
                    <button className="button button-primary md:tw-mt-0 tw-mt-3 md:tw-ml-3" type="submit">
                        Salvar
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default AddProgramModal;
