import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { processPaymentForAdvertiser } from 'base';
import yup from 'validator';
import { usePaymentInfoForUser } from 'hooks/payments';
import { currencyFormatter } from 'utils';
import { getInsertionsCount } from 'services/advertiser/campaign';
import CreditCardSelect from 'organisms/CreditCardSelect';
import CouponForm from 'components/CouponForm';
import { useCampaignGrantTotal } from 'hooks/campaign';

const schema = yup.object({
    selectedCreditCard: yup.string().required().label('Cartão de crédito'),
});

function usePaymentForm(campaign, coupon, formOptions, mutationOptions) {
    const methods = useForm({
        schema: yupResolver(schema),
        ...formOptions,
    });

    const { mutate: processPayment, isLoading } = useMutation(
        ({ campaignId, coupon, items }) => processPaymentForAdvertiser({ campaignId, items, coupon }),
        mutationOptions,
    );

    function onSubmit() {
        const tvPrograms = campaign.mediaCentral.selectedTVPrograms.filter(program => getInsertionsCount(program) > 0);
        const radioPrograms = campaign.mediaCentral.selectedRadioPrograms.filter(
            program => getInsertionsCount(program) > 0,
        );

        const items = [...tvPrograms, ...radioPrograms].map(program => {
            const totalInsertions = getInsertionsCount(program);
            return {
                id: program.id,
                title: `${program.programName} (${program.programType}) - ${totalInsertions} inserções de ${campaign.objectivesAndDuration.duration} segundos`,
                unit_price: program.insertionValue || 0,
                quantity: totalInsertions,
            };
        });
        return processPayment({ campaignId: campaign.id, coupon, items });
    }

    const { isSubmitting } = methods.formState;

    return {
        ...methods,
        isLoading: isLoading || isSubmitting,
        onSubmit: methods.handleSubmit(onSubmit),
    };
}

const PaymentForm = ({ campaign, onAddNewCreditCard, onPayment, onCancel }) => {
    const [coupon, setCoupon] = useState(null);
    const { data: paymentInfo, isLoading: isLoadingPaymentsInfo } = usePaymentInfoForUser();
    const {
        onSubmit,
        register,
        isLoading: isProcessingPayment,
    } = usePaymentForm(
        campaign,
        coupon,
        {
            defaultValues: {
                selectedCreditCard: paymentInfo?.creditCard?.id,
            },
        },
        {
            onSuccess() {
                onPayment();
            },
        },
    );

    function handleAddNewCreditCardClick(event) {
        event.preventDefault();
        onAddNewCreditCard();
    }

    function handleCancelClick(event) {
        event.preventDefault();
        onCancel();
    }

    const hasCreditCard = !!paymentInfo?.creditCard;

    const isLoading = isLoadingPaymentsInfo || isProcessingPayment;

    const grantTotalValue = useCampaignGrantTotal(campaign);
    const grantTotal = useCampaignGrantTotal(campaign, coupon);
    const showMessage = coupon && grantTotalValue < coupon.value;
    return (
        <form key="paymentCampaignListForm" onSubmit={onSubmit} className="tw-p-8">
            <h3>Pagamento</h3>
            <p className="tw-mt-2 tw-mb-4">
                {hasCreditCard ? (
                    'Selecione o cartão cadastrado ou clique no ícone para cadastra um novo.'
                ) : (
                    <span>
                        Selecione o cartão e clique em <span className="tw-text-pink">Pagar</span> para efetuar o
                        pagamento.
                    </span>
                )}
            </p>
            <label className="tw-font-bold tw-block tw-mb-1 tw-text-sm" htmlFor="selectedCreditCard">
                Cartões Cadastrados
            </label>
            <CreditCardSelect name="selectedCreditCard" ref={register} />
            <button className="tw-mt-6" onClick={handleAddNewCreditCardClick} disabled={isLoading} type="button">
                <span className="tw-rounded-full tw-px-4 tw-py-2 tw-mr-2 tw-bg-pink tw-text-white">+</span>
                Adicionar novo cartão
            </button>

            <div className="tw-my-4">
                <CouponForm coupon={coupon} grantTotal={grantTotal} onSubmit={setCoupon} onRemove={setCoupon} />
                {coupon && (
                    <>
                        <p className="tw-text-sm">
                            Cupom <strong>{coupon.code}</strong> aplicado!
                        </p>
                        <i className="tw-text-pink">{currencyFormatter.format(grantTotalValue)}</i>
                        <i className="tw-text-pink tw-mx-2">- {currencyFormatter.format(Number(coupon.value))}</i>(
                        <strong>{coupon.code}</strong>)<i className="tw-text-pink"> =</i>
                        <i className="tw-text-pink tw-font-bold tw-mx-2">{currencyFormatter.format(grantTotal)}</i>
                    </>
                )}
                {showMessage && (
                    <p className="tw-text-purple tw-text-sm my-2">
                        Atenção, a diferença do desconto não poderá ser usada posteriormente{' '}
                    </p>
                )}
            </div>

            <div className="tw-mt-10 tw-text-center">
                <button type="button" onClick={handleCancelClick} className="button button-primary-outlined tw-mr-4">
                    Cancelar
                </button>
                <button type="submit" className="button button-primary" disabled={isLoading || !hasCreditCard}>
                    Pagar
                </button>
            </div>
        </form>
    );
};

export default PaymentForm;
