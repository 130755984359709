/* eslint-disable no-unused-vars */
/* eslint-disable unicorn/new-for-builtins */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { sendPiMail } from 'base';
import { toast } from 'react-toastify';
import { getProgramReferenceById } from 'api/programs';
import { handlePromiseError } from 'utils/handles';
import { updateCampaign } from 'api/campaign';
import RequestPIButton from './RequestPIButton';
import Loading from 'components/Loading';

function ProgramInsertionRow({ insertionQuantity, programName, duration, programId, onChange }) {
    return [...Array(insertionQuantity).keys()]?.map(index => (
        <tr key={index}>
            <td className="tw-text-purple tw-text-left" align="left">
                {programName}
            </td>
            <td className="tw-text-purple" align="center">
                {duration} Seg.
            </td>
            <th className="tw-text-purple" align="center">
                <input type="date" onChange={event => onChange(programId, index, event)} />
            </th>
        </tr>
    ));
}

function ProgramRowInfo({ programRef, duration, date }) {
    const [program, setProgram] = useState();

    useEffect(() => {
        programRef.get().then(response => setProgram({ id: programRef.id, ...response.data() }));
    }, [programRef]);

    return (
        <tr>
            <td className="tw-text-purple tw-text-left" align="left">
                {program?.programName}
            </td>
            <td className="tw-text-purple" align="center">
                {duration} Seg.
            </td>
            <th className="tw-text-purple" align="center">
                {moment(date.toDate()).format('DD/MM/YYYY')}
            </th>
        </tr>
    );
}

function ProgramsTable({
    campaign,
    programs,
    handleCancelClick,
    totalInsertions,
    handleConfirmInsertions,
    isEditingDates,
    setIsEditingDates,
}) {
    const [loading, setLoading] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const [approvedDates, setApprovedDates] = useState([]);

    const handleChange = useCallback(async (programId, index, event) => {
        const {
            target: { value: date },
        } = event;

        const program = await getProgramReferenceById(programId);
        setApprovedDates(current => {
            const key = `${programId}-${index}`;
            const approvedDateObject = {
                key,
                programId,
                date: moment(date).toDate(),
                program,
            };

            if (current.some(approvedDate => approvedDate.key === key)) {
                return [...current.filter(approvedDate => approvedDate.key !== key), approvedDateObject];
            }

            return [...current, approvedDateObject];
        });
    }, []);

    const handleAddInsertions = useCallback(async () => {
        setLoading(true);

        const payload = {
            ...campaign,
            piSentAt: moment().toDate(),
            approvedDates: approvedDates.map(({ date, program }) => ({ date, program })),
        };

        try {
            await updateCampaign(campaign.id, payload);
            await sendPiMail({ campaignId: campaign.id });
            toast.success('PI SOLICITADO COM SUCESSO!!');
        } catch {
            handlePromiseError('FALHA AO SOLICITAR PI!');
        } finally {
            setLoading(false);
        }

        setIsEditingDates(false);
    }, [approvedDates, campaign, setIsEditingDates]);

    useEffect(() => {
        if (approvedDates?.length === totalInsertions) {
            setEnableButton(true);
        }
        if (approvedDates.some(({ date }) => !moment(date).isValid())) {
            setEnableButton(false);
        }
    }, [approvedDates, totalInsertions, setEnableButton]);

    return (
        <>
            {loading ? <Loading /> : undefined}
            <table className="tw-w-full tw-mt-6">
                <thead>
                    <tr>
                        <th className="tw-text-purple tw-text-left" align="left">
                            Programa
                        </th>
                        <th className="tw-text-purple" align="center">
                            Qtde - Sec.
                        </th>
                        <th className="tw-text-purple" align="center">
                            Data
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {campaign?.approvedDates?.length && !isEditingDates
                        ? campaign.approvedDates.map((approvedDate, index) => (
                              <ProgramRowInfo
                                  key={index}
                                  programRef={approvedDate.program}
                                  duration={campaign?.objectivesAndDuration?.duration}
                                  date={approvedDate.date}
                              />
                          ))
                        : programs?.map(program => (
                              <ProgramInsertionRow
                                  key={program.programId}
                                  insertionQuantity={program.insertionQuantity}
                                  programName={program.programName}
                                  duration={campaign?.objectivesAndDuration?.duration}
                                  onChange={handleChange}
                                  programId={program.programId}
                              />
                          ))}
                </tbody>
            </table>
            <div className="tw-flex md:tw-flex-row tw-flex-col tw-justify-center md:tw-justify-between md:tw-items-center tw-mt-4">
                <button
                    type="button"
                    className="button tw-w-full md:tw-w-1/2 tw-text-pink tw-border-2 tw-border-pink tw-mr-4"
                    onClick={handleCancelClick}
                >
                    Cancelar
                </button>
                {campaign?.approvedDates?.length && !isEditingDates ? (
                    <button
                        onClick={handleConfirmInsertions}
                        className="button tw-w-full md:tw-w-1/2 tw-border-2 tw-border-pink button-primary md:tw-mt-0 tw-mt-3"
                        data-button-type="Package"
                        disabled={loading}
                    >
                        Confirmar
                    </button>
                ) : (
                    <RequestPIButton
                        data-button-type="pi"
                        disabled={!enableButton || loading}
                        onClick={handleAddInsertions}
                    />
                )}
            </div>
        </>
    );
}

export default ProgramsTable;
