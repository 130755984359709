import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { getCoverageCitiesByState, getCoverageCity } from 'api/coverageCities';
import { normalizeString } from 'utils';

function useCoverageCities(state) {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetch() {
            const list = [];
            const snap = await getCoverageCitiesByState(state).get();
            snap.forEach(document => {
                const { name } = document.data();
                list.push({
                    label: name,
                    value: name,
                });
            });
            setData(list);
        }
        if (state) {
            fetch().catch(error => Sentry.captureException(error));
        }
    }, [state]);

    return data?.sort((a, b) => (normalizeString(a.value) > normalizeString(b.value) ? 1 : -1));
}

function useCoverageCitiesCompanyIds(state, city) {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetch() {
            const companiesIds = new Set();
            setData([]);

            const coverageCities = await getCoverageCity(state, city).get();
            if (coverageCities.empty) {
                return setData([]);
            }
            const programsReferences = coverageCities.docs.flatMap(coverageCity => coverageCity.data().programsRefs);
            const allPrograms = await Promise.all(programsReferences.map(programReference => programReference.get()));
            allPrograms.forEach(program => {
                if (program.exists) {
                    const { companyId } = program.data();
                    companiesIds.add(companyId);
                }
            });
            const allCompaniesIds = Array.from(companiesIds);
            setData(allCompaniesIds);
        }

        if (state && city) {
            fetch().catch(error => Sentry.captureException(error));
        }
    }, [state, city]);

    return data;
}

export { useCoverageCities, useCoverageCitiesCompanyIds };
