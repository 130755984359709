import React from 'react';
import * as PropTypes from 'prop-types';
import { getColor } from './utils';

const TocantinsPathIcon = ({ active = false, available = false }) => {
    const color = getColor(active, available);
    return (
        <path
            fill={color}
            stroke="#FFFFFF"
            strokeWidth="1.0404"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M289.558,235.641
        c16.104,0.575,44.973-31.647,44.835-45.259c-0.136-13.612-17.227-58.446-22.349-66.088c-5.122-7.628-37.905,2.506-37.905,2.506
        S234.852,233.695,289.558,235.641z"
        />
    );
};

TocantinsPathIcon.propTypes = { active: PropTypes.bool };

export default TocantinsPathIcon;
