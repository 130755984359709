import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { currencyFormatter } from 'utils';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import { usePaymentInfoForUser } from 'hooks/payments';
import { useCurrentCampaign } from 'hooks/campaign';
import { useUser } from 'hooks/user';
import { useFeatureFlagPaymentByPIXConfig, usePaymentPhoneNumberFromConfig } from 'hooks/config';
import { analytics } from 'base';
import PaymentMethodSelector from 'organisms/PaymentMethodSelector';
import CartOverview from './CartOverview';
import Payment from './Payment';
import SelectCriativoModal from './SelectCriativoModal';
import CampaignOverview from '../../CampaignOverview';
import CampaignTotal from '../../CampaignTotal';
import useCurrentCampaignGrantTotal from '../../useCurrentCampaignGrantTotal';
import { AnalyticsContext } from '../../analyticsContext';

const OverviewStep = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isSelectCreativeModalOpen, toggleSelectCreativeModalOpen] = useReducer(state => !state, false);

    const isAddingCampaign = useSelector(state => state.campaignsReducer.isAddingCampaign);
    const {
        objectivesAndDuration,
        mediaCentral: { selectedTVPrograms, selectedRadioPrograms },
        coupon,
        paymentMethod,
    } = useCurrentCampaign();
    const totalToPay = useCurrentCampaignGrantTotal(coupon);

    const { beginCheckout } = useContext(AnalyticsContext);

    useEffect(() => {
        dispatch(CampaignCreators.unlockStep('overview'));
        beginCheckout();
    }, [beginCheckout, dispatch]);

    const selectedPrograms = useMemo(
        () => [...selectedTVPrograms, ...selectedRadioPrograms],
        [selectedRadioPrograms, selectedTVPrograms],
    );

    const handlePreviousClick = event => {
        event.preventDefault;
        dispatch(CampaignCreators.previousStep());
    };

    const { data: paymentPhoneNumber, isLoading: isLoadingPhoneNumber } = usePaymentPhoneNumberFromConfig({
        enabled: paymentMethod === 'pix',
    });

    function handleCampaigSaved(documentReference, error) {
        if (error) {
            toast.error(error.message || 'Erro no PAGAMENTO! Confira os dados e tente novamente.');
        }

        if (documentReference) {
            toast.success('Campanha salva com sucesso!');
            toggleSelectCreativeModalOpen();
        }
    }
    const user = useUser();

    function handleSaveCampaignClick(event) {
        event.preventDefault();
        dispatch(CampaignCreators.setCampaignEditable(false));
        dispatch(CampaignCreators.saveCurrentCampaign(handleCampaigSaved));

        if (paymentMethod === 'pix') {
            const text = encodeURIComponent(`Olá...
Me chamo ${user.displayName} (${user.email}) sou da empresa ${user.company} CNPJ (${user.cnpj || user.cpf}).

Acabei de registrar a campanha:
 - *Nome*: _"${objectivesAndDuration.name}"_
 - *Cidade*: _${objectivesAndDuration.city}_
 - *Duração*: _${objectivesAndDuration.duration}s_
 - *Valor a pagar*: _${currencyFormatter.format(totalToPay)}s_

e gostaria de efetuar o pagamento via PIX.

Como devo proceder?
            `);
            const redirectUrl = `https://api.whatsapp.com/send?phone=${paymentPhoneNumber}&text=${text}`;
            window.open(redirectUrl, '_blank');
        }
    }
    function handleSelectCriativoClose() {
        toggleSelectCreativeModalOpen();
        history.push('/campanhas');
    }

    const { data: featureFlagPixEnabled } = useFeatureFlagPaymentByPIXConfig();

    const { data: paymentInfo } = usePaymentInfoForUser();
    const hasCreditCardAvailable = paymentInfo?.creditCard;
    const mustSelectCreditCard = paymentMethod === 'credit-card' && !hasCreditCardAvailable && totalToPay > 0;

    const handlePaymentMethodSelected = paymentMethodSelected => {
        analytics.logEvent(`campaign_payment_select-${paymentMethodSelected}`);
        dispatch(CampaignCreators.setPaymentMethod(paymentMethodSelected));
    };

    return (
        <div className="tw-mt-4 ">
            <div className="tw-flex md:tw-place-content-between md:tw-flex-row-reverse tw-flex-col tw-gap-x-8 tw-mb-8">
                <CampaignOverview programs={selectedPrograms} />
            </div>

            <CartOverview />

            <div className="tw-flex tw-place-items-center tw-justify-end">
                <div className="tw-w-96">
                    <CampaignTotal programs={selectedPrograms} />
                </div>
            </div>

            <h3 className="tw-mt-4">Pagamento</h3>
            {featureFlagPixEnabled && (
                <PaymentMethodSelector
                    onPaymentMethodSelect={handlePaymentMethodSelected}
                    paymentMethod={paymentMethod}
                />
            )}
            <Payment paymentMethod={paymentMethod} />

            <div className="tw-mt-10 tw-text-center">
                <button
                    type="button"
                    onClick={handlePreviousClick}
                    className="button button-primary-outlined tw-mr-4"
                    disabled={isAddingCampaign}
                >
                    Voltar
                </button>

                <button
                    type="button"
                    className="button button-primary"
                    onClick={handleSaveCampaignClick}
                    disabled={
                        mustSelectCreditCard || isAddingCampaign || (paymentMethod === 'pix' && isLoadingPhoneNumber)
                    }
                >
                    {totalToPay > 0 ? 'Pagar' : 'Salvar'}
                </button>
            </div>

            {isSelectCreativeModalOpen && <SelectCriativoModal onClose={handleSelectCriativoClose} />}
        </div>
    );
};

export default OverviewStep;
