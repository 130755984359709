import React from 'react';
import { currencyFormatter } from 'utils';
import CreativePriceDescription from 'molecules/CreativePriceDescription';

/**
 * Render creative price description
 * @param {Object} creative - The creative
 * @param {string} description - Description of payment
 * @param {number} quantity - Quantity of creatives
 * @param {number} price - Price of the creative
 * @param {Object} [coupon] - The coupon to be used
 * @returns {JSX.Element}
 * @constructor
 */
const CreativePaymentTable = ({ creative, description, quantity, price, coupon }) => {
    const totalToPay = price - (coupon ? coupon.value : 0);
    return (
        <>
            <div className="tw-w-full tw-overflow-x-auto">
                <table className="tw-border-separate tw-w-full">
                    <thead>
                        <tr>
                            <th className="tw-text-purple tw-text-left" align="left">
                                Descrição
                            </th>
                            <th className="tw-text-purple tw-text-left">Criativo</th>
                            <th className="tw-text-purple tw-text-center">Qtde</th>
                            <th className="tw-text-purple tw-text-left">Duração</th>
                            <th className="tw-text-purple tw-text-left">Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{description}</td>
                            <td>{creative.commercialName}</td>
                            <td>{quantity}</td>
                            <td>{creative.durationTime}</td>
                            <td>{currencyFormatter.format(price)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr className="tw-border-1 tw-border-gray-300" />
            <CreativePriceDescription coupon={coupon} amountToPay={totalToPay} />
        </>
    );
};

export default CreativePaymentTable;
