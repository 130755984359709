import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Loading from 'react-fullscreen-loading';
import Select from 'molecules/Select';
import states from 'utils/states';

import yup from 'validator';
import Input from 'molecules/InputField';

import { useUser } from 'hooks/user';
import { useDispatch } from 'react-redux';
import { updateUser } from 'store/modules/user';

import { clearString } from 'utils';
import { getAddressByCEP } from 'services/cep';

const schema = yup.object().shape({
    cep: yup.string().required().label('CEP'),
    city: yup.string().required().label('Cidade'),
    state: yup.string().required().label('Estado'),
    street: yup.string().required().label('Logradouro'),
    neighborhood: yup.string().required().label('Bairro'),
    number: yup.string().required().label('Número'),
    complement: yup.string(),
});

function Address() {
    const [loading, setLoading] = useState(false);

    const { handleSubmit, register, errors, control, setValue, watch } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const { push } = useHistory();
    const dispatch = useDispatch();

    const user = useUser();

    const onSubmit = address => {
        setLoading(true);

        function onSuccess() {
            toast.success('Dados salvos com sucesso!');
            setLoading(false);
        }

        function onFailure() {
            toast.error('Falha ao salvar dados!');
            setLoading(false);
        }

        dispatch(updateUser({ ...user, address }, { onSuccess, onFailure }));
    };

    const zipCode = watch('cep', '');

    useEffect(() => {
        async function fetchAddressData(zip) {
            const response = await getAddressByCEP(zip);
            if (response.data) {
                const { cep, state, city, street, neighborhood } = response.data;
                setValue('cep', cep);
                setValue('state', state);
                setValue('street', street);
                setValue('city', city);
                setValue('neighborhood', neighborhood);
            }
        }
        if (clearString(zipCode).length === 8) {
            fetchAddressData(clearString(zipCode));
        }
    }, [setValue, zipCode]);

    return (
        <>
            {loading && <Loading />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="tw-flex tw-w-full tw-flex-col tw-justify-between md:tw-flex-row lg:tw-my-8">
                    <Input
                        label="CEP"
                        name="cep"
                        error={errors.address?.cep}
                        defaultValue={user?.address?.cep}
                        className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/3"
                        placeholder="Digite aqui"
                        control={control}
                        mask="99999-999"
                    />
                    <Input
                        label="Cidade"
                        name="city"
                        error={errors.address?.city}
                        defaultValue={user?.address?.city}
                        className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/3 md:tw-mx-4"
                        placeholder="Digite aqui"
                        ref={register}
                    />
                    <Controller
                        as={Select}
                        label="Estado"
                        name="state"
                        error={errors.address?.state}
                        defaultValue={user?.address?.state || ''}
                        className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/3"
                        control={control}
                    >
                        <option value="select">Selecionar</option>
                        {states.map(({ label, value }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </Controller>
                </div>
                <div className="tw-flex tw-w-full tw-flex-col tw-justify-between md:tw-flex-row">
                    <Input
                        label="Logradouro"
                        name="street"
                        className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/2 md:tw-mr-2"
                        error={errors.address?.street}
                        defaultValue={user?.address?.street}
                        placeholder="Digite aqui"
                        ref={register}
                    />
                    <Input
                        label="Bairro"
                        name="neighborhood"
                        className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/2 md:tw-ml-2"
                        error={errors.address?.neighborhood}
                        defaultValue={user?.address?.neighborhood}
                        placeholder="Digite aqui"
                        ref={register}
                    />
                </div>
                <div className="tw-flex tw-w-full tw-flex-col tw-justify-between md:tw-flex-row md:tw-mt-8">
                    <Input
                        label="Número"
                        name="number"
                        className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-1/5 md:tw-mr-2"
                        error={errors.address?.number}
                        defaultValue={user?.address?.number}
                        placeholder="Digite aqui"
                        ref={register}
                    />
                    <Input
                        label="Complemento"
                        name="complement"
                        className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-4/5 md:tw-ml-2"
                        defaultValue={user?.address?.complement}
                        placeholder="Digite aqui"
                        ref={register}
                    />
                </div>
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-mt-14">
                    <button className="button button-primary-outlined tw-block" type="button" onClick={() => push('/')}>
                        Cancelar
                    </button>
                    <button type="submit" className="button button-primary tw-mt-4 md:tw-mt-0 tw-block md:tw-ml-4">
                        {loading ? 'Salvando...' : 'Salvar Alterações'}
                    </button>
                </div>
            </form>
            <br />
        </>
    );
}

export default Address;
