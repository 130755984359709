import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FaPen, FaTimes } from 'react-icons/fa';
import { setProgram, setStep } from 'store/modules/program';
import { currencyFormatter } from 'utils';

const ProgramRow = ({ program, regions, onDelete }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const region = regions.find(region => region.id === program.programRegionId);

    const handleEditProgram = useCallback(
        event => {
            event.preventDefault();
            dispatch(setProgram(program));
            dispatch(setStep(0));
            history.push(`/programas/${program.id}`);
        },
        [dispatch, history, program],
    );
    const handlePrepareToDeleteProgram = useCallback(
        event => {
            event.preventDefault();
            onDelete(program);
        },
        [onDelete, program],
    );

    return (
        <tr>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                <p>{program.programName}</p>
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="right">
                {`${program.availableSeconds} Seg.`}
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1" align="right">
                {`${currencyFormatter.format(program.insertionCost)}`}
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1 tw-text-center tw-font-bold">
                {region?.regionName || '-'}
            </td>
            <td className="tw-border-b-2 tw-border-gray-light tw-py-1">
                <div className="tw-flex tw-flex-row tw-items-center">
                    <FaPen size={18} color="#087A99" className="tw-cursor-pointer" onClick={handleEditProgram} />
                    <FaTimes
                        size={22}
                        color="#cd0e4b"
                        className="tw-cursor-pointer tw-ml-3"
                        onClick={handlePrepareToDeleteProgram}
                    />
                </div>
            </td>
        </tr>
    );
};

export default ProgramRow;
