import React from 'react';
import { usePaymentInfoForUser } from 'hooks/payments';

const CreditCardSelect = React.forwardRef(({ ...rest }, reference) => {
    const { data: paymentInfo, isLoading } = usePaymentInfoForUser();

    const isLoadingCreditCard = isLoading;
    const hasCreditCardAvailable = paymentInfo?.creditCard;
    const hasNoCreditCardAvailable = !isLoadingCreditCard && !paymentInfo?.creditCard;

    return (
        <select className="cimtia-select" {...rest} ref={reference}>
            {!hasCreditCardAvailable && isLoadingCreditCard && <option>Carregando...</option>}
            {hasCreditCardAvailable && (
                <option value={paymentInfo.creditCard.id}>
                    {paymentInfo.creditCard.holderName} **** **** **** {paymentInfo.creditCard.lastDigits}
                </option>
            )}
            {hasNoCreditCardAvailable && <option>Nenhum cartão cadastrado</option>}
        </select>
    );
});

CreditCardSelect.displayName = 'CreditCardSelect';

export default CreditCardSelect;
