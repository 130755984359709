import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Creators as CampaignCreators } from 'store/modules/campaign';
import { CimtiaProvider } from 'src/context/cimtia';
import { useQuery } from 'hooks/query';
import { useCurrentCampaign, useCurrentStep } from 'hooks/campaign';
import { useFeatureFlagRadio } from 'hooks/config';
import Wizard from 'components/Wizard';
import Loading from 'components/Loading';
import scrollTop from 'utils/scrollTop';
import LocalizationStep from './Steps/LocalizationStep';
import ObjectivesAndDurationStep from './Steps/ObjectivesAndDurationStep';
import BudgetStep from './Steps/BudgetStep';
import OverviewStep from './Steps/OverviewStep';
import MediaPlanStep from './Steps/MediaPlanStep/MediaPlanStep';
import MediaCentralStep from './Steps/MediaCentralStep';
import TargetAndSegmentationStep from './Steps/TargetAndSegmentationStep';
import VehicleTypeChosenStep from './Steps/VehicleTypeChosenStep';
import { AnalyticsContext, AnalyticsProvider, STEP_KEYS } from './analyticsContext';

const CampaignWizard = () => {
    const currentStep = useCurrentStep();
    const campaignVehicleType = useQuery().get('tipo');

    const { data: isFeatureRadioEnabled, isLoading, isFetched } = useFeatureFlagRadio();

    const { budget } = useCurrentCampaign();
    const { logStepEvent } = useContext(AnalyticsContext);

    const isAutomatic = budget?.budgetType === 'automatic';

    function handleStepChange(step) {
        const stepKey = STEP_KEYS[step];
        logStepEvent(stepKey);
    }

    useEffect(() => {
        scrollTop();
    }, [currentStep]);

    if (!isFetched && isLoading) {
        return <Loading />;
    }

    return (
        <div className="tw-pb-12">
            <Wizard step={currentStep} onStepChange={handleStepChange}>
                <Wizard.Step title="Localização">
                    <LocalizationStep />
                </Wizard.Step>
                <Wizard.Step title="Tipo" visible={!campaignVehicleType && isFeatureRadioEnabled}>
                    <VehicleTypeChosenStep />
                </Wizard.Step>
                <Wizard.Step title="Objetivos e Duração">
                    <ObjectivesAndDurationStep />
                </Wizard.Step>
                <Wizard.Step title="Investimento">
                    <BudgetStep />
                </Wizard.Step>
                <Wizard.Step title="Target e Segmento" visible={isAutomatic}>
                    <TargetAndSegmentationStep />
                </Wizard.Step>
                <Wizard.Step title="Central de Mídia">
                    <MediaCentralStep />
                </Wizard.Step>
                <Wizard.Step title="Plano de Mídia">
                    <MediaPlanStep />
                </Wizard.Step>
                <Wizard.Step title="Pagamento">
                    <OverviewStep />
                </Wizard.Step>
            </Wizard>
        </div>
    );
};

const RegisterCampaignPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(CampaignCreators.setNewCurrentCampaign());
    }, [dispatch]);

    return (
        <CimtiaProvider>
            <AnalyticsProvider>
                <CampaignWizard />
            </AnalyticsProvider>
        </CimtiaProvider>
    );
};

export default RegisterCampaignPage;
