import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAvailableProgramsByType } from 'hooks/campaign';

const VehicleTypeChosenStep = () => {
    const availablePrograms = useAvailableProgramsByType();
    const availableTypes = Object.keys(availablePrograms);

    const history = useHistory();
    useEffect(() => {
        if (availableTypes.length === 1) {
            history.push(`/campanhas/criar?tipo=${availableTypes[0].toLowerCase()}`);
        }
    }, [availableTypes, history]);

    return (
        <div className=" tw-p-12">
            <div className="tw-flex tw-flex-col tw-items-center tw-align-middle tw-justify-center tw-gap-x-8">
                <div className="tw-w-1/3">
                    <h3>Em qual veículo deseja anunciar?</h3>
                    <p>Escolha qual tipo de veículo deseja veicular sua campanha.</p>
                </div>

                <div className="tw-flex tw-place-content-center tw-items-center tw-align-middle tw-justify-center tw-gap-x-8 tw-w-1/3 tw-py-8">
                    {availableTypes.map(programType => (
                        <Link
                            key={programType}
                            to={`/campanhas/criar?tipo=${programType.toLowerCase()}`}
                            className="button button-outline tw-w-80"
                        >
                            {programType}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default VehicleTypeChosenStep;
