import React, { useEffect, useState } from 'react';
import moment from 'moment';

const ProgramsTableWithApprovedDates = ({ campaign }) => {
    const [data, setData] = useState([]);

    const {
        approvedDates,
        objectivesAndDuration: { duration },
    } = campaign;

    useEffect(() => {
        async function populateData() {
            const list = [];
            for (const { program, date } of approvedDates) {
                const programData = await program.get();

                list.push({
                    id: program.id,
                    ...programData.data(),
                    date,
                });
            }
            setData(list);
        }
        populateData();
    }, [approvedDates]);

    return !data?.length ? (
        <h3 className="tw-mt-4">Carregando inserções...</h3>
    ) : (
        <table className="tw-w-full tw-mt-6">
            <thead>
                <tr>
                    <th className="tw-text-purple tw-text-left" align="left">
                        Programa
                    </th>
                    <th className="tw-text-purple tw-text-left">Data de Veiculação</th>
                    <th className="tw-text-purple tw-text-center">Duração</th>
                </tr>
            </thead>
            <tbody>
                {data?.map(({ id, programName, date }) => (
                    <tr key={id}>
                        <td className="tw-text-purple tw-text-left" align="left">
                            {programName}
                        </td>
                        <td className="tw-text-purple tw-text-left">{moment(date.toDate()).format('DD/MM/YYYY')}</td>
                        <td className="tw-text-purple tw-text-center">{`${duration} Seg.`}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ProgramsTableWithApprovedDates;
