/* eslint-disable react-perf/jsx-no-new-array-as-prop */
import React, { useCallback } from 'react';
import yup from 'validator';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'molecules/InputField';

const schema = yup.object().shape({
    password: yup.string().defined().required().min(6).label('Senha'),
    confirmPassword: yup
        .string()
        .defined()
        .required()
        .min(6)
        .oneOf([yup.ref('password'), null], 'As senhas são diferentes')
        .label('Confirmar senha'),
});

const ThirdStep = ({ setStep, registerData, submitData }) => {
    const { handleSubmit, register, errors } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(
        values => {
            return submitData({
                ...registerData,
                ...values,
            });
        },
        [submitData, registerData],
    );

    const handleBack = () => setStep(previous => previous - 1);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="tw-flex tw-w-full tw-flex-col tw-justify-between md:tw-flex-row">
                <Input
                    label="Senha"
                    name="password"
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-5/12 md:tw-max-w-xs"
                    type="password"
                    error={errors.password}
                    defaultValue={registerData.password}
                    ref={register}
                />
                <Input
                    label="Confirmar senha"
                    name="confirmPassword"
                    className="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-5/12 md:tw-max-w-xs"
                    type="password"
                    error={errors.confirmPassword}
                    defaultValue={registerData.confirmPassword}
                    ref={register}
                />
            </div>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-mt-14">
                <button
                    className="button button-primary-outlined tw-block tw-w-full md:tw-w-1/4"
                    type="button"
                    onClick={handleBack}
                >
                    Voltar
                </button>
                <button
                    type="submit"
                    className="button button-primary tw-mt-4 md:tw-mt-0 tw-block tw-w-full md:tw-w-1/4 md:tw-ml-4"
                >
                    Prosseguir
                </button>
            </div>
        </form>
    );
};

export default ThirdStep;
