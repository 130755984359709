import React, { useCallback, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { FaEdit } from 'react-icons/fa';
import formatRelative from 'date-fns/formatRelative';
import { ptBR } from 'date-fns/locale';
import { updateCampaign } from 'api/campaign';
import { firebase } from 'base';
import { currencyFormatter } from 'utils';
import ProgramsTable from './ProgramsTable';
import { handlePromiseError } from 'utils/handles';

const PackageInsertionsModal = ({ campaign, open, onClose }) => {
    const [isEditingDates, setIsEditingDates] = useState(false);
    const [changeDates, setChangeDates] = useState(false);

    const handleCancelClick = event => {
        event.preventDefault();
        onClose();
    };

    function handleChangeDates(event) {
        setIsEditingDates(true);
        setChangeDates(true);
        event.preventDefault();
        return updateCampaign(campaign.id, {
            ...campaign,
            piSentAt: firebase.firestore.FieldValue.delete(),
        });
    }

    const totalInsertions = campaign?.packageItem?.programs?.reduce(
        (sum, program) => (sum += program.insertionQuantity),
        0,
    );

    const handleConfirmInsertions = useCallback(async () => {
        async function approveDates() {
            return updateCampaign(campaign?.id, {
                status: 'PENDING_CREATIVE',
            }).catch(handlePromiseError('Falha ao aprovar datas'));
        }
        await approveDates();
        onClose();
    }, [campaign?.id, onClose]);

    return (
        <Modal open={open} onClose={onClose}>
            <div className="tw-p-1">
                <h2 className="tw-text-pink">{campaign.piSentAt ? 'Aprovar Inserções' : 'Solicitar PI'}</h2>
                <p>
                    Confira a <strong>disponibilidade</strong> dos espaços para confirmar as inserções no{' '}
                    <span className="tw-text-purple tw-font-bold">mapa abaixo</span>.
                </p>
                <div className="tw-text-sm tw-p-3 tw-shadow-md tw-rounded-md tw-flex md:tw-flex-row tw-flex-col md:tw-justify-between md:tw-items-center tw-mt-4">
                    <div>
                        <p>
                            <span className="tw-text-pink tw-font-bold">Total de Comerciais: </span>
                            <span className="tw-text-gray">{`${totalInsertions} inserções`}</span>
                        </p>
                    </div>
                    <div className="tw-flex md:tw-flex-row tw-flex-col">
                        <p>
                            <span className="tw-text-pink tw-font-bold">Total: </span>
                            <span className="tw-text-gray">{currencyFormatter.format(campaign.campaignValue)}</span>
                        </p>
                    </div>
                </div>

                {campaign?.approvedDates && (
                    <div className="tw-text-sm tw-p-2 tw-shadow-sm tw-rounded-md tw-flex tw-place-content-center tw-justify-between tw-align-middle tw-items-center tw-mx-8 tw-mt-2">
                        <p className="tw-text-gray tw-flex-grow ">
                            {campaign.piSentAt ? (
                                <>
                                    PI solicitado a{' '}
                                    {formatRelative(campaign.piSentAt.toDate(), new Date(), {
                                        locale: ptBR,
                                    })}
                                </>
                            ) : undefined}
                        </p>
                        <button
                            type="button"
                            className="button button-small tw-ml-2 tw-flex tw-justify-center tw-place-content-center tw-align-middle tw-items-center"
                            onClick={handleChangeDates}
                        >
                            <FaEdit size={20} color="gray" />
                            Alterar datas
                        </button>
                    </div>
                )}
                <div className="tw-w-full tw-overflow-x-auto">
                    <ProgramsTable
                        campaign={campaign}
                        programs={campaign?.packageItem?.programs}
                        handleCancelClick={handleCancelClick}
                        totalInsertions={totalInsertions}
                        changeDates={changeDates}
                        setChangeDates={setChangeDates}
                        handleConfirmInsertions={handleConfirmInsertions}
                        isEditingDates={isEditingDates}
                        setIsEditingDates={setIsEditingDates}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default PackageInsertionsModal;
