import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { setNewProgram } from 'store/modules/program';

const AddProgramButton = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleAddNewProgram = event => {
        event.preventDefault();
        dispatch(setNewProgram());
        history.push('/programas/novo');
    };

    return (
        <div className="tw-w-full tw-flex md:tw-justify-end tw-justify-center tw-my-4">
            <button
                type="button"
                className="tw-flex tw-items-center tw-border-none tw-rounded-md tw-bg-pink tw-text-white tw-font-bold tw-px-5 tw-py-0 tw-h-10 md:tw-ml-3"
                onClick={handleAddNewProgram}
            >
                <FaPlus size={18} className="tw-text-white" />
                <span className="tw-block tw-ml-3">Adicionar Programa</span>
            </button>
        </div>
    );
};

export default AddProgramButton;
