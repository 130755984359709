/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';
import { useDispatch } from 'react-redux';
import PlusIcon from 'icons/PlusIcon';
import { Creators as CampaignCreators } from 'store/modules/campaign';

const AddProgramCard = () => {
    const dispatch = useDispatch();
    function handleAddProgram(event) {
        event.preventDefault();
        dispatch(CampaignCreators.previousStep());
    }

    return (
        <div className="tw-w-full tw-max-w-xs tw-rounded-md tw-h-full tw-bg-gray-lighting tw-relative">
            <button
                type="button"
                onClick={handleAddProgram}
                style={{ minHeight: '280px' }}
                className="tw-flex tw-flex-col md:tw-p-0 tw-p-3 tw-w-full tw-h-full tw-items-center tw-place-content-center tw-align-middle tw-place-self-stretch tw-shadow-md"
            >
                <span className="tw-rounded-full tw-bg-purple tw-text-white">
                    <PlusIcon className="tw-inline tw-text-white" />
                </span>
                Adicionar outros programas
            </button>
        </div>
    );
};

export default AddProgramCard;
