import React from 'react';
import PropTypes from 'prop-types';

const CursorClickIcon = ({ className = '' }) => (
    <svg
        className={'icon ' + className}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
        />
    </svg>
);

CursorClickIcon.propTypes = {
    className: PropTypes.string,
};
CursorClickIcon.defaultProps = {
    className: '',
};

export default CursorClickIcon;
