import React from 'react';

function One({ className }) {
    return (
        <svg
            width="34"
            height="71"
            viewBox="0 0 34 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M0.96 20.792V0.823999H33.504V71H11.136V20.792H0.96Z" fill="#7DC5AF" fillOpacity="0.15" />
        </svg>
    );
}

export default One;
