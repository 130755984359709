import React from 'react';

function PlayIcon({ onClick, className }) {
    return (
        <svg
            width="29"
            height="33"
            viewBox="0 0 29 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            className={className}
        >
            <path
                d="M5.24238 0.728843C2.35613 -0.983224 0 0.426714 0 3.85085V29.1492C0 32.5733 2.35613 33.9832 5.26202 32.2712L26.8206 19.5817C29.7265 17.8697 29.7265 15.1102 26.8206 13.3981L5.24238 0.728843Z"
                fill="#E5195A"
            />
        </svg>
    );
}

export default PlayIcon;
