import produce from 'immer';
import { createReducer, createActions } from 'reduxsauce';

const initialState = {
    activeStep: 0,
    isLoadingCreatives: false,
    creatives: [],
    creative: {},
};

// Actions
export const { Types, Creators } = createActions(
    {
        goNextStep: [],
        goPreviousStep: [],
        setStep: ['step'],
        setIsLoadingCreatives: ['isLoading'],
        getCreatives: ['userId', 'duration', 'meta'],
        setMedia: ['data'],
        setCommercial: ['data'],
        setOffers: ['data'],
        setMoreInfo: ['data'],
        setCreativeReference: ['reference'],
        setNewCreative: [],
        getCreativesSuccess: ['creatives'],
        creativeSubmit: ['creative', 'meta', 'paymentMethod', 'coupon'],
        setCreativeCampaignIds: ['data', 'meta'],
    },
    { prefix: 'creative/' },
);

// Reducer
function goNextStep(state = initialState) {
    return produce(state, draft => {
        draft.activeStep = state.activeStep + 1;
    });
}
function goPreviousStep(state = initialState) {
    return produce(state, draft => {
        draft.activeStep = state.activeStep - 1;
    });
}
function setStep(state = initialState, action) {
    return produce(state, draft => {
        draft.activeStep = action.step;
    });
}
function setIsLoadingCreatives(state = initialState, action) {
    return produce(state, draft => {
        draft.isLoadingCreatives = action.isLoading;
    });
}

function getCreativesSuccess(state = initialState, action) {
    return produce(state, draft => {
        draft.creatives = action.creatives;
    });
}
function setMedia(state = initialState, action) {
    return produce(state, draft => {
        draft.creative = {
            ...draft.creative,
            media: action.data.media,
            durationTime: action.data.durationTime,
            creativeType: action.data?.creativeType || 'TV',
        };
    });
}
function setCommercial(state = initialState, action) {
    return produce(state, draft => {
        draft.creative = {
            ...draft.creative,
            ...action.data,
        };
    });
}
function setOffers(state = initialState, action) {
    return produce(state, draft => {
        draft.creative = {
            ...draft.creative,
            ...action.data,
        };
    });
}
function setMoreInfo(state = initialState, action) {
    return produce(state, draft => {
        draft.creative = {
            ...draft.creative,
            ...action.data,
        };
    });
}
function setCreativeReference(state = initialState, action) {
    return produce(state, draft => {
        draft.creative.reference = action.reference;
    });
}

function setNewCreative(state = initialState) {
    return produce(state, draft => {
        draft.creative = {};
    });
}

export default createReducer(initialState, {
    [Types.GO_NEXT_STEP]: goNextStep,
    [Types.GO_PREVIOUS_STEP]: goPreviousStep,
    [Types.SET_STEP]: setStep,
    [Types.SET_IS_LOADING_CREATIVES]: setIsLoadingCreatives,
    [Types.GET_CREATIVES_SUCCESS]: getCreativesSuccess,
    [Types.SET_MEDIA]: setMedia,
    [Types.SET_COMMERCIAL]: setCommercial,
    [Types.SET_OFFERS]: setOffers,
    [Types.SET_MORE_INFO]: setMoreInfo,
    [Types.SET_CREATIVE_REFERENCE]: setCreativeReference,
    [Types.SET_NEW_CREATIVE]: setNewCreative,
});
