import moment from 'moment';
import React from 'react';
import CreativePaymentTable from 'organisms/CreativePaymentTable/CreativePaymentTable';

const PaymentInfoSection = ({ creative }) => {
    const dateOfPayment = creative.payment.dateOfPayment.toDate();
    return (
        <>
            <h2 className="tw-text-pink tw-normal-case tw-font-bold tw-mt-6">Resumo da Compra</h2>
            <p className="tw-mt-2 tw-mb-6">
                <span className="tw-text-purple">Data da compra: </span>
                {moment(dateOfPayment).format('DD/MM/YYYY')}
            </p>
            <CreativePaymentTable
                creative={creative}
                coupon={creative.coupon}
                price={creative.payment.paidAmount}
                quantity={1}
                description="Edição, criação e montagem de Comercial"
            />
        </>
    );
};

export default PaymentInfoSection;
