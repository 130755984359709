import React, { useMemo } from 'react';
import CitiesList from 'organisms/CitiesList';

const getCampaignObjective = objective => (objective === 'brandAwareness' ? 'Reconhecimento' : 'Alcance');
const getPublicString = targetAndSegmentation => {
    let label = '';

    if (!targetAndSegmentation) {
        label = 'Campanha manual';
    }

    if (targetAndSegmentation?.gender) {
        let gender = 'Todos os gêneros';

        if (targetAndSegmentation?.gender === 'male') {
            gender = 'Masculino';
        }

        if (targetAndSegmentation?.gender === 'female') {
            gender = 'Feminino';
        }

        label = gender;
    }

    if (
        targetAndSegmentation?.socialClass.classA &&
        targetAndSegmentation?.socialClass.classB &&
        targetAndSegmentation?.socialClass.classC &&
        targetAndSegmentation?.socialClass.classC
    ) {
        label += ', todas as classes sociais';
    } else {
        const classes = [];
        if (targetAndSegmentation?.socialClass.classA) {
            classes.push('A');
        }
        if (targetAndSegmentation?.socialClass.classB) {
            classes.push('B');
        }
        if (targetAndSegmentation?.socialClass.classC) {
            classes.push('C');
        }
        if (targetAndSegmentation?.socialClass.classD) {
            classes.push('D');
        }

        classes.length === 1
            ? (label += `, classe social "${classes[0]}"`)
            : (label += `, classes sociais ${classes.join(', ')}`);
    }

    if (targetAndSegmentation?.ageRange) {
        if (typeof targetAndSegmentation?.ageRange === 'string') {
            const [from, to] = targetAndSegmentation?.ageRange?.split(',') || [];
            label += `, ${from} a ${to} anos`;
        } else if (typeof targetAndSegmentation?.ageRange === 'object') {
            label += `, ${targetAndSegmentation?.ageRange?.min} a ${targetAndSegmentation?.ageRange?.max} anos`;
        }
    }

    return label;
};

const CampaignInfo = ({ campaign }) => {
    const allPrograms = useMemo(
        () => [
            ...(campaign.mediaCentral?.selectedTVPrograms || []),
            ...(campaign.mediaCentral?.selectedRadioPrograms || []),
        ],
        [campaign.mediaCentral.selectedRadioPrograms, campaign.mediaCentral.selectedTVPrograms],
    );

    return (
        <div className="tw-w-full tw-flex tw-justify-between tw-flex-row tw-rounded-md tw-p-3 tw-bg-gray-xlighter">
            <div className="tw-w-1/5 tw-px-3 tw-text-center">
                <h6 className="tw-text-purple tw-font-bold tw-text-left tw-text-sm">Objetivo da Campanha</h6>
                <p className="tw-text-left tw-text-xs">
                    {getCampaignObjective(campaign.objectivesAndDuration.objective)}
                </p>
            </div>
            <div className="tw-w-1/5 tw-px-3 tw-text-center">
                <h6 className="tw-text-purple tw-font-bold tw-text-left tw-text-sm">Seu Público</h6>
                <p className="tw-text-left tw-text-xs">
                    {campaign.budget?.budgetType === 'manual'
                        ? 'Campanha manual'
                        : getPublicString(campaign.targetAndSegmentation)}
                </p>
            </div>
            <div className="tw-w-1/5 tw-px-3 tw-text-center">
                <h6 className="tw-text-purple tw-font-bold tw-text-left tw-text-sm">Duração do Comercial</h6>
                <p className="tw-text-left tw-text-xs">{`${campaign.objectivesAndDuration.duration} segundos`}</p>
            </div>
            <div className="tw-w-1/5 tw-px-3 tw-text-center">
                <h6 className="tw-text-purple tw-font-bold tw-text-left tw-text-sm">Região de Cobertura</h6>

                <CitiesList
                    state={campaign.localization.code}
                    city={campaign.objectivesAndDuration.city}
                    programs={allPrograms}
                    className="tw-text-xs tw-text-left"
                />
            </div>
        </div>
    );
};

export default CampaignInfo;
