/* eslint-disable unicorn/better-regex */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { analytics, firebase } from 'base';
import { signUp } from 'store/modules/auth';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import ThirdStep from './Steps/ThirdStep';
import styleConfig from '../../../style_config.json';

function cleanString(value) {
    if (!value) return '';
    return value.replace(/[^A-Z0-9]+/gi, '');
}

const STEP_KEYS = ['basic-info', 'company-info', 'password'];

const Register = () => {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [registerData, setRegisterData] = React.useState({});

    const dispatch = useDispatch();

    const submitData = useCallback(
        values => {
            setLoading(true);
            dispatch(
                signUp(
                    {
                        ...values,
                        phone: cleanString(values.phone),
                        cnpj: cleanString(values.cnpj),
                    },
                    {
                        onFailure: error => {
                            setLoading(false);
                            Sentry.captureException(error);
                            toast.error(error.message);
                        },
                        onSuccess: () => {
                            analytics.logEvent(firebase.analytics.EventName.SIGN_UP);
                            localStorage.setItem('@cimtia:emailVerified', 'false');
                            window.open('/', '_self');
                        },
                    },
                ),
            );
        },
        [dispatch],
    );

    // eslint-disable-next-line react/display-name
    const Steps = memo(() => {
        switch (step) {
            case 0:
                return <FirstStep setStep={setStep} registerData={registerData} setRegisterData={setRegisterData} />;
            case 1:
                return <SecondStep setStep={setStep} registerData={registerData} setRegisterData={setRegisterData} />;
            default:
                return (
                    <ThirdStep
                        setStep={setStep}
                        registerData={registerData}
                        setRegisterData={setRegisterData}
                        submitData={submitData}
                    />
                );
        }
    });

    useEffect(() => {
        analytics.logEvent(`register/step-${STEP_KEYS[step]}`);
    }, [step]);

    return (
        <div className="blue-background">
            <div className="tw-w-screen min-h-screen tw-py-12 tw-flex tw-align-middle tw-items-center tw-place-content-center tw-flex-col">
                <div className="tw-w-full tw-max-w-screen-md">
                    <div className="tw-flex tw-justify-center tw-mb-10">
                        <img src={styleConfig['logo-vertical.svg']} alt="Cimtia logo" className="tw-w-32 tw-h-32" />
                    </div>
                    <div className="page-card tw-mx-4">
                        {loading ? (
                            <h2 className="tw-mb-4 tw-text-center">Cadastrando...</h2>
                        ) : (
                            <>
                                <h2 className="tw-mb-4 tw-text-center">Cadastro</h2>
                                {step === 0 && (
                                    <>
                                        <p className="tw-text-black tw-text-center tw-my-4 tw-text-xs">
                                            Já possui conta?{' '}
                                            <Link to="/" className="tw-decoration-none tw-text-xs">
                                                Log in
                                            </Link>
                                        </p>
                                        <div className="tw-flex tw-justify-center tw-my-4">
                                            <hr className="tw-w-60 tw-bg-gray" />
                                        </div>
                                        <h1 className="tw-text-base tw-text-center tw-normal-case tw-font-bold tw-text-black">
                                            Ou faça o seu cadastro:
                                        </h1>
                                    </>
                                )}
                                <p className="tw-text-base tw-text-center tw-text-gray tw-mb-2">
                                    Preencha os dados e comece a anunciar agora mesmo
                                </p>
                                <h4 className="tw-text-center tw-mb-4">
                                    Atenção:{' '}
                                    <span className="tw-underline">
                                        as informações preenchidas são dados fiscais para emissão de nota.
                                    </span>
                                </h4>
                                <Steps />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
