import { firestore } from 'base';

let paymentInfoConverter = {
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return {
            ...data,
            creditCard: {
                ...data.creditCard,
                dateCreated: data.creditCard.dateCreated.toDate(),
                dateUpdated: data.creditCard.dateUpdated.toDate(),
                expireDate: data.creditCard.expireDate.toDate(),
            },
            dateCreated: data.dateCreated.toDate(),
        };
    },
};
export function getPaymentInfoFor(userId) {
    return firestore.collection('paymentInfo').doc(userId).withConverter(paymentInfoConverter);
}
