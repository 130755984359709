/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable unicorn/no-null */
import React, { useCallback, useEffect } from 'react';
import * as yup from 'yup';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormLabel, TextField, Checkbox, Button, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { useProgram } from 'hooks/program';
import { setProgram } from 'store/modules/program';

const useStyles = makeStyles(() => ({
    socialClassesContainer: {
        marginTop: 12,
    },
    socialClassesGroup: {
        display: 'flex',
    },
    buttons: {
        marginTop: 40,
    },
}));

const genders = [
    { label: 'Homens', value: 'male' },
    { label: 'Mulheres', value: 'female' },
    { label: 'Todos', value: 'all' },
];

const segments = [
    { value: 'Agro', label: 'Agro' },
    { value: 'Alimentos', label: 'Alimentos' },
    { value: 'Automotivo', label: 'Automotivo' },
    { value: 'Construção', label: 'Construção' },
    { value: 'Educação', label: 'Educação' },
    { value: 'Entretenimento', label: 'Entretenimento' },
    { value: 'Hotelaria / Turismo', label: 'Hotelaria / Turismo' },
    { value: 'Industria', label: 'Industria' },
    { value: 'Informática', label: 'Informática' },
    { value: 'Moda / Vestuário', label: 'Moda / Vestuário' },
    { value: 'Papelaria', label: 'Papelaria' },
    { value: 'Pet', label: 'Pet' },
    { value: 'Saúde', label: 'Saúde' },
    { value: 'Segurança', label: 'Segurança' },
    { value: 'Serviços', label: 'Serviços' },
    { value: 'Transporte', label: 'Transporte' },
    { value: 'Tech', label: 'Tech' },
];

const schema = yup.object({
    segments: yup.array().required('Informe o segmento!'),
    gender: yup.string().required('Informe o gênero'),
    impact: yup
        .number()
        .min(10, 'Informe o número de pessoas alcançadas por inserção')
        .typeError('Informe o número de pessoas alcançadas'),
    socialClass: yup
        .object({
            classA: yup.boolean(),
            classB: yup.boolean(),
            classC: yup.boolean(),
            classD: yup.boolean(),
        })
        .test('socialClass', null, value => {
            if (value.classA || value.classB || value.classC || value.classD) {
                return true;
            }
            return new yup.ValidationError('Selecione uma ou mais classes sociais', null, 'socialClass');
        }),
});

const colourStyles = {
    control: styles => ({
        ...styles,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        border: 0,
        borderRadius: 0,
        padding: 0,
        borderBottom: '1px solid #949494;',
        boxShadow: 'none',
        color: '#000',
    }),
};

function Audience({ handleNext, handleBack }) {
    const { setValue, errors, control, handleSubmit, register } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            socialClass: {
                classA: false,
                classB: false,
                classC: false,
                classD: false,
            },
        },
    });

    const program = useProgram();

    const dispatch = useDispatch();

    const classes = useStyles();

    const handleGenderChange = useCallback(
        gender => {
            setValue('gender', gender);
        },
        [setValue],
    );

    const onSubmit = useCallback(
        values => {
            const gender = genders.find(item => item.label === values.gender)?.value;
            dispatch(
                setProgram({
                    ...values,
                    gender,
                    segments: values.segments.map(item => item.value),
                }),
            );

            handleNext();
        },
        [dispatch, handleNext],
    );

    useEffect(() => {
        for (const name of Object.keys(errors)) {
            toast.error(errors[name].message);
        }
    }, [errors]);

    useEffect(() => {
        if (program.gender) {
            setValue('gender', genders.find(item => item.value === program.gender)?.value);
        }

        if (program.socialClass) {
            setValue('socialClass.classA', program.socialClass.classA);
            setValue('socialClass.classB', program.socialClass.classB);
            setValue('socialClass.classC', program.socialClass.classC);
            setValue('socialClass.classD', program.socialClass.classD);
        }
    }, [program, setValue]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Autocomplete
                        options={genders}
                        getOptionLabel={option => option.label}
                        fullWidth
                        defaultValue={genders.find(gender => gender.value === program.gender)}
                        noOptionsText="Nenhum resultado"
                        onChange={(_, newValue) => handleGenderChange(newValue)}
                        renderInput={parameters => (
                            <TextField
                                {...parameters}
                                label="O programa é voltado para *"
                                size="medium"
                                margin="dense"
                                name="gender"
                                inputRef={register}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="impact"
                        defaultValue={program.impact}
                        inputRef={register}
                        label="Pessoas alcançadas por inserção*"
                        size="medium"
                        margin="dense"
                        type="number"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        as={Select}
                        isMulti
                        options={segments}
                        name="segments"
                        control={control}
                        styles={colourStyles}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        noOptionsMessage={() => 'Nenhuma opção'}
                        placeholder={<span>Selecionar segmentos</span>}
                        defaultValue={segments?.filter(segment =>
                            program?.segments?.find(item => item === segment.value),
                        )}
                    />
                </Grid>
                <Grid className={classes.socialClassesContainer} item xs={12} style={{ zIndex: 0 }}>
                    <div className={classes.socialClasses}>
                        <FormLabel>Classe Social *</FormLabel>
                        <div className={classes.socialClassesGroup}>
                            <Controller
                                render={({ onChange, onBlur, value, ref }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onBlur={onBlur}
                                                onChange={event => onChange(event.target.checked)}
                                                checked={value}
                                                inputRef={ref}
                                            />
                                        }
                                        label="A"
                                    />
                                )}
                                name="socialClass.classA"
                                control={control}
                                defaultValue={program?.socialClass?.classA || false}
                            />
                            <Controller
                                render={({ onChange, onBlur, value, ref }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onBlur={onBlur}
                                                onChange={event => onChange(event.target.checked)}
                                                checked={value}
                                                inputRef={ref}
                                            />
                                        }
                                        label="B"
                                    />
                                )}
                                name="socialClass.classB"
                                control={control}
                                defaultValue={program?.socialClass?.classB || false}
                            />
                            <Controller
                                render={({ onChange, onBlur, value, ref }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onBlur={onBlur}
                                                onChange={event => onChange(event.target.checked)}
                                                checked={value}
                                                inputRef={ref}
                                            />
                                        }
                                        label="C"
                                    />
                                )}
                                name="socialClass.classC"
                                control={control}
                                defaultValue={program?.socialClass?.classC || false}
                            />
                            <Controller
                                render={({ onChange, onBlur, value, ref }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onBlur={onBlur}
                                                onChange={event => onChange(event.target.checked)}
                                                checked={value}
                                                inputRef={ref}
                                            />
                                        }
                                        label="D"
                                    />
                                )}
                                name="socialClass.classD"
                                control={control}
                                defaultValue={program?.socialClass?.classD || false}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.buttons}>
                <Button onClick={handleBack}>Voltar</Button>
                <Button type="submit" variant="contained" color="primary">
                    Próximo
                </Button>
            </div>
        </form>
    );
}

export default Audience;
