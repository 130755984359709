import { auth, firestore } from 'base';

// Actions
const NEW_COMPANY = 'company/NEW_COMPANY';
const GET_COMPANIES = 'company/GET_COMPANIES';
const GET_COMPANY_BY_ID = 'company/GET_COMPANY_BY_ID';
const GET_COMPANIES_BY_IDS = 'company/GET_COMPANIES_BY_IDS';
const GET_COMPANIES_BY_STATE = 'company/GET_COMPANIES_BY_STATE';
const COMPANY_ERROR = 'company/COMPANY_ERROR';
const GET_COMPANY_BY_USER_ID = 'GET_COMPANY_BY_USER_ID';

// Actions creator
export const getCompanyByUserId = userId => async dispatch => {
    const company = await firestore.collection('company').where('ownerIds', 'array-contains', userId).get();

    return dispatch({
        type: GET_COMPANY_BY_USER_ID,
        payload: {
            id: company?.docs[0]?.id,
            ...company?.docs[0]?.data(),
        },
    });
};

export const getCompanyByOwnerId = ownerId => async dispatch => {
    const company = await firestore.collection('company').where('ownerId', '==', ownerId).get();

    return dispatch({
        type: GET_COMPANY_BY_USER_ID,
        payload: {
            id: company?.docs[0]?.id,
            ...company?.docs[0]?.data(),
        },
    });
};

export const getCompanies = () => async dispatch => {
    let firestoreCompanies = [];
    await firestore
        .collection('company')
        .get()
        .then(snapshot => snapshot.forEach(document_ => firestoreCompanies.push(document_.data())));

    return dispatch({
        type: 'GET_COMPANIES',
        payload: firestoreCompanies,
    });
};

export const getCompaniesByIds = arrayIds => async dispatch => {
    let arrayCompanies = [];

    for (const arrayId of arrayIds) {
        const company = await firestore
            .collection('company')
            .doc(arrayId)
            .get()
            .then(document_ => document_.data());
        arrayCompanies.push({ id: arrayId, ...company });
    }

    return dispatch({
        type: GET_COMPANIES_BY_IDS,
        payload: arrayCompanies,
    });
};

export const getCompaniesByState = state => async dispatch => {
    let arrayCompanies = [];

    await firestore
        .collection('company')
        .where('companyState', '==', state)
        .get()
        .then(snapshot => snapshot.forEach(document_ => arrayCompanies.push(document_.data())));

    return dispatch({
        type: 'GET_COMPANIES_BY_STATE',
        payload: arrayCompanies,
    });
};

export const newCompany = (data, callback) => async dispatch => {
    auth.createUserWithEmailAndPassword(data.userEmail, data.password)
        .then(response => {
            firestore.collection('company').doc(response.user.uid).set({
                nameCompany: data.companyName,
                phone: data.companyPhone,
                emailCompany: data.emailCompany,
                cnpj: data.cnpj,
                address: data.address,
                imageUrl: data.imageUrl,
                types: data.types,
                state: data.state,
            });
            firestore.collection('users').doc(response.user.uid).set({
                company: response.user.uid,
                cnpj: data.cnpj,
                name: data.userName,
                email: data.userEmail,
                role: 'supplier',
                active: false,
            });
        })
        .catch(error => {
            dispatch({
                type: 'COMPANY_ERROR',
                payload: error.message,
            });
            callback();
        });
};

const initialState = {
    companies: [],
    company: {},
    userCompany: {},
    err: '',
};

// Reducer
export default function (state = initialState, action) {
    switch (action.type) {
        case NEW_COMPANY:
            return {
                ...state,
                // company: action.payload
            };
        case GET_COMPANIES:
            return {
                ...state,
                companies: action.payload,
            };
        case GET_COMPANY_BY_ID:
            return {
                ...state,
                company: action.payload,
            };
        case GET_COMPANIES_BY_IDS:
            return {
                ...state,
                companies: action.payload,
            };
        case GET_COMPANIES_BY_STATE:
            return {
                ...state,
                companies: action.payload,
            };
        case COMPANY_ERROR:
            return {
                ...state,
                err: action.payload,
            };
        case GET_COMPANY_BY_USER_ID: {
            return {
                ...state,
                company: action.payload,
            };
        }
        default:
            return state;
    }
}
