/* eslint-disable unicorn/prefer-add-event-listener */
import React from 'react';

import { Container } from './styled';

function UploadInput({ icon: Icon, label, handleFileChange }) {
    return (
        <Container htmlFor="upload">
            {Icon && <Icon size={20} />}
            {label}
            <input type="file" id="upload" onChange={handleFileChange} accept="image/*" />
        </Container>
    );
}

export default UploadInput;
