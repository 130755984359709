import React from 'react';
import * as PropTypes from 'prop-types';
import { getColor } from './utils';

const PernambucoPathIcon = ({ active = false, available = false }) => {
    const color = getColor(active, available);
    return (
        <path
            fill={color}
            stroke="#FFFFFF"
            strokeWidth="1.0404"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M373.011,167.238
        c2.709-0.795,6.218-14.106,8.325-15.106c4.136-1.986,17.255-1.437,17.8,4.903c-0.437-0.068,8.189-2.273,7.479-1.466
        c1.7-0.711,10.518-4.723,12.599-4.82c0.274-0.013,4.603,0.905,3.068,2.315c-0.464,0.439,4.219,3.698,10.789,3.45
        c4.66-0.176,5.179-3.436,8.627-4.409c5.89-1.67,4.737,3.698,5.589,6.943c-1.182,2.684-1.646,5.586-2.74,8.285
        c-1.533,3.792-9.804,9.791-13.39,12.119c-7.287,4.778-21.802-4.067-22.762-5.67c-0.602-0.985-2.55-5.121-3.178-5.107
        c-0.629,0.356-1.04,0.861-1.287,1.519c-0.904-0.013-7.256-3.533-7.502-4.655c-4.769-1.151-5.425,6.108-8.957,6.19
        c0.219,0.108-8.244,6.681-7.506,3.314C383.556,170.4,374.241,168.566,373.011,167.238z"
        />
    );
};

PernambucoPathIcon.propTypes = { active: PropTypes.bool };

export default PernambucoPathIcon;
