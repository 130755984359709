import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

function ClaqueteIcon({ className, color, message: Message }) {
    const [showMessage, setShowMessage] = useState(false);

    const toggleMessage = () => setShowMessage(state => !state);

    return (
        <div className="tw-relative tw-cursor-pointer">
            {showMessage && Message && (
                <OutsideClickHandler onOutsideClick={toggleMessage}>
                    <Message />
                </OutsideClickHandler>
            )}
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                onClick={toggleMessage}
            >
                <g clipPath="url(#clip0)">
                    <path
                        d="M10.0021 20.0042C15.5261 20.0042 20.0042 15.5261 20.0042 10.0021C20.0042 4.4781 15.5261 0 10.0021 0C4.4781 0 0 4.4781 0 10.0021C0 15.5261 4.4781 20.0042 10.0021 20.0042Z"
                        fill={color || '#D8D8D8'}
                    />
                    <g clipPath="url(#clip1)">
                        <path
                            d="M4.45027 10.415V13.8538C4.45027 14.4861 4.96471 15 5.59651 15H13.8495C14.4818 15 14.9957 14.486 14.9957 13.8538V10.415H4.45027Z"
                            fill="white"
                        />
                        <path
                            d="M9.43228 5.04785L7.71657 5.455L9.15396 7.57006L11.0123 7.14733L9.43228 5.04785Z"
                            fill="white"
                        />
                        <path
                            d="M7.23973 5.56836L5.39059 6.00667L6.81286 8.10202L8.6739 7.67882L7.23973 5.56836Z"
                            fill="white"
                        />
                        <path
                            d="M14.9884 6.0006L14.5982 4.52057C14.5083 4.16064 14.1397 3.93416 13.7761 4.01714L12.2355 4.38302L13.6972 6.53797L14.8173 6.28305C14.8783 6.2693 14.9306 6.23123 14.9627 6.17806C14.9948 6.12489 15.004 6.06112 14.9884 6.0006Z"
                            fill="white"
                        />
                        <path
                            d="M11.7582 4.49609L9.91919 4.93258L11.5019 7.03663L13.2172 6.64644L11.7582 4.49609Z"
                            fill="white"
                        />
                        <path d="M6.7606 8.12305L6.02701 9.95658H7.8257L8.55929 8.12305H6.7606Z" fill="white" />
                        <path d="M9.05309 8.12305L8.3195 9.95658H10.1182L10.8518 8.12305H9.05309Z" fill="white" />
                        <path
                            d="M14.7664 8.12305H13.6381L12.9045 9.95703H14.9957V8.35228C14.9956 8.22528 14.8934 8.12305 14.7664 8.12305Z"
                            fill="white"
                        />
                        <path d="M11.3456 8.12305L10.612 9.95658H12.4103L13.1443 8.12305H11.3456Z" fill="white" />
                        <path
                            d="M4.91379 6.12012L4.54011 6.2086C4.35809 6.24987 4.20404 6.36036 4.10638 6.51901C4.00872 6.67812 3.97983 6.86518 4.02522 7.04628L4.45025 8.72621V9.9559H5.53321L6.22141 8.23608L6.33328 8.21087L4.91379 6.12012Z"
                            fill="white"
                        />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                    <clipPath id="clip1">
                        <rect width="11" height="11" fill="white" transform="translate(4 4)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

export default ClaqueteIcon;
