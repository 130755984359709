import React from 'react';

const PlayIcon = ({ ...rest }) => (
    <svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <ellipse cx="23.9785" cy="23.2209" rx="23.6237" ry="23.2209" fill="#4F4F4F" />
        <path
            d="M31.5687 21.9153L19.5714 13.842C19.0174 13.4689 18.4591 13.2715 17.9951 13.2715C17.098 13.2715 16.5431 13.9792 16.5431 15.1638V32.6124C16.5431 33.7957 17.0973 34.502 17.9923 34.502C18.457 34.502 19.0064 34.3044 19.5616 33.9302L31.5645 25.8571C32.3364 25.3371 32.7639 24.6373 32.7639 23.8858C32.7641 23.1348 32.3415 22.4352 31.5687 21.9153Z"
            fill="white"
        />
    </svg>
);

export default PlayIcon;
