import React from 'react';
import useCurrentCampaignAnalytics from './useCurrentCampaignAnalytics';

export const STEP_KEYS = [
    'localization_step',
    'objectives_and_duration_step',
    'budget_step',
    'target_and_segmentation_step',
    'media_central_step',
    'media_plan_step',
    'overview_step',
];

const [firstStep] = STEP_KEYS;

export const AnalyticsContext = React.createContext();

export const AnalyticsProvider = ({ children }) => {
    const analyticsMethods = useCurrentCampaignAnalytics(firstStep);

    return <AnalyticsContext.Provider value={analyticsMethods}>{children}</AnalyticsContext.Provider>;
};
