import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { signOut } from 'store/modules/auth';
import UserIconSmall from 'icons/UserIconSmall';
import MenuAltIcon from 'icons/MenuAltIcon';
import LogoutIcon from 'icons/LogoutIcon';
import CampaignIcon from 'icons/CampaignIcon';
import DolarIcon from 'icons/DolarIcon';
import SupplierDashboardIcon from 'icons/SupplierDashboardIcon';
import MenuItem from 'components/Menu/MenuItem';
import styleConfig from '../../style_config.json';

const rootStyle = { zIndex: 999 };

const SupplierMenu = ({ mobile, onMouseLeave }) => {
    const [isHover, setIsHover] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    function handleLogout(event) {
        event.preventDefault();
        dispatch(signOut(() => history.push('/')));
    }
    function handleMouseEnter(event) {
        event.preventDefault();
        setIsHover(true);
    }

    function handleMouseLeave(event) {
        if (event) {
            event.preventDefault();
        }
        onMouseLeave();
        setIsHover(false);
    }

    const rootClass = clsx(
        'tw-bg-purple',
        'tw-fixed',
        'tw-bottom-0',
        {
            'tw-w-11/12 tw-max-w-xs': mobile,
            'tw-hidden tw-w-28 hover:tw-w-96 sm:tw-flex': !mobile,
        },
        'tw-top-0',
        'tw-flex tw-flex-col tw-content-between tw-place-content-between',
        {
            'menu-active': isHover,
        },
    );

    return (
        <div className={rootClass} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={rootStyle}>
            <div className="tw-flex tw-items-center tw-place-content-between tw-align-middle tw-justify-center tw-content-center tw-bg-transparent sm:tw-bg-pink tw-h-24">
                {isHover || mobile ? (
                    <img
                        src={styleConfig['logo-horizontal.svg']}
                        alt="Logo horizontal"
                        className="tw-w-full tw-px-10 tw-py-10 sm:tw-px-20 sm:tw-py-10 tw-inline tw-fill-current"
                    />
                ) : (
                    <img
                        src={styleConfig['logo-only.svg']}
                        alt="Logo only"
                        className="tw-w-full tw-h-24 tw-max-w-max tw-px-6 tw-py-4 tw-inline tw-fill-current"
                    />
                )}
            </div>

            <div>
                <div className="tw-flex tw-flex-col tw-place-content-stretch tw-justify-evenly tw-align-middle tw-place-items-stretch tw-items-center tw-flex-grow tw-pt-6">
                    <MenuItem
                        to="/"
                        handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                        title={(isHover || mobile) && 'Dashboard'}
                        icon={<SupplierDashboardIcon className="tw-w-7 tw-h-9 tw-inline" />}
                    />
                    <MenuItem
                        to="/perfil"
                        handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                        title={(isHover || mobile) && 'Meu perfil'}
                        icon={<UserIconSmall className="tw-w-7 tw-h-9 tw-inline" />}
                    />
                    <MenuItem
                        to="/campanhas"
                        handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                        title={(isHover || mobile) && 'Campanhas'}
                        icon={<CampaignIcon className="tw-w-7 tw-h-9 tw-inline" />}
                    />
                    <MenuItem
                        to="/programas"
                        handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                        title={(isHover || mobile) && 'Programas'}
                        icon={<MenuAltIcon className="tw-w-7 tw-h-9 tw-inline" />}
                    />
                    <MenuItem
                        to="/comercial"
                        handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                        title={(isHover || mobile) && 'Área Comercial'}
                        icon={<DolarIcon className="tw-w-7 tw-h-9 tw-inline" />}
                    />
                </div>
            </div>
            <div className="tw-flex tw-flex-col tw-place-content-evenly tw-justify-evenly tw-items-center tw-mb-2">
                <MenuItem
                    handleMouseLeave={() => (mobile ? handleMouseLeave() : null)}
                    title={(isHover || mobile) && 'Sair'}
                    icon={<LogoutIcon className="tw-w-7 tw-h-7 tw-inline" />}
                    hover={isHover}
                    onClick={handleLogout}
                />
            </div>
        </div>
    );
};

export default SupplierMenu;
