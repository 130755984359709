import React, { useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import ReactPlayer from 'react-player/lazy';
import { Controller } from 'react-hook-form';
import { useProgram } from 'hooks/program';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputRange from 'react-input-range';
import { DevTool } from '@hookform/devtools';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { firebase } from 'base';
import 'react-input-range/lib/css/index.css';
import { Creators as ActionCreators } from 'store/modules/regions';
import { uploadProgramImage } from 'api/programs';
import Select from 'molecules/Select';
import InputField from 'molecules/InputField';
import InputFieldCurrencyFieldV2 from 'molecules/InputField/InputCurrencyFieldV2';
import { useUserCompany } from 'hooks/company';
import { useRegions } from 'hooks/regions';
import useBaseInfoForm from './useBaseInfoForm';
import UploadIcon from '../../../icons/UploadIcon';

const DEFAULT_CLASS_NAMES = {
    activeTrack: 'input-range__track input-range__track--active',
    disabledInputRange: 'input-range input-range--disabled',
    inputRange: 'input-range',
    labelContainer: 'input-range__label-container',
    maxLabel: 'input-range__label input-range__label--max',
    minLabel: 'input-range__label input-range__label--min',
    slider: 'input-range__slider',
    sliderContainer: 'input-range__slider-container',
    track: 'input-range__track input-range__track--background',
    valueLabel: 'input-range__label input-range__label--value',
};

const ageRangeClassName = {
    ...DEFAULT_CLASS_NAMES,
    activeTrack: `${DEFAULT_CLASS_NAMES.activeTrack} tw-bg-pink`,
    slider: `${DEFAULT_CLASS_NAMES.slider} tw-bg-purple`,
};

function useUploadFile(setBanners) {
    const [tasksMap, setTasksMap] = useState(new Map());

    const uploadFile = file => {
        const fileHash = v4();
        const onUploadNext = snapshot => {
            const percentCompleted = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            const fileTaskInfo = tasksMap.get(fileHash);
            setTasksMap(
                new Map(
                    tasksMap.set(fileHash, {
                        ...fileTaskInfo,
                        percentCompleted,
                    }),
                ),
            );
        };
        const onUploadError = error => {
            if (error) {
                toast.error('Falha ao adicionar imagem!');
            }
            const fileTaskInfo = tasksMap.get(fileHash);
            setTasksMap(
                new Map(
                    tasksMap.set(fileHash, {
                        ...fileTaskInfo,
                        error,
                    }),
                ),
            );
        };
        const onUploadCompleted = () => {
            const fileTaskInfo = tasksMap.get(fileHash);
            fileTaskInfo.uploadTask
                .then(imageReference => imageReference.ref.getDownloadURL())
                .then(downloadUrl => {
                    setTasksMap(
                        new Map(
                            tasksMap.set(fileHash, {
                                ...fileTaskInfo,
                                downloadUrl,
                            }),
                        ),
                    );
                    if (downloadUrl) {
                        setBanners(current => [...current, { id: v4(), image: downloadUrl, isMain: false }]);
                    }
                });
        };

        const uploadTask = uploadProgramImage(file);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
            next: onUploadNext,
            error: onUploadError,
            complete: onUploadCompleted,
        });
        tasksMap.set(fileHash, {
            uploadTask: uploadTask,
        });
    };

    return { tasksMap, uploadFile };
}

const BaseInfo = ({ handleNext }) => {
    const [banners, setBanners] = useState([]);
    const [teaserUrl, setTeaserUrl] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();
    const program = useProgram();
    const company = useUserCompany();
    const regions = useRegions();
    const { uploadFile } = useUploadFile(setBanners);
    const { control, register, setValue, onSubmit, formState, watch } = useBaseInfoForm(banners, program, handleNext);
    const { isSubmitting, errors } = formState;

    const ageRange = watch('ageRange');

    const handleChange = async event => {
        for (const file of event.target.files) {
            uploadFile(file);
        }
    };

    const setMainBanner = id =>
        setBanners(current =>
            current.map(banner => ({
                ...banner,
                isMain: banner.id === id,
            })),
        );

    useEffect(() => {
        for (const name of Object.keys(errors)) {
            toast.error(errors[name].message);
        }
    }, [errors]);

    useEffect(() => {
        if (program?.programState) {
            setValue('programState', program.programState);
        }
    }, [program, setValue]);

    useEffect(() => {
        if (program?.banners) {
            setBanners([...program.banners]);
        }
    }, [program?.banners]);

    useEffect(() => {
        if (program?.teaserUrl) {
            setTeaserUrl(program.teaserUrl);
        }
    }, [program?.teaserUrl]);

    useEffect(() => {
        dispatch(ActionCreators.getRegions(company.id));
    }, [dispatch, company]);

    const ageRangeDefaultValue = useMemo(
        () => ({
            min: program?.ageRange?.split(',').map(item => Number(item))[0] || 0,
            max: program?.ageRange?.split(',').map(item => Number(item))[1] || 100,
        }),
        [program?.ageRange],
    );

    function handleBackButton(event) {
        event.preventDefault();
        history.push('/programas');
    }

    return (
        <div className="tw-w-full">
            <h2>Cadastro de programas</h2>
            <form onSubmit={onSubmit}>
                <div className="tw-flex tw-flex-col tw-my-5">
                    <label className="tw-block tw-text-gray-700 tw-my-3 tw-text-sm">
                        {`Faixa etária do seu público (${ageRange?.min || 0} a ${ageRange?.max || 100} anos)`}
                    </label>
                    <Controller
                        as={InputRange}
                        name="ageRange"
                        minValue={0}
                        maxValue={100}
                        formatLabel={() => ''}
                        classNames={ageRangeClassName}
                        defaultValue={ageRangeDefaultValue}
                        control={control}
                    />
                </div>
                <div className="tw-flex tw-flex-col md:tw-flex-row">
                    <InputField
                        name="programName"
                        ref={register}
                        defaultValue={program.programName || ''}
                        label="Nome do Programa"
                        className="tw-mt-6 tw-w-full md:tw-w-2/5 md:tw-mx-3"
                        inputClassName="tw-w-full tw-border-gray-500"
                    />
                    <InputField
                        name="availableSeconds"
                        type="number"
                        ref={register}
                        defaultValue={program.availableSeconds || ''}
                        label="Segundos Disponíveis"
                        className="tw-mt-6 tw-w-full md:tw-w-1/5 md:tw-mx-3"
                        inputClassName="tw-w-full tw-border-gray-500"
                    />
                    <InputFieldCurrencyFieldV2
                        name="insertionCost"
                        control={control}
                        defaultValue={program.insertionCost || ''}
                        label="Valor de 30s"
                        className="tw-mt-6 tw-w-full md:tw-w-1/5 md:tw-mx-3"
                        inputClassName="tw-w-full tw-border-gray-500"
                    />
                    <InputField
                        name="insertionDiscount"
                        type="number"
                        ref={register}
                        defaultValue={program.insertionDiscount || ''}
                        label="Desconto (%)"
                        className="tw-mt-6 tw-w-full md:tw-w-1/5 md:tw-mx-3"
                        inputClassName="tw-w-full tw-border-gray-500"
                    />
                </div>
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-my-5">
                    <Select
                        name="programRegionId"
                        label="Região em que será exibido"
                        className="tw-w-full md:tw-w-1/3 md:tw-mx-3"
                        defaultValue={program?.programRegionId}
                        ref={register}
                        error={errors.programRegionId}
                    >
                        {regions?.map(element => (
                            <option key={element.id} value={element.id}>
                                {element.regionName}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between tw-items-baseline">
                    <div className="tw-flex tw-flex-col tw-w-full md:tw-w-1/2">
                        <label htmlFor="logo" className="tw-cursor-pointer">
                            <span className="tw-block tw-mb-3">Adicione imagens que representam o programa</span>
                            <input
                                id="logo"
                                type="file"
                                multiple
                                accept="image/x-png,image/gif,image/jpeg"
                                className="tw-hidden"
                                onChange={event => handleChange(event)}
                            />
                            <div className="tw-flex tw-max-w-xs tw-items-center tw-justify-start tw-rounded-xl tw-bg-gray-200">
                                <UploadIcon />
                                <span className="tw-block tw-ml-3 tw-text-gray-400 tw-text-sm">
                                    Selecione seu arquivo
                                </span>
                            </div>
                        </label>
                        {banners.length > 0 && (
                            <>
                                <span className="tw-text-gray-600 tw-text-xs tw-my-3">
                                    Clique na imagem para selecioná-la como imagem principal do programa
                                </span>
                                <div className="tw-flex tw-items-center tw-mt-4 tw-flex-wrap">
                                    {banners.map(({ id, image, isMain }) => (
                                        <div className="tw-relative" key={id}>
                                            <img
                                                src={image}
                                                alt="image"
                                                width="217"
                                                onClick={() => setMainBanner(id)}
                                                height="152"
                                                className="tw-mr-3 tw-mb-3 tw-rounded-md tw-cursor-pointer tw-border hover:tw-border-pink"
                                            />
                                            {isMain && (
                                                <FaCheckCircle
                                                    className="tw-right-5 tw-bottom-5 tw-font-semibold tw-text-pink tw-mt-0 tw-absolute"
                                                    size={30}
                                                />
                                            )}
                                            <button
                                                className="tw-border-none tw-font-bold tw-bg-transparent tw-cursor-pointer tw-text-red tw-ml-5 tw-absolute tw-top-3 tw-right-7"
                                                type="button"
                                                onClick={() =>
                                                    setBanners(current => current.filter(item => item.id !== id))
                                                }
                                            >
                                                <FaTimes size={18} className="tw-text-pink" />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        <div className="tw-flex tw-flex-col tw-mt-5">
                            <span className="tw-block tw-mb-3">Informações do programa</span>
                            <textarea
                                name="programDescription"
                                ref={register}
                                defaultValue={program.programDescription || ''}
                                className="tw-max-w-md tw-p-3 tw-w-full tw-bg-transparent tw-border-b-2 tw-border-t-0 tw-border-r-0 tw-border-l-0"
                            />
                            <span className="tw-text-gray-400 tw-text-sm">
                                Informe aqui as principais informações do seu programa
                            </span>
                        </div>
                    </div>
                    <div className="tw-flex tw-flex-col tw-w-full md:tw-w-1/2">
                        <InputField
                            name="teaserUrl"
                            ref={register}
                            defaultValue={program.teaserUrl || ''}
                            onChange={event => setTeaserUrl(event.target.value)}
                            label="Adicione o URL do vídeo teaser do programa"
                            className="tw-mt-6 md:tw-ml-6 md:tw-mx-3"
                            inputClassName="tw-w-full tw-border-gray-500"
                        />
                        {teaserUrl && (
                            <div className="tw-flex tw-justify-center tw-mt-3">
                                <ReactPlayer url={teaserUrl} controls width="430px" height="320px" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="tw-mt-10 tw-text-center tw-mb-4 md:tw-mt-24">
                    <button
                        className="button button-primary-outlined tw-mr-4 tw-w-full md:tw-w-1/6"
                        onClick={handleBackButton}
                        type="button"
                        disabled={isSubmitting}
                    >
                        Voltar
                    </button>
                    <button
                        className="button button-primary tw-border-2 tw-border-pink md:tw-mt-0 tw-mt-3 tw-w-full md:tw-w-1/6"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Prosseguir
                    </button>
                </div>
                {__DEVELOPMENT__ && <DevTool control={control} />}
            </form>
        </div>
    );
};

export default BaseInfo;
