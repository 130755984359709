import React from 'react';

function Three({ className }) {
    return (
        <svg
            width="52"
            height="73"
            viewBox="0 0 52 73"
            fill="nTree"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M1.32 23.808C1.512 16.192 3.72 10.368 7.944 6.336C12.232 2.304 18.28 0.287996 26.088 0.287996C31.144 0.287996 35.464 1.152 39.048 2.88C42.632 4.608 45.32 6.944 47.112 9.888C48.968 12.832 49.896 16.16 49.896 19.872C49.896 24.352 48.84 27.872 46.728 30.432C44.616 32.928 42.248 34.624 39.624 35.52V35.904C47.304 38.784 51.144 44.128 51.144 51.936C51.144 56.032 50.184 59.648 48.264 62.784C46.344 65.92 43.592 68.352 40.008 70.08C36.488 71.808 32.296 72.672 27.432 72.672C19.112 72.672 12.584 70.688 7.848 66.72C3.112 62.752 0.68 56.544 0.552 48.096H21.288C21.16 50.4 21.48 52.16 22.248 53.376C23.08 54.528 24.488 55.104 26.472 55.104C27.624 55.104 28.584 54.688 29.352 53.856C30.12 52.96 30.504 51.808 30.504 50.4C30.504 48.608 29.896 47.264 28.68 46.368C27.528 45.408 25.544 44.928 22.728 44.928H19.176V27.744H22.632C26.984 28 29.16 26.272 29.16 22.56C29.16 20.96 28.808 19.776 28.104 19.008C27.464 18.24 26.632 17.856 25.608 17.856C23.24 17.856 22.056 19.84 22.056 23.808H1.32Z"
                fill="#7DC5AF"
                fillOpacity="0.15"
            />
        </svg>
    );
}

export default Three;
