import { useUserId } from 'hooks/user';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Creators as ActionsCreators } from 'store/modules/creative';
import { useCreative } from 'hooks/creative';

function creativeByDurationAndKind(creative, campaign) {
    const creativeDuration = Number(creative.durationTime);
    const campaignDuration = Number(campaign.objectivesAndDuration?.duration);
    return creativeDuration === campaignDuration && campaign.kind === creative.creativeType;
}

export function useCreativesForCampaign(campaign) {
    const userId = useUserId();
    const dispatch = useDispatch();

    useEffect(() => {
        if (userId) {
            dispatch(ActionsCreators.getCreatives(userId));
        }
    }, [dispatch, userId]);

    const allPayiedCreatives = useCreative().filter(c => c.payment);

    return allPayiedCreatives.filter(creative => creativeByDurationAndKind(creative, campaign));
}
