import { cleanCnpj } from 'utils';

export const CAMPAIGN_STATUS = {
    pendingPayment: 'PENDING_PAYMENT',
    pendingApproveInsertions: 'PENDING_APPROVE_INSERTIONS',
    pendingCreative: 'PENDING_CREATIVE',
    pendingApproveCreative: 'PENDING_APPROVE_CREATIVE',
    approved: 'APPROVED',
    refused: 'REFUSED',
    finished: 'FINISHED',
};
export const PENDING_CAMPAIGN_STATUS = new Set([
    CAMPAIGN_STATUS.pendingApproveCreative,
    CAMPAIGN_STATUS.pendingApproveInsertions,
    CAMPAIGN_STATUS.pendingCreative,
    CAMPAIGN_STATUS.pendingPayment,
]);
export const APPROVED_CAMPAIGN_STATUS = new Set([CAMPAIGN_STATUS.approved]);
export const FINISHED_CAMPAIGN_STATUS = new Set([CAMPAIGN_STATUS.finished, CAMPAIGN_STATUS.refused]);

export const getInsertionValue = (duration, insertionValue, budgetType) => {
    if (budgetType === 'automatic') return insertionValue;
    switch (duration) {
        case 10:
            return Number(insertionValue) * 0.5;
        case 15:
            return Number(insertionValue) * 0.5;
        case 45:
            return Number(insertionValue) * 1.5;
        case 60:
            return Number(insertionValue) * 2;
        default:
            return Number(insertionValue);
    }
};

export const getProgramInsertionValue = (companies, program, duration, userCNPJ, budgetType) => {
    if (budgetType === 'automatic' && program.insertionValue) {
        return program.insertionValue;
    }

    let insertionValue = program.insertionValue || program.insertionCost;

    const discountsByCnpj = companies.map(({ id, discounts }) => ({
        companyId: id,
        discounts,
    }));

    const cleanedUserCNPJ = cleanCnpj(userCNPJ);
    const hasUserDiscount = item => cleanedUserCNPJ === cleanCnpj(item.cnpj);
    const foundDiscount = discountsByCnpj.find(
        discountByCnpj =>
            discountByCnpj.discounts?.find(item => hasUserDiscount(item)) &&
            discountByCnpj.companyId === program.companyId,
    );

    const insertionDiscount = Number(program.insertionDiscount || 0);

    if (foundDiscount) {
        const discountItem = foundDiscount.discounts[0];
        insertionValue = program.insertionCost - program.insertionCost * (discountItem.discount / 100);
    } else if (insertionDiscount > 0 && insertionDiscount <= 100) {
        insertionValue = program.insertionCost - program.insertionCost * (insertionDiscount / 100);
    }

    return getInsertionValue(Number(duration), insertionValue, budgetType);
};

export const getInsertionsCount = program => {
    let count = 0;
    for (let day of program.showDays) {
        count += day.insertions || 0;
    }
    return count;
};

export const totalInsertionsForPrograms = (programs, packagePrograms) => {
    if (packagePrograms) {
        return packagePrograms.reduce((sum, program) => (sum += program.insertionQuantity), 0);
    }
    let total = 0;
    for (let program of programs) {
        total += getInsertionsCount(program);
    }
    return total;
};

export const grantTotalForProgram = (insertions, program, companies, duration, userCNPJ, budgetType) => {
    let insertionValue = program.insertionValue;
    if (!insertionValue) {
        insertionValue = getProgramInsertionValue(companies, program, duration, userCNPJ, budgetType);
    }
    return insertionValue * insertions;
};

export const grantTotalForPrograms = (programs, companies, duration, userCNPJ, budgetType) => {
    let total = 0;
    for (let program of programs) {
        const insertions = getInsertionsCount(program);
        total += grantTotalForProgram(insertions, program, companies, duration, userCNPJ, budgetType);
    }
    return total;
};

export const getTotalImpactOfPrograms = programs => {
    let total = 0;
    for (let program of programs) {
        const insertionsForProgram = getInsertionsCount(program);
        total += insertionsForProgram * (program.impact || 0);
    }
    return total;
};
