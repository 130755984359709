import React, { useMemo, useReducer } from 'react';
import CalculationContext from './CalculationContext';
import CalculationReducer from './CalculationReducer';

const initialState = {
    transactions: [],
};

const CalculationState = ({ children }) => {
    const [state, dispatch] = useReducer(CalculationReducer, initialState);

    // eslint-disable-next-line no-unused-vars
    const addTransaction = transaction => {
        dispatch({
            type: 'ADD_TRANSACTION',
            payload: transaction,
        });
    };

    // eslint-disable-next-line no-unused-vars
    const deleteTransaction = id => {
        dispatch({
            type: 'DELETE_TRANSACTION',
            payload: id,
        });
    };

    // eslint-disable-next-line no-unused-vars
    const updateTransaction = (newTransaction, index) => {
        dispatch({
            type: 'UPDATE_TRANSACTION_LABEL',
            payload: { newTransaction, index },
        });
    };

    // eslint-disable-next-line no-unused-vars
    const updatePrograms = (id, programs) => {
        dispatch({
            type: 'UPDATE_PROGRAMS',
            payload: { id, programs },
        });
    };

    const calculationValue = useMemo(
        () => ({
            transactions: state.transactions,
            addTransaction,
            deleteTransaction,
            updateTransaction,
            updatePrograms,
        }),
        [state.transactions],
    );
    return <CalculationContext.Provider value={calculationValue}>{children}</CalculationContext.Provider>;
};

export default CalculationState;
