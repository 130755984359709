import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import scrollTop from 'utils/scrollTop';
import GenderMaleIcon from 'assets/icons/gender_male.png';
import GenderFemaleIcon from 'assets/icons/gender_female.png';
import GenderAllIcon from 'assets/icons/gender_all.png';
import Section from './Section';

const GenderButton = ({ icon, text, active, value, className = '' }) => {
    const { setValue } = useFormContext();

    function handleGenderClick(event) {
        event.preventDefault();
        setValue('gender', value, { shouldDirty: true, shouldValidate: true });
    }

    return (
        <button
            className={clsx('icon-card tw-h-28 tw-w-28 tw-px-5 tw-py-4', className, {
                'icon-card__selected': active,
            })}
            type="button"
            onClick={handleGenderClick}
        >
            <div>
                <img src={icon} alt={text} />
            </div>
            <div>
                <span className="tw-text-sm tw-mt-2">{text}</span>
            </div>
        </button>
    );
};

const GenderSelector = () => {
    const { formState, register, unregister, watch } = useFormContext();
    const { errors } = formState;

    useEffect(() => {
        register('gender');
        return () => {
            unregister('gender');
        };
    }, [register, unregister]);

    const selectedGender = watch('gender');

    useEffect(() => {
        if (Object.keys(errors)?.find(key => key === 'gender')) {
            scrollTop();
        }
    }, [errors]);

    return (
        <Section title="O Seu negócio é voltado para:" error={errors.gender}>
            <div className="tw-flex tw-justify-start tw-align-middle tw-place-items-center tw-text-pink tw-flex-wrap">
                <div className="tw-mr-2">
                    <GenderButton
                        text="Homem"
                        value="male"
                        active={selectedGender === 'male'}
                        icon={GenderMaleIcon}
                        className="tw-mr-4 tw-mt-6"
                    />
                </div>
                <div className="tw-mr-2">
                    <GenderButton
                        text="Mulher"
                        value="female"
                        active={selectedGender === 'female'}
                        icon={GenderFemaleIcon}
                        className="tw-mr-4 tw-mt-6"
                    />
                </div>
                <div className="tw-mr-2">
                    <GenderButton
                        text="Todos"
                        value="other"
                        active={selectedGender === 'other'}
                        icon={GenderAllIcon}
                        className="tw-mt-6"
                    />
                </div>
            </div>
        </Section>
    );
};

export default GenderSelector;
