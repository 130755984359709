import React from 'react';
import { useFormContext } from 'react-hook-form';
import Select from 'molecules/Select';
import Section from './Section';
import bestTimes from './bestTimes.json';

const BestTimeSelector = () => {
    const { errors, register } = useFormContext();
    return (
        <Section title="Melhor Período/Horários*">
            <Select name="bestTime" ref={register()} error={errors.bestTime}>
                {bestTimes.map(besTime => (
                    <option key={besTime.value} value={besTime.value}>
                        {besTime.label}
                    </option>
                ))}
            </Select>
        </Section>
    );
};

export default BestTimeSelector;
